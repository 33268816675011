<template>
  <div class="overlay" v-if="this.modalOpen" @click="close()"></div>
  <div class="modal" v-if="this.modalOpen && this.loggedUser">
    <div class="current-user">
      <h1>{{ this.loggedUser.name }}</h1>
      <h3>{{ this.loggedUser.email }}</h3>
      <div v-if="organization" class="organization-number">
        <FlagPhone :phone="organization.number" />
        <h3>{{ this.organization.number }}</h3>
      </div>

      <div class="buttons">
        <router-link class="user_profile" to="/settings/profile" v-tooltip.bottom="config">
          <button v-if="loggedUser" @click="close()" class="button">
            <i class="fas fa-cog"></i>
            {{ $t('navbar.users.config') }}
          </button>
        </router-link>
        <button v-if="loggedUser" @click="onClickLogout()" class="button">
          <i class="fas fa-sign-out-alt"></i>
          {{ $t('navbar.users.logout') }}
        </button>
      </div>
    </div>
    <div style="overflow: auto; max-height: 400px;">
      <div class="users-div" :key="UserAPI.id" v-for="(UserAPI) in getUsers()">
        <a target="_blank" :href="'/user/change/' + getUserToken(UserAPI.id) ">
          <div v-if="loggedUser && loggedUser.name" class="avatar">{{ UserAPI.name.substring(0,1) }}</div>
          <div class="name">{{ UserAPI.name }}</div>
          <div class="email">{{ UserAPI.email }}</div>
          <div :key="userCounters" v-for="(userCounters, indexCounter) in loggedUserAPI" class="counters">
            <div>
              <div class="counters-line-all-chats" v-if="indexCounter === UserAPI.id">{{ $t('navbar.users.all_chats') }}: </div>
              <div class="counters-line-all-chats numbers" v-if="indexCounter === UserAPI.id && (userCounters.all_chats > 0) && (userCounters.all_chats <= 99)">{{userCounters.all_chats}}</div>
              <div v-if="indexCounter === UserAPI.id && (userCounters.all_chats === 0)" class="counters-line-all-chats numbers">0</div>
              <div v-if="indexCounter === UserAPI.id && (userCounters.all_chats > 99)" class="counters-line-all-chats numbers">+99</div>
            </div>
            <div>
              <div class="counters-line-my-chats" v-if="indexCounter === UserAPI.id">{{ $t('navbar.users.my_chats') }}: </div>
              <div v-if="indexCounter === UserAPI.id && (userCounters.my_chats>0) && (userCounters.my_chats<=99)" class="counters-line-my-chats numbers">{{userCounters.my_chats}}</div>
              <div v-if="indexCounter === UserAPI.id && (userCounters.my_chats === 0)" class="counters-line-my-chats numbers">0</div>
              <div v-if="indexCounter === UserAPI.id && (userCounters.my_chats > 99)" class="counters-line-my-chats numbers">+99</div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <button style="margin-top: 15px; align-items: center" @click="newLogin()" class="button"><i class="fas fa-plus"></i> {{ $t('navbar.users.new_login') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import FlagPhone from '@/components/common/FlagPhone'

export default {
  name: 'Users',
  components: { FlagPhone },
  computed: mapGetters(['loggedUserAPI', 'counters', 'loggedUser', 'alerts', 'organization', 'organizationUsers', 'searchResults']),
  data () {
    return {
      modalOpen: false,
      locale: 'es'
    }
  },
  methods: {
    ...mapActions(['fetchLoggedUserAPI', 'logout']),
    close () {
      this.modalOpen = false
    },
    newLogin () {
      window.open(process.env.VUE_APP_WEB_HOST_LOGIN, '_blank')
      this.modalOpen = false
    },
    getUserToken (user) {
      const sessionTokens = JSON.parse(localStorage.getItem('accessTokens'))
      for (const defaultUser in sessionTokens) {
        if (sessionTokens[defaultUser].id === user) {
          return defaultUser
        }
      }
    },
    onClickLogout () {
      this.logout()
    },
    open () {
      const localeCookie = Cookies.get('ma-locale')
      if (localeCookie) {
        this.locale = localeCookie
      }

      if (!this.locale) {
        if (navigator.languages !== undefined) {
          this.locale = navigator.languages[0]
        }

        if (!this.locale) {
          this.locale = navigator.language
        }

        if (this.locale) {
          this.locale = this.locale.substr(0, 2)
        }
      }

      if (!this.locale) {
        this.locale = 'es'
      }

      this.modalOpen = true
      this.fetchLoggedUserAPI()
    },
    getUsers () {
      const users = []
      const usersTokens = localStorage.getItem('accessTokens')
      if (usersTokens) {
        const usersTokensParsed = JSON.parse(usersTokens)

        for (const userToken in usersTokensParsed) {
          if (this.loggedUser.id !== usersTokensParsed[userToken].id) {
            users.push(usersTokensParsed[userToken])
          }
        }
      }
      return users
    }
  },
  created () {
    this.emitter.on('openUsers', this.open)
    this.emitter.on('closeUsers', this.close)
  },
  unmounted () {
    this.emitter.off('openUsers')
    this.emitter.off('closeUsers')
  }
}
</script>

<style scoped>
.modal {
  position: absolute;
  top: 60px;
  right: 15px;
  left: unset;
  bottom: unset;
  margin: 0;
  height: fit-content;

  background-color: #E9EEF6;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px, rgba(0, 0, 0, 0.23) 0 3px 6px;
}

p {
  margin-top: 3px;
  margin-bottom: 3px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  position: relative;
  margin-top: 12px;
  width: 100%;
  top: 0;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.current-user {
  text-align: center;
  margin-bottom: 20px;
}

.current-user h1 {
  margin: 0;
  padding: 0;
  font-size: 20px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.current-user h3 {
  margin: 0;
  padding: 0;
  font-weight: 400;
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.current-user .buttons {
  display: inline-flex;
}

.current-user .buttons button {
  width: 190px;
  color: #4285f4;
  background: transparent;
}

.current-user .buttons button:hover {
  background: #d7e0ef;
}

.current-user .buttons button:first-of-type {
  margin-right: 5px;
}

.current-user .buttons button:last-of-type {
  margin-left: 5px;
}

.users-div {
  width: calc(100% - 20px);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 10px;
  position: relative;
  text-decoration: none;
  margin: 0 0 3px;
}

.users-div a {
  color: #111b21;
  text-decoration: none;
  cursor: pointer;
}

.users-div .name {
  position: absolute;
  left: 60px;
  top: 11px;
  font-weight: 500;
  width: calc(100% - 160px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.users-div .email {
  position: absolute;
  left: 60px;
  top: 30px;
  font-size: 12px;
  width: calc(100% - 160px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.users-div:hover {
  background-color: rgba(197, 217, 249, 0.37);
}

.users-div .counters {
  position: absolute;
  top: 12px;
  font-size: 12px;
  color: #4285f4;
}

.users-div .counters .counters-line-all-chats {
  display: inline-flex;
  margin-left: 270px;
}
.users-div .counters .counters-line-my-chats {
  display: inline-flex;
  margin-left: 270px;
  margin-top: 4px;
}
.users-div .counters .numbers {
  font-weight: bold;
  margin-left: 2px;
}

.avatar {
  height: 36px;
  border-radius: 100%;
  cursor: pointer;
  width: 36px;
  border: 1px solid #EDEFF1;
  line-height: 36px;
  text-align: center;
  font-size: 17px;
  background-color: #4285f45e;
  color: #4285f4;
  font-weight: bold;
}

.overlay {
  opacity: 1;
  background-color: rgba(32,33,36,0.0);
}

ul li:hover {
  background-color: #F1F3F4;
}

ul a {
  color: #111b21;
  text-decoration: none;
}

.organization-number {
  position: relative;
  display: inline-flex;
  margin-top: 15px;
}

</style>

<style>
.alert img {
  text-align: center;
}

.organization-number .phone-flag {
  margin-top: 1px;
  margin-right: 5px;
  position: relative;
  bottom: unset;
  left: unset;
  overflow: hidden;
}
</style>
