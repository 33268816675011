<template>
  <div class="settings-group">
    <h3>{{ $t('settings.apps.title') }}</h3>
    <div class="form-group">
      <a href="https://play.google.com/store/apps/details?id=com.manycontacts.manycontacts&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
        <img src="../../../../assets/gplay.png" height="60px" />
      </a>

      <a href="https://apps.apple.com/es/app/manycontacts/id1636561350" target="_blank">
        <img src="../../../../assets/itunes.svg" style="margin: 7px; height: 47px;" />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SectionMobileApps'
}
</script>

<style scoped>
</style>
