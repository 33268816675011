<template>
  <div class="settings-group">
    <h3>{{ $t('settings.widget.title') }}</h3>
    <div class="form-group">
      <p v-html="$t('settings.widget.description')"></p>
    </div>
<pre v-if="organization && organization.watype !== null"><code>&lt;!-- Start of ManyContacts.com Widget --&gt;
  &lt;script&gt;(function(d,s,a,b,c,p,m,x){
    var f=d.getElementsByTagName(s)[0],j=d.createElement(s);
    j.async=true;j.src='https://widget.manycontacts.com/widget.js?a='+a+'&b='+b+'&c='+c+'&p='+p+'&m='+m+'&x='+x;
    f.parentNode.insertBefore(j,f);
  })(document,'script','{{ organization.id }}','34','{{ organization.number.replace('+','') }}','bottom-left','no-consent','en');&lt;/script&gt;
&lt;!-- End of ManyContacts.com Widget --&gt;
</code></pre>

<pre v-if="organization && organization.watype === null"><code>{{ $t('onboarding.blocked_widget') }}
</code></pre>
    <div class="form-group">
      <p class="widget-buttons-whit-css" v-html="$t('settings.widget.buttons-css')"></p>
    </div>

    <div>
      <img width="300" height="551" src="../../../../assets/widget.png">
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SectionWidget',
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['fetchOrganization'])
  },
  created () {
    if (this.organization === null) {
      this.fetchOrganization()
    }
  }
}
</script>

<style scoped>
pre {
  background: #f4f4f4;
  border: 1px solid #ddd;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1em;
  display: block;
  word-wrap: break-word;
}
</style>
