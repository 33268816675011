import axios from 'axios'

const state = {
  responses: []
}
const getters = {
  responses: (state) => state.responses
}
const actions = {
  async fetchResponses ({ commit, rootState }, { query, limit }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/short_responses?filter=${query}&limit=${limit}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setResponses', res.data)
  },
  async emptyResponses ({ commit }) {
    commit('setResponses', [])
  },
  async createResponse ({ commit, rootState }, shortResponse) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/short_response`, shortResponse, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((newResponse) => {
      commit('addResponse', newResponse.data)
    }).catch(() => {})
  },
  async updateResponse ({ commit, rootState }, shortResponse) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/short_response/${shortResponse.id}`, shortResponse, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async deleteResponse ({ commit, rootState }, shortResponse) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/short_response/${shortResponse.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteResponse', shortResponse)
    }).catch(() => {})
  }
}
const mutations = {
  setResponses: (state, responses) => (state.responses = responses),
  addResponse: (state, newResponse) => (state.responses.push(newResponse)),
  deleteResponse: (state, newResponse) => {
    const index = state.responses.findIndex(resp => resp.id === newResponse.id)
    if (index !== -1) {
      state.responses.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
