
<template>
    <router-link :to="{ name: 'IntegrationRedirectLocale', params: { feature: module.key, locale: this.$i18n.locale }}" @click="onClickModule(module)" class="module" :class="{ featured: module.featured === 1 }">
      <div class="icon">
        <div :class="module.key" class="icon-wrapper">
          <img loading="lazy" style="border-radius: 4px;" :src="$t(`integrations.${module.key}.icon`)" onerror="this.style.display='none'"/>
        </div>
      </div>
      <div class="tags">
        <div class="tag" v-for="tag in module.tags" :key="tag.id">{{$t(`modules.tags.${tag.name}`)}}</div>
      </div>
      <div class="name">{{ $t(`integrations.${module.key}.title`) }}</div>
      <div class="short_description">
        {{ $t(`integrations.${module.key}.subtitle`) }}
      </div>
      <button>{{ $t(`modules.integrations.more_info`) }}</button>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IntegrationModule',
  components: {
  },
  computed: mapGetters(['organizationModules', 'organization']),
  methods: {
    onClickModule (module) {
      this.emitter.emit('openModuleIntegrationPopup', module)
    }
  },
  props: {
    module: Object
  }
}
</script>

<style scoped>
.module {
  position: relative;
  height: 275px;
  width: 400px;
  margin-bottom: 20px;
  margin-right: 15px;
  cursor: pointer;
  padding: 24px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow linear .04s,border-color linear .2s;
}

.mobile .module {
  margin-right: 0;
  width: calc(100% - 40px);
}

a.module {
  text-decoration: none;
  color: inherit;
}

.module:hover {
  background-color: #f9fafb;

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.icon {
  width: 40px;
  height: 40px;
}

.icon img {
  height: 40px;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  /*background: linear-gradient(to bottom right, #F1F8FF,#C4E0FD);*/
  /*color: #4285f4;*/
  font-size: 21px;
  text-align: center;
  line-height: 40px;
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

/*.icon-wrapper.calendar {*/
/*  color: #00cec9*/
/*}*/

/*.icon-wrapper.pipeline {*/
/*  color: #ff7675*/
/*}*/

/*.icon-wrapper.multi-user {*/
/*  color: #fdcb6e*/
/*}*/

.icon-wrapper i {

}

.name {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}

.short_description {
  margin-top: 10px;
  font-size: 14px;
  color: #6b7280;
  letter-spacing: 0.2px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 85px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.price {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  color: #4285f4;
}

span.active {
  position: absolute;
  top: 24px;
  left: 70px;
  font-weight: 400;
  font-size: 11px;
  padding: 5px 8px;
  border-radius: 6px;
  margin-left: 10px;
  color: #1a202c;
  background-color: rgb(82, 204, 167,.15);
  border: 1px solid #7bed9f;
}

.active i {
  color: #7bed9f;
}

.tags {
  position: absolute;
  top: 40px;
  right: 20px;
  text-align: right;
}

.tags .tag {
  padding: 1px 7px;
  text-decoration: none;
  float: left;
  margin: 13px 0 0 10px;
  border-radius: 6px;
  border: 1px solid;
  background-color: #fff;
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transform: translateZ(0);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: #757575;
}

button {
  width: calc(100% - 48px);
  height: 40px;
  position: absolute;
  bottom: 20px;
  background-color: transparent;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  color: #4285f4;
  border: 1px solid #4285f4;
  cursor: pointer;
}

button:hover {
  background-color: #4285f4;
  color: white;
}
</style>
