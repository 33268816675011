<template>
  <div class="content">
    <SettingsOptions />
    <SettingsPanel />
  </div>
</template>

<script>
import SettingsOptions from './SettingsOptions'
import SettingsPanel from './SettingsPanel'

export default {
  name: 'SettingsContent',
  components: {
    SettingsOptions,
    SettingsPanel
  }
}
</script>

<style scoped>
  .content {
    width: calc(100% - 250px - 71px);
    margin-left: 71px;
  }
</style>
