import axios from 'axios'
import Cookies from 'js-cookie'

const state = {
  loggedUser: null,
  locale: null,
  allLocales: ['en', 'es', 'it', 'pt'],
  loggedUserAPI: null
}
const getters = {
  loggedUser: (state) => state.loggedUser,
  locale: (state) => state.locale,
  allLocales: (state) => state.allLocales,
  loggedUserAPI: (state) => state.loggedUserAPI
}
const actions = {
  async fetchLoggedUserAPI ({ commit, dispatch, rootState }) {
    const usersTokens = localStorage.getItem('accessTokens')
    if (usersTokens) {
      const usersTokensParsed = JSON.parse(usersTokens)
      axios.post(`${process.env.VUE_APP_SERVERHOST}api/user/fetch-counters`, { data: usersTokensParsed }, {
        headers: {
          token: rootState.sessions.userIsAutenticate
        }
      }).then((res) => {
        commit('setLoggedUserAPI', res.data)
      }).catch(() => {})
    }
  },
  async fetchLoggedUser ({ commit, rootState }) {
    const tokenSession = sessionStorage.getItem('accessTokensSession')
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/user`, {
      headers: {
        token: tokenSession
      }
    })

    if (res.status === 412 || res.status === 401) {
      sessionStorage.removeItem('accessTokensSession')

      const redirectUrl = process.env.VUE_APP_WEB_HOST
      location.href = redirectUrl
    }

    commit('setLoggedUser', res.data)
    const locale = Cookies.get('ma-locale')
    if (res.data.locale === null) {
      Cookies.remove('ma-locale')
    }
    if (locale !== null &&
      res.data.locale !== null &&
      locale !== res.data.locale) {
      Cookies.set('ma-locale', res.data.locale)
      window.location.reload()
    }
  },
  async updateUserStatus ({ commit, rootState }, status) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/user/status`, {
      status: status
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async updateUser ({ commit, dispatch, rootState }, user) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/user/${user.id}`, user, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      if (state.loggedUser.id === user.id) {
        dispatch('fetchLoggedUser')
      }
    }).catch(() => {})
  },
  async updatePassword ({ commit, dispatch, rootState }, obj) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/user/password`, obj, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async deleteUser ({ commit, rootState }, user) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/user/${user.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteOrganizationUser', user)
    }).catch(() => {
      commit('deleteOrganizationUser', user)
    })
  },
  async logout ({ rootState }) {
    const redirectUrl = process.env.VUE_APP_WEB_HOST
    axios.get(`${process.env.VUE_APP_SERVERHOST}api/logout`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      const finalTokens = {}
      const usersTokens = localStorage.getItem('accessTokens')
      const parsedUsersTokens = JSON.parse(usersTokens)
      for (const user in parsedUsersTokens) {
        if (parsedUsersTokens[user].id !== state.loggedUser.id) {
          finalTokens[user] = parsedUsersTokens[user]
        }
      }

      localStorage.setItem('accessTokens', JSON.stringify(finalTokens))

      if (parsedUsersTokens.length === 0) {
        localStorage.removeItem('accessTokens')
      }

      sessionStorage.removeItem('accessTokensSession')

      location.href = redirectUrl
    })
  }
}
const mutations = {
  setLoggedUserAPI: (state, loggedUserAPI) => (state.loggedUserAPI = loggedUserAPI),
  setLoggedUser: (state, loggedUser) => (state.loggedUser = loggedUser),
  setLocale: (state, locale) => (state.locale = locale),
  deleteLoggedUserTeam: (state, team) => {
    if (state.loggedUser.teams) {
      const index = state.loggedUser.teams.findIndex(el => el.id === team.id)
      if (index !== -1) {
        state.loggedUser.teams.splice(index, 1)
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
