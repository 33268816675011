<template>
  <div class="settings-group" v-if="loggedUser && loggedUser.canSeeSettings===1" >
    <h3>{{ $t('settings.organization.title') }}</h3>
    <p>{{ $t('settings.organization.logo_description') }}</p>
    <form @submit="onSubmitProfileOrganization">
      <div class="form-group">
        <div>
          <img v-if="organization" @click="onClickImage" id="profile-img" class="profile-img" :src="getLogoUrl()" onerror="this.src='/manycontacts-isotype.svg';"/>
          <input hidden type="file" id="upload-profile-image" ref="imageLogo" accept="image/jpeg,image/png" class="input-select" @change="onChangeOrganizationLogo($event)">
        </div>
        <div class="switch-text">
          {{ $t('settings.organization.name_organization') }}
        </div>
        <div class="form-group">
          <input style="display: block" v-if="organization" type="text" id="organization-name" name="organization-name" v-model="organization.name">
        </div>
        <div class="form-group">
          <button class="button" style="margin-top: 10px; margin-bottom: 10px; padding-bottom: 10px" type="submit">{{ $t('settings.profile.personal.button') }}</button>
        </div>
      </div>
    </form>
  </div>
  <div class="settings-group">
    <h3>{{ $t('settings.profile.personal.title') }}</h3>

    <form v-if="loggedUser" @submit="onSubmitProfile">
      <div class="switch-group">
        <div class="switch-text" v-if="loggedUser.isActive === true">{{ $t('navbar.status.active') }}</div>
        <div class="switch-text" v-if="loggedUser.isActive === false">{{ $t('navbar.status.ooo') }}</div>
        <Switch v-on:update:checked="onChangeUserStatus" v-model:checked="loggedUser.isActive" />
      </div>
      <div class="form-group">
          <label for="user-email">{{ $t('settings.profile.personal.email') }}</label>
          <input disabled type="email" id="user-email" name="email" v-model="loggedUser.email">
      </div>
      <div class="form-group">
        <label for="user-name">{{ $t('settings.profile.personal.name') }}</label>
        <input type="text" id="user-name" name="name" v-model="loggedUser.name">
      </div>
      <div class="form-group">
        <label for="user-locale">{{ $t('settings.profile.personal.locale') }}</label>
        <select id="user-locale" v-model="loggedUser.locale" name="locale">
          <option v-for="locale in allLocales" :key="locale" :value="locale">{{ $t('locale.' + locale) }}</option>
        </select>
      </div>
      <div class="form-group">
        <button class="button" type="submit">{{ $t('settings.profile.personal.button') }}</button>
      </div>
    </form>
  </div>

  <div class="settings-group">
    <h3>{{ $t('settings.profile.password.title') }}</h3>

    <form v-if="loggedUser" @submit="onSubmitPasswords">
      <div class="form-group">
        <label for="user-password">{{ $t('settings.profile.password.current') }}</label>
        <input v-model="oldPassword" type="password" id="user-password" name="old">
      </div>
      <div class="form-group">
        <label for="user-new">{{ $t('settings.profile.password.new') }}</label>
        <input v-model="newPassword" type="password" id="user-new" name="new">
      </div>
      <div class="form-group">
        <button class="button" type="submit">{{ $t('settings.profile.password.button') }}</button>
      </div>
    </form>
  </div>

  <div class="settings-group">
    <h3>{{ $t('settings.notifications.title') }} <i @click="openVideoHelp($t('help.links.notifications'))" class="help fas fa-question" v-tooltip.right="{ content: $t('help.title') }"></i></h3>
    <br>
    <div v-if="showRequestNotificationPermissions()" class="form-group blue">
      <b>{{ $t('settings.notifications.browser.not_enabled_title') }}</b>
      <p>{{ $t('settings.notifications.browser.not_enabled_subtitle') }}</p>
      <button @click="requestNotificationsGranted" class="button" type="submit">{{ $t('settings.notifications.browser.request') }}</button>
    </div>
    <br>
    <div v-if="loggedUser" class="form-group">
      <h4>{{ $t('settings.notifications.unassigned') }}</h4>
      <p>{{ $t('settings.notifications.unassigned_subtitle') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'notifyUnassigned')" v-model:checked="loggedUser.notifyUnassigned" />
    </div>
    <div v-if="loggedUser" class="form-group">
      <h4>{{ $t('settings.notifications.mine') }}</h4>
      <p>{{ $t('settings.notifications.mine_subtitle') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'notifyMine')" v-model:checked="loggedUser.notifyMine" />
    </div>
    <hr>
    <div v-if="loggedUser" class="form-group">
      <h4>{{ $t('settings.notifications.mentions') }}</h4>
      <p>{{ $t('settings.notifications.mentions_subtitle') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'notifyMentions')" v-model:checked="loggedUser.notifyMentions" />
    </div>
    <div v-if="loggedUser" class="form-group">
      <h4>{{ $t('settings.notifications.reminders') }}</h4>
      <p>{{ $t('settings.notifications.reminders_subtitle') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'notifyReminders')" v-model:checked="loggedUser.notifyReminders" />
    </div>
    <hr>
  </div>

  <div class="settings-group logout">
      <a @click="onClickLogout" href="#">{{ $t('settings.profile.logout') }}</a>
  </div>

</template>

<script>
import Switch from '@/components/common/Switch'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionProfile',
  components: {
    Switch
  },
  data () {
    return {
      oldPassword: null,
      newPassword: null,
      timer: null
    }
  },
  computed: mapGetters(['loggedUser', 'allLocales', 'organization']),
  methods: {
    ...mapActions(['updateUser', 'updatePassword', 'logout', 'updateUserStatus', 'updateOrganization', 'updateOrganizationLogo', 'fetchOrganization']),
    getLogoUrl () {
      return this.organization.logo || '/manycontacts-isotype.svg'
    },
    openVideoHelp (url) {
      this.emitter.emit('openVideoHelp', url)
    },
    onSubmitProfile (event) {
      event.preventDefault()

      if (this.loggedUser.name === '' ||
          this.loggedUser.email === '') {
        this.$toast.open({
          message: this.$t('form.error_required'),
          type: 'error'
        })

        return
      }

      this.updateUser(this.loggedUser)
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    onChangeOrganizationLogo (event) {
      if (event.target.files.length === 0) {
        return
      }
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      if (FileReader && file) {
        const fr = new FileReader()
        fr.onload = function () {
          document.getElementById('profile-img').src = fr.result
        }
        fr.readAsDataURL(file)
      }
    },
    onSubmitProfileOrganization (event) {
      event.preventDefault()
      if (this.$refs.imageLogo.files.length !== 0) {
        this.updateOrganization(this.organization).then(() => {
          const file = this.$refs.imageLogo.files[0]
          const formData = new FormData()
          formData.append('file', file)
          this.updateOrganizationLogo(file).then(() => {
            this.$toast.open({
              message: this.$t('form.saved')
            })
            this.fetchOrganization()
          }).catch(() => {
            this.$toast.open({
              message: this.$t('form.error_required'),
              type: 'error'
            })
          })
        }).catch(() => {
          this.$toast.open({
            message: this.$t('form.error_required'),
            type: 'error'
          })
        })
      } else {
        this.updateOrganization(this.organization).then(() => {
          this.$toast.open({
            message: this.$t('form.saved')
          })
          this.fetchOrganization()
        }).catch(() => {
          this.$toast.open({
            message: this.$t('form.error_required'),
            type: 'error'
          })
        })
      }
    },
    onClickImage () {
      document.getElementById('upload-profile-image').click()
    },
    async onSubmitPasswords (event) {
      event.preventDefault()

      if (this.oldPassword === '' ||
        this.newPassword === '') {
        this.$toast.open({
          message: this.$t('form.error_required'),
          type: 'error'
        })

        return
      }

      this.updatePassword({
        old: this.oldPassword,
        new: this.newPassword
      }).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      }).catch(() => {
        this.$toast.open({
          message: this.$t('settings.profile.password.error_incorrect_password'),
          type: 'error'
        })
      })
    },
    onClickLogout (e) {
      e.preventDefault()
      this.logout()
    },
    onChangeSwitch (value, key) {
      const userUpd = { id: this.loggedUser.id }
      userUpd[key] = value
      this.updateUser(userUpd).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onChangeUserStatus (value) {
      this.updateUserStatus(value)
    },
    showRequestNotificationPermissions () {
      return window.Notification && (window.Notification.permission === undefined || window.Notification.permission === 'default')
    },
    requestNotificationsGranted () {
      const self = this
      this.$notification.requestPermission().then(() => {
        if (window.Notification && window.Notification.permission === 'granted') {
          self.$toast.open({
            message: self.$t('settings.notifications.browser.enabled')
          })
        }
      })
    }
  }
}
</script>

<style scoped>

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  cursor: pointer;
}

h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
}

textarea {
  height: unset !important;
}

.settings-group.logout {
  border: 0;
  text-align: right;
  min-height: 250px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.form-group.blue {
  background-color: #4285f45e;
  padding: 20px;
  border-radius: 4px;
  min-height: 90px;
}

.help {
  font-size: 9px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  text-align: center;
  line-height: 15px;
  margin-left: 5px;
}

.switch-group {
  position: absolute;
  right: 20px;
  top: 20px;
}

.switch-text {
  float: left;
  text-align: right;
  margin-right: 60px;
  line-height: 35px;
}

.input-select {
  display: none;
}

</style>
