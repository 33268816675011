<template>
  <div v-if="this.modalOpen" class="overlay" @click="closeOverlay">
    <div class="modal">
      <h3 v-if="!this.event.id">{{ $t('event.new.title') }}</h3>
      <h3 v-if="this.event.id">{{ $t('event.edit.title') }}</h3>
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

      <div class="form-group">
        <label for="event-date">{{ $t('event.date') }}</label>
        <input disabled type="text" id="event-date" :value="$moment(this.event.date).format('YYYY-MM-DD HH:mm')">
      </div>

      <div class="form-group">
        <label for="event-name">{{ $t('event.name') }}</label>
        <input type="text" id="event-name" v-model="this.event.name">
      </div>

      <div v-if="this.isWhatsAppChat()">
        <div>
          <button class="plain-button" v-if="this.event.notification === false" @click="this.event.notification = true">
            {{ $t('event.reminder.add') }}
            <img style="height: 15px; vertical-align: text-bottom;" src="../../assets/send-scheduled_blue.svg" />
          </button>
          <div style="clear: both"></div>
          <div v-if="this.event.notification === true">
            <button class="plain-button remove" @click="this.event.notification = false">
              {{ $t('event.reminder.remove') }}
              <img style="height: 15px; vertical-align: text-bottom;" src="../../assets/send-scheduled_red.svg" />
            </button>

            <div class="form-group">
              <label for="event-reminder-date">{{ $t('event.reminder.hours_before') }}</label>
              <input style="width: 35px; display: inline-block; margin: 0 5px;" type="number" id="event-reminder-date" v-model="this.event.notificationDate">
              <label for="event-reminder-date">{{ $t('event.reminder.hours_after') }}</label>
            </div>

            <div class="form-group">
              <label for="event-reminder-text">{{ $t('event.reminder.text') }}</label>
              <textarea v-on:keydown.enter="onEnterTextarea" id="event-reminder-text" v-model="this.event.notificationText"></textarea>
            </div>
          </div>
        </div>
      </div>

      <button :disabled="!this.event.name.trim() || !this.event.date" class="button" @click="onClickSubmit">{{ $t('short_responses.submit') }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewCalendarEvent',
  data () {
    return {
      modalOpen: false,
      event: {
        id: null,
        name: '',
        date: new Date(),
        notification: false,
        notificationText: '',
        notificationDate: 24
      }
    }
  },
  computed: mapGetters(['selectedContact', 'organization']),
  methods: {
    ...mapActions(['sendReminder', 'fetchContact']),
    onClickSubmit () {
      this.sendReminder({ contact: this.selectedContact, event: this.event }).then(() => {
        this.fetchContact(this.selectedContact.id)
      })

      this.close()
      this.$toast.open({
        message: this.$t('form.saved')
      })

      setTimeout(() => {
        const messageInputEl = document.getElementById('message-input')
        if (messageInputEl) {
          messageInputEl.focus()
        }
      }, 200)
    },
    closeOverlay (event) {
      if (event.target.classList.contains('overlay')) {
        this.close()
      }
    },
    close () {
      this.modalOpen = false
      this.event = {
        id: null,
        name: '',
        date: new Date(),
        notification: false,
        notificationText: '',
        notificationDate: 24
      }
    },
    open (e) {
      if (e.event) {
        this.event = e.event
      } else {
        // this.event.date = this.$moment(e.date).format('YYYY-MM-DDTHH:mm')
        this.event.date = e.date
      }

      this.modalOpen = true

      setTimeout(() => {
        const el = document.getElementById('event-name')
        if (el) {
          el.focus()
        }
      }, 500)
    },
    onEnterTextarea (e) {
      e.preventDefault()
    },
    isWhatsAppChat () {
      return this.selectedContact.user_id === null && this.selectedContact.isInternalSupport === 0
    }
  },
  created () {
    this.emitter.on('openNewCalendarEvent', this.open)
  }
}
</script>

<style scoped>
.modal {
  width: 400px;
  height: 470px;
  margin: 0 auto;
  position: relative;
  top: 30px;
  left: 0;
}

.overlay {
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.loading {
  text-align: center;
  margin-top: 100px;
  color: #2c3e50;
}

.plain-button {
  width: 100%;
  text-align: right;
  color: #4285f4;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  margin-bottom: 10px;
}

.plain-button.remove{
  color: #ff7675;
}
</style>
