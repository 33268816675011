import axios from 'axios'

const actions = {
  async fetchWhatsappProfileAbout ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/about`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data.text
  },
  async fetchWhatsappProfileBusiness ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/business`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data
  },
  async fetchBusinessAccount ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/businessaccount`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data
  },
  async saveWhatsappProfilePhoto ({ commit, dispatch, rootState }, { formData }) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/photo`, formData, {
      headers: {
        token: rootState.sessions.userIsAutenticate,
        'Content-Type': 'multipart/form-data'
      }
    }).then(() => {
      return true
    }).catch(() => {
      return false
    })
  },
  async saveWhatsappProfileAbout ({ commit, rootState }, text) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/about`, {
      text: text
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async saveWhatsappProfileBusiness ({ commit, rootState }, business) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/business`, {
      business
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async saveFacebookAPI ({ commit, rootState }, code) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/facebook/api`, {
      code: code
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((response) => {
      return response
    }).catch((error) => {
      return error
    })
  },
  async saveFacebookAPINumber ({ commit, rootState }, selectedNumber) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/facebook/number`, selectedNumber, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((response) => {
      if (response.status === 200) {
        return true
      } else {
        return false
      }
    }).catch(() => {
      return false
    })
  },
  async saveWabaId ({ commit, rootState }, fbWabaId) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/migration/waba`, {
      fbWabaId: fbWabaId
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async saveSignupCode ({ commit, rootState }, code) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/whatsapp/migration/code`, {
      code: code
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  }
}

export default {
  actions
}
