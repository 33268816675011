<template>
  <div class="search">
    <div class="advanced">
      <v-select @option:selected="onChangeTags" @option:deselected="onChangeTags" v-if="tags" multiple v-model="this.selectedTags" :placeholder="$t('search.tags_placeholder')" :options="tags" label="name"></v-select>
      <div @click="clearSearch" class="close"><i class="fas fa-times"></i></div>
    </div>
    <ul>
    <div class="list-header" v-if="searchResults.contacts"><div>{{ $t('search.contacts') }}</div></div>
    <div class="empty-results" v-if="searchResults.contacts.length === 0">{{ $t('search.empty') }}</div>
    <ListItemContact v-for="contact in searchResults.contacts" :contact="contact" :key="contact.id" />
    <div class="list-header" v-if="searchResults.messages"><div>{{ $t('search.messages') }}</div></div>
    <ListItemMessage v-for="message in searchResults.messages" :message="message" :key="message.id" />
      <div class="empty-results" v-if="searchResults.messages.length === 0">{{ $t('search.empty') }}</div>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListItemContact from './ListItemContact'
import ListItemMessage from './ListItemMessage'

export default {
  name: 'ListSearch',
  computed: mapGetters(['searchResults', 'tags']),
  methods: {
    ...mapActions(['clearSearch', 'querySearch']),
    onChangeTags () {
      const tagIds = []
      for (const tag of this.selectedTags) {
        tagIds.push(tag.id)
      }
      const search = document.getElementById('input-search').value
      this.querySearch({ search, tagIds })
    }
  },
  data () {
    return {
      selectedTags: null
    }
  },
  beforeUnmount () {
    this.clearSearch()
    this.$emit('clearSearch')
  },
  components: {
    ListItemMessage,
    ListItemContact
  }
}
</script>

<style scoped>
  ul {
    margin: 0;
    padding: 0;
  }

  .search {
    width: 330px;
    float: left;
    height: calc(100vh - 67px);
    position: absolute;
    top: 57px;
    right: 60px;
    background-color: #ffffff;
    border: 1px solid #EDEFF1;
    overflow-y: scroll;
    overflow-x: hidden;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }

  .mobile .search {
    width: 100%;
    left: 0;
    box-shadow: none;
  }

  .list-header > div {
    background: rgba(242, 245, 245, 0.8);
    height: 30px;
    line-height: 30px;
    text-align: center;
  }

  .advanced {
    padding: 10px;
    width: 85%;
  }

  .close {
    position: absolute;
    top: 18px;
    right: 16px;
    cursor: pointer;
  }

  .empty-results{
    padding: 15px;
  }
</style>
