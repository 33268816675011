import axios from 'axios'

const state = {
  organizationModules: null
}
const getters = {
  organizationModules: (state) => state.organizationModules
}
const actions = {
  async fetchOrganizationModules ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/organization/modules`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setOrganizationModules', res.data)
  },
  async enableOrganizationModule ({ commit, rootState }, key) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/organization/module/${key}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      commit('setOrganizationModules', res.data)
    })
  },
  async disableOrganizationModule ({ commit, rootState }, key) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/organization/module/${key}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      commit('setOrganizationModules', res.data)
    })
  }
}
const mutations = {
  setOrganizationModules: (state, organizationModules) => (state.organizationModules = organizationModules)
}

export default {
  state,
  getters,
  actions,
  mutations
}
