import axios from 'axios'

const state = {
  klaviyoAudiences: []
}
const getters = {
  klaviyoAudiences: (state) => state.klaviyoAudiences
}
const actions = {
  async fetchKlaviyoAudiences ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/klaviyo/audiences`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setKlaviyoAudiences', res.data)
  },
  async fetchKlaviyoAudienceCount ({ commit, rootState }, data) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/klaviyo/audience/count/`, data, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data.count
  }
}

const mutations = {
  setKlaviyoAudiences: (state, klaviyoAudiences) => (state.klaviyoAudiences = klaviyoAudiences)
}

export default {
  state,
  getters,
  actions,
  mutations
}
