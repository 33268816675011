<template>
  <div v-if="this.modalOpen" class="overlay">
    <div class="modal">
      <h3>{{ $t('conversations.invoicing.autorecharge.enable') }}</h3>
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      <div v-html="$t('conversations.invoicing.autorecharge.popup')"></div>
      <div style="text-align: center">
        <button @click="addCredits(1000)" class="button button-small">1000</button>
        <button @click="addCredits(2000)" class="button button-small">2000</button>
        <button @click="addCredits(5000)" class="button button-small">5000</button>
        <button @click="addCredits(10000)" class="button button-small">10000</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'EnableAutoRecharge',
  data () {
    return {
      modalOpen: false
    }
  },
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['fetchCheckoutSessionLink']),

    close () {
      this.modalOpen = false
    },
    open (e) {
      this.modalOpen = true
    },
    addCredits (number) {
      this.fetchCheckoutSessionLink({ credits: number, autoRecharge: true }).then((url) => {
        location.href = url
      })
    }
  },
  created () {
    this.emitter.on('openEnableAutoRecharge', this.open)
  }
}
</script>

<style scoped>
.modal {
  width: 550px;
  height: 300px;
  margin: 0 auto;
  position: relative;
  top: 30px;
  left: 0;
}

.overlay {
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

button {
  position: relative;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 10px;
  margin-left: 10px;
  bottom: unset;
}

</style>
