import axios from 'axios'

const state = {
  counters: null
}
const getters = {
  counters: (state) => state.counters
}
const actions = {
  async fetchCounters ({ commit, rootState }) {
    await axios.get(`${process.env.VUE_APP_SERVERHOST}api/user/counters`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  SOCKET_updateCounters ({ commit, rootState }, data) {
    if (state.counters && state.counters.unassigned < data.unassigned) {
      if (rootState.user.loggedUser && rootState.user.loggedUser.notifyUnassigned === true) {
        if (!rootState.messages.contact || rootState.messages.contact.last_user_id !== null) {
          const audio = new Audio('/tone.mp3')
          audio.autoplay = true
          audio.play().catch(() => {})
        }
      }
    }

    commit('setCounters', data)

    const favicon = document.querySelector("link[rel='icon']")
    const faviconSize = 256

    const canvas = document.createElement('canvas')
    canvas.width = faviconSize
    canvas.height = faviconSize

    const context = canvas.getContext('2d')
    const img = document.createElement('img')
    img.src = favicon.href

    img.onload = () => {
      // Draw Original Favicon as Background
      context.drawImage(img, 0, 0, faviconSize, faviconSize)

      // Draw Notification Circle
      context.beginPath()
      context.arc(faviconSize / 3, canvas.height - faviconSize / 3, faviconSize / 3, 0, 2 * Math.PI)
      context.fillStyle = '#FF0000'
      context.fill()

      // Draw Notification Number
      context.font = (faviconSize / 16 * 10).toString() + 'px "helvetica", sans-serif'
      context.textAlign = 'center'
      context.textBaseline = 'middle'
      context.fillStyle = '#FFFFFF'

      if (rootState.user.loggedUser) {
        let number = data.assigned[rootState.user.loggedUser.id] ? data.assigned[rootState.user.loggedUser.id] : 0

        if (number > 99) {
          number = '+'
        }

        context.fillText(number, faviconSize / 3, canvas.height - faviconSize / 3)

        // Replace favicon
        favicon.href = canvas.toDataURL('image/png')
      }
    }
  }
}
const mutations = {
  setCounters: (state, counters) => (state.counters = counters),
  addUnreadCounter: (state, contact) => {
    state.counters.grouped[contact.id] = 1
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
