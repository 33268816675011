<template>
  <div class="reminder-datetime-picker">
    <v-date-picker v-model="date" mode="dateTime" :locale="this.loggedUser.locale" is24hr :minute-increment="5" :first-day-of-week="2"></v-date-picker>
    <button @click="onCancel" class="button button-cancel">{{ $t('buttons.cancel') }}</button>
    <button @click="onSelect" class="button">{{ $t('buttons.select') }}</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      date: null
    }
  },
  computed: mapGetters(['loggedUser']),
  methods: {
    onCancel () {
      this.date = null
      this.$emit('cancel')
    },
    onSelect () {
      if (this.date === null) {
        this.$emit('cancel')
        return
      }

      this.$emit('select', this.date)
    }
  }
}
</script>

<style scoped>
.reminder-datetime-picker {
  position: absolute;
  background-color: #ffffff;
  bottom: 37px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
  background-clip: padding-box;
}

.button {
  bottom: 10px;
  width: fit-content;
  padding: 0 20px;
  right: 10px;
}

.button-cancel {
  bottom: 10px;
  width: fit-content;
  padding: 0 20px;
  left: 10px;
  background-color: transparent;
  color: #ff7675;
}

.button-cancel:hover {
  background-color: transparent;
}

.vc-container {
  border: 0;
  margin-bottom: 60px;
}

</style>
