<template>
  <div class="mobile-sidebar">
    <aside class="menu-collapse">
      <ul>
        <li>
          <div class="new-chat" @click="clickNewMessage">
            <i class="fas fa-plus"></i>
          </div>
        </li>
        <router-link class="sidebar-home" v-if="loggedUser && loggedUser.canSeeSettings" to="/">
          <li :class="[['Home'].includes(this.$route.name) ? 'active' : '']">
            <div class="sidebar-icon"><i class="fas fa-home"></i></div>
          </li>
        </router-link>
        <router-link to="/chats" class="chats-unassigned" @click="onClickFetchContacts(0)">
          <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 0 ? 'active' : '']">
            <div class="sidebar-icon"><i class="far fa-comment"></i></div>
            <div v-if="counters && counters.unassigned > 0 && counters.unassigned <= 99" class="unread-badge unread-badge-important">{{ counters.unassigned }}</div>
            <div v-if="counters && counters.unassigned > 0 && counters.unassigned > 99" class="unread-badge unread-badge-important">+99</div>
          </li>
        </router-link>
        <router-link v-if="loggedUser && loggedUser.canSeeAllChats" to="/chats/all" @click="onClickFetchContacts(1)">
          <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 1 ? 'active' : '']">
            <div class="sidebar-icon"><i class="far fa-comments"></i></div>
            <div v-if="counters && counters.all > 0 && counters.all <= 99" class="unread-badge">{{ counters.all }}</div>
            <div v-if="counters && counters.all > 0 && counters.all > 99" class="unread-badge">+99</div>
          </li>
        </router-link>

  <!--      <div v-if="loggedUser && teams.length > 0 && loggedUser.teams && loggedUser.teams.length > 0">-->
  <!--        <router-link :key="team.id" v-for="team in loggedUser.teams" :team="team" :to="'/chats/team/' + team.id" @click="onClickFetchTeamContacts(team)">-->
  <!--          <li class="team" :class="[this.filter === team.id ? 'active' : '']">-->
  <!--            <div class="sidebar-icon">{{ getTeamName(team).substring(0,1) }}</div>-->
  <!--            <div v-if="counters && counters.teams[team.id] > 0 && counters.teams[team.id] <= 99" class="unread-badge">{{ counters.teams[team.id] }}</div>-->
  <!--            <div v-if="counters && counters.teams[team.id] > 0 && counters.teams[team.id] > 99" class="unread-badge">+99</div>-->
  <!--          </li>-->
  <!--        </router-link>-->
  <!--      </div>-->

        <router-link to="/chats/my-chats" @click="onClickFetchContacts(2)">
          <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 2 ? 'active' : '']">
            <div class="sidebar-icon"><i class="far fa-comment-dots"></i></div>
            <div v-if="loggedUser && counters && counters.assigned[loggedUser.id] > 0 && counters.assigned[loggedUser.id] <= 99" class="unread-badge">{{ counters.assigned[loggedUser.id] }}</div>
            <div v-if="loggedUser && counters && counters.assigned[loggedUser.id] > 0 && counters.assigned[loggedUser.id] > 99" class="unread-badge">+99</div>
          </li>
        </router-link>
        <router-link to="/chats/starred" @click="onClickFetchContacts(3)">
          <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 3 ? 'active' : '']">
            <div class="sidebar-icon"><i class="far fa-star"></i></div>
            <div v-if="loggedUser && counters && counters.starred[loggedUser.id] > 0 && counters.starred[loggedUser.id] <= 99" class="unread-badge">{{ counters.starred[loggedUser.id] }}</div>
            <div v-if="loggedUser && counters && counters.starred[loggedUser.id] > 0 && counters.starred[loggedUser.id] > 99" class="unread-badge">+99</div>
          </li>
        </router-link>
        <router-link to="/reminders" @click="onClickFetchContacts(6)">
          <li :class="[parseInt(this.filter) === 6 ? 'active' : '']">
            <div class="sidebar-icon"><i class="far fa-bell"></i></div>
            <div v-if="loggedUser && counters && counters.reminders[loggedUser.id] > 0 && counters.reminders[loggedUser.id] <= 99" class="unread-badge">{{ counters.reminders[loggedUser.id] }}</div>
            <div v-if="loggedUser && counters && counters.reminders[loggedUser.id] > 0 && counters.reminders[loggedUser.id] > 99" class="unread-badge">+99</div>
          </li>
        </router-link>
        <router-link to="/mentions" @click="onClickFetchMentions(4)">
          <li :class="[parseInt(this.filter) === 4 ? 'active' : '']">
            <div class="sidebar-icon"><i class="fas fa-at"></i></div>
            <div v-if="loggedUser && counters && counters.mentions[loggedUser.id] > 0 && counters.mentions[loggedUser.id] <= 99" class="unread-badge">{{ counters.mentions[loggedUser.id] }}</div>
            <div v-if="loggedUser && counters && counters.mentions[loggedUser.id] > 0 && counters.mentions[loggedUser.id] > 99" class="unread-badge">+99</div>
          </li>
        </router-link>
        <router-link to="/internal" @click="onClickFetchContacts(7)">
          <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 7 ? 'active' : '']">
            <div class="sidebar-icon"><i class="fas fa-th-large"></i></div>
            <span>{{ $t('sidebar.internal') }}</span>
            <div v-if="loggedUser && counters && counters.internal && counters.internal[loggedUser.id] > 0 && counters.internal[loggedUser.id] <= 99" class="unread-badge">{{ counters.internal[loggedUser.id] }}</div>
            <div v-if="loggedUser && counters && counters.internal && counters.internal[loggedUser.id] > 0 && counters.internal[loggedUser.id] > 99" class="unread-badge">+99</div>
          </li>
        </router-link>
      </ul>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MobileSidebar',
  computed: mapGetters(['counters', 'loggedUser', 'newMessageOpen', 'filter', 'section', 'locale', 'teams', 'organization']),
  methods: {
    ...mapActions([
      'fetchContacts',
      'fetchMentions',
      'fetchCounters',
      'toggleNewMessage',
      'clearSearch',
      'setPage',
      'setFilterView',
      'fetchTeamContacts',
      'fetchInboundEmails'
    ]),
    clickNewMessage () {
      this.toggleNewMessage()
      setTimeout(() => {
        document.getElementById('tel-input').focus()
      })
    },
    getTeamName (team) {
      const found = this.teams.find(el => el.id === team.id)
      if (found) {
        return found.name
      }
      return null
    },
    onClickFetchContacts (filter) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchContacts({ filter: filter })
    },
    onClickFetchTeamContacts (team) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchTeamContacts(team.id)
    },
    onClickFetchMentions (filter) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.fetchMentions(filter)
    },
    onClickFetchEmails () {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setFilterView(null)
      this.fetchInboundEmails()
    }
  }
}
</script>

<style scoped>
  .mobile-sidebar {
    display: none;
  }

  .mobile .mobile-sidebar {
    display: block;
  }

  aside {
    background-color: #ffffff;
    bottom: 0px;
    width: 100vw;
    position: absolute;
    height: 60px;
    overflow-x: auto;
    text-align: center;
    border-top: 1px solid #EDEFF1;
    z-index: 3;
  }

  .menu-collapse ul {
    padding-left: 10px;
    font-size: 20px;
    width: 70px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    flex-direction: row;
    justify-content: center;
    margin-top: 7px;
  }

  .menu-collapse ul li {
    padding: 0;
    min-height: 40px;
    border-radius: 100%;
    width: 40px;
    text-align: center;
    margin-bottom: 2px;
    line-height: 40px;
  }

  .menu-collapse ul li.team {
    padding-left: 4px;
  }

  .menu-collapse ul li.team .sidebar-icon {
    display: block;
  }

  .menu-collapse ul li span {
    display: none;
  }

  .menu-collapse ul li .sidebar-icon {
    width: 40px;
    float: left;
  }

  .menu-collapse ul li .unread-badge {
    position: absolute;
    top: 11px;
    left: 25px;
    font-size: 0px;
    height: 10px;
    width: 10px;
  }

  .new-chat {
    color: #5299DC;
    height: 48px;
    width: 48px;
    background-color: #fff;
    background-image: none;
    cursor: pointer;
    line-height: 48px;
    font-size: 23px;
    margin-top: -5px;
  }

  .new-chat i {
    margin-right: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px 10px 0 0;
  }

  ul.mentions {
    margin-top: 25px;
  }

  ul li {
    position: relative;
    padding: 5px 0px 5px 25px;
    -webkit-border-top-right-radius: 50px;
    -webkit-border-bottom-right-radius: 50px;
    -moz-border-radius-topright: 50px;
    -moz-border-radius-bottomright: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  ul li .sidebar-icon {
    width: 35px;
    float: left;
  }

  ul li .unread-badge {
    margin-right: 8px;
  }

  ul li:hover {
    background-color: #F1F3F4;
  }

  ul li.active {
    background-color: #4285f45e;
    color: #4285f4;
    font-weight: bold;
  }

  ul li.team {
    padding-left: 60px;
  }

  ul li.team .sidebar-icon {
    display: none;
  }

  ul a {
    color: #111b21;
    text-decoration: none;
  }

  .unread-badge {
    box-sizing: border-box;
    width: 21px;
    border-radius: 100%;
    background-color: transparent;
    color: #4285f4;
    border: 1px solid #4285f4;
    font-weight: bold;
    text-align: center;
    float: right;
    font-size: 10px;
    height: 20px;
    line-height: 18px;
  }

  .unread-badge.unread-badge-important {
    background-color: #4285f4;
    color: #ffffff;
  }

  .menu-collapse .group-settings {
    bottom: 15px;
  }

  .group-settings {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .group-settings a {
    color: #111b21;
  }

  .menu-collapse .group-settings {
    height: 37px;
  }

  .button-settings {
    position: absolute;
    left: 25px;
    top: 15px;
    cursor: pointer;
  }

  .menu-collapse .button-settings {
    font-size: 20px;
    bottom: -22px;
    top: unset;
  }
</style>
