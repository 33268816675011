<template>
  <li>
    {{ $t('contact.list_empty') }}
  </li>
</template>

<script>
export default {
  name: 'ListItemEmptyResult',
  props: {
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
  height: 80px;
  color: #111b21;
  display: -webkit-box;
  display: -webkit-flex;
  display: block;
  position: relative;
  text-align: center;
  vertical-align: middle;
  padding: 100px 15px 10px;
  text-align: center;
}
</style>
