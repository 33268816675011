<template>
  <div class="settings-group">
    <h3>{{ $t('settings.funnels.title') }}</h3>
    <div class="form-group">
      <p v-html="$t('settings.funnels.description')"></p>
      <button v-if="funnels" @click="newItem" class="button" type="submit">{{ $t('settings.funnels.button_create') }}</button>
    </div>
    <br>
    <br>
    <br>
    <div style="margin-top:100px" v-if="funnels && funnels.length > 0">
        <div class="funnel settings-group" :key="funnel.id" v-for="funnel in funnels">
          <div class="funnel-name">
            <div>
              <i v-if="funnel.default_funnel===1 || funnel.default_funnel ===true" class='fas fa-star star-yellow'></i>
              <i v-if="funnel.default_funnel===0 || funnel.default_funnel ===false" class='fas fa-star star-grey' @click="updateDefaultFunnel(funnel)" v-tooltip.left="{ content: $t('settings.funnels.star_grey') }"></i>
              {{ funnel.name }}
            </div>
          </div>
          <div class="funnel-created">({{ $t('settings.custom_fields.created_at') }}: {{ $moment(funnel.createdAt).format('L') }})</div>
          <div class="funnel-actions">
            <i @click="editItem(funnel)" class="far fa-edit"></i>
            <i @click="deleteItem(funnel)" class="far fa-trash-alt"></i>
          </div>

          <div style="margin-top: 70px; margin-bottom: 10px;">{{ $t('settings.funnels.stages') }}</div>
          <div class="stages">
            <draggable @change="onChange(funnel)" v-model="funnel.stages" item-key="id">
              <template #item="{element}">
                <div class="stage">
                  <i class="fas fa-bars"></i>
                  <input @input="onChangeValue(funnel, element)" type="text" class="form-control" v-model="element.name" />
                  <i @click="deleteStage(funnel, element)" class="far fa-trash-alt delete-stage"></i>
                </div>
              </template>
            </draggable>
          </div>
          <button class="button button-small" @click="addStage(funnel)">{{ $t('settings.funnels.new_stage') }}</button>
        </div>
    </div>
  </div>

  <NewFunnel v-if="this.newFunnelOpen"/>
  <DeleteFunnelStage />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewFunnel from '@/components/modals/NewFunnel'
import DeleteFunnelStage from '@/components/modals/DeleteFunnelStage'

export default {
  name: 'SectionFunnels',
  components: {
    DeleteFunnelStage,
    NewFunnel
  },
  data () {
    return {
      timer: null
    }
  },
  computed: mapGetters(['funnels', 'newFunnelOpen']),
  methods: {
    ...mapActions([
      'fetchFunnels',
      'emptyCustomFields',
      'toggleNewFunnel',
      'setNewFunnelData',
      'emptyNewFunnelData',
      'deleteFunnel',
      'updateFunnel',
      'createFunnelStage',
      'updateFunnelStage',
      'deleteFunnelStage',
      'updateFunnelStageOrders'
    ]),
    onChange (funnel) {
      this.updateFunnelStageOrders(funnel)
    },
    newItem () {
      this.emptyNewFunnelData()
      this.toggleNewFunnel()
    },
    editItem (item) {
      this.setNewFunnelData(item)
      this.toggleNewFunnel()
    },
    deleteItem (item) {
      if (confirm(this.$t('settings.funnels.delete.confirm', { name: item.name }))) {
        this.deleteFunnel(item).then(() => {
          this.fetchFunnels()
        })

        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    },
    addStage (funnel) {
      this.createFunnelStage(funnel)
    },
    deleteStage (funnel, stage) {
      this.emitter.emit('openDeleteFunnelStage', { funnel, stage })
    },
    onChangeValue (funnel, stage) {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.updateFunnelStage({ funnel, stage }).then(() => {
        })
      }, 200)
    },
    updateDefaultFunnel (funnelClicked) {
      funnelClicked.default_funnel = true
      this.updateFunnel(funnelClicked).then(() => {
        this.fetchFunnels()
      })
    }
  },
  created () {
    this.fetchFunnels()
  }
}
</script>

<style scoped>
  p {
    width: calc(100% - 200px);
    max-width: 600px;
    float: left;
    margin: 0;
  }

  .funnel {
    margin: 0 0 20px 0;
  }

  .funnel .funnel-name {
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
    font-weight: 500;
  }

  .funnel .funnel-created {
    position: absolute;
    top: 20px;
    right: 80px;
  }

  .funnel .funnel-actions {
    position: absolute;
    top: 21px;
    right: 20px;
    display: inline-flex;
  }

  .funnel .funnel-actions i {
    margin-left: 10px;
    cursor: pointer;
  }

  .funnel .stages {
    border: 1px solid #EDEFF1;
    width: 100%;
  }

  .funnel .stages .stage {
    height: 50px;
    line-height: 50px;
    padding: 0 20px;
    cursor: grab;
  }

  .funnel .stages .stage input {
    width: calc(100% - 100px);
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.5;
    color: #495057;
    margin: 0 20px;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 4px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  }

  .funnel .stages .stage:not(:first-of-type){
    border-top: 1px solid #EDEFF1;
  }

  .funnel .stages .stage .delete-stage {
    cursor:pointer;
  }

  button {
    width: fit-content;
    position: relative;
    bottom: unset;
    margin-top: 10px;
  }
  .star-grey  {
    color: #CFD0D0;
  }
  .star-grey:hover {
    color: #959495;
  }
  .star-yellow {
    color: #F3C967;
  }
</style>
