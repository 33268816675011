<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3 v-if="!this.newCustomFieldData.id">{{ $t('settings.custom_fields.new.title') }}</h3>
    <h3 v-if="this.newCustomFieldData.id">{{ $t('settings.custom_fields.edit.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="cf-name">{{ $t('settings.custom_fields.new.name') }}</label>
      <input @input="onInput" type="text" id="cf-name" v-model="this.newCustomFieldData.name">
    </div>

    <button :disabled="!this.newCustomFieldData.name.trim()" class="button" @click="onClickSubmit">{{ $t('settings.custom_fields.new.submit') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewCustomField',
  computed: mapGetters(['newCustomFieldData']),
  methods: {
    ...mapActions(['toggleNewCustomField', 'createCustomField', 'updateCustomField', 'emptyNewCustomFieldData']),
    onClickSubmit () {
      if (this.newCustomFieldData.id === null) {
        this.newCustomFieldData.name = this.newCustomFieldData.name.toLowerCase()

        this.createCustomField(this.newCustomFieldData)
      } else {
        this.newCustomFieldData.name = this.newCustomFieldData.name.toLowerCase()

        this.updateCustomField(this.newCustomFieldData)
      }

      this.toggleNewCustomField()
      this.emptyNewCustomFieldData()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    close () {
      this.toggleNewCustomField()
    },
    onInput () {
      this.newCustomFieldData.name = this.newCustomFieldData.name.toLowerCase()
      this.newCustomFieldData.name = this.newCustomFieldData.name.replaceAll(' ', '_')
      this.newCustomFieldData.name = this.newCustomFieldData.name.replace(/\W/g, '')
    }
  }
}
</script>

<style scoped>
.modal {
  height: 320px
}
</style>
