<template>
  <div class="settings-group">
    <h3>{{ $t('settings.short_responses.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.short_responses.description') }}</p>
      <button @click="newResponse" class="button" type="submit">{{ $t('settings.short_responses.button_create') }}</button>
    </div>
    <table v-if="responses && responses.length > 0">
      <thead>
        <th style="width: 20%">{{ $t('short_responses.title') }}</th>
        <th style="width: 57%">{{ $t('short_responses.text') }}</th>
        <th style="width: 15%">{{ $t('short_responses.created_at') }}</th>
        <th style="width: 8%"></th>
      </thead>
      <tbody>
        <tr :key="response.id" v-for="response in responses">
          <td>{{ response.name }}</td>
          <td>
            <div class="response-text">
              {{ response.text }}
            </div>
          </td>
          <td>{{ $moment(response.createdAt).format('L') }}</td>
          <td>
            <i @click="editResponse(response)" class="far fa-edit"></i>
            <i @click="deleteShortResponse(response)" class="far fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <NewShortResponse v-if="this.newShortResponseOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewShortResponse from '../../../modals/NewShortResponse'

export default {
  name: 'SectionShortResponses',
  components: {
    NewShortResponse
  },
  computed: mapGetters(['responses', 'newShortResponseOpen']),
  methods: {
    ...mapActions([
      'fetchResponses',
      'emptyResponses',
      'toggleNewShortResponse',
      'setNewShortResponseData',
      'emptyNewShortResponseData',
      'deleteResponse'
    ]),
    newResponse () {
      this.emptyNewShortResponseData()
      this.toggleNewShortResponse()
    },
    editResponse (response) {
      this.setNewShortResponseData(response)
      this.toggleNewShortResponse()
    },
    deleteShortResponse (response) {
      if (confirm(this.$t('short_responses.delete.confirm', { name: response.name }))) {
        this.deleteResponse(response)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    }
  },
  created () {
    this.fetchResponses({ query: '', limit: null })
  },
  beforeUnmount () {
    this.emptyResponses()
  }
}
</script>

<style scoped>
  p {
    width: calc(100% - 200px);
    max-width: 600px;
    float: left;
    margin: 0;
  }

  table {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
  }

  thead {
    height: 40px;
    text-align: left;
    border-bottom: 1px solid #EDEFF1;
  }

  tr {
    height: 50px;
    border-bottom: 1px solid #EDEFF1;
  }

  tr i {
    margin-left: 10px;
    cursor: pointer;
  }

  .response-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 370px;
  }
</style>
