import axios from 'axios'

const state = {
  pendingReminders: []
}
const getters = {
  pendingReminders: (state) => state.pendingReminders
}
const actions = {
  async sendReminder ({ commit, dispatch, rootState }, { contact, event }) {
    await axios.post(`${process.env.VUE_APP_SERVERHOST}api/reminder/${contact.id}`, event, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    // commit('addMessage', res.data)
    dispatch('fetchPendingRemindersForContact', contact)
  },
  async setReminderStatus ({ commit, dispatch, rootState }, { reminderId, status, contact }) {
    const res = await axios.put(`${process.env.VUE_APP_SERVERHOST}api/reminder/${reminderId}`, {
      done: status
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateMessage', res.data)
    dispatch('fetchConversation', { contact: contact, messageId: null })
    // dispatch('fetchPendingRemindersForContact', contact)
  },
  async deleteReminder ({ commit, dispatch, rootState }, { reminderId, contact, messageId }) {
    await axios.delete(`${process.env.VUE_APP_SERVERHOST}api/reminder/${reminderId}/contact/${contact.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('deleteMessageById', messageId)
    dispatch('fetchPendingRemindersForContact', contact)
  },
  async fetchPendingRemindersForContact ({ commit, dispatch, rootState }, contact) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/reminder/${contact.id}/pending`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('updateMessage', res.data)
    commit('setPendingReminders', res.data)
    dispatch('fetchMessagesScheduled', contact.id)
  },
  async fetchAllRemindersForContact ({ commit, dispatch, rootState }, contact) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/reminder/${contact.id}/all`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async fetchCalendar ({ commit, dispatch, rootState }, { start, end, user }) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/calendar`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      },
      params: {
        start: start,
        end: end,
        user: user
      }
    }).then((res) => { return res.data })
  }
}
const mutations = {
  setPendingReminders: (state, pendingReminders) => (state.pendingReminders = pendingReminders)
}

export default {
  state,
  getters,
  actions,
  mutations
}
