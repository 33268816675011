<template>
  <div class="settings-group">
    <h3>{{ $t('settings.zadarma.title') }}</h3>
    <p v-html="$t('settings.zadarma.subtitle')"></p>

    <form @submit="onSubmit">
      <div class="form-group">
        <label for="zadarma-apikey">{{ $t('settings.zadarma.key') }}</label>
        <input type="text" id="zadarma-apikey" name="zadarma-apikey" v-model="zadarmaSettings.key">
      </div>
      <div class="form-group">
        <label for="zadarma-secret">{{ $t('settings.zadarma.secret') }}</label>
        <input type="text" id="zadarma-secret" name="secret-secret" v-model="zadarmaSettings.secret">
      </div>
      <div class="form-group">
        <button class="button" type="submit">{{ $t('settings.profile.personal.button') }}</button>
      </div>
    </form>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionZadarma',
  computed: mapGetters(['zadarmaSettings']),
  methods: {
    ...mapActions(['fetchZadarmaSettings', 'setZadarmaSettings']),
    onSubmit (event) {
      event.preventDefault()

      this.setZadarmaSettings()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    }
  },
  created () {
    this.fetchZadarmaSettings()
  }
}
</script>

<style scoped>

h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
}

textarea {
  height: unset !important;
}

.settings-group.logout {
  border: 0;
  text-align: right;
  min-height: 250px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.form-group.blue {
  background-color: #4285f45e;
  padding: 20px;
  border-radius: 4px;
  min-height: 90px;
}

.help {
  font-size: 9px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  text-align: center;
  line-height: 15px;
  margin-left: 5px;
}

.switch-group {
  position: absolute;
  right: 20px;
  top: 20px;
}

.switch-text {
  float: left;
  text-align: right;
  margin-right: 60px;
  line-height: 35px;
}

</style>
