<template>
  <div class="content">
    <Funnel v-if="funnels && funnels.length > 0 && selectedFunnel && organizationUsers && teams && loggedUser" />
    <div v-if="funnels && funnels.length === 0" class="empty-screen">
      <router-link to="/settings/funnels">
        <button class="button">{{ $t('settings.funnels.empty_screen.first_funnel') }}</button>
      </router-link>
      <img src="../../../assets/funnel.png">
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Funnel from '@/components/home/pipelines/Funnel'

export default {
  name: 'Content_Pipelines',
  components: {
    Funnel
  },
  computed: mapGetters(['funnels', 'selectedFunnel', 'organizationUsers', 'teams', 'loggedUser']),
  methods: {
    ...mapActions(['fetchFunnels', 'setSelectedFunnel'])
  },
  created () {
    this.fetchFunnels().then(() => {
      if (this.funnels.length > 0) {
        if (localStorage.funnelId) {
          const funnel = this.funnels.find(el => el.id === localStorage.funnelId)

          if (funnel) {
            this.setSelectedFunnel(funnel)
            return
          }
        }
        for (const funnel of this.funnels) {
          if (funnel.default_funnel === 1) {
            this.setSelectedFunnel(funnel)
          }
        }

        if (this.selectedFunnel === null) {
          this.setSelectedFunnel(this.funnels[0])
        }
      }
    })
  }
}
</script>

<style scoped>
.content {
  width: calc(100% - 71px);
  margin-left: 71px;
}

.empty-screen {
  width: 70%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.empty-screen img {
  width: 100%;
  margin-top: 50px;
}

.empty-screen button {
  margin-top: 30px;
  padding: 0 20px;
  position: relative;
  width: fit-content;
  top: unset;
  left: unset;
  right: unset;
  bottom: unset;
}

.mobile .content, .mobile .hideSidebarChats .content {
  width: 100%;
  margin-left: 0px;
}

</style>
