<template>
  <div class="date" v-if="message.firstMessageOfTheDay">
    {{ $moment(message.metadata.time).format('DD MMM YYYY') }}
  </div>

  <div :id="message.id" class="message" :class="[
    message.type,
    emoji ? 'emoji' : '',
    message.mediatype ? message.mediatype : '',
    message.contact ? 'contact' : '',
    message.done ? 'done' : '',
    message.location ? 'image location': ''
  ]">
    <div class="reply" v-if="message.reply_to">
<!--      <i class="fas fa-reply"></i> Respuesta a un mensaje anterior-->
      <Bubble v-if="this.replyTo" :message="this.replyTo" />
    </div>
    <span class="data" v-if="!message.mediafile_id && !message.contact && message.type === 'sent' && !message.location">
      <!-- Ogp v-if="ogpUrl" :url="ogpUrl" / -->
      <span class="text" v-html="text"></span>
    </span>
    <span class="data" v-if="!message.mediafile_id && !message.contact && message.type === 'sent' && message.location === true">

      <a :href="getMapsUrl()" target="_blank" v-tooltip.right="{ content: $t('messages.body.openmaps') }">
        <img src="../../../assets/location-thumb.jpeg" />
        <div v-if="message.name" class="caption">{{ message.name }}</div>
        <div class="address" v-if="message.address">{{ message.address }}</div>
      </a>
    </span>
    <span class="data" v-if="!message.mediafile_id && !message.contact && message.type === 'received' && !message.location">
      <!-- Ogp v-if="ogpUrl" :url="ogpUrl" / -->
      <div v-if="message.participant" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <span class="text" v-if="text && !text.includes('<script')" v-html="text"></span>
      <span class="text" v-if="text && text.includes('<script')">{{ text }}</span>
    </span>
    <span class="data" v-if="!message.mediafile_id && !message.contact && message.type === 'received' && message.location === true">
      <div v-if="message.participant" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <a :href="getMapsUrl()" target="_blank" v-tooltip.right="{ content: $t('messages.body.openmaps') }">
        <img src="../../../assets/location-thumb.jpeg" />
        <div v-if="message.name" class="caption">{{ message.name }}</div>
        <div class="address" v-if="message.address">{{ message.address }}</div>
      </a>
    </span>
    <span class="data" v-if="message.type === 'note'" v-html="text"></span>
    <span class="data" v-if="message.type === 'assigned' && message.userName">{{ $t('messages.body.assignedTo') }} {{ message.userName }}</span>
    <span class="data" v-if="message.type === 'assigned' && !message.userName">{{ $t('messages.body.unassigned') }} </span>
    <span class="data" v-if="message.type === 'closed'">{{ $t('messages.body.closedBy') }} {{ message.userName }}</span>
    <span class="data" v-if="message.type === 'funnel-stage'"><i class="fas fa-filter"></i>&nbsp;&nbsp;&nbsp;{{ message.userName }}: {{ message.text }}</span>

    <span class="data" v-if="message.type === 'reminder'">
      <i class="calendar far fa-calendar"></i>
      <span class="reminder-date">{{ $moment(message.date).format('DD MMM YYYY HH:mm') }}</span>
      <div class="reminder-body">{{ text }}</div>
      <input v-if="!message.done" type="checkbox" :checked="message.done" @change="onChangeReminderDone" v-tooltip.top="{ content: $t('messages.body.reminder_mark_as_done') }">
      <div class="dropdown"><i v-if="message.done" class="fas fa-ellipsis-h"></i><i @click="onClickDeleteEvent" v-if="!message.done" class="fa-solid fa-trash" style="position: absolute;font-size: 12px; right: 3px; top:2px" v-tooltip.top="{ content: $t('event.delete') }"></i>
        <div v-if="message.done" class="dropdown-list">
          <div v-if="message.done" @click="onChangeReminderDone">{{ $t('messages.body.reminder_mark_as_undone') }}</div>
          <div @click="onClickDeleteEvent">{{ $t('event.delete') }}</div>
        </div>
      </div>
      <div v-if="message.notification" class="alert-scheduled">
        <img style="height: 16px; margin-left: 5px; margin-top: 5px;" src="../../../assets/send-scheduled_black.svg" v-tooltip.right="{ content: getTooltipAlert(message) }" />
      </div>
    </span>

    <div class="data" v-if="message.contact">
      <b>{{ message.contact.name.formatted_name }}</b>
      <hr>
      <div class="phone" v-for="phone in message.contact.phones" :key="phone.phone">
        {{ phone.phone }}
        <i @click="initConversation(phone.phone)" class="fas fa-comment-medical" v-tooltip.right="{ content: $t('messages.body.start_conversation', { number: phone.phone }) }"></i>
      </div>
    </div>

    <div class="data" v-if="message.mediafile_id && ['image'].includes(message.mediatype)">
      <div v-if="message.participant && message.type === 'received'" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <div class="placeholder">
          <img loading="lazy" :src="getMediaUrl()" @click="onClickImage(message)" v-tooltip.right="{ content: $t('messages.body.preview') }"/>
      </div>
      <div v-if="message.caption" class="caption">{{ message.caption }}</div>
    </div>

    <div class="data" v-if="message.mediafile_id && ['sticker'].includes(message.mediatype)">
      <div v-if="message.participant && message.type === 'received'" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <div class="placeholder">
        <img loading="lazy" :src="getMediaUrl()"/>
      </div>
      <div v-if="message.caption" class="caption">{{ message.caption }}</div>
    </div>

    <div class="data" v-if="message.mediafile_id && ['video'].includes(message.mediatype)">
      <div v-if="message.participant && message.type === 'received'" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <div class="placeholder">
        <video controls>
          <source :src="getMediaUrl()" :type="message.mimetype">
          Your browser does not support the video tag.
        </video>
      </div>
      <div v-if="message.caption" class="caption">{{ message.caption }}</div>
    </div>

    <div class="data" v-if="message.mediafile_id && ['voice','audio'].includes(message.mediatype)">
      <div v-if="message.participant && message.type === 'received'" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <audio controls>
        <source :src="getMediaUrl()" :type="message.mimetype">
        <source :src="getMediaUrl() + '/convert/mp3'" type="audio/mpeg">
        Your browser does not support the audio tag.
      </audio>
      <div v-if="message.caption" class="caption">{{ message.caption }}</div>
    </div>

    <div class="data" v-if="message.mediafile_id && ['document'].includes(message.mediatype)" v-tooltip.right="{ content: $t('messages.body.download') }">
      <div v-if="message.participant && message.type === 'received'" :style="{ color: stringToColor(message.participant.name) }" class="participant"><b>{{ message.participant.name }}</b></div>
      <div class="document-container">
        <a :href="getDownloadUrl()">
          <i v-if="message.mimetype === 'application/pdf'" class="far fa-file-pdf"></i>
          <i v-else-if="message.mimetype === 'text/csv'" class="far fa-file-excel"></i>
          <i v-else-if="message.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="far fa-file-word"></i>
          <i v-else-if="message.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.sheet'" class="far fa-file-excel"></i>
          <i v-else-if="message.mimetype === 'text/xml'" class="far fa-file-code"></i>
          <i v-else class="far fa-file"></i>
          {{ message.filename }}
        </a>
      </div>
      <div v-if="message.caption" class="caption">{{ message.caption }}</div>
    </div>

    <div v-if="message.metadata" class="metadata">
      <span class="time">{{ $moment(message.metadata.time).format('HH:mm') }}</span>
      <span v-if="message.metadata.tick" class="tick">
        <svg v-if="message.metadata.tick === 3" xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
          <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7">
          </path>
        </svg>
        <svg v-else-if="message.metadata.tick === 2" width="16px" height="10px" viewBox="0 0 16 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <g id="tick" fill-rule="nonzero">
                    <path d="M15.01,1.316 L14.532,0.944 C14.3735131,0.821498778 14.1459126,0.849614143 14.022,1.007 L8.666,7.88 C8.61000157,7.9544804 8.52431925,8.00085196 8.43133881,8.00699943 C8.33835836,8.01314689 8.24731678,7.97845956 8.182,7.912 L7.824,7.587 C7.75868322,7.52054044 7.66764164,7.48585311 7.57466119,7.49200057 C7.48168075,7.49814804 7.39599843,7.5445196 7.34,7.619 L6.962,8.099 C6.84012826,8.26352424 6.85536727,8.49210939 6.998,8.639 L8.318,9.906 C8.38359363,9.9722376 8.47481012,10.0065796 8.56780083,10.0000472 C8.66079154,9.99351476 8.74631098,9.94675746 8.802,9.872 L15.074,1.824 C15.197056,1.66476223 15.1684667,1.43604743 15.01,1.312 L15.01,1.316 Z M10.91,1.316 L10.432,0.944 C10.2735131,0.821498778 10.0459126,0.849614143 9.922,1.007 L4.566,7.88 C4.51000157,7.9544804 4.42431925,8.00085196 4.33133881,8.00699943 C4.23835836,8.01314689 4.14731678,7.97845956 4.082,7.912 L1.892,5.77 C1.7478824,5.6295067 1.51736806,5.63174037 1.376,5.775 L0.953,6.208 C0.813008826,6.35170975 0.815692337,6.58159724 0.959,6.722 L4.214,9.907 C4.27945529,9.97334835 4.37058424,10.0078629 4.46356975,10.0015229 C4.55655525,9.99518301 4.6421561,9.94861875 4.698,9.874 L10.97,1.826 C11.0925012,1.66751314 11.0643859,1.43991257 10.907,1.316 L10.91,1.316 Z" id="Shape" fill="#92A58C"></path>
                    <path d="M10.91,1.316 L10.432,0.944 C10.2735131,0.821498778 10.0459126,0.849614143 9.922,1.007 L4.566,7.88 C4.51000157,7.9544804 4.42431925,8.00085196 4.33133881,8.00699943 C4.23835836,8.01314689 4.14731678,7.97845956 4.082,7.912 L1.892,5.77 C1.7478824,5.6295067 1.51736806,5.63174037 1.376,5.775 L0.953,6.208 C0.813008826,6.35170975 0.815692337,6.58159724 0.959,6.722 L4.214,9.907 C4.27945529,9.97334835 4.37058424,10.0078629 4.46356975,10.0015229 C4.55655525,9.99518301 4.6421561,9.94861875 4.698,9.874 L10.97,1.826 C11.0925012,1.66751314 11.0643859,1.43991257 10.907,1.316 L10.91,1.316 Z" id="Shape" fill="#4FC3F7"></path>
                </g>
            </g>
        </svg>
        <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
          <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#92A58C">
          </path>
        </svg>
      </span>
    </div>
    <div v-if="message.conchi_user_id" class="metadata-ai-robot" @click="openTrainingAgentAI(message)">
      <i class="fas fa-robot"></i>
      <i class="fas fa-pencil"></i>
    </div>
  </div>
  <div v-if="message.error && this.organization" class="error" :class="[message.type]">
    {{ $t('errors.message_not_send') }} <span v-html="$t('errors.' + message.error, {fbWabaId: this.organization.fbWabaId, fbBusinessId: this.organization.fbBusinessId})"></span> <i class="fas fa-exclamation-triangle"></i>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import anchorme from 'anchorme'
// import Ogp from '../../common/Ogp'

export default {
  name: 'Bubble',
  // components: { Ogp },
  props: {
    message: Object
  },
  computed: mapGetters(['selectedContact', 'organization']),
  data () {
    return {
      emoji: false,
      text: null,
      ogpUrl: null,
      replyTo: null
    }
  },
  updated () {
    if (this.message.text) {
      let input = this.message.text
      input = input.replaceAll('\n', '<br>')

      this.text = anchorme({
        input,
        options: {
          attributes: {
            target: '_blank'
          }
        }
      })

      if (['sent', 'received'].includes(this.message.type)) {
        const urls = anchorme.list(input)
        if (urls.length > 0) {
          this.ogpUrl = urls[0].string
        }
      }
    }
  },
  created () {
    if (this.message.text) {
      let input = this.message.text
      input = input.replaceAll('\n', '<br>')

      this.text = anchorme({
        input,
        options: {
          attributes: {
            target: '_blank'
          }
        }
      })

      if (['sent', 'received'].includes(this.message.type)) {
        const urls = anchorme.list(input)
        if (urls.length > 0) {
          this.ogpUrl = urls[0].string
        }
      }
    }

    if (this.message.reply_to) {
      this.fetchMessage(this.message.reply_to).then((message) => {
        this.replyTo = message
      })
    }

    if (!['sent', 'received'].includes(this.message.type)) {
      return
    }

    if (this.message.mediafile_id || this.message.contact) {
      return
    }

    if (this.message.location === true) {
      return
    }

    const ranges = [
      '\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]',
      ' ' // Also allow spaces
    ].join('|')

    const removeEmoji = this.message.text.replace(new RegExp(ranges, 'g'), '')
    const isOnlyEmojis = !removeEmoji.length

    if (isOnlyEmojis && this.message.text.length <= 8) {
      this.emoji = true
    }
  },
  methods: {
    ...mapActions(['startConversation', 'setReminderStatus', 'fetchContact', 'deleteReminder', 'fetchMessage', 'fetchAgentAI']),
    getMediaUrl () {
      return `${process.env.VUE_APP_SERVERHOST}api/message/${this.selectedContact.id}/media/${this.message.mediafile_id}`
    },
    getDownloadUrl () {
      return this.getMediaUrl() + '/download'
    },
    initConversation (phone) {
      let phoneNumber = phone.replace('+', '')
      phoneNumber = phoneNumber.replaceAll(' ', '')

      this.startConversation(phoneNumber)
    },
    onChangeReminderDone (event) {
      let scrollTop = null
      const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
      if (conversationContainerEl) {
        scrollTop = conversationContainerEl.scrollTop
      }

      this.setReminderStatus({ reminderId: this.message.reminderId, status: !this.message.done, contact: this.selectedContact }).then(() => {
        this.fetchContact(this.selectedContact.id).then(() => {
          if (scrollTop !== null && conversationContainerEl) {
            conversationContainerEl.scrollTop = scrollTop
          }
        })
      })
    },
    onClickDeleteEvent () {
      let scrollTop = null
      const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
      if (conversationContainerEl) {
        scrollTop = conversationContainerEl.scrollTop
      }

      this.deleteReminder({ reminderId: this.message.reminderId, contact: this.selectedContact, messageId: this.message.id }).then(() => {
        this.fetchContact(this.selectedContact.id).then(() => {
          if (scrollTop !== null && conversationContainerEl) {
            conversationContainerEl.scrollTop = scrollTop
          }
        })
      })
    },
    stringToColor (str) {
      let i
      let hash = 0
      for (i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash)
      }
      let color = '#'
      for (i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xFF
        color += ('00' + value.toString(16)).substr(-2)
      }
      return color
    },
    getTooltipAlert (message) {
      const date = this.$moment(message.notification.date).format('DD MMM YYYY HH:mm')
      return `[${date}] ${message.notification.text}`
    },
    getMapsUrl () {
      return `https://www.google.com/maps?q=${this.message.lat},${this.message.long}&z=17`
    },
    onClickImage (message) {
      this.emitter.emit('openImageOverlay', { message, contact: this.selectedContact })
    },
    async openTrainingAgentAI (message) {
      this.emitter.emit('openTrainingModelAI', message)
    }
  }
}
</script>

<style scoped>
.message {
  color: #111b21;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  margin: 0 0 5px 0;
  max-width: 85%;
  word-wrap: break-word;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata-ai-robot {
  font-size: 14px;
  color: #636e72;
  display: inline-block;
  position: absolute;
  top: 3px;
  left: -30px;
  cursor: pointer;
  padding: 5px 0px 5px 4px;
}

.metadata-ai-robot:hover .fas.fa-pencil {
  display: inline-block;
}

.metadata-ai-robot:hover .fas.fa-robot {
  display: none;
}

.fas.fa-pencil {
  display: none;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.message.image .metadata,
.message.sticker .metadata,
.message.video .metadata,
.message.audio .metadata,
.message.voice .metadata,
.message.document .metadata,
.message.contact .metadata {
  text-align: right;
  padding: 0 0 0 0;
  width: 100%;
  float: none;
}

.message.document .data {
  min-height: 40px;
}

.message.location a {
  text-decoration: none;
}

.message.location .address {
  color: #111b21;
  font-size: 11px;
}

.message.document .data a {
  color: #111b21;
  text-decoration: none;
}

.message.document:hover .data a {
  color: #4285f4;
}

.message.document .data i {
  font-size: 20px;
  margin-right: 5px;
  margin-top: 13px;
  vertical-align: text-bottom;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 6px;
  height: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.received {
  background: #fff;
  border-radius: 0px 7.5px 7.5px 7.5px;
  width: fit-content;
}

.received .metadata {
  padding: 0 0 0 16px;
}

.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

.sent {
  background: #d9fdd3;
  border-radius: 7.5px 0px 7.5px 7.5px;
  width: fit-content;
  margin-left: auto;
}

.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.note {
  background-color: #ffefbe
}

.reminder {
  min-width: 210px;
  background-color: #ffdad1;
}

.reminder.done {
  background-color: rgba(225, 245, 254, 0.92);
  /*text-decoration: line-through;*/
}

.reminder-date {
  color: #111b21;
  background-color: rgb(250, 177, 160);
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 3px 10px;
  width: fit-content;
  text-align: center;
  line-height: 19px;
  position: relative;
  margin: 0 10px;
  right: unset;
  bottom: unset;
}

.reminder hr {
  margin: 10px 0;
}

.reminder-body {
  padding-top: 9px;
}

.reminder.done .reminder-date {
  background-color: #97DCFC;
}

.alert-scheduled {
  width: fit-content;
  height: fit-content;
  position: absolute;
  right: 27px;
  top: 6px;
}

.message .alert-scheduled img{
  height: 16px;
  width: fit-content;
  min-width: fit-content;
}

.assigned, .closed, .funnel-stage {
  background-color: rgba(225, 245, 254, 0.92);
}

.note, .assigned, .closed, .reminder, .funnel-stage {
  color: rgba(0, 0, 0, 0.88);
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  margin: 4px auto 5px auto;
  width: 85%;
  word-wrap: break-word;
  display: table;
  border-radius: 7.5px;
  box-shadow: 0 1px .5px rgba(0, 0, 0, .13);
}

.reminders-pending .reminder {
  margin: 4px 25px 5px 25px;
  max-width: calc(100% - 50px - 16px);
  width: calc(100% - 50px - 16px);
}

.note:after,
.assigned:after,
.closed:after,
.reminder:after,
.funnel-stage:after {
  display: none;
}

.date {
  width: 100%;
  margin: 13px auto;
  font-weight: 500;
  text-align: center;
  color: #667781;
}

.emoji span {
  font-size: 40px;
}

.emoji .text {
  line-height: 40px;
}

.message img,
.message video,
.message audio {
  min-width: 200px;
  max-width: 100%;
  max-height: 300px;
  cursor: pointer;
}

.message.sticker img {
  max-width: 150px;
  min-width: unset;
  cursor: auto;
}

.caption {
  margin-top: 5px;
  text-align: left;
  max-width: 300px;
}

.document-container {
  background-color: rgb(0, 0, 0, 0.06);
  border-radius: 7px;
  min-height: 40px;
  line-height: 40px;
  padding: 0 15px;
}

.message.image .placeholder,
.message.sticker .placeholder,
.message.video .placeholder,
.message.audio .placeholder,
.message.voice .placeholder{
  /*min-width: 150px;*/
  /*min-height: 100px;*/
  /*background-repeat: no-repeat;*/
  /*background-size: 100px;*/
  /*background-position: center;*/
  /*background-image: url('../../../assets/img_placeholder.svg');*/
  text-align: center;
}

.message.received img {
  background-color: white;
}

.message.sent img {
  background-color: rgb(225, 255, 199);
}

.message.contact {
  padding-top: 12px;
}

.message.contact hr {
  margin: 8px 0;
}

.message.contact .data {
  padding: 0 15px;
}

.message.contact .phone {
  padding: 3px 0;
}

.message.contact .phone i {
  margin-left: 5px;
  cursor: pointer;
}

.error {
  background-color: transparent;
  font-size: 10px;
  color: #cc3d3d;
  margin-bottom: 5px;
  margin-top: -3px;
}

audio::-webkit-media-controls-panel {
  background-color: #b9d2fb;
}

.participant {
  color: #4285f4;
  font-size: 14px;
}

.reminders-pending .reminder {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  height: inherit;
}

.reminders-pending .reminder .metadata {
  display: none;
}

.reminder input {
  position: absolute;
  right: 25px;
  top: 7px;
  z-index: 20000;
}

.reminders-pending .reminder input {
  top: 9px;
}

.reminders-pending .reminder .reminder-date {
  margin-left: 12px;
}

.reminder hr {
  margin: 10px 20px;
}

.reminders-pending .reminder hr {
  margin: 10px 20px 10px 40px;
}

.reminder .calendar-delete {
  position: absolute;
  right: 10px;
  top: 31px;
  cursor: pointer;
}

.reminders-pending .reminder .calendar-delete {
  top: 11px;
}

.bell {
  display: inline;
  position: absolute;
  right: 13px;
  top: 5px;
}

.reminders-pending .reminder .calendar {
  margin-left: 5px;
  margin-top: 4px;
}

.reminders-pending .reminder .bell {
  right: 15px;
  top: 6px;
  position: absolute;
}

.notification-date {
  border: 1px solid rgb(250, 177, 160);
  background: transparent;
}

.reminder.done .notification-date {
  border: 1px solid #97DCFC;
  background-color: transparent;
}

.dropdown {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 200px;
  text-align: right;
}

.reminders-pending .dropdown {
  margin-top: 2px;
}

.dropdown-list {
  text-align: left;
  position: absolute;
  right: 0px;
  z-index: 10000;
  display: none;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #E3E8EA;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  margin-right: -5px;
}

.dropdown-list div {
  padding: 6px;
}

.dropdown-list div:hover {
  background-color: #4285f4;
  color: white;
}

.dropdown-list div:not(:first-of-type){
  border-top: 1px solid #E3E8EA;
}

.dropdown:hover .dropdown-list {
  display: block;
}

.message .reply {
  color: #969595;
  font-size: 11px;
  margin-bottom: 2px;
}

.message .reply .message {
  margin: 0;
  border-left: 5px solid #4285f4;
  border-radius: 7.5px;
  background-color: #5e5e5e21;
}

.emoji .reply span {
  font-size: 14px;
}

.emoji .reply .text {
  line-height: 18px;
}

.message .reply .message .metadata {
  display: none;
}

.message .reply .message:after {
  border: 0px;
}

</style>
