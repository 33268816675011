<template>
  <div class="settings-group">
    <h3>{{ $t('settings.custom_fields.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.custom_fields.description') }}</p>
      <button @click="newCF" class="button" type="submit">{{ $t('settings.custom_fields.button_create') }}</button>
    </div>
    <table v-if="customFields && customFields.length > 0">
      <thead>
        <th style="width: 72%">{{ $t('settings.custom_fields.name') }}</th>
        <th style="width: 20%">{{ $t('settings.custom_fields.created_at') }}</th>
        <th style="width: 8%"></th>
      </thead>
      <tbody>
        <tr :key="cf.id" v-for="cf in customFields">
          <td>{{ cf.name }}</td>
          <td>{{ $moment(cf.createdAt).format('L') }}</td>
          <td>
            <i @click="editCF(cf)" class="far fa-edit"></i>
            <i @click="deleteCF(cf)" class="far fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <NewCustomField v-if="this.newCustomFieldOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewCustomField from '../../../modals/NewCustomField'

export default {
  name: 'SectionCustomFields',
  components: {
    NewCustomField
  },
  computed: mapGetters(['customFields', 'newCustomFieldOpen']),
  methods: {
    ...mapActions([
      'fetchCustomFields',
      'emptyCustomFields',
      'toggleNewCustomField',
      'setNewCustomFieldData',
      'emptyNewCustomFieldData',
      'deleteCustomField'
    ]),
    newCF () {
      this.emptyNewCustomFieldData()
      this.toggleNewCustomField()
    },
    editCF (cf) {
      this.setNewShortResponseData(cf)
      this.toggleNewCustomField()
    },
    deleteCF (cf) {
      if (confirm(this.$t('settings.custom_fields.delete.confirm', { name: cf.name }))) {
        this.deleteCustomField(cf)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    }
  },
  created () {
    this.fetchCustomFields()
  }
}
</script>

<style scoped>
  p {
    width: calc(100% - 200px);
    max-width: 600px;
    float: left;
    margin: 0;
  }

  table {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
  }

  thead {
    height: 40px;
    text-align: left;
    border-bottom: 1px solid #EDEFF1;
  }

  tr {
    height: 50px;
    border-bottom: 1px solid #EDEFF1;
  }

  tr i {
    margin-left: 10px;
    cursor: pointer;
  }
</style>
