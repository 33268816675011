<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3>{{ $t('settings.promote.new.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="sr-title">{{ $t('settings.promote.new.number') }}</label>
      <input type="number" id="sr-title" v-model="this.newPromoteData.number">
    </div>

    <div class="form-group">
      <label for="sr-text">{{ $t('settings.promote.text') }}</label>
      <textarea id="sr-text" v-model="this.newPromoteData.text"></textarea>
    </div>

    <button :disabled="this.newPromoteData.number === ''" class="button" @click="onClickSubmit">{{ $t('settings.promote.submit') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewPromote',
  computed: mapGetters(['newPromoteData']),
  methods: {
    ...mapActions(['toggleNewPromote', 'createPromote', 'emptyNewPromoteData']),
    onClickSubmit () {
      if (this.newPromoteData.id === null) {
        this.createPromote(this.newPromoteData)
      }

      this.toggleNewPromote()
      this.emptyNewPromoteData()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    close () {
      this.toggleNewPromote()
    }
  }
}
</script>

<style scoped>
.modal {
  height: 320px
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>
