import axios from 'axios'
import router from '@/router'

const state = {
  selectedEmail: null,
  inboundEmails: null,
  page: 0
}
const getters = {
  selectedEmail: (state) => state.selectedEmail,
  inboundEmails: (state) => state.inboundEmails,
  inboundEmailsPage: (state) => state.page
}
const actions = {
  async fetchInboundEmails ({ commit, rootState }) {
    commit('setInboundEmailsPage', 0)
    const query = {
      page: 0
    }

    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/inbound_emails`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      },
      params: query
    })

    const contactListEl = document.getElementById('contact-list')
    if (contactListEl) {
      contactListEl.scrollTop = 0
    }

    commit('setInboundEmails', res.data)
  },
  async fetchInboundEmailsNextPage ({ commit, rootState }) {
    commit('setInboundEmailsPage', state.page + 1)

    const query = {
      page: state.page
    }

    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/inbound_emails`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      },
      params: query
    })

    commit('addInboundEmails', res.data)
    return res.data.length > 0
  },
  async fetchInboundEmail ({ commit, dispatch, rootState }, emailId) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/inbound_emails/${emailId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setSelectedEmail', res.data)

    await router.push(`/email/${emailId}`)
    // if (res.data.contact) {
    //   commit('setSelectedContact', res.data.contact)
    // }
  },
  async markInboundEmailAsManaged ({ commit, dispatch, rootState }, email) {
    const res = await axios.put(`${process.env.VUE_APP_SERVERHOST}api/inbound_emails/${email.id}/managed`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setSelectedEmail', res.data)
    commit('updateInboundEmails', res.data)
  },
  async markInboundEmailAsUnmanaged ({ commit, dispatch, rootState }, email) {
    const res = await axios.put(`${process.env.VUE_APP_SERVERHOST}api/inbound_emails/${email.id}/unmanaged`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setSelectedEmail', res.data)
    commit('updateInboundEmails', res.data)
  },
  setInboundEmailsPage ({ commit }, page) {
    commit('setInboundEmailsPage', page)
  },
  setSelectedEmail ({ commit }, email) {
    commit('setSelectedEmail', email)
  }
}
const mutations = {
  setSelectedEmail: (state, email) => (state.selectedEmail = email),
  setInboundEmailsPage: (state, page) => (state.page = page),
  setInboundEmails: (state, inboundEmails) => (state.inboundEmails = inboundEmails),
  addInboundEmails: (state, inboundEmails) => {
    state.inboundEmails = state.inboundEmails.concat(inboundEmails)
  },
  updateInboundEmails: (state, updEmail) => {
    const index = state.inboundEmails.findIndex(email => email.id === updEmail.id)
    if (index !== -1) {
      state.inboundEmails.splice(index, 1, updEmail)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
