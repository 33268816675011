<template>
  <div id="contact-list" class="container-list">
    <div v-if="organization" class="container-list-header">
      <div style="font-size: 15px; color: black; font-weight: bold">{{ $t('emails.title') }}</div>
      <button class="button refresh" style="display: inline-flex" type="submit" @click="fetchInboundEmails" v-tooltip.right="{ content: $t('emails.subtitle', { number: organization.number.replace('+','')}) }">
        <i class="fa fa-refresh fa-lg" style="margin-top: 20px;margin-right: 4px; font-size: 12px" aria-hidden="true"></i>
        <p style="font-size: 13px; bottom: 1px">{{$t('emails.button_refresh', {number:organization.number.replace('+', '')})}}</p>
      </button>
    </div>
    <ul v-infinite-scroll="this.loadMode" infinite-scroll-distance="500" v-if="inboundEmails && inboundEmails.length > 0">
      <ListItem :key="email.id" v-for="email in inboundEmails" :email="email" />
    </ul>
    <ul v-if="inboundEmails && inboundEmails.length === 0">
      <ListItemEmptyResult />
    </ul>
    <ul v-if="!inboundEmails">
      <ListItemNotLoaded v-for="index in 10" :key="index" />
    </ul>
  </div>
</template>

<script>
import ListItem from './ListItem'
import ListItemNotLoaded from './ListItemNotLoaded'
import ListItemEmptyResult from './ListItemEmptyResult'

import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'List_Emails',
  computed: mapGetters(['inboundEmails', 'inboundEmailsPage', 'organizationUsers', 'organization']),
  data () {
    return {
      busy: false,
      hasMoreData: true
    }
  },
  methods: {
    ...mapActions(['fetchInboundEmails', 'fetchInboundEmailsNextPage', 'fetchInboundEmail']),
    loadMode () {
      if (this.busy || (this.page > 0 && !this.hasMoreData)) {
        return
      }

      this.busy = true
      setTimeout(async () => {
        const hasMoreData = await this.fetchInboundEmailsNextPage()
        this.hasMoreData = hasMoreData
        this.busy = false
      }, 1)
    },
    reset () {
      this.hasMoreData = true
    }
  },
  created () {
    this.fetchInboundEmails().then(() => {
      const emailId = this.$route.params.emailId
      if (undefined !== emailId) {
        this.fetchInboundEmail(emailId)
      }
    })
  },
  components: {
    ListItem,
    ListItemEmptyResult,
    ListItemNotLoaded
  }
}

</script>

<style scoped>
.container-list {
  width: 300px;
  float: left;
  border-right: 1px solid #EDEFF1;
  height: calc(100vh - 67px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.container-list-header {
  border-bottom: 1px solid #EDEFF1;
  padding: 10px 15px 10px 15px;
  text-align: left;
  font-weight: 400;
  color: #5299dc;
}

.container-list-header img {
  height: 15px;
  cursor: pointer;
}

.container-list-header .filters-close {
  position: absolute;
  top: 10px;
  left: 18px;
  cursor: pointer;
}

.container-list-header .filters {
  font-size: 14px;
  text-align: left;
  margin-bottom: 10px;
}

.container-list-header .filters input[type=checkbox] {
  margin-top: 7px;
  vertical-align: bottom;
}

.menu-collapse .container-list {
  width: 330px;
}

button.refresh {
  padding: 0 15px;
  right: 0px;
  position: relative;
  margin-top: 15px;
  width: fit-content;
  bottom: 0;
  line-height: 14px;
}

ul {
  margin: 0;
  padding: 0;
}
</style>
