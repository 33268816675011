<template>
  <div class="content-panel">
    <div class="content-wrapper">
      <SectionProfile v-if="'profile' === this.section"/>
      <div v-if="loggedUser && loggedUser.canSeeSettings">
        <SectionWhatsapp v-if="'whatsapp' === this.section"/>
        <SectionInvoicing v-if="'invoicing' === this.section"/>
        <SectionImport v-if="'import' === this.section"/>
        <SectionWidget v-if="'widget' === this.section"/>
        <SectionTags v-if="'tags' === this.section"/>
        <SectionShortResponses v-if="'short_responses' === this.section"/>
        <SectionPermissions v-if="'permissions' === this.section"/>
        <SectionTeams v-if="'teams' === this.section"/>
        <SectionAutoReply v-if="'auto_reply' === this.section"/>
        <SectionDevelopers v-if="'developers' === this.section"/>
        <SectionRouting v-if="'routing' === this.section"/>
        <SectionPromote v-if="'promote' === this.section"/>
        <SectionCustomFields v-if="'custom_fields' === this.section"/>
        <SectionChatbot v-if="'chatbot' === this.section"/>
        <SectionZapier v-if="'zapier' === this.section"/>
        <SectionMobileApps v-if="'apps' === this.section"/>
        <SectionTemplates v-if="'templates' === this.section"/>
        <SectionFunnels v-if="'funnels' === this.section"/>
        <SectionKlaviyo v-if="'klaviyo' === this.section"/>
        <SectionZadarma v-if="'zadarma' === this.section"/>
        <SectionAI v-if="'ai' === this.section"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SectionProfile from '@/components/home/settings/sections/SectionProfile'
import SectionInvoicing from '@/components/home/settings/sections/SectionInvoicing'
import SectionImport from '@/components/home/settings/sections/SectionImport'
import SectionWidget from '@/components/home/settings/sections/SectionWidget'
import SectionTags from '@/components/home/settings/sections/SectionTags'
import SectionShortResponses from '@/components/home/settings/sections/SectionShortResponses'
import SectionPermissions from '@/components/home/settings/sections/SectionPermissions'
import SectionTeams from '@/components/home/settings/sections/SectionTeams'
import SectionAutoReply from '@/components/home/settings/sections/SectionAutoReply'
import SectionWhatsapp from '@/components/home/settings/sections/SectionWhatsapp'
import SectionDevelopers from '@/components/home/settings/sections/SectionDevelopers'
import SectionRouting from '@/components/home/settings/sections/SectionRouting'
import SectionPromote from '@/components/home/settings/sections/SectionPromote'
import SectionCustomFields from '@/components/home/settings/sections/SectionCustomFields'
import SectionChatbot from '@/components/home/settings/sections/SectionChatbot'
import SectionZapier from '@/components/home/settings/sections/SectionZapier'
import SectionMobileApps from '@/components/home/settings/sections/SectionMobileApps'
import SectionTemplates from '@/components/home/settings/sections/SectionTemplates'
import SectionFunnels from '@/components/home/settings/sections/SectionFunnels'
import SectionKlaviyo from '@/components/home/settings/sections/SectionKlaviyo'
import SectionZadarma from '@/components/home/settings/sections/SectionZadarma'
import SectionAI from '@/components/home/settings/sections/SectionConchi.vue'

export default {
  name: 'SettingsPanel',
  computed: mapGetters(['section', 'loggedUser']),
  components: {
    SectionKlaviyo,
    SectionMobileApps,
    SectionPromote,
    SectionWhatsapp,
    SectionProfile,
    SectionInvoicing,
    SectionImport,
    SectionWidget,
    SectionTags,
    SectionShortResponses,
    SectionTeams,
    SectionPermissions,
    SectionAutoReply,
    SectionDevelopers,
    SectionRouting,
    SectionCustomFields,
    SectionChatbot,
    SectionZapier,
    SectionTemplates,
    SectionFunnels,
    SectionZadarma,
    SectionAI
  }
}
</script>

<style scoped>
.content-panel {
  height: calc(100vh - 67px);
  width: calc(100%);
  margin-left: 330px;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  overflow: scroll;
}

.importantAlert .content-panel, .unpaidInvoiceAlert .content-panel, .mustMigrate .content-panel {
  height: calc(100vh - 127px);
}

.content-wrapper {
  width: calc(100% - 300px - 71px - 30px);
  margin: 55px 30px 30px 0px;
}

</style>

<style>
.settings-group {
  border-radius: 4px;
  border: 1px solid #EDEFF1;
  padding: 0 20px 20px 20px;
  margin-right: 30px;
  margin-bottom: 1rem;
  min-height: 200px;
  position: relative;
}

.form-group {
  margin-bottom: 1rem;
  min-height: 20px;
}

.form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

.form-group input,
.form-group select,
.form-group textarea {
  display: block;
  width: calc(100% - 24px);
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
}

.form-group textarea {
  height: 100px;
  z-index: 1;
  position: relative;
}

.form-group select {
  width: 50%;
}

.form-group button[type=submit] {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
  text-align: center;
  bottom: unset;
  position: relative;
  min-width: 100px;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  padding: 0 25px;
  float: right;
  width: fit-content;
}

.form-group button[type=submit]:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.form-group button[type=submit]:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.button-rounded-small {
  background-color: #4285f4;
  color: #ffffff;
  height: 20px;
  width: 20px;
  text-align: center;
  position: absolute;
  cursor: pointer;
  padding: 0;
  border-radius: 100%;
  border: 0;
  z-index: 1000;
  left: 5px;
}

.button-rounded-small.level-empty {
  bottom: -10px !important;
}

.steps-container {
  position: relative;
  margin-bottom: 50px;
}

.steps-container .button-rounded-small {
  bottom: -35px;
}
</style>
