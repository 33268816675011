<template>
  <div>
    <ImportantAlert />
    <UnpaidInvoiceAlert />
    <Navbar />
    <div class="content">
      <Sidebar />
      <div style="padding: 20px;">
        <h1 class="title">{{ $t('campaign.campaigns') }}</h1>
        <button class="button" @click="openNewCampaign()">{{ $t('campaign.new_campaign')}}</button>

        <br>
        <br>
        <div class="stats-totals" v-if="campaigns && campaigns.totals">
          <div class="kpi">
            <h1>{{ formatNumber(campaigns.totals.total) }}</h1>
            <div>Mensajes Enviados</div>
          </div>

          <div class="kpi">
            <h1>{{ formatNumber(campaigns.totals.received) }}</h1>
            <div>Mensajes Recibidos</div>
            <div class="percent">{{ Math.floor((campaigns.totals.received/campaigns.totals.total) * 100) }}%</div>
          </div>

          <div class="kpi">
            <h1>{{ formatNumber(campaigns.totals.read) }}</h1>
            <div>Mensajes Leídos</div>
            <div class="percent">{{ Math.floor((campaigns.totals.read/campaigns.totals.total) * 100) }}%</div>
          </div>

          <div hidden class="kpi">
            <h1>{{ formatNumber(campaigns.totals.responded) }}</h1>
            <div>Mensajes respondidos</div>
            <div class="percent">{{ Math.floor((campaigns.totals.responded/campaigns.totals.total) * 100) }}%</div>
          </div>

          <div class="kpi">
            <h1>{{ formatNumber(campaigns.totals.blacklist) }}</h1>
            <div>Blacklist</div>
            <div class="percent">{{ Math.floor((campaigns.totals.blacklist/campaigns.totals.total) * 100) }}%</div>
          </div>
        </div>

        <div style="overflow-x: scroll; height: calc(100vh - 290px)">
          <table v-if="campaigns && campaigns.campaigns && campaigns.campaigns.length > 0">
            <thead>
            <th style="width: 30%">Nombre</th>
            <th style="">Fecha</th>
            <th style="">Estado</th>
            <th style="">Recibidos</th>
            <th style="">Leídos</th>
            <th hidden style="">Respondidos</th>
            <th style="">Blacklist</th>
            <th style="width: 3%"></th>
            </thead>
            <tbody>
            <tr v-for="campaign in campaigns.campaigns" :key="campaign.id" :class="{'paused': campaign.paused === 1}">
              <td>
                <div>
                  <div class="" style="text-transform: capitalize; font-weight: 500">{{ campaign.name }}</div>
                  <div v-if="campaign.type === 'klaviyo'" class="second-line" style="text-transform: capitalize">{{ campaign.type }}: {{ campaign.query.klaviyoAudience.name }} ({{ campaign.query.klaviyoAudience.type }})</div>
                  <div v-if="campaign.type !== 'klaviyo'" class="second-line" style="text-transform: capitalize">{{ campaign.type }}</div>
                </div>
                <div v-if="campaign.paused === 1" class="paused-alert">
                  WhatsApp ha pausado tu campaña porque un volumen elevado de receptores la están marcando como <b>envío no deseado.</b>
                  <br>
                  Se ha creado una etiqueta llamada <b>PAUSED_{{ campaign.name.toUpperCase().replaceAll(' ', '_') }}</b> con los contactos a los que NO se les ha enviado la campaña y los créditos se han devuelto a tu balance.
                </div>
              </td>
              <td v-if="campaign.date">
                {{ $moment(campaign.date).format('DD MMM YYYY HH:mm') }}
              </td>
              <td v-if="!campaign.date">
                {{ $moment(campaign.createdAt).format('DD MMM YYYY - HH:mm') }}
              </td>
              <td>
                <div v-if="campaign.sent === 0 && campaign.scheduled">
                  <div class="campaign-status scheduled"><i class="fas fa-clock"></i> Programado</div>
                  <div class="second-line">{{ formatNumber(campaign.total) }} mensajes</div>
                </div>
                <div v-if="campaign.sent === 0 && !campaign.scheduled">
                  <div class="campaign-status sending"><i class="fas fa-play-circle"></i> Enviando</div>
                  <div class="second-line">{{ Math.floor((campaign.completed/campaign.total) * 100) }}% ({{ formatNumber(campaign.completed) }} de {{ formatNumber(campaign.total) }} mensajes)</div>
                </div>
                <div v-if="campaign.sent === 1 && campaign.paused === 0">
                  <div class="campaign-status sent"><i class="fas fa-check-circle"></i> Enviado</div>
                  <div class="second-line">{{ formatNumber(campaign.total) }} destinatarios</div>
                </div>
                <div v-if="campaign.sent === 1 && campaign.paused === 1">
                  <div class="campaign-status paused"><i class="fas fa-pause-circle"></i> Pausada</div>
                  <div class="second-line">{{ formatNumber(campaign.total) }} destinatarios</div>
                </div>
              </td>
              <td>
                <div v-if="campaign.sent === 1">
                  <div v-if="campaign.total === 0" class="first-line">-</div>
                  <div v-if="campaign.total > 0" class="first-line">{{ Math.floor((campaign.received/campaign.total) * 100) }}%</div>
                  <div class="second-line">{{ formatNumber(campaign.received) }} destinatarios</div>
                </div>
              </td>
              <td>
                <div v-if="campaign.sent === 1">
                  <div v-if="campaign.total === 0" class="first-line">-</div>
                  <div v-if="campaign.total > 0" class="first-line">{{ Math.floor((campaign.read/campaign.total) * 100) }}%</div>
                  <div class="second-line">{{ formatNumber(campaign.read) }} destinatarios</div>
                </div>
              </td>
              <td hidden>
                <div v-if="campaign.sent === 1">
                  <div v-if="campaign.total === 0" class="first-line">-</div>
                  <div v-if="campaign.total > 0" class="first-line">{{ Math.floor((campaign.read/campaign.total) * 100) }}%</div>
                  <div class="second-line">{{ formatNumber(campaign.read) }} destinatarios</div>
                </div>
              </td>
              <td>
                <div v-if="campaign.sent === 1">
                  <div v-if="campaign.total === 0" class="first-line">-</div>
                  <div v-if="campaign.total > 0" class="first-line">{{ Math.floor((campaign.blacklist/campaign.total) * 100) }}%</div>
                  <div class="second-line">{{ formatNumber(campaign.blacklist) }} destinatarios</div>
                </div>
              </td>
              <td style="text-align: right">
                <i v-if="campaign.scheduled" @click="delCampaign(campaign)" class="far fa-trash-alt" v-tooltip.left="{ content: $t('campaign.delete.button') }"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <MobileSidebar />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import Navbar from '../components/home/Navbar'
import Sidebar from '../components/home/Sidebar'
import NewMessage from '../components/modals/NewMessage'

import { mapActions, mapGetters } from 'vuex'
import ImportantAlert from '@/components/home/ImportantAlert'
import UnpaidInvoiceAlert from '@/components/home/UnpaidInvoiceAlert'

import MobileSidebar from '../components/mobile/Sidebar'

import jwt from 'jsonwebtoken'

export default {
  name: 'Campaigns',
  components: {
    UnpaidInvoiceAlert,
    ImportantAlert,
    Navbar,
    Sidebar,
    NewMessage,
    MobileSidebar
  },
  computed: mapGetters(['campaigns', 'organization', 'newMessageOpen']),
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      today: today,
      newYear: newYear,
      modalOpen: false,
      locale: 'es'
    }
  },
  methods: {
    ...mapActions(['fetchCampaigns', 'deleteCampaign', 'setFilterView', 'setSection']),
    getUrl (dashboard, campaignId) {
      const params = {
        organization: this.organization.id,
        text_campaign_sent: this.$t('campaign.messages.sent'),
        text_campaign_received: this.$t('campaign.messages.received'),
        text_campaign_read: this.$t('campaign.messages.read')
      }

      if (campaignId) {
        params.campaign_id = campaignId
      }

      const payload = {
        resource: { dashboard: dashboard },
        params: params,
        exp: Math.round(Date.now() / 1000) + (10 * 60)
      }

      const secretKey = process.env.VUE_APP_METABASE_SECRET_KEY
      const token = jwt.sign(payload, secretKey)

      return process.env.VUE_APP_METABASE_SITE_URL + '/embed/dashboard/' + token + '#bordered=false&titled=false'
    },
    openNewCampaign () {
      const query = {
        filter: null,
        selectedTags: [],
        selectedUsers: [],
        onlyClosedConversations: false,
        onlyUnreadConversations: false,
        conversationsWithReminders: false,
        conversationsWithScheduled: false
      }

      this.emitter.emit('openBulkSend', query)
    },
    delCampaign (campaign) {
      if (confirm(this.$t('campaign.delete.confirm', { name: campaign.name }))) {
        this.deleteCampaign(campaign)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    },
    formatNumber (number, decPlaces = 2) {
      decPlaces = Math.pow(10, decPlaces)

      const abbrev = ['k', 'm', 'b', 't']

      for (let i = abbrev.length - 1; i >= 0; i--) {
        const size = Math.pow(10, (i + 1) * 3)

        if (size <= number) {
          number = Math.round(number * decPlaces / size) / decPlaces
          if ((number === 1000) && (i < abbrev.length - 1)) {
            number = 1
            i++
          }

          number += abbrev[i]
          break
        }
      }
      return number
    }
  },
  created () {
    this.fetchCampaigns()

    this.setFilterView(null)
    this.setSection(null)
  }
}
</script>

<style scoped>
.content {
  margin-top: 67px;
  width: calc(100% - 71px);
  margin-left: 71px;
}

.importantAlert .content, .unpaidInvoiceAlert .content, .mustMigrate .content {
  height: calc(100vh - 126px);
  margin-top: 127px;
}

.menu-collapse .content {
  width: calc(100% - 250px - 70px);
  margin-left: 70px;
}

.mobile .content {
  width: 100%;
  margin-left: 0px;
}

.slide {
  top: 0;
  bottom: 0;
  height: unset;
  right: 0;
  width: 400px;
  margin-left: unset;
  left: unset;
}

h1.title {
  float: left;
  border: 0;
  line-height: 40px;
  margin: 0;
  margin-right: 30px;
}

button {
  position: relative;
  bottom: unset;
  width: fit-content;
  padding: 0 10px;
}

.stats-totals {
  display: flex;
}

.stats-totals .kpi {
  width: calc(100% / 5);
  text-align: center;
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  margin: 10px;
  padding: 20px 10px;
}

.stats-totals .kpi:first-of-type {
  margin-left: 0;
}

.stats-totals .kpi:last-of-type {
  margin-right: 0;
}

.stats-totals .kpi h1 {
  margin: 0;
  font-size: 48px;
}

.stats-totals .kpi .percent {
  font-size: 12px;
}

table {
  width: 100%;
  text-align: left;
}

table thead {
  height: 50px;
}

table thead th {
  border-bottom: 1px solid #EDEFF1;
}

table tbody tr {
  vertical-align: baseline;
}

table tbody tr.paused {
  height: 100px;
}

table tbody tr.paused div.paused-alert {
  background-color: rgba(255, 234, 167, 0.5);
  height: 33px;
  border-radius: 4px;
  position: absolute;
  width: calc(100vw - 135px);
  bottom: 7px;
  padding: 3px 10px;
  font-size: 11px;
  line-height: 17px;
}

table tbody tr td {
  border-bottom: 1px solid #EDEFF1;
  padding: 6px 0px;
  position: relative;
}

table tbody tr td .campaign-status {
  padding: 2px 6px;
  height: 20px;
  border-radius: 20px;
  font-weight: 600;
  width: fit-content;
  line-height: 20px;
}

table tbody tr td .campaign-status.scheduled {
  background-color: rgb(116, 185, 255, 0.5);
  color: #0984e3;
}

table tbody tr td .campaign-status.sending {
  background-color: rgb(162, 155, 254, 0.5);
  color: #6c5ce7;
}

table tbody tr td .campaign-status.sent {
  background-color: rgb(85, 239, 196, 0.5);
  color: #00b894;
}

table tbody tr td .campaign-status.paused {
  background-color: rgba(255, 234, 167, 0.5);
  color: #faa810;
}

table tbody tr td .first-line {
  font-weight: 600;
  font-size: 20px;
}

table tbody tr td .second-line {
  font-size: 12px;
  color: #6f6f70;
  margin-top: 3px;
}

table tbody tr td i {
  cursor: pointer;
}
</style>

<style>
.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}

.EmbedFrame-footer {
  display: none !important;
}

</style>
