<template>
  <div v-if="modalOpen" class="overlay" @click="closeOverlay"></div>
  <div v-if="modalOpen" class="modal conchi-user">
    <h3 v-if="conchiUser && !conchiUser.id" > {{ $t('settings.agent_ai.new.title') }}</h3>
    <h3 v-if="conchiUser && conchiUser.id">{{ $t('settings.agent_ai.edit.title', { name: this.conchiUser.name } ) }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
    <div v-if="conchiUser" class="tabs">
      <div class="tab" :class="{active: this.tabSelected === 'step1'}" @click="this.tabSelected = 'step1'">{{ $t('settings.agent_ai.triggers.step') }}</div>
      <div class="tab" :class="{active: this.tabSelected === 'step2'}" @click="this.tabSelected = 'step2'">{{ $t('settings.agent_ai.edit.step') }}</div>
      <div class="tab" :class="{active: this.tabSelected === 'step3'}" @click="this.tabSelected = 'step3'">{{ $t('settings.agent_ai.outcome.step') }}</div>
    </div>

    <div v-if="conchiUser" class="scrolleable">
        <div v-if="this.tabSelected === 'step1'">
          <div class="form-group">
            <label for="nameAgent">{{ $t('settings.agent_ai.name') }}</label>
            <input v-model="conchiUser.name" type="text" id="nameAgent" name="nameAgent" class="input-data">
          </div>

          <div class="form-group">
            <label>{{ $t('settings.agent_ai.triggers.title') }}</label>
            <div>
              <div class="switch">
                <div><Switch v-model:checked="conchiUser.triggers.allChatsWithoutAssigned" /></div>
                <div class="switch-triggers">{{ $t('settings.agent_ai.triggers.all_chats_without_assigned') }}</div>
              </div>
              <div class="switch">
                <div><Switch v-model:checked="conchiUser.triggers.allChatsClosed"/></div>
                <div class="switch-triggers">{{ $t('settings.agent_ai.triggers.all_chats_closed') }}</div>
              </div>
              <div class="switch">
                <div><Switch v-model:checked="conchiUser.triggers.allChatsWithoutConversations"/></div>
                <div class="switch-triggers">{{ $t('settings.agent_ai.triggers.all_chats_without_conversations') }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.tabSelected === 'step2'">
          <div class="form-group">
            <label>{{ $t('settings.agent_ai.edit.block1') }}</label>
            <div>
              <textarea :maxlength="promptMaxLength" v-model="conchiUser.block_1" :placeholder="$t('settings.agent_ai.edit.block1_placeholder')"/>
              <div class="char-counter">{{ conchiUser.block_1.length }} / {{ promptMaxLength }}</div>
              <div class="prompt-explain">
                *{{ $t('settings.agent_ai.edit.block1_description') }}
              </div>
            </div>
          </div>

          <div class="form-group">
            <label>{{ $t('settings.agent_ai.edit.block3') }}</label>
            <div>
              <textarea :maxlength="promptMaxLength" v-model="conchiUser.block_3" :placeholder="$t('settings.agent_ai.edit.block3_placeholder')"/>
              <div class="char-counter">{{ conchiUser.block_3.length }} / {{ promptMaxLength }}</div>
              <div class="prompt-explain">
                *{{ $t('settings.agent_ai.edit.block3_description') }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="this.tabSelected === 'step3'">
          <div style="margin-bottom: 40px;">
            <div class="form-group">
              <label>{{ $t('settings.agent_ai.edit.block2') }}</label>
              <div>
                <textarea :maxlength="promptMaxLength" v-model="conchiUser.block_2" :placeholder="$t('settings.agent_ai.edit.block2_placeholder')"/>
                <div class="char-counter">{{ conchiUser.block_2.length }} / {{ promptMaxLength }}</div>
                <div class="prompt-explain">
                  *{{ $t('settings.agent_ai.edit.block2_description') }}
                </div>
              </div>
            </div>
            <div :key="idx" v-for="(scenario, idx) in conchiUser.scenarios">
              <div class="form-group">
                <label>{{ $t('settings.agent_ai.edit.block4.if') }}</label>
                <input v-model="scenario.if" type="text" id="outcomeIfAgent" name="outcomeIfAgent" class="input-data" :placeholder="$t('settings.agent_ai.edit.block4.if_placeholder')">
              </div>
              <div class="form-group">
                <label>{{ $t('settings.agent_ai.edit.block4.say') }}</label>
                <input v-model="scenario.say" type="text" id="outcomeSayAgent" name="outcomeSayAgent" class="input-data" :placeholder="$t('settings.agent_ai.edit.block4.say_placeholder')">
              </div>

            <div>
              {{ $t('settings.agent_ai.outcome.should_do') }}
            </div>
            <div style="display: flex; margin-top: 15px;">
              <div class="switch">
                <div><Switch v-model:checked="scenario.outcome.open" /></div>
                <div class="switch-triggers">{{ $t('settings.agent_ai.outcome.archive_conversation') }}</div>
              </div>
            </div>
            <br>
            <div style="display: flex; text-align: center">
              <div>
                <i style="margin-top: 10px; margin-left: 4px;" class="fas fas fa-user"></i>
              </div>
              <div v-if="organization && organizationUsers" class="group" v-tooltip.left="{ content: $t('messages.crm.user_assigned') }">
                <v-select class="noclear select-conchi-user" style="margin-left:10px;" v-model="scenario.outcome.user" :reduce="user => user.id" :options="this.organizationUsers" label="name" :placeholder="$t('messages.crm.user_assigned_placeholder')">
                  <template #option="option">
                    <span>
                      <i v-if="!option.ai" class="fas fa-user" aria-hidden="true" style="margin-left: 3px;  margin-right: 4px"></i>
                      <i v-if="option.ai" class="fas fa-robot" aria-hidden="true" style="margin-right: 4px"></i>
                      {{ option.name }}
                    </span>
                  </template>
                </v-select>
              </div>
              <div>
                <i style="margin-top: 12px; margin-left: 48px" class="fas fa-filter"></i>
              </div>
              <div v-if="organization && stagesList" class="group" v-tooltip.right="{ content: $t('sidebar.pipelines') }">
                <v-select class="noclear select-conchi-user select-conchi-user-pipelines" v-model="scenario.outcome.stage" :options="this.stagesList" label="name" :placeholder="$t('messages.crm.user_assigned_placeholder')" :selectable="(option) => option.id !== 'notSelectable' && option.funnelId !== 'notSelectable'">
                  <template #option="option">
                    <span style="display: flex">
                      <div v-if="option.funnelId === 'notSelectable'" style="margin-top: 5px;"></div>
                      <i v-if="option.id === 'notSelectable'" style="margin-top: 1px; margin-left: 0px; font-size: 13px" class="fas fa-filter"></i>
                      <div v-if="option.id !== 'notSelectable'" style="font-size: 13px; margin-left: 13px">{{ option.name }}</div>
                      <div v-if="option.id === 'notSelectable'" style="font-size: 13px;">{{ option.name }}</div>
                    </span>
                  </template>
                </v-select>
              </div>
            </div>
            <br>
            <div style="display: flex">
              <div>
                <i style="margin-top: 10px; margin-left: 2px" class="fas fas fa-users"></i>
              </div>
              <div v-if="teams" class="group" v-tooltip.left="{ content: $t('messages.crm.teams') }">
                <v-select class="noclear select-conchi-user"  style="margin-left:6px;" @option:selected="onSelectAddTeam(this.selectedTeam, scenario)" v-model="this.selectedTeam" :reduce="team => team.id" :options="this.teams" :selectable="(option) => !scenario.outcome.teams.find(team => team === option.id)" label="name" :placeholder="$t('messages.crm.teams_placeholder')">
                  <template #option="option">
                    <span>
                      <i class="fas fa-users" aria-hidden="true" style="margin-right: 4px"></i>
                      {{ option.name }}
                    </span>
                  </template>
                </v-select>
                <div class="tags" v-if="scenario.outcome.teams">
                  <div :key="teamId" v-for="teamId in scenario.outcome.teams" class="tag" :style="{ backgroundColor: '#cecece' }">
                    {{ (this.teams.find(el => el.id === teamId)?.name || '') }}
                      <i @click="onDeleteTeam(teamId, scenario)" v-tooltip.top="{ content: $t('messages.crm.teams_delete') }" class="fas fa-times"></i>
                  </div>
                </div>
              </div>
              <div>
                <i style="margin-top: 11px; margin-left: 45px" class="fas fas fa-tags"></i>
              </div>
              <div v-if="tags" class="group" v-tooltip.right="{ content: $t('settings.tags.title') }">
                <v-select class="noclear select-conchi-user" @option:selected="onSelectAddTag(this.selectedTag, scenario)" v-model="this.selectedTag" :options="this.tags" label="name" :placeholder="$t('messages.crm.tags.placeholder')" :selectable="(option) => !scenario.outcome.tags.find(tag => tag.id === option.id)">
                  <template #option="option">
                    <span>
                      <div style="display: flex">
                        <div>
                          <div class="tag-bubble-color" :style="{ backgroundColor: option.color }"></div>
                        </div>
                        <div>
                          {{ option.name }}
                        </div>
                      </div>
                    </span>
                  </template>
                </v-select>
                <div class="tags" v-if="scenario.outcome.tags">
                  <div :key="tag.id" v-for="tag in scenario.outcome.tags" class="tag" :tag="tag" :style="{ backgroundColor: tag.color }">
                    {{ (this.tags.find(el => el.id === tag.id)?.name || '') }}
                    <i @click="onDeleteTag(tag.id, scenario)" v-tooltip.top="{ content: $t('messages.crm.teams_delete') }" class="fas fa-times"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button :disabled="isButtonSubmitDisabled()" v-if="this.tabSelected === 'step3'" @click="save" class="button">{{ $t('settings.agent_ai.new.submit.step3') }}</button>
      </div>
      <button :disabled="isButtonSubmitDisabled()" v-if="this.tabSelected === 'step1'" @click="goToNextStep" class="button">{{ $t('settings.agent_ai.new.submit.step1') }}</button>
      <button :disabled="isButtonSubmitDisabled()" v-if="this.tabSelected === 'step2'" @click="goToNextStep" class="button">{{ $t('settings.agent_ai.new.submit.step2') }}</button>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Switch from '@/components/common/Switch.vue'

export default {
  name: 'NewConchiUser',
  components: { Switch },
  data () {
    return {
      modalOpen: false,
      promptMaxLength: 20000,
      tabSelected: 'step1',
      busy: false,
      isEdited: false,
      conchiUser: null,
      selectedTag: null,
      selectedTeam: null,
      selectUser: null,
      stagesList: []
    }
  },
  computed: mapGetters([
    'organizationUsers',
    'selectedContact',
    'tags',
    'funnels',
    'organization',
    'organizationUsersToAssign',
    'teams'
  ]),
  methods: {
    ...mapActions([
      'createAgentAI',
      'fetchAgentsAI',
      'editAgentAI',
      'fetchTags',
      'fetchFunnels',
      'fetchAgentAI',
      'fetchOrganizationUsers'
    ]),
    close () {
      if (this.isEdited) {
        if (confirm(this.$t('settings.agent_ai.close_confirm'))) {
          this.modalOpen = false
          this.conchiUser = null
        }

        return
      }

      this.modalOpen = false
      this.conchiUser = null
    },
    open (options) {
      this.loadFormDefaults()

      if (!options || !options.id) {
        this.conchiUser = {
          name: '',
          block_1: '',
          block_2: '',
          block_3: '',
          triggers: {
            allChatsWithoutAssigned: false,
            allChatsClosed: false,
            allChatsWithoutConversations: false
          },
          scenarios: [{
            if: '',
            say: '',
            outcome: {
              open: false, // FIXME: This should be called 'archive' or 'archived' instead of 'open', now means exactly the opposite that it should.
              user: null,
              teams: [],
              tags: [],
              stage: {}
            }
          }]
        }

        this.tabSelected = 'step1'
      }

      if (options && options.id) {
        this.fetchAgentAI(options.id).then((conchiUser) => {
          this.conchiUser = conchiUser
        })
      }

      if (options && options.tab) {
        this.tabSelected = options.tab
      }

      this.selectedTeam = null
      this.selectUser = null
      this.modalOpen = true
    },
    loadFormDefaults () {
      this.stagesList = []

      this.fetchTags('').then(() => {
        this.fetchFunnels().then((funnels) => {
          for (const funnel of funnels) {
            this.stagesList.push({
              id: 'notSelectable',
              funnelId: null,
              name: `${funnel.name}`

            })
            for (const stage of funnel.stages) {
              this.stagesList.push({
                id: stage.id,
                funnelId: funnel.id,
                name: `${stage.name}`
              })
            }
          }

          this.stagesList.push({
            id: '0',
            funnelId: 'notSelectable',
            name: ' '
          })

          this.stagesList.push({
            id: '0',
            funnelId: null,
            name: '🚫 ' + this.$t('settings.funnels.delete.out_of_funnel')
          })
        })
      })
    },
    closeOverlay (event) {
      if (event.target.classList.contains('overlay')) {
        this.close()
      }
    },
    goToNextStep () {
      if (this.tabSelected === 'step1') {
        this.tabSelected = 'step2'
      } else if (this.tabSelected === 'step2') {
        this.tabSelected = 'step3'
      }
    },
    save () { // TODO: refactor this method, I'm not 100% convinced of the way it's implemented
      this.busy = true

      if (!this.conchiUser.id) {
        this.createAgentAI(this.conchiUser).then(() => {
          this.fetchAgentsAI()
          this.$toast.open({
            message: this.$t('settings.agent_ai.new.success')
          })

          this.busy = false
          this.isEdited = false
          this.close()
          this.fetchOrganizationUsers(this.$t)
        }).catch(() => {
          this.$toast.open({
            message: this.$t('settings.agent_ai.new.error_edit'),
            type: 'error'
          })
          this.busy = false
        })
      } else {
        this.editAgentAI(this.conchiUser).then(() => {
          this.fetchAgentsAI()
          this.$toast.open({
            message: this.$t('settings.agent_ai.new.success_edit')
          })

          this.busy = false
          this.isEdited = false
          this.close()
          this.fetchOrganizationUsers(this.$t)
        }).catch(() => {
          this.$toast.open({
            message: this.$t('settings.agent_ai.edit.error_edit'),
            type: 'error'
          })
          this.busy = false
        })
      }
    },
    onSelectAddTeam (team, scenario) {
      scenario.outcome.teams.push(team)
      this.selectedTeam = null
    },
    onDeleteTeam (teamId, scenario) {
      const index = scenario.outcome.teams.findIndex(t => t === teamId)
      if (index !== -1) {
        scenario.outcome.teams.splice(index, 1)
      }
    },
    onSelectAddTag (tag, scenario) {
      scenario.outcome.tags.push(tag)
      this.selectedTag = null
    },
    onDeleteTag (tagId, scenario) {
      const index = scenario.outcome.tags.findIndex(t => t.id === tagId)
      if (index !== -1) {
        scenario.outcome.tags.splice(index, 1)
      }
    },
    isButtonSubmitDisabled () {
      if (['step1', 'step3'].includes(this.tabSelected)) {
        if (!this.conchiUser.name.trim()) {
          return true
        }
      }
      if (['step2', 'step3'].includes(this.tabSelected)) {
        if (!this.conchiUser.block_1.trim()) {
          return true
        }
        if (!this.conchiUser.block_3.trim()) {
          return true
        }
      }
      if (['step3'].includes(this.tabSelected)) {
        if (!this.conchiUser.block_2.trim()) {
          return true
        }

        if (this.conchiUser.scenarios.length === 0) {
          return true
        }

        if (this.conchiUser.scenarios.some(scenario => !scenario.if.trim() || !scenario.say.trim())) {
          return true
        }

        if (this.busy) {
          return true
        }
      }

      return false
    }
  },
  async created () {
    this.emitter.on('openNewConchiUser', this.open)
    this.emitter.on('closeNewConchiUser', this.close)
  },
  unmounted () {
    this.emitter.off('openNewConchiUser')
    this.emitter.off('closeNewConchiUser')
  },
  watch: {
    conchiUser: {
      handler: function (newValue, oldValue) {
        if (!oldValue || !newValue) {
          return
        }

        if (oldValue.name && newValue.name) {
          this.isEdited = true
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
>>> {
  --vs-state-disabled-color: #111b21;
  --vs-state-disabled-bg: #e9ecef;
}

.switch {
  display: flex;
}

.switch-triggers {
  margin-left: 50px;
  margin-top:3px
}

.select-conchi-user, .select-conchi-user-pipelines {
  min-width: 370px;
  float: left;
  margin-left:10px;
}

.group {
  min-height:30px;
  max-width: 370px;
}

.tag-bubble-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
  margin-top: 5px;
}

.vs__selected-options {
  flex-wrap: nowrap;
}

.vs__selected-options .vs__selected {
  flex-wrap: nowrap;
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 145px;
}

.overlay {
  z-index: 3000003;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 3000004;
  background-color: #ffffff;
  position: fixed;
  top: 50px;
  left: 50%;
  margin-left: -440px;
  width: 840px;
  height: calc(100vh - 140px);
  max-height: 750px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.mobile .modal {
  width: calc(100% - 40px);
  left: unset;
  margin-left: unset;
}

.modal h3 {
  margin-top: 0;
}

.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
}

.modal .input-data {
  width: calc(100% - 26px);
}

.modal textarea {
  width: calc(100% - 30px);
}

.char-counter {
  text-align: right;
  font-size: 12px;
  color: #6E7A8A;
  margin-top: 5px;
  margin-right: 4px;
}

.scrolleable{
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 90px);
}

button {
  position: relative;
  margin-top: 20px;
  width: calc(100%);
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  width: 90%;
  padding-left: 11px;
  max-width: 370px;
}

.tags .tag {
  border-radius: 8px;
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  box-shadow: 0 1px 1px rgba(11,20,26,.13);
}

.tags .tag i {
  color: rgba(60,60,60,0.4);
  cursor: pointer;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs .tab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #EDEFF1;
  padding: 8px;
  cursor: pointer;
}

.tabs .tab.active {
  font-weight: 700;
  border-top: 1px solid #EDEFF1;
  border-left: 1px solid #EDEFF1;
  border-right: 1px solid #EDEFF1;
  border-bottom: 0;

  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.vs__search {
  width: 140px;
}

.noclear .vs__clear {
  display: none;
}

.vs__selected-options {
  display: flex;
  max-height: 30px;
}

.vs__selected-options .vs__selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 145px;
}

.prompt-explain {
  text-align: left;
  font-size: 12px;
  color: #6E7A8A;
  width: 600px;
  margin-top: -14px;
}
</style>

<style>
.group select {
  display: inline;
  width: calc(100% - 27px);
  padding: 6px 0;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  margin-left: 12px;
}

.conchi-user .select-conchi-user-pipelines .vs__dropdown-option--disabled{
  font-weight: 700;
  font-size: 12px;
  background-color: rgba(242, 245, 245, 1);
  color: #111b21;
  padding-bottom: 3px;
}

.conchi-user .vs__dropdown-option--selected.vs__dropdown-option--disabled{
  font-size: 14px;
  background-color: #ffffff;
  color: lightgrey;
}

.conchi-user .vs__dropdown-menu {
  z-index: 999999999 !important;
  height: 100px !important;
}
</style>
