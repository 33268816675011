<template>
  <div class="content">
    <table v-if="organizations && organizations.length > 0" style="text-align: left;">
      <tr style="height: 50px">
        <th style="border-bottom: 1px solid black; width: 200px;">Organization</th>
        <th style="border-bottom: 1px solid black;">Number</th>
        <th style="border-bottom: 1px solid black;">Email</th>
        <th style="border-bottom: 1px solid black;">Partner</th>
        <th style="border-bottom: 1px solid black;">Estado</th>
      </tr>
      <tr v-for="organization in organizations" :key="organization.id" style="height: 50px">
        <td style="border-bottom: 1px solid lightgrey;">{{ organization.name }}</td>
        <td style="border-bottom: 1px solid lightgrey;">{{ organization.number }}</td>
        <td style="border-bottom: 1px solid lightgrey;">{{ organization.email }}</td>
        <td style="border-bottom: 1px solid lightgrey;">{{ organization.partner }}</td>
        <td style="border-bottom: 1px solid lightgrey;">
          <button style="width: fit-content; margin-right: 10px;" v-if="organization.gateway_key_temp && organization.fbWabaId" @click="migrateWaba(organization, 'SMS')" class="button">Enviar SMS</button>
          <button style="width: fit-content; margin-right: 10px;" v-if="organization.gateway_key_temp && organization.fbWabaId" @click="migrateWaba(organization, 'VOICE')" class="button">Llamada</button>
          <span style="cursor: pointer;" v-if="organization.gateway_key_temp && organization.fbWabaId" @click="openPopup(organization)">Abrir popup</span>
          <div v-if="organization.gateway_key_temp && !organization.fbWabaId" style="color: red">Falta el WABA ID en la Base de datos.</div>
          <div v-if="!organization.gateway_key_temp">No iniciado por el cliente</div>
        </td>
      </tr>
    </table>
  </div>

  <WABAMigrationPopup />
</template>

<script>
import { mapActions } from 'vuex'
import WABAMigrationPopup from '@/components/modals/WABAMigrationPopup'

export default {
  name: 'AdminMigrateWABA',
  components: { WABAMigrationPopup },
  data () {
    return {
      organizations: []
    }
  },
  methods: {
    ...mapActions(['getOrganizationsToMigrate', 'startMigration']),
    migrateWaba (organization, type) {
      // this.emitter.emit('openWABAMigrationPopup', organization)

      this.startMigration({
        organizationId: organization.id,
        hash: this.$route.query.hash,
        type: type
      }).then(() => {
        this.emitter.emit('openWABAMigrationPopup', organization)
      }).catch((err) => {
        alert('Error al iniciar la migracion: ' + JSON.stringify(err.response.data))
      })
    },
    openPopup (organization) {
      this.emitter.emit('openWABAMigrationPopup', organization)
    }
  },
  created () {
    if (!this.$route.query.hash) {
      location.href = 'https://www.manycontacts.com'
    }

    this.getOrganizationsToMigrate(this.$route.query.hash).then((response) => {
      this.organizations = response.data
    })

    this.emitter.on('closeWABAMigrationPopup', () => {
      this.getOrganizationsToMigrate(this.$route.query.hash).then((response) => {
        this.organizations = response.data
      })
    })
  }
}
</script>

<style scoped>

.content {
  width: 80%;
  position: absolute;
  left: 10%;
  height: 90%;
  overflow: scroll;
}

.button {
  position: relative;
  bottom: unset;
}

</style>
