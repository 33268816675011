<template>
  <div class="settings-group">
    <h3>{{ $t('settings.agent_ai.settings_title') }}</h3>
    <div v-if="this.loggedUser.canSeeSettings">
      <div class="form-group" style="min-height: 120px">
        <p v-html="$t('settings.agent_ai.description')"></p>
        <button v-if="organization && organization.features.ai && conchiUsers && conchiUsers.length < 10" @click="openNewAgentAI" class="button" type="submit">{{ $t('settings.agent_ai.button_create') }}</button>
      </div>
      <div class="form-group">
        <table v-if="conchiUsers &&  conchiUsers.length !==0 ">
          <thead>
          <th>{{ $t('settings.agent_ai.name') }}</th>
          <th>{{ $t('settings.agent_ai.created_at')}}</th>
          <th></th>
          </thead>
          <tbody>
          <tr :key="user.id" v-for="user in conchiUsers">
            <td>{{ user.name }}</td>
            <td> {{ $moment(user.createdAt).format('L') }}</td>
            <td style="text-align: right;">
              <a @click="openEditAgentAIFeedback(user)" style="cursor: pointer">{{ $t('settings.agent_ai.table_edit_feedback')}}<i class="far fa-edit"></i></a>
              <a @click="openEditAgentAI(user)" style="cursor: pointer; margin-left: 20px">{{ $t('settings.agent_ai.table_edit_prompt')}}<i class="far fa-edit"></i></a>
              <i @click="delUser(user)" style="margin-left: 20px" class="far fa-trash-alt"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <EditConchiUserFeedback />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import EditConchiUserFeedback from '@/components/modals/EditConchiUserFeedback.vue'

export default {
  name: 'SectionAI',
  components: {
    EditConchiUserFeedback
  },
  computed: mapGetters(['loggedUser', 'conchiUsers', 'organization']),
  methods: {
    ...mapActions([
      'fetchAgentAI',
      'fetchAgentsAI',
      'deleteAgentAI',
      'fetchOrganizationUsers'
    ]),
    openNewAgentAI () {
      this.emitter.emit('openNewConchiUser')
    },
    openEditAgentAI (user) {
      this.emitter.emit('openNewConchiUser', { id: user.id, tab: 'step2' })
    },
    openEditAgentAIFeedback (user) {
      this.emitter.emit('openEditConchiUserFeedback', user.id)
    },
    delUser (user) {
      if (confirm(this.$t('settings.permissions.delete.confirm', { name: user.name }))) {
        this.deleteAgentAI(user).then(() => {
          this.$toast.open({
            message: this.$t('form.deleted')
          })

          this.fetchAgentsAI().then(() => {
            this.fetchOrganizationUsers(this.$t)
          })
        })
      }
    }
  },
  mounted () {
    this.fetchAgentsAI()
  }
}
</script>

<style scoped>
p {
  width: calc(100% - 200px);
  float: left;
  margin: 0;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

</style>
