<template>
  <div class="settings-container">
    <h3>{{ $t('settings.your_account') }}</h3>
    <ul>
      <router-link to="/settings/profile">
        <li :class="['profile' === this.section ? 'active' : '']">
          <i class="far fa-user"></i>
          {{ $t('settings.profile.title') }}
          <i @click="openVideoHelp($t('help.links.profile'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
        </li>
      </router-link>
      <div v-if="loggedUser && loggedUser.canSeeSettings">
        <router-link to="/settings/whatsapp">
          <li :class="['whatsapp' === this.section ? 'active' : '']">
            <i class="fab fa-whatsapp"></i>
            {{ $t('settings.whatsapp.title') }}
            <i @click="openVideoHelp($t('help.links.whatsapp'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/import">
          <li :class="['import' === this.section ? 'active' : '']">
            <i class="fa-solid fa-file-import"></i>
            {{ $t('settings.import.title') }}
             <i @click="openVideoHelp($t('help.links.import'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/apps">
          <li :class="['apps' === this.section ? 'active' : '']">
            <i class="fas fa-mobile"></i>
            {{ $t('settings.apps.title') }}
          </li>
        </router-link>
        <router-link to="/settings/invoicing">
          <li :class="['invoicing' === this.section ? 'active' : '']">
            <i class="fas fa-coins"></i>
            {{ $t('settings.invoicing.title') }}
            <i @click="openVideoHelp($t('help.links.invoicing'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
      </div>
      <div v-if="loggedUser && loggedUser.canSeeSettings">
        <br>
        <h3>{{ $t('settings.title') }}</h3>
        <router-link v-if="this.organization && this.organization.features.funnels" to="/settings/funnels">
          <li :class="['funnels' === this.section ? 'active' : '']">
            <i class="fas fa-funnel-dollar"></i>
            {{ $t('settings.funnels.title') }}
            <i @click="openVideoHelp($t('help.links.pipeline'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/permissions">
          <li :class="['permissions' === this.section ? 'active' : '']">
            <i class="fas fa-user"></i>
            {{ $t('settings.permissions.title') }}
            <i @click="openVideoHelp($t('help.links.permissions'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/ai" v-if="this.organization && this.organization.features.ai">
          <li :class="['ai' === this.section ? 'active' : '']">
            <i class="fas fa-robot"></i>
            {{ $t('settings.agent_ai.settings_title') }}
          </li>
        </router-link>
        <router-link to="/settings/teams">
          <li :class="['teams' === this.section ? 'active' : '']">
            <i class="fas fa-users"></i>
            {{ $t('settings.teams.title') }}
            <i @click="openVideoHelp($t('help.links.teams'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/routing">
          <li :class="['routing' === this.section ? 'active' : '']">
            <i class="fas fa-route"></i>
            {{ $t('settings.routing.title') }}
            <i @click="openVideoHelp($t('help.links.routing'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/tags">
          <li :class="['tags' === this.section ? 'active' : '']">
            <i class="fas fa-tags"></i>
            {{ $t('settings.tags.title') }}
            <i @click="openVideoHelp($t('help.links.tags'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/short_responses">
          <li :class="['short_responses' === this.section ? 'active' : '']">
            <i class="fas fa-bolt"></i>
            {{ $t('settings.short_responses.title') }}
            <i @click="openVideoHelp($t('help.links.short_responses'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link v-if="this.organization && this.organization.features.campaigns" to="/settings/templates">
          <li :class="['templates' === this.section ? 'active' : '']">
            <i class="far fa-comment-dots"></i>
            {{ $t('settings.templates.title') }}
            <i @click="openVideoHelp($t('help.links.templates'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/auto_reply">
          <li :class="['auto_reply' === this.section ? 'active' : '']">
            <i class="fas fa-reply"></i>
            {{ $t('settings.auto_reply.title') }}
            <i @click="openVideoHelp($t('help.links.auto_reply'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/chatbot">
          <li :class="['chatbot' === this.section ? 'active' : '']">
            <i class="fa-solid fa-diagram-project"></i>
            Chatbot
            <i @click="openVideoHelp($t('help.links.chatbot'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/custom_fields">
          <li :class="['custom_fields' === this.section ? 'active' : '']">
            <i class="fas fa-stream"></i>
            {{ $t('settings.custom_fields.title') }}
            <i @click="openVideoHelp($t('help.links.custom_fields'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
      </div>
    </ul>
    <br>
    <div v-if="loggedUser && loggedUser.canSeeSettings">
    <h3>Marketing</h3>
      <ul>
        <router-link to="/settings/widget" v-if="this.organization && (!this.organization.partner || this.organization.partner !== 'joinchat')" :data-hint="$t('wizard.hints.widget')" data-hint-position="bottom-middle">
          <li :class="['widget' === this.section ? 'active' : '']">
            <i class="far fa-circle"></i>
            {{ $t('settings.widget.title') }}
            <i @click="openVideoHelp($t('help.links.widget'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/promote">
          <li :class="['promote' === this.section ? 'active' : '']">
            <i class="fas fa-qrcode"></i>
            {{ $t('settings.promote.title') }}
            <i @click="openVideoHelp($t('help.links.promote'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link v-if="this.organization && this.organization.watype === 'api'" to="/settings/klaviyo">
          <li :class="['klaviyo' === this.section ? 'active' : '']">
            <img width="14" src="../../../assets/klaviyo.svg" />
            Klaviyo
            <i @click="openVideoHelp($t('help.links.klaviyo'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
      </ul>
    </div>
    <br>
    <div v-if="loggedUser && loggedUser.canSeeSettings">
      <h3>{{ $t('settings.integrations.title') }}</h3>
      <ul>
        <router-link to="/settings/developers">
          <li :class="['developers' === this.section ? 'active' : '']">
            <i class="fa-solid fa-code"></i>
            {{ $t('settings.developers.title') }}
            <i @click="openVideoHelp($t('help.links.api'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/zadarma" v-if="this.organization && (!this.organization.partner || !this.organization.partner.includes('citelia'))">
          <li :class="['zadarma' === this.section ? 'active' : '']">
            <i class="fas fa-phone-volume"></i>
            {{ $t('settings.zadarma.settings') }}
            <i @click="openVideoHelp($t('help.links.zadarma'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
        <router-link to="/settings/zapier">
          <li :class="['zapier' === this.section ? 'active' : '']">
            <i class="fas fa-puzzle-piece"></i>
            Zapier / Pabbly
            <i @click="openVideoHelp($t('help.links.zapier'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          </li>
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SettingsOptions',
  computed: mapGetters(['section', 'loggedUser', 'organization']),
  methods: {
    openVideoHelp (url) {
      this.emitter.emit('openVideoHelp', url)
    }
  }
}
</script>

<style scoped>
  .settings-container {
    width: 250px;
    float: left;
    height: calc(100vh - 67px - 50px);
    overflow: scroll;
    padding: 0 25px 50px;
  }

  .importantAlert .settings-container, .unpaidInvoiceAlert .settings-container, .mustMigrate .settings-container {
    height: calc(100vh - 127px - 50px);
  }

  .menu-collapse div {
    width: 250px;
  }

  ul {
    margin: 0;
    padding: 0;
  }

  ul a {
    text-decoration: none;
  }

  li {
    padding: 5px 15px;
    border-radius: 35px;
    margin-top: 5px;
    cursor: pointer;
    color: #111b21;
    position: relative;
  }

  li:hover {
    background-color: #F1F3F4;
  }

  li.active {
    background-color: #4285f45e;
    color: #4285f4;
    font-weight: bold;
  }

  li i, li img {
    margin-right: 5px;
  }

  li .help {
    display: none;
    position: absolute;
    right: 8px;
    font-size: 15px;
    cursor: pointer;
    top: 6px;
    height: 15px;
    width: 15px;
    text-align: center;
    line-height: 15px;
  }

  li.active .help,
  li:hover .help {
    display: block;
  }

  .pro {
    color: #111b21;
    background-color: #fab1a0;
    border-radius: 3.5px;
    font-size: 7px;
    font-weight: 600;
    padding: 3px;
    box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
    position: absolute;
    top: 6px;
    right: 26px;
  }

  .new {
    background-color: #55efc4;
  }
</style>
