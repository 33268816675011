<template>
  <li @click="loadConversation" :class="{
    closed: !contact.open /*&& (!counters || !counters.grouped[contact.id])*/,
    unread: (counters && counters.grouped[contact.id]),
    active: (selectedContact !== null && contact.id === selectedContact.id)
  }">
    <label class="checkbox">
      <input type="checkbox" name="checkbox" />
    </label>
    <div :class="{ group: contact.isGroup === 1 }" class="message-avatar">
      <img loading="lazy" v-if="contact.avatar" :src="contact.avatar" onerror="this.style.display='none'"/>
      <img loading="lazy" v-if="organization && organization.watype=== 'api' && !contact.avatar && contact.number" :src="getAvatarUrl(contact)" onerror="this.style.display='none'"/>
    </div>
    <div v-if="contact.isGroup === 1" class="group-badge">{{ $t('contact.group') }}</div>
    <img v-if="this.isRegularChat() && contact.isInternalSupport != true" class="whatsapp-logo" src="../../../assets/whatsapp-logo.svg" />
    <img v-if="this.isRegularChat() === false || contact.isInternalSupport == true" class="manycontacts-isotype" src="../../../assets/manycontacts-isotype.svg" />
    <FlagPhone v-if="contact.isGroup === 0" :phone="contact.number" />
    <div class="message-name">
      {{ contact.name }}
    </div>
    <div class="contact-scheduled" v-if="contact.hasScheduledMessage === true">
      <img src="../../../assets/send-scheduled_green.svg" />
    </div>
    <div v-if="reminderDays != null" :class="{ old: reminderDays <= 0 }" class="reminder">
      <span v-if="reminderDays > 0">+</span>{{this.reminderDays}}
      <i class="far fa-calendar"></i>
    </div>
    <div class="message-time">
      <div v-if="new Date(contact.updatedAt) > this.today">{{ $moment(contact.updatedAt).format('HH:mm') }}</div>
      <div v-else-if="new Date(contact.updatedAt) > this.newYear">{{ $moment(contact.updatedAt).format('DD MMM') }}</div>
      <div v-else>{{ $moment(contact.updatedAt).format('L') }}</div>
    </div>
    <div v-if="this.isRegularChat() && organizationUsers!== null && organizationUsers.length > 0 && contact.last_user_id" class="message-status">
      <i v-if="isAiUser(contact.last_user_id)" class="fas fa-robot"></i>
      {{ organizationUsers.find(el => el.id === contact.last_user_id).name }}
    </div>
    <div v-if="this.isRegularChat() && contact.tags" class="message-tags">
      <div v-tooltip.top="{ content: tag.name }" :key="tag.id" v-for="tag in contact.tags" class="tag" :style="{ backgroundColor: tag.color }"></div>
    </div>

    <div v-if="counters && counters.grouped[contact.id] && counters.grouped[contact.id] <= 99" class="unread-badge">{{ counters.grouped[contact.id] }}</div>
    <div v-if="counters && counters.grouped[contact.id] && counters.grouped[contact.id] > 99" class="unread-badge">+99</div>

    <div v-if="this.isRegularChat()" @click="onClickStarred(contact)" class="message-star" :class="{starred: contact.starred}"><i class="fas fa-star"></i></div>
    <div class="message-options"><i class="fas fa-ellipsis-h"></i></div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FlagPhone from '@/components/common/FlagPhone'

export default {
  name: 'ListItem',
  components: { FlagPhone },
  methods: {
    ...mapActions(['toggleStarredContact', 'fetchConversation']),
    getAvatarUrl (contact) {
      if (!contact.number) {
        return
      }

      let number = contact.number
      number = number.replace('+', '')
      number = number.replaceAll(' ', '')

      return `https://avatar.manycontacts.com/${this.organization.id}/${number}.jpg`
    },
    onClickStarred (contact) {
      const updContact = Object.assign({}, contact)
      updContact.starred = !updContact.starred

      this.toggleStarredContact(updContact)
    },
    loadConversation () {
      this.fetchConversation({ contact: this.contact, messageId: null }).then(() => {
        this.emitter.emit('onDiscardFile')
      })
    },
    setReminder () {
      if (this.contact && this.contact.mostRecentUndoneReminder) {
        const todayMmnt = this.$moment(this.today)
        const reminderMmnt = this.$moment(this.contact.mostRecentUndoneReminder)
        this.reminderDays = reminderMmnt.diff(todayMmnt, 'days')
      } else {
        this.reminderDays = null
      }
    },
    isRegularChat () {
      return this.contact.user_id === null
    },
    isAiUser (userId) {
      const user = this.organizationUsers.find(user => user.id === userId)

      if (!user) {
        return false
      }

      if (user.ai) {
        return true
      }

      return false
    }
  },
  computed: mapGetters(['selectedContact', 'counters', 'organizationUsers', 'organization']),
  props: {
    contact: Object
  },
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      today: today,
      newYear: newYear,
      reminderDays: null
    }
  },
  created () {
    this.setReminder()
  },
  updated () {
    this.setReminder()
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
  height: 50px;
  color: #111b21;
  padding: 10px 15px;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  cursor: pointer;
}

li.active {
  background: rgba(242, 245, 245, 0.8);
  border-left: 3px solid #4285f45e;
}

li.closed {
  opacity: 0.4;
}

li:hover {
  background: rgba(242, 245, 245, 0.8);
  z-index: 2;
}

li .message-avatar {
  position: absolute;
  top: 8px;
  left: 10px;
  width: 40px;
  height: 40px;
  background: url(../../../assets/individual.png) no-repeat center / contain;
  background-size: 35px;
  overflow: hidden;
}

li .message-avatar img {
  width: 40px;
  height: 40px;
  border-radius: 100%;
}

li .message-avatar.group {
  background: url(../../../assets/group.png) no-repeat center / contain;
  background-size: 35px;
}

li .whatsapp-logo {
  width: 12px;
  height: 12px;
  position: absolute;
  left: 13px;
  bottom: 7px;
}

li .manycontacts-isotype {
  width: 17px;
  height: 23px;
  position: absolute;
  left: 11px;
  bottom: 1px;
}

li .message-name {
  position: absolute;
  left: 60px;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 16px;
  font-width: 400;
}

li.unread .message-name {
  font-weight: 500;
}

li .message-time {
  position: absolute;
  right: 15px;
  color: #667781;
  font-size: 12px;
}

li.unread .message-time {
  color: #4285f4;
  font-weight: 700;
}

li .message-status {
  position: absolute;
  left: 60px;
  top: 30px;
  color: #667781;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  max-width: 170px;
}

li.unread .message-status {
  color: #111b21;
}

li .message-tags {
  position: absolute;
  left: 60px;
  top: 52px;
  color: #667781;
  max-width: 180px;
  overflow: hidden;
  max-height: 10px;
}

li .message-tags .tag {
  border-radius: 100%;
  width: 10px;
  height: 10px;
  margin-right: 5px;
  float: left;
}

li .message-star {
  position: absolute;
  right: 15px;
  bottom: 11px;
  color: #CFD0D0;
}

li .unread-badge {
  position: absolute;
  bottom: 8px;
  right: 40px;
}

li:hover .message-star {
  color: #959495;
}

li .message-star.starred,
li:hover .message-star.starred {
  color: #F3C967;
}

li:hover .message-star {
  color: #959495;
}

li .message-star {
  position: absolute;
  right: 15px;
  bottom: 10px;
  color: #CFD0D0;
}

li .message-options {
  display: none;
  position: absolute;
  right: 15px;
  top: 10px;
}

li:hover input[type="checkbox"] {
  border: 0.15em solid #959495;
}

.checkbox {
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1;
  margin-top: 2px;
  margin-right: 10px;
}

.checkbox + .checkbox {
  margin-top: 1em;
}

.checkbox--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #CFD0D0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.unread-badge {
  box-sizing: border-box;
  min-width: 21px;
  border-radius: 20px;
  background-color: transparent;
  border: 1px solid #4285f4;
  color: #4285f4;
  font-weight: bold;
  text-align: center;
  float: right;
  font-size: 10px;
  height: 20px;
  line-height: 18px;
  padding: 0 1px;
}

.reminder {
  position: absolute;
  right: 89px;
  bottom: 7px;
  font-size: 12px;
  color: #27ae60;
  padding: 2px;
  border-radius: 4px;
}

.reminder.old {
  color: rgb(231, 76, 60);
  font-weight: bold;
}

.contact-scheduled {
  color: #27ae60;
  position: absolute;
  right: 69px;
  bottom: 7px;
}

.contact-scheduled img {
  height: 12px;
}

.group-badge {
  font-size: 10px;
  z-index: 1;
  background-color: #4285f4;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-radius: 4px;
  padding: 1px 3px;
  color: white;
  position: absolute;
  top: 12px;
  right: 83px;
  font-weight: 600;
}
</style>
