import axios from 'axios'

const state = {
  funnels: null,
  selectedFunnel: null,
  funnelFilter: {
    filter: [],
    unassigned: true
  }
}
const getters = {
  funnels: (state) => state.funnels,
  selectedFunnel: (state) => state.selectedFunnel,
  funnelFilter: (state) => state.funnelFilter
}

const actions = {
  async fetchFunnels ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/funnels`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    commit('setFunnels', res.data)
    return res.data
  },
  async createFunnel ({ commit, rootState }, cf) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/funnel`, cf, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((newCf) => {
      commit('addFunnel', newCf.data)
    }).catch(() => {})
  },
  async updateFunnel ({ commit, rootState }, cf) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/funnel/${cf.id}`, cf, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async deleteFunnel ({ commit, dispatch, rootState }, cf) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/funnel/${cf.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchFunnels')
      if (cf.id === state.selectedFunnel.id) {
        dispatch('setSelectedFunnel', null)
      }
    }).catch(() => {})
  },

  async createFunnelStage ({ commit, dispatch, rootState }, funnel) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnel.id}/stage`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchFunnels')
    }).catch(() => {})
  },
  async updateFunnelStage ({ commit, rootState }, { funnel, stage }) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnel.id}/stage/${stage.id}`, stage, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async updateFunnelStageOrders ({ commit, rootState }, funnel) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnel.id}/stages/order`, { stages: funnel.stages }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async deleteFunnelStage ({ commit, dispatch, rootState }, { funnel, stage, newStageId }) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnel.id}/stage/${stage.id}/to/${newStageId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchFunnels')
    }).catch(() => {})
  },
  async setSelectedFunnel ({ commit }, funnel) {
    if (funnel === null) {
      localStorage.removeItem('funnelId')
    } else {
      localStorage.funnelId = funnel.id
    }

    commit('setSelectedFunnel', funnel)
  },
  async fetchFunnelStageContacts ({ commit, rootState }, { funnel, stage, funnelFilter }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnel.id}/stage/${stage.id}/contacts`, {
      params: funnelFilter,
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async updateFunnelStageContact ({ commit, rootState }, { contactId, funnelId, stageId }) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnelId}/stage/${stageId}/contact/${contactId}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async fetchFunnelStageContactsNextPage ({ commit, rootState }, { funnel, stage, funnelFilter, page = 0 }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/funnel/${funnel.id}/stage/${stage.id}/contacts?page=${page}`, {
      params: funnelFilter,
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async deleteContactFromFunnels ({ commit, rootState }, { contactId }) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/funnel/contact/${contactId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async setFunnelFilter ({ commit }, funnelFilter) {
    commit('setFunnelFilter', funnelFilter)
  }
}
const mutations = {
  setFunnels: (state, funnels) => (state.funnels = funnels),
  addFunnel: (state, newFunnel) => (state.funnels.push(newFunnel)),
  setSelectedFunnel: (state, funnel) => {
    state.selectedFunnel = null
    state.selectedFunnel = funnel
  },
  setFunnelFilter: (state, funnelFilter) => {
    state.funnelFilter = funnelFilter
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
