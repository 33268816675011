
<template>
    <router-link :to="{ name: 'FeatureRedirectLocale', params: { feature: module.key, locale: this.$i18n.locale }}" @click="onClickModule(module)" class="module" :class="{ featured: module.featured === 1 }" :hidden="hideModule()">
      <div class="icon">
        <div :class="module.key" class="icon-wrapper">
          <i v-if="module.key==='calendar'" class="far fa-calendar"></i>
          <i v-if="module.key==='pipeline'" class="fas fa-filter"></i>
          <i v-if="module.key==='multi-user'" class="fas fa-users"></i>
          <i v-if="module.key==='api'" class="fa-solid fa-code"></i>
          <i v-if="module.key==='campaigns'" class="fas fa-bullhorn"></i>
          <i v-if="module.key==='chatbot'" class="fas fa-robot"></i>
          <i v-if="module.key==='custom-fields'" class="fas fa-stream"></i>
          <i v-if="module.key==='emailbox'" class="fas fa-envelope"></i>
          <i v-if="module.key==='import-excel'" class="fas fa-file-excel"></i>
          <i v-if="module.key==='import-qr'" class="fas fa-qrcode"></i>
          <i v-if="module.key==='inbound-call-wa'" class="fas fa-phone"></i>
          <i v-if="module.key==='internal-chat'" class="fas fa-th-large"></i>
          <i v-if="module.key==='klaviyo'"> <img width="20" src="../../../assets/klaviyo_blue.svg" /></i>
          <i v-if="module.key==='link-qr'" class="fas fa-link"></i>
          <i v-if="module.key==='pabbly-connect'" class="fas fa-project-diagram"></i>
          <i v-if="module.key==='widget'" class="fas fa-circle"></i>
          <i v-if="module.key==='zadarma'" class="fas fa-phone-volume"></i>
          <i v-if="module.key==='zapier'" class="fas fa-puzzle-piece"></i>
          <i v-if="module.key==='scheduled-sent'"> <img width="20" src="../../../assets/send-scheduled_blue.svg" /></i>
        </div>
      </div>
      <div v-if="module.price === 0" class="price"></div>
      <div v-if="module.price > 0" class="price">{{ module.price }}€<span v-if="module.pricePerUser">/{{ $t('modules.agent') }}</span><span v-if="module.monthly">/{{ $t('modules.month') }}</span></div>
      <div class="tags">
        <div class="tag" v-for="tag in module.tags" :key="tag.id">{{$t(`modules.tags.${tag.name}`)}}</div>
      </div>
      <div class="name">{{ $t(`modules.${module.key}.title`) }}</div>
      <div class="short_description">
        {{ $t(`modules.${module.key}.subtitle`) }}
      </div>
      <div v-if="isModuleEnabled()">
        <span class="active"><i class="fas fa-check-circle"></i> {{ $t('modules.active') }}</span>
      </div>
      <button v-if="module.activateable === 1 && !isModuleEnabled()">{{ $t(`modules.${module.key}.button`) }}</button>
      <button v-if="module.activateable === 1 && isModuleEnabled()">{{ $t('modules.manage') }}</button>
      <button v-if="module.activateable === 0">{{ $t(`modules.${module.key}.button`) }}</button>
    </router-link>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FeatureModule',
  components: {
  },
  computed: mapGetters(['organizationModules', 'organization']),
  methods: {
    onClickModule (module) {
      this.emitter.emit('openModulePopup', module)
    },
    isModuleEnabled () {
      if (this.organizationModules && this.organizationModules.includes(this.module.key)) {
        return true
      }

      return false
    },
    hideModule () {
      if (this.$route.params && this.$route.params.partner && this.$route.params.partner === 'joinchat' && this.module.key === 'widget') {
        return true
      }

      if (this.$route.params && this.$route.params.partner && this.$route.params.partner.includes('citelia') && this.module.key === 'zadarma') {
        return true
      }

      if (!this.organization || !this.organization.partner) {
        return false
      }

      if (this.organization && this.organization.partner && this.organization.partner === 'joinchat' && this.module.key === 'widget') {
        return true
      }

      if (this.organization && this.organization.partner && this.organization.partner.includes('citelia') && this.module.key === 'zadarma') {
        return true
      }

      return false
    }
  },
  props: {
    module: Object
  }
}
</script>

<style scoped>
.module {
  position: relative;
  height: 275px;
  width: 400px;
  margin-bottom: 20px;
  margin-right: 15px;
  cursor: pointer;
  padding: 24px;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0 0 0 transparent;
  transition: box-shadow linear .04s,border-color linear .2s;
}

.mobile .module {
  margin-right: 0;
  width: calc(100% - 40px);
}

a.module {
  text-decoration: none;
  color: inherit;
}

.module:hover {
  background-color: #f9fafb;

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.icon {
  width: 40px;
  height: 40px;
}

.icon img {
  height: 40px;
}

.icon-wrapper {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  background: linear-gradient(to bottom right, #F1F8FF,#C4E0FD);
  color: #4285f4;
  font-size: 21px;
  text-align: center;
  line-height: 40px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/*.icon-wrapper.calendar {*/
/*  color: #00cec9*/
/*}*/

/*.icon-wrapper.pipeline {*/
/*  color: #ff7675*/
/*}*/

/*.icon-wrapper.multi-user {*/
/*  color: #fdcb6e*/
/*}*/

.icon-wrapper i {

}

.name {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}

.short_description {
  margin-top: 10px;
  font-size: 14px;
  color: #6b7280;
  letter-spacing: 0.2px;
  line-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 85px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.price {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  color: #4285f4;
}

span.active {
  position: absolute;
  top: 24px;
  left: 70px;
  font-weight: 400;
  font-size: 11px;
  padding: 5px 8px;
  border-radius: 6px;
  margin-left: 10px;
  color: #1a202c;
  background-color: rgb(82, 204, 167,.15);
  border: 1px solid #7bed9f;
}

.active i {
  color: #7bed9f;
}

.tags {
  position: absolute;
  top: 40px;
  right: 20px;
  text-align: right;
}

.tags .tag {
  padding: 1px 7px;
  text-decoration: none;
  float: left;
  margin: 13px 0 0 10px;
  border-radius: 6px;
  border: 1px solid;
  background-color: #fff;
  box-sizing: border-box;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transform: translateZ(0);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: #757575;
}

button {
  width: calc(100% - 48px);
  height: 40px;
  position: absolute;
  bottom: 20px;
  background-color: transparent;
  border-radius: 6px;
  font-weight: 500;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  color: #4285f4;
  border: 1px solid #4285f4;
  cursor: pointer;
}

button:hover {
  background-color: #4285f4;
  color: white;
}
</style>
