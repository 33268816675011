<template>
  <div class="settings-group">
    <h3>{{ $t('settings.teams.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.teams.description') }}</p>
      <button @click="newTeam" class="button" type="submit">{{ $t('settings.teams.button_create') }}</button>
    </div>
    <table v-if="teams && teams.length > 0">
      <thead>
      <th style="width: 14%">{{ $t('settings.teams.name') }}</th>
      <th style="width: 68%">{{ $t('settings.teams.people') }}</th>
      <th style="width: 10%">{{ $t('settings.teams.created_at') }}</th>
      <th style="width: 8%"></th>
      </thead>
      <tbody>
      <tr :key="team.id" v-for="team in teams">
        <td><div>{{ team.name }}</div></td>
        <td>
          <div :key="member.id" v-for="member in team.members" class="member" :member="member">
            <i class="fas fa-user" aria-hidden="true"></i>
            {{ member.name }}
            <i @click="delTeamMember(member, team)" v-tooltip.top="{ content: $t('settings.teams.members.remove') }" class="fas fa-times"></i>
          </div>
          <v-select @option:selected="onSelectAddTeamUser(team)" class="add-member" v-if="organizationUsers"  v-model="this.selectedUser" :placeholder="$t('settings.teams.members.add')" :options="organizationUsers" label="name" :selectable="(option) => !team.members.find(user => user.id === option.id)">
            <template #option="option">
              <span>
                <i class="fas fa-user" aria-hidden="true"></i> {{ option.name}}
              </span>
            </template>
          </v-select>
        </td>
        <td>{{ $moment(team.createdAt).format('L') }}</td>
        <td>
          <i @click="editTeam(team)" class="far fa-edit"></i>
          <i @click="delTeam(team)" class="far fa-trash-alt"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionTeams',
  data () {
    return {
      selectedUser: null
    }
  },
  computed: mapGetters(['teams', 'organizationUsers']),
  methods: {
    ...mapActions([
      'fetchTeams',
      'emptyTeams',
      'toggleNewTeamOpen',
      'setNewTeamData',
      'emptyNewTeamData',
      'deleteTeam',
      'deleteTeamUser',
      'addTeamUser',
      'fetchOrganizationUsers',
      'fetchLoggedUser'
    ]),
    newTeam () {
      this.emptyNewTeamData()
      this.toggleNewTeamOpen()
    },
    editTeam (team) {
      this.setNewTeamData(team).then(() => {
        this.fetchLoggedUser()
      })
      this.toggleNewTeamOpen()
    },
    delTeam (team) {
      if (confirm(this.$t('settings.teams.delete.confirm', { name: team.name }))) {
        this.deleteTeam(team).then(() => {
          this.fetchLoggedUser()
        })
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    },
    delTeamMember (user, team) {
      this.deleteTeamUser({ team: team, user: user }).then(() => {
        this.fetchLoggedUser()
      })
    },
    onSelectAddTeamUser (team) {
      this.addTeamUser({ team: team, user: this.selectedUser }).then(() => {
        this.fetchLoggedUser()
      })
      this.selectedUser = null
    }
  },
  created () {
    this.fetchTeams().then(() => {
      this.fetchOrganizationUsers(this.$t)
    })
  }
}
</script>

<style scoped>
p {
  width: calc(100% - 200px);
  max-width: 600px;
  float: left;
  margin: 0;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

td {
  padding: 5px 0;
}

.member {
  display: inline-block;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  width: fit-content;
  background-color: #EDEFF1;
  margin-bottom: 5px;
}

.add-member {
  width: 140px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}
</style>
