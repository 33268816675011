<template>
  <div>
    <ImportantAlert />
    <UnpaidInvoiceAlert />
    <Navbar />
    <div>
      <Sidebar />
      <Content />
    </div>
  </div>
  <MobileSidebar />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import Navbar from '../components/home/Navbar'
import NewMessage from '../components/modals/NewMessage'

import MobileSidebar from '../components/mobile/Sidebar'

import { mapActions, mapGetters } from 'vuex'

import ImportantAlert from '@/components/home/ImportantAlert'
import UnpaidInvoiceAlert from '@/components/home/UnpaidInvoiceAlert'
import Content from '@/components/home/pipelines/Content'
import Sidebar from '@/components/home/Sidebar'

// import introJs from 'intro.js'
// import 'intro.js/minified/introjs.min.css'

export default {
  name: 'Pipelines',
  methods: {
    ...mapActions(['toggleNewUserInvite'])
  },
  props: {
    filterView: Number,
    page: Number
  },
  computed: mapGetters(['newMessageOpen', 'loggedUser']),
  components: {
    Sidebar,
    Content,
    UnpaidInvoiceAlert,
    ImportantAlert,
    Navbar,
    NewMessage,
    MobileSidebar
  },
  created () {
    if (this.$route.query.ft) {
      // setTimeout(() => {
      //   const intro = introJs()
      //
      //   intro.onchange(async () => {
      //     const step = intro.currentStep()
      //     console.log('currentStep', step)
      //
      //     switch (step) {
      //       case 1:
      //         // eslint-disable-next-line no-case-declarations
      //         // const supportChatButtonEl = document.getElementById('support-chat')
      //         // supportChatButtonEl.click()
      //         break
      //       case 3:
      //         // eslint-disable-next-line no-case-declarations
      //         // const messageInputEl = document.getElementById('message-input')
      //         // messageInputEl.value = '/'
      //         // await this.fetchResponses({ query: '', limit: null })
      //         break
      //       case 5:
      //         // this.emptyResponses()
      //         // await this.toggleNewUserInvite()
      //         // await this.$router.push('/settings/permissions')
      //         break
      //     }
      //   })
      //
      //   intro.onbeforechange(() => {
      //     if (intro._introItems[intro._currentStep].onBeforeChangeFn) {
      //       intro._introItems[intro._currentStep].onBeforeChangeFn()
      //     }
      //   })
      //
      //   intro.oncomplete(() => {
      //     intro.showHints()
      //   })
      //
      //   intro.setOptions({
      //     prevLabel: this.$t('wizard.back'),
      //     nextLabel: this.$t('wizard.next'),
      //     doneLabel: this.$t('wizard.finish'),
      //     hintButtonLabel: this.$t('wizard.hintok'),
      //     steps: [
      //       {
      //         title: this.$t('wizard.steps.1.title'),
      //         intro: this.$t('wizard.steps.1.text')
      //       },
      //       {
      //         element: document.querySelector('.main-menu'),
      //         title: this.$t('wizard.steps.2.title'),
      //         intro: this.$t('wizard.steps.2.text'),
      //         position: 'right'
      //       },
      //       {
      //         title: this.$t('wizard.steps.3.title'),
      //         intro: this.$t('wizard.steps.3.text'),
      //         onBeforeChangeFn: function () {
      //           this.element = document.getElementById('input-controls')
      //           this.position = 'top'
      //         }
      //       },
      //       {
      //         title: this.$t('wizard.steps.4.title'),
      //         intro: this.$t('wizard.steps.4.text'),
      //         onBeforeChangeFn: function () {
      //           this.element = document.querySelector('.templates-container')
      //           this.position = 'top'
      //         }
      //       },
      //       {
      //         title: this.$t('wizard.steps.5.title'),
      //         intro: this.$t('wizard.steps.5.text'),
      //         onBeforeChangeFn: function () {
      //           this.element = document.querySelector('.group-crm-tags')
      //           this.position = 'left'
      //         }
      //       },
      //       {
      //         title: this.$t('wizard.steps.6.title'),
      //         intro: this.$t('wizard.steps.6.text')
      //       }
      //     ]
      //   }).start()
      // }, 200)
    }
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.importantAlert .content, .unpaidInvoiceAlert .content, .mustMigrate .content {
  margin-top: 127px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
