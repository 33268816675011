<template>
  <li @click="loadEmail" :class="{
    closed: email.managed_user_name !== null,
    active: (selectedEmail !== null && email.id === selectedEmail.id)
  }">
    <label class="checkbox">
      <input type="checkbox" name="checkbox" />
    </label>
    <div class="email-recipient">
      <span v-if="email.recipient.name">{{ email.recipient.name }}</span>
      <span v-if="!email.recipient.name">{{ email.recipient.email }}</span>
    </div>
    <div class="email-subject">
      {{ email.subject }}
    </div>
    <div class="email-preview">
      {{ email.preview }}
    </div>
    <div class="message-time">
      <div v-if="new Date(email.timestamp) > this.today">{{ $moment(email.timestamp).format('HH:mm') }}</div>
      <div v-else-if="new Date(email.timestamp) > this.newYear">{{ $moment(email.timestamp).format('DD MMM') }}</div>
      <div v-else>{{ $moment(email.timestamp).format('L') }}</div>
    </div>
<!--    <div v-if="organizationUsers!== null && organizationUsers.length > 0 && email.managed_user_id" class="message-status">-->
<!--      <i class="fas fa-user"></i>-->
<!--      {{ organizationUsers.find(el => el.id === email.managed_user_id).name }}-->
<!--    </div>-->
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListItem_Emails',
  methods: {
    ...mapActions(['fetchInboundEmail']),
    loadEmail () {
      this.fetchInboundEmail(this.email.id)
    }
  },
  computed: mapGetters(['organizationUsers', 'selectedEmail']),
  props: {
    email: Object
  },
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      today: today,
      newYear: newYear,
      reminderDays: null
    }
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
  height: 50px;
  color: #111b21;
  padding: 10px 15px;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  cursor: pointer;
}

li.active {
  background: rgba(242, 245, 245, 0.8);
  border-left: 3px solid #4285f45e;
}

li.unread {
  font-weight: bold;
}

li.closed {
  opacity: 0.4;
}

li:hover {
  background: rgba(242, 245, 245, 0.8);
  /*box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);*/
  z-index: 2;
}

li .email-recipient {
  position: absolute;
  width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 190px;
}

li .email-subject {
  position: absolute;
  width: 250px;
  top: 29px;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li .email-preview {
  position: absolute;
  width: 250px;
  top: 45px;
  font-size: 14px;
  color: #667781;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

li .message-time {
  position: absolute;
  right: 15px;
  color: #667781;
}

li .message-status {
  position: absolute;
  left: 60px;
  top: 30px;
  color: #667781;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  max-width: 170px;
}

li:hover input[type="checkbox"] {
  border: 0.15em solid #959495;
}

.checkbox {
  display: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1;
  margin-top: 2px;
  margin-right: 10px;
}

.checkbox + .checkbox {
  margin-top: 1em;
}

.checkbox--disabled {
  color: var(--form-control-disabled);
  cursor: not-allowed;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #CFD0D0;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  box-shadow: inset 1em 1em var(--form-control-color);
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

input[type="checkbox"]:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}
</style>
