<template>
  <div v-if="selectedEmail !== null" class="content-panel">
    <Header />
    <div class="email-container">
      <div v-if="selectedEmail.managed_user_id" class="bubble">
        {{ organizationUsers.find(el => el.id === selectedEmail.managed_user_id).name }} {{ $t('emails.managed_by') }}
      </div>
      <div v-if="!selectedEmail.managed_user_id && selectedEmail.managed_user_name " class="bubble">
        {{ selectedEmail.managed_user_name }} {{ $t('emails.managed_by') }}
      </div>
      <div v-if="selectedEmail.content.html" v-html="selectedEmail.content.html"></div>
      <div v-if="!selectedEmail.content.html">{{ selectedEmail.content.text }}</div>
    </div>
  </div>
  <div v-if="selectedEmail === null" class="content-panel content-panel-empty">
    {{ $t('messages.list_empty') }}
  </div>
</template>

<script>
import Header from './Header'

import { mapGetters } from 'vuex'

export default {
  name: 'Panel_Email',
  computed: mapGetters(['selectedEmail', 'organizationUsers']),
  components: {
    Header
  }
}
</script>

<style scoped>
.content-panel {
  height: calc(100vh - 67px);
  width: calc(100% - 300px);
  margin-left: 300px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.menu-collapse .content-panel {
  width: calc(100% - 330px);
  margin-left: 330px;
}

.content-panel-empty {
  text-align: center;
  padding-top: 100px;
}

.email-container {
  text-align: left;
  height: calc(100% - 50px);
  position: relative;
  z-index: 0;
  top: 50px;
  padding: 14px;
  overflow: scroll;
}

.bubble {
  color: #111b21;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  max-width: 85%;
  word-wrap: break-word;
  z-index: 2;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
  background-color: rgba(225, 245, 254, 0.92);
  margin: 0 auto 20px;
  border-radius: 4px;
  width: fit-content;
}

</style>
