import axios from 'axios'

const state = {
  scheduledMessages: []
}
const getters = {
  scheduledMessages: (state) => state.scheduledMessages
}
const actions = {
  async fetchMessagesScheduled ({ commit, rootState }, contactId) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/scheduled/${contactId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setScheduledMessages', res.data)
  },
  async createMessageScheduled  ({ commit, dispatch, rootState }, { contactId, text, date }) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/scheduled/${contactId}`, { text, date }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchMessagesScheduled', contactId)
    })
  },
  async deleteMessageScheduled ({ commit, dispatch, rootState }, { contactId, scheduledId }) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/scheduled/${contactId}/message/${scheduledId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchMessagesScheduled', contactId)
    })
  }
}
const mutations = {
  setScheduledMessages: (state, scheduledMessages) => {
    state.scheduledMessages = scheduledMessages

    setTimeout(() => {
      const remindersPending = document.getElementsByClassName('reminders-pending')
      const conversationContainer = document.getElementById('conversation-container')

      if (remindersPending && remindersPending.length > 0 && conversationContainer) {
        const remindersPendingEl = remindersPending[0]
        if (remindersPendingEl) {
          const height = remindersPendingEl.clientHeight + 60

          conversationContainer.style.height = `calc(100% - ${height}px)`
          conversationContainer.style.paddingTop = `${height}px`
        }
      }
    }, 500)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
