<template>
  <div class="overlay" v-if="this.modalOpen">
    <div class="modal" v-if="organization">
      <h3>Migrar WABA</h3>
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

      <div class="form-group">
        <div>{{organization.name}}</div>
        <div>{{organization.number}}</div>
      </div>

      <div class="form-group">
        <label>Code (from SMS or Call)</label>
        <input type="text" v-model="this.code">
      </div>

      <div class="form-group">
        <label>Backup (from 360Dialog)</label>
        <textarea v-model="this.backup"></textarea>
      </div>

      <div class="form-group">
        <label>Backup Password (from 360Dialog)</label>
        <input type="text" v-model="this.backupPassword">
      </div>

      <button :disabled="!this.code.trim() || !this.backup.trim() || !this.backupPassword" class="button" @click="onClickSubmit">Migrar número</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WABAMigrationPopup',
  computed: mapGetters(['']),
  data () {
    return {
      modalOpen: false,
      organization: null,
      code: '',
      backup: '',
      backupPassword: ''
    }
  },
  methods: {
    ...mapActions(['finishMigration']),
    onClickSubmit () {
      this.finishMigration({
        organizationId: this.organization.id,
        hash: this.$route.query.hash,
        code: this.code,
        backup: this.backup,
        backupPassword: this.backupPassword
      }).then(() => {
        this.close()
        alert('Migración completada')
      }).catch((err) => {
        alert('Error al migrar: ' + JSON.stringify(err.response.data))
      })
    },
    close () {
      this.modalOpen = false
      this.organization = null
      this.emitter.emit('closeWABAMigrationPopup')
    },
    open (organization) {
      this.organization = organization
      this.modalOpen = true
    }
  },
  created () {
    this.emitter.on('openWABAMigrationPopup', this.open)
  },
  unmounted () {
    this.emitter.off('openWABAMigrationPopup')
  }

}
</script>

<style scoped>
.modal {
  z-index: 3000001;
  width: 400px;
  height: 500px;
  margin: 0 auto;
  position: relative;
  top: 30px;
  left: 0;
}

.overlay {
  z-index: 2000000;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}
</style>
