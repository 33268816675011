<template>
  <div v-if="this.openModal" class="overlay">
    <div class="modal">
      <h3>{{ $t('settings.whatsapp.import.wabusiness.popup.title') }}</h3>
      <p v-html="$t('settings.whatsapp.import.wabusiness.popup.subtitle')"></p>
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

      <div class="form-group">
        <select @change="onChangeUser" v-model="this.userId">
          <option :disabled="this.userId" v-for="user in organizationUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
        </select>
      </div>

      <div v-if="this.userId">
        <br>
        <div>{{ $t('settings.whatsapp.import.wabusiness.popup.scan') }}</div>
        <br>
        <qrcode-vue v-if="importQrTemp" :value="importQrTemp" size="200" level="H"></qrcode-vue>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ImportWhatsAppBusiness',
  computed: mapGetters(['organizationUsers', 'importQrTemp']),
  data () {
    return {
      userId: null,
      openModal: false
    }
  },
  sockets: {
    closeImportQR () {
      this.fetchImports()
      this.close()
    }
  },
  methods: {
    ...mapActions(['getNewImportQR', 'fetchImports']),
    onChangeUser () {
      this.getNewImportQR({ userId: this.userId })
    },
    close () {
      this.openModal = false
    },
    open () {
      this.userId = null
      this.openModal = true
    }
  },
  created () {
    this.emitter.on('openImportWhatsAppBusiness', this.open)
  }
}
</script>

<style scoped>

.overlay {
  z-index: 2000000;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  height: unset !important;
}

select {
  width: 100% !important;
}
</style>
