<template>
  <div id="input-controls" class="controls input-controls">
    <div class="left">
      <h4>No tienes créditos (<a target="_blank" href="https://www.manycontacts.com/blog/whatsapp-business-api-conversaciones/">?</a>)</h4>
      <p>Añade para continuar</p>
    </div>
    <div class="right">
      <button @click="addCredits(1000)" class="button button-small">+1000</button>
      <button @click="addCredits(2000)" class="button button-small">+2000</button>
      <button @click="addCredits(5000)" class="button button-small">+5000</button>
      <button @click="addCredits(10000)" class="button button-small">+10000</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'InputNoCredits',
  props: {
  },
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['fetchCheckoutSessionLink']),
    addCredits (number) {
      this.fetchCheckoutSessionLink({ credits: number }).then((url) => {
        location.href = url
      })
    }
  }
}
</script>

<style scoped>
.controls {
  position: absolute;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  background-color: rgba(242, 245, 245, 1);
  border-top: 1px solid #E3E8EA;
  text-align: left;
  display: inline-flex;
  font-size: 14px;
}

.left {
  width: fit-content;
  min-width: 155px;
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  padding-left: 5px;
}

.right {
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
  overflow-y: scroll;
  display: inline-flex;
}

.mobile .controls {
  position: fixed;
}

.controls .control-buttons {
  float: left;
  position: relative;
  font-size: 14px;
  color: #667781;
  padding: 15px;
}

button {
  position: relative;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 5px;
  margin-left: 5px;
  bottom: unset;
}

h4, p {
  margin: unset;
  padding: unset;
}
</style>
