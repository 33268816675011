<template>
  <div>
    <div v-if="!invitation" class="loading fa-3x">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
    <div v-if="invitation" class="content">
      <div class="logo-container">
        <img class="logo" src="../assets/manycontacts-logo.svg"/>
      </div>
      <br>

      <h3>{{ $t('pages.invite.title', { name: invitation.userName }) }}</h3>
      <p>
        {{ $t('pages.invite.subtitle') }}
      </p>

      <br>

      <div class="form-group">
        <label for="invite-name">{{ $t('pages.invite.name') }}</label>
        <input type="text" id="invite-name" name="name" v-model="invitation.name" disabled>
      </div>

      <div class="form-group">
        <label for="invite-email">{{ $t('pages.invite.email') }}</label>
        <input type="text" id="invite-email" name="name" v-model="invitation.email" disabled>
      </div>

      <div class="form-group">
        <label for="invite-password">{{ $t('pages.invite.password') }}</label>
        <input type="text" id="invite-password" name="password" v-model="password">
      </div>

      <br>

      <div class="form-group">
        <button @click="submit" :disabled="!this.password || this.password.length <= 8" class="button" type="submit">
          {{ $t('pages.invite.submit', { name: invitation.userName }) }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Invite',
  props: {
    token: String
  },
  data () {
    return {
      invitation: null,
      password: null
    }
  },
  methods: {
    ...mapActions(['fetchInvitation', 'acceptInvitation']),
    submit () {
      this.acceptInvitation({
        token: this.invitation.token,
        password: this.password
      })
    }
  },
  created () {
    this.fetchInvitation(this.token).then((invitation) => {
      this.invitation = invitation

      setTimeout(() => {
        document.getElementById('invite-password').focus()
      }, 500)
    }).catch(() => {
      window.location.href = process.env.VUE_APP_APP_HOST + '/404'
    })
  }
}
</script>

<style scoped>
.loading {
  text-align: center;
  margin-top: 100px;
  color: #2c3e50;
}

.logo-container {
  text-align: center;
}

.logo {
  height: 35px;
}

.content {
  width: 400px;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.mobile .content {
  margin-top: 30px;
  position: relative;
  width: calc(100% - 40px);
  padding: 0 20px;
  left: unset;
  margin-left: unset;
}

button {
  width: 100% !important;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}
</style>
