<template>
  <div v-if="this.openModal" class="overlay"></div>
</template>

<script>
export default {
  name: 'MetaCloudPopupOpened',
  data () {
    return {
      openModal: false
    }
  },
  methods: {
    close () {
      this.openModal = false
    },
    open () {
      this.openModal = true
    }
  },
  created () {
    this.emitter.on('openMetaCloudPopupOpened', this.open)
    this.emitter.on('closeMetaCloudPopupOpened', this.close)
  },
  unmounted () {
    this.emitter.off('openMetaCloudPopupOpened')
    this.emitter.off('closeMetaCloudPopupOpened')
  }
}
</script>

<style scoped>

.overlay {
  z-index: 2000000;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  height: 220px
}

select {
  width: 100% !important;
}
</style>
