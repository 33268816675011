<template>
  <div class="conversation-frame">
    <Panel />
    <Crm />
  </div>
</template>

<script>
import Panel from '@/components/home/chat/Panel'
import Crm from '@/components/home/Crm'
import { mapActions } from 'vuex'
export default {
  name: 'ConversationFrame',
  components: { Panel, Crm },
  methods: {
    ...mapActions(['startConversation'])
  },
  props: {
    number: String
  },
  mounted () {
    this.startConversation(this.number.replaceAll('+', ''))
  }
}
</script>

<style>
.conversation-frame .content-panel {
  margin-left: 0;
  height: 100vh;
  width: calc(100% - 250px);
}

.conversation-frame .crm {
  top: 0px !important;
  height: 100%;
}

.conversation-frame .crm .contact-locked{
  bottom: 0px;
}
</style>
