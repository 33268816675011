<template>
  <div v-if="this.promote" class="overlay">
    <div class="modal">
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      <br><br>
      <div class="form-group">
        <label for="sr-title">{{ $t('settings.promote.link') }}</label>
        <textarea id="sr-title" :value="getLink().replace(/\s/g, '%20')" readonly></textarea>
      </div>

      <div class="form-group">
        <label for="sr-title">{{ $t('settings.promote.qr_code') }}</label>
        <qr-code :text="getLink().replace(/\s/g, '%20')" size="200"></qr-code>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ShowPromote',
  data () {
    return {
      promote: null
    }
  },
  methods: {
    close () {
      this.promote = null
    },
    open (promote) {
      this.promote = promote
    },
    getLink () {
      return `https://wa.me/${this.promote.number}?text=${this.promote.text}`
    }
  },
  created () {
    this.emitter.on('openShowPromote', this.open)
  }
}
</script>

<style scoped>
 .modal {
   width: 400px;
   height: 420px;
   margin: 0 auto;
   position: relative;
   top: 30px;
   left: 0;
 }

 .overlay {
   opacity: 1;
   background-color: rgba(32,33,36,0.3);
 }
 </style>
