<template>
  <li>
    <div class="message-avatar blink"></div>
    <div class="message-name blink"></div>
    <div class="message-time blink"></div>
    <div class="message-status blink"></div>
  </li>
</template>

<script>
export default {
  name: 'ListItemNotLoaded_Emails',
  props: {
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
  height: 80px;
  color: #111b21;
  padding: 10px 15px;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  cursor: pointer;
}

li .message-avatar {
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  background-color: #DFE5E7;
  -webkit-mask: url(../../../../assets/contact.svg) no-repeat center / contain;
  mask: url(../../../../assets/contact.svg) no-repeat center / contain;
}

li .message-name {
  position: absolute;
  left: 60px;
}

li .message-time {
  position: absolute;
  right: 15px;
  color: #667781;
}

li .message-status {
  position: absolute;
  left: 60px;
  top: 28px;
  color: #667781;
}

li:hover .message-time {
  display: none;
}
</style>
