<template>
  <form>
    <div class="content">
      <a href="https://manycontacts.com" target="_blank">
        <div class="logo-container">
          <img class="logo" src="../assets/manycontacts-logo.svg"/>
        </div>
      </a>

      <br>

      <h3>Login</h3>
      <p v-if="!this.multiUser">
        {{ $t('login.text_description')}}
        <router-link to="/register">{{ $t('login.register') }}</router-link>
      </p>
      <p v-if="this.multiUser">
        {{ $t('login.text_description_multi_user')}}
      </p>
      <div v-if="this.multiUser && !this.loginOn" style="margin-top: 30px; overflow: auto; max-height: 360px">
        <div @click="loginActualUser(UserAPI.id)" :key="UserAPI.id" v-for="(UserAPI) in getUsers()" class="user-logged">
          <div>
            <div class="avatar">{{ UserAPI.name.substring(0,1) }}</div>
          </div>
          <div>
            <div class="name">{{ UserAPI.name }}</div>
            <div class="email">{{ UserAPI.email }}</div>
          </div>
        </div>
      </div>
      <button v-if="this.multiUser && !this.loginOn" style="margin-top: 50px; align-items: center" @click="this.newLogin()" class="add-account button"><i class="fas fa-plus"></i> {{ $t('navbar.users.new_login') }}</button>
      <p style="text-align: center; margin-top: 10px" v-if="this.multiUser && !this.loginOn">
        <router-link to="/register">{{ $t('login.register') }}</router-link>
      </p>
      <br>
      <div v-if="credentialsInvalid" class="invalid-credentials">
        {{ $t('login.fail_autentication')}}
      </div>
      <div v-if="this.loginOn || !this.multiUser">
        <div class="form-group">
          <label for="email">{{ $t('login.email') }}</label>
          <input type="email" id="email" name="name" v-model="email" placeholder="ramon@tuempresa.com">
        </div>

        <div class="form-group">
          <label for="password">{{ $t('login.password') }}</label>
          <input type="password" id="password" name="password" v-model="password">
        </div>

        <br>

        <div class="form-group">
            <button :disabled="!email || !password || password.length < 8" type="submit" @click="submit($event)" class="button">
              {{ $t('login.submit') }}
            </button>
        </div>

        <div style="margin-top: 40px" v-html="$t('login.recover_password')"></div>
      </div>
      <br>
      <hr>
      <div class="language-selector">
        <label>{{ $t('pages.register.locale') }}: </label>
        <select v-model="locale" @change="onChangeLocale">
          <option value="es">🇪🇸 ES</option>
          <option value="en">🇬🇧 EN</option>
          <option value="it">🇮🇹 IT</option>
          <option value="pt">🇵🇹🇧🇷 PT</option>
        </select>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  data () {
    return {
      locale: null,
      email: null,
      password: null,
      credentialsInvalid: false,
      multiUser: false,
      loginOn: false
    }
  },
  methods: {
    ...mapActions(['login']),
    submit (event) {
      event.preventDefault()
      this.login({ email: this.email, password: this.password }).then(() => {
        window.location.href = process.env.VUE_APP_APP_HOST + '/'
      }).catch(() => {
        this.credentialsInvalid = true
      })
    },
    onChangeLocale () {
      window.fetchTranslationsFor(this.locale, this.setLocale(this))
    },
    setLocale () {
      Cookies.remove('ma-locale')
      Cookies.set('ma-locale', this.locale)
      this.$i18n.locale = this.locale
    },
    getUsers () {
      const users = []
      const usersTokens = localStorage.getItem('accessTokens')
      if (usersTokens) {
        const usersTokensParsed = JSON.parse(usersTokens)

        for (const userToken in usersTokensParsed) {
          users.push(usersTokensParsed[userToken])
        }
      }
      return users
    },
    loginActualUser (user) {
      const sessionTokens = JSON.parse(localStorage.getItem('accessTokens'))
      for (const defaultUser in sessionTokens) {
        if (sessionTokens[defaultUser].id === user) {
          sessionStorage.setItem('accessTokensSession', defaultUser)
        }
      }
      window.location.href = process.env.VUE_APP_APP_HOST + '/'
    },
    getMultiUser () {
      const usersTokens = localStorage.getItem('accessTokens')
      if (usersTokens) {
        const usersTokensParsed = JSON.parse(usersTokens)
        if (Object.keys(usersTokensParsed).length > 0) {
          this.multiUser = true
        }
      }
    },
    newLogin () {
      this.loginOn = true
    }
  },
  mounted () {
    const localeCookie = Cookies.get('ma-locale')
    if (localeCookie) {
      this.locale = localeCookie
    }

    if (!this.locale) {
      if (navigator.languages !== undefined) {
        this.locale = navigator.languages[0]
      }

      if (!this.locale) {
        this.locale = navigator.language
      }

      if (this.locale) {
        this.locale = this.locale.substr(0, 2)
      }
    }

    if (!this.locale) {
      this.locale = 'es'
    }

    if (this.$route.query.hsp) {
      this.phone = atob(this.$route.query.hsp)
    }

    this.getMultiUser()
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
}

.logo-container {
  text-align: center;
}

.logo {
  height: 35px;
}

.content {
  width: 400px;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.mobile .content {
  margin-top: 30px;
  position: relative;
  width: calc(100% - 40px);
  padding: 0 20px;
  left: unset;
  margin-left: unset;
}

.invalid-credentials {
  background-color: #ff7675;
  color: black;
  text-align: center;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}

.add-account {
  text-align: center;
  cursor: pointer;
  position: relative;
}

.name {
  font-weight: bold;
  margin-left: 10px;
  width: 335px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.email {
  margin-left: 10px;
  width: 335px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
button {
  width: 100% !important;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.language-selector {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.avatar {
  height: 36px;
  border-radius: 100%;
  cursor: pointer;
  width: 36px;
  border: 1px solid #EDEFF1;
  line-height: 36px;
  text-align: center;
  font-size: 17px;
  background-color: #4285f45e;
  color: #4285f4;
  font-weight: bold;
}

.user-logged {
  padding: 5px;
  border-radius: 5px;
  display: flex;
  margin-top: 15px;
  cursor: pointer;
  margin-bottom: 15px;
  overflow: hidden;
}

.user-logged:hover {
  background-color: #f5f5f5;
}

</style>
