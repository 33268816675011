import axios from 'axios'

const state = {
  imports: null,
  importQrTemp: null,
  importsExcel: []
}
const getters = {
  imports: (state) => state.imports,
  importQrTemp: (state) => state.importQrTemp,
  importsExcel: (state) => state.importsExcel
}
const actions = {
  async getNewImportQR ({ commit, rootState }, { userId }) {
    await axios.get(`${process.env.VUE_APP_SERVERHOST}api/qr/import?userId=${userId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async fetchImports ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/imports`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setImports', res.data)
    return res.data
  },
  async createImport ({ commit, dispatch, rootState }, data) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/import`, data, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((newCf) => {
      dispatch('fetchImports')
    }).catch(() => {})
  },
  SOCKET_newImportQR ({ commit, dispatch }, qr) {
    commit('setImportQRTemp', qr)
  },
  async fetchimportExcels ({ commit, dispatch, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/imports/excel`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setImportsExcels', res.data)

    for (const imports of res.data) {
      if (['pending', 'in_progress'].includes(imports.status)) {
        setTimeout(() => {
          dispatch('fetchimportExcels')
        }, 5000)

        break
      }
    }
  },
  async createImportExcel ({ commit, dispatch, rootState }, data) {
    const formData = new FormData()
    formData.append('file', data)

    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/imports/excel`, formData, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  }
}
const mutations = {
  setImports: (state, imports) => (state.imports = imports),
  setImportQRTemp: (state, qrTemp) => (state.importQrTemp = qrTemp),
  setImportsExcels: (state, importsExcel) => (state.importsExcel = importsExcel)
}

export default {
  state,
  getters,
  actions,
  mutations
}
