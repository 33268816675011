import axios from 'axios'
import router from '../../router'

const state = {
  contact: null,
  messages: null,
  showDropdown: false,
  showEmojis: false,
  modeNoteActive: false
}
const getters = {
  selectedContact: (state) => state.contact,
  contactMessages: (state) => state.messages,
  showDropdown: (state) => state.showDropdown,
  showEmojis: (state) => state.showEmojis,
  modeNoteActive: (state) => state.modeNoteActive
}
const actions = {
  async fetchConversation ({ commit, dispatch, rootState }, { contact, messageId }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/messages/${contact.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('showDropdown', false)

    commit('setSelectedContact', await dispatch('fetchContact', contact.id))
    commit('setMessages', res.data)

    dispatch('fetchPendingRemindersForContact', contact).then(() => {
      dispatch('getContactFileCount', contact)
    })
    // dispatch('fetchMessagesScheduled', contact.id)

    if (rootState.user.loggedUser && contact.last_user_id === rootState.user.loggedUser.id) {
      dispatch('setCurrentContactMessagesAsRead')
    }

    if (rootState.contacts.filter === 4) { // Seeing a message from Notes
      dispatch('setCurrentContactMentionsAsRead', contact.id)
    }

    setTimeout(() => {
      const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
      if (conversationContainerEl) {
        conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
      }
    }, 100)
    setTimeout(() => {
      commit('showDropdown', true)
      if (messageId !== null) {
        const messageBubbleEl = document.getElementById(messageId)
        if (messageBubbleEl) {
          messageBubbleEl.scrollIntoView(false)
        }
      }
    }, 500)

    if (location.pathname.includes('calendar') ||
      location.pathname.includes('pipeline') ||
      location.pathname.includes('email') ||
      location.pathname.includes('settings') ||
      location.pathname.includes('stats') ||
      location.pathname.includes('conversation/frame')) {
      return
    }

    if (messageId === null || messageId === undefined || messageId === 'undefined') {
      await router.push(`/conversation/${contact.id}`)
    } else {
      await router.push(`/conversation/${contact.id}/${messageId}`)
    }
  },
  async fetchMessage ({ commit, rootState }, messageId) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/messages/${state.contact.id}/message/${messageId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      return res.data
    }).catch(() => {
      return null
    })
  },
  async fetchConversationPreviousMessages ({ commit, rootState }) {
    if (state.messages === null || state.messages.length === 0) {
      return
    }

    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/messages/${state.contact.id}?timestamp=${state.messages[0].metadata.time}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('addMessagesOnTop', res.data)
  },
  async sendMessageText ({ commit, dispatch, rootState }, { contact, text }) {
    const contactDelta = []
    if (contact.open === false) {
      contactDelta.open = true
    }

    if (contact.last_user_id !== rootState.user.loggedUser.id) {
      contactDelta.last_user_id = rootState.user.loggedUser.id
      commit('showDropdown', false)
      dispatch('assignContactToLoggedUser', contact).then(() => {
        commit('showDropdown', true)
      })
      dispatch('setCurrentContactMessagesAsRead')
    }

    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/message/${contact.id}/text`, {
      text: text
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('addMessage', res.data)
    dispatch('setContactUpdatedToNowOnNewMessage', { contactId: contact.id, lastUserId: null })

    contactDelta.updatedAt = new Date().toISOString()
    commit('updateContactDelta', { updContact: contact, delta: contactDelta })
    commit('updateSelectedContactDelta', contactDelta)
    dispatch('goUpContactById', contact.id)
  },
  async sendNote ({ commit, dispatch, rootState }, { contact, text }) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/message/${contact.id}/note`, {
      text: text
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setModeNoteActive', false)
    commit('addMessage', res.data)
  },
  async sendMessageMedia ({ commit, dispatch, rootState }, { contact, formData, voice }) {
    const contactDelta = []
    if (contact.open === false) {
      contactDelta.open = true
    }

    if (contact.last_user_id !== rootState.user.loggedUser.id) {
      contactDelta.last_user_id = rootState.user.loggedUser.id
      dispatch('assignContactToLoggedUser', contact)
      dispatch('setCurrentContactMessagesAsRead')
    }

    let url = `${process.env.VUE_APP_SERVERHOST}api/message/${contact.id}/media`
    if (voice === true) {
      url = `${process.env.VUE_APP_SERVERHOST}api/message/${contact.id}/voice`
    }

    const res = await axios.post(url, formData, {
      headers: {
        token: rootState.sessions.userIsAutenticate,
        'Content-Type': 'multipart/form-data'
      }
    })

    commit('setModeNoteActive', false)
    commit('addMessage', res.data)
    dispatch('setContactUpdatedToNowOnNewMessage', { contactId: contact.id, lastUserId: null })

    contactDelta.updatedAt = new Date().toISOString()
    commit('updateContactDelta', { updContact: contact, delta: contactDelta })
    commit('updateSelectedContactDelta', contactDelta)
    dispatch('goUpContactById', contact.id)
  },
  async setCurrentContactMessagesAsRead ({ commit, rootState }) {
    await axios.get(`${process.env.VUE_APP_SERVERHOST}api/message/${state.contact.id}/read`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async setConversationContactNotes ({ commit, dispatch, rootState }, { contact }) {
    await axios.put(`${process.env.VUE_APP_SERVERHOST}api/contact/${contact.id}/notes`, {
      notes: contact.notes
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  toggleNoteMode ({ commit }) {
    commit('toggleModeNote')
  },
  setModeNoteActive ({ commit }, active) {
    commit('setModeNoteActive', active)
  },
  toggleEmojis ({ commit }) {
    commit('toggleEmojis')
  },
  clearSelectedContact ({ commit }) {
    commit('setSelectedContact', null)
  },
  clearMessages ({ commit }) {
    commit('setMessages', null)
  },
  SOCKET_newMessage ({ commit, dispatch, rootState }, data) {
    if (state.contact && state.contact.id === data.contactId) {
      commit('addMessage', data.message)

      if (rootState.user.loggedUser && state.contact.last_user_id === rootState.user.loggedUser.id) {
        dispatch('setCurrentContactMessagesAsRead')
      }

      const updContact = state.contact
      updContact.updatedAt = new Date().toISOString()
      if (data.lastUserId) {
        updContact.last_user_id = data.lastUserId
      }

      if (data.lastInbound) {
        updContact.lastInbound = data.lastInbound
      }

      commit('setSelectedContact', updContact)
    } else {
      if (data.lastUserId !== undefined && data.message && data.message.type === 'received') {
        if (rootState.user.loggedUser && rootState.user.loggedUser.notifyMine === true) {
          if (rootState.user.loggedUser && data.lastUserId === rootState.user.loggedUser.id) {
            const audio = new Audio('/tone.mp3')
            audio.autoplay = true
            audio.play().catch(() => {})
          }
        }

        if (rootState.counters && rootState.counters.counters) {
          const counters = rootState.counters.counters
          let contactCounter = counters.grouped[data.contactId] || 0
          contactCounter++
          counters.grouped[data.contactId] = contactCounter
          commit('setCounters', counters)
        }
      }
    }
    if (!location.pathname.includes('pipeline')) {
      dispatch('setContactUpdatedToNowOnNewMessage', { contactId: data.contactId, lastUserId: data.lastUserId })

      if (['sent', 'received'].includes(data.message.type)) {
        dispatch('goUpContactById', data.contactId)
      }
    }

    if (location.pathname.includes('pipeline')) {
      if (['received'].includes(data.message.type) &&
        rootState.funnels.selectedFunnel !== null &&
        rootState.funnels.selectedFunnel.stages &&
        rootState.funnels.selectedFunnel.stages.length > 0) {
        window.emitter.emit('updateFunnelStage', rootState.funnels.selectedFunnel.stages[0].id)
      }
    }

    if (data.contactId && data.message && data.message.type === 'assigned') {
      const contact = rootState.contacts.contacts.find(contact => contact.id === data.contactId)
      if (contact) {
        if (contact.last_user_id !== data.message.userId) {
          if (!data.message.userId && data.message.userName) {
            // team add: do nothing
          } else {
            contact.last_user_id = data.message.userId
          }
          commit('updateContact', contact)
        }
      }
      if (state.contact && state.contact.id === data.contactId) {
        if (state.contact.last_user_id !== data.message.userId) {
          if (!data.message.userId && data.message.userName) {
            // team add: do nothing
          } else {
            state.contact.last_user_id = data.message.userId
          }
          commit('setSelectedContact', state.contact)
        }
      }
    }

    if (data.contactId && data.message && data.message.type === 'closed') {
      const contact = rootState.contacts.contacts.find(contact => contact.id === data.contactId)
      if (contact && contact.open !== 0) {
        contact.open = 0
        commit('closeContact', contact)
      }
      if (state.contact && state.contact.id === data.contactId) {
        if (state.contact.open !== 0) {
          state.contact.open = 0
          commit('setSelectedContact', state.contact)
        }
      }
    }
  },
  SOCKET_updateMessageStatus ({ commit, dispatch }, data) {
    if (state.contact && state.contact.id === data.contactId) {
      commit('updateMessageStatus', data.message)

      if (['sent', 'received'].includes(data.message.type)) {
        dispatch('goUpContactById', data.contactId)
      }
    }
  }
}
const mutations = {
  setSelectedContact: (state, contact) => {
    state.contact = null
    state.contact = contact
  },
  updateSelectedContactDelta: (state, delta) => {
    const master = state.contact
    Object.entries(delta).forEach(([key, value]) => {
      master[key] = value
    })

    state.contact = null
    state.contact = master
  },
  setMessages: (state, messages) => (state.messages = messages),
  addMessagesOnTop: (state, messages) => {
    state.messages = messages.concat(state.messages)
  },
  addMessage: (state, newMessage) => {
    if (!state.messages) {
      return
    }

    state.messages.push(newMessage)
  },
  updateMessage: (state, updMessage) => {
    if (!state.messages) {
      return
    }

    const index = state.messages.findIndex((el) => el.id === updMessage.id)
    if (index !== -1) {
      state.messages[index] = updMessage
    }
  },
  deleteMessageById: (state, messageId) => {
    if (!state.messages) {
      return
    }

    const index = state.messages.findIndex((el) => el.id === messageId)
    if (index !== -1) {
      state.messages.splice(index, 1)
    }
  },
  updateMessageStatus: (state, updMessage) => {
    if (!state.messages) {
      return
    }

    const index = state.messages.findIndex((el) => el.id === updMessage.id)
    if (index !== -1) {
      const message = Object.assign({}, state.messages[index])
      message.metadata.tick = updMessage.status
      message.error = updMessage.error
      state.messages[index] = message
    }
  },
  showDropdown: (state, show) => (state.showDropdown = show),
  setModeNoteActive: (state, active) => (state.modeNoteActive = active),
  toggleModeNote: (state) => (state.modeNoteActive = !state.modeNoteActive),
  toggleEmojis: (state) => (state.showEmojis = !state.showEmojis)
}

export default {
  state,
  getters,
  actions,
  mutations
}
