<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3 v-if="!this.newTagData.id">{{ $t('settings.tags.new.title') }}</h3>
    <h3 v-if="this.newTagData.id">{{ $t('settings.tags.edit.title', { name: this.newTagData.name }) }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="tag-name">{{ $t('settings.tags.name') }}</label>
      <input type="text" id="tag-name" v-model="this.newTagData.name">
    </div>

    <div class="form-group">
      <label>Color</label>
      <div class="tag" :style="{ backgroundColor: this.newTagData.color }">
        {{ this.newTagData.name }}
        <i  class="fas fa-times"></i>
      </div>
      <br>
      <color-picker v-model:pureColor="this.newTagData.color" :format="hex" :zIndex="9999999" :useType="pure" :disableHistory="true" :disableAlpha="true" isWidget="true"/>
    </div>

    <button :disabled="!this.newTagData.name.trim()" class="button" @click="onClickSubmit">{{ $t('settings.tags.new.submit') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import tinycolor from 'tinycolor2'

export default {
  name: 'NewTag',
  computed: mapGetters(['newTagData']),
  methods: {
    ...mapActions(['toggleNewTagOpen', 'createTag', 'updateTag', 'emptyNewTagData']),
    onClickSubmit () {
      this.newTagData.color = tinycolor(this.newTagData.color).toHexString()

      if (this.newTagData.id === null) {
        this.createTag(this.newTagData).then(() => {
          this.emitter.emit('crmRefreshTags')
        })
      } else {
        this.updateTag(this.newTagData).then(() => {
          this.emitter.emit('crmRefreshTags')
        })
      }

      this.toggleNewTagOpen()
      this.emptyNewTagData()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    close () {
      this.toggleNewTagOpen()
    }
  }
}
</script>

<style scoped>
 .modal {
   height: 540px;
 }

 .tag {
   width: fit-content;
   border-radius: 7.5px;
   padding: 5px;
   margin-right: 10px;
   margin-top: 10px;
   box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
 }

 .tag i {
   color: rgba(60,60,60,0.4);
   cursor: pointer;
 }
</style>
