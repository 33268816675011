import axios from 'axios'
import Cookies from 'js-cookie'

const state = {
  invitations: null
}
const getters = {
  invitations: (state) => state.invitations
}
const actions = {
  async fetchInvitations ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/invitation`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setInvitations', res.data)
  },
  async fetchInvitation ({ commit }, token) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/invitation/${token}`)

    if (res.status !== 200) {
      window.location.href = process.env.VUE_APP_APP_HOST + '/404'
    }

    return res.data
  },
  async acceptInvitation ({ commit }, invitation) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/invitation/${invitation.token}`, {
      password: invitation.password
    }, {
    }).then((res) => {
      Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
      Cookies.set('accessToken', res.data.token, { expires: 7, path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
      window.location.href = process.env.VUE_APP_APP_HOST + '/chats'
    }).catch(() => {
      alert('Error')
    })
  },
  async createInvitation ({ commit, rootState }, invitation) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/invitation`, invitation, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      commit('addInvitation', res.data)
    })
  },
  async deleteInvitation ({ commit, rootState }, invitation) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/invitation/${invitation.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteInvitation', invitation)
    }).catch(() => {
      commit('deleteInvitation', invitation)
    })
  }
}
const mutations = {
  setInvitations: (state, invitations) => (state.invitations = invitations),
  addInvitation: (state, newInvitation) => {
    if (!state.invitations) {
      return
    }

    state.invitations.push(newInvitation)
  },
  deleteInvitation: (state, invitation) => {
    const index = state.invitations.findIndex(el => el.id === invitation.id)
    if (index !== -1) {
      state.invitations.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
