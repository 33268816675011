<template>
  <div v-if="this.download"  class="overlay">
    <div class="modal">
      <h3>{{ $t('download.title') }}</h3>
      <p>{{ $t('download.subtitle') }}</p>
      <div class="fa-3x loading">
        <i class="fas fa-circle-notch fa-spin"></i>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'DownloadExcel',
  data () {
    return {
      download: false
    }
  },
  sockets: {
    downloadExcelReady (data) {
      if (data.id) {
        this.downloadExcel(data.id).then(() => { this.close() }).catch(() => { this.close() })
      }
    }
  },
  methods: {
    ...mapActions(['downloadExcel']),
    close () {
      this.download = null
    },
    open () {
      this.download = true
    }
  },
  created () {
    this.emitter.on('openDownloadExcel', this.open)
  }
}
</script>

<style scoped>
 .modal {
   z-index: 3000001;
   width: 400px;
   height: 300px;
   margin: 0 auto;
   position: relative;
   top: 30px;
   left: 0;
 }

 .overlay {
   z-index: 2000000;
   opacity: 1;
   background-color: rgba(32,33,36,0.3);
 }

 .loading {
   text-align: center;
   margin-top: 100px;
   color: #2c3e50;
 }
 </style>
