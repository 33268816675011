import axios from 'axios'

const state = {
  routingSteps: []
}
const getters = {
  routingSteps: (state) => state.routingSteps
}
const actions = {
  async fetchRoutingSteps ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/routing_steps`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setRoutingSteps', res.data)
  },
  async saveRoutingSteps ({ commit, rootState }, steps) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/routing_steps`, { steps: steps }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  }
}
const mutations = {
  setRoutingSteps: (state, steps) => (state.routingSteps = steps)
}

export default {
  state,
  getters,
  actions,
  mutations
}
