<template>
  <div class="overlay" @click="close"></div>
  <div class="modal slide">
    <h3 v-if="this.selectedContact">{{ $t('event.contact.events_of', { name: this.selectedContact.name}) }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
    <div class="eventslist">
      <Bubble :key="message.id" v-for="message in events" :message="message" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Bubble from '@/components/home/chat/Bubble'

export default {
  name: 'ContactEventsList',
  components: { Bubble },
  computed: mapGetters(['selectedContact']),
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      events: [],
      today: today,
      newYear: newYear
    }
  },
  methods: {
    ...mapActions(['toggleEventsListOpen', 'fetchAllRemindersForContact']),
    close () {
      this.toggleEventsListOpen()
    }
  },
  mounted () {
    if (this.selectedContact) {
      this.events = []
      this.fetchAllRemindersForContact(this.selectedContact)
        .then((events) => {
          this.events = events
        }).catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>
h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 35px !important;
}

.slide {
  top: 0;
  bottom: 0;
  height: unset;
  right: 0;
  width: 40%;
  margin-left: unset;
  left: unset;
}

.eventslist {
  overflow-y: visible;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
<style>
.eventslist .message.reminder {
  width: 95%;
  min-width: 95%;
  height: fit-content;
}

.eventslist .message.reminder .metadata{
  display: none;
}

</style>
