<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3 v-if="!this.newTeamData.id">{{ $t('settings.teams.new.title') }}</h3>
    <h3 v-if="this.newTeamData.id">{{ $t('settings.teams.edit.title', { name: this.newTeamData.name }) }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="team-name">{{ $t('settings.teams.name') }}</label>
      <input type="text" id="team-name" v-model="this.newTeamData.name">
    </div>

    <button :disabled="!this.newTeamData.name.trim()" class="button" @click="onClickSubmit">{{ $t('settings.teams.new.submit') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewTeam',
  computed: mapGetters(['newTeamData']),
  methods: {
    ...mapActions(['toggleNewTeamOpen', 'createTeam', 'updateTeam', 'emptyNewTeamData']),
    onClickSubmit () {
      if (this.newTeamData.id === null) {
        this.createTeam(this.newTeamData).then(() => {
          this.emitter.emit('crmRefreshTeams')
        })
      } else {
        this.updateTeam(this.newTeamData).then(() => {
          this.emitter.emit('crmRefreshTeams')
        })
      }

      this.toggleNewTeamOpen()
      this.emptyNewTeamData()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    close () {
      this.toggleNewTeamOpen()
    }
  }
}
</script>

<style scoped>

</style>
