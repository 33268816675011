import axios from 'axios'

const state = {
  modules: null,
  modulesTags: null,
  modulesSEO: null
}
const getters = {
  modules: (state) => state.modules,
  modulesTags: (state) => state.modulesTags,
  modulesSEO: (state) => state.modulesSEO
}
const actions = {
  async fetchModules ({ commit, rootState }, params) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/modules`, {
      params: params,
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    if (params.seo) {
      commit('setModulesSEO', res.data)
      return res.data
    }

    if (!params.tag) {
      commit('setModules', res.data)
    }

    return res.data
  },
  async fetchModuleByKey ({ commit, rootState }, key) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/module/${key}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  },
  async fetchModulesTags ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/modules/tags`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setModulesTags', res.data)
  },
  async fetchModulesRandom ({ rootState }, module) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/modules/random/${module.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
    return res.data
  }
}
const mutations = {
  setModules: (state, modules) => (state.modules = modules),
  setModulesTags: (state, modulesTags) => (state.modulesTags = modulesTags),
  setModulesSEO: (state, modulesSEO) => (state.modulesSEO = modulesSEO)
}

export default {
  state,
  getters,
  actions,
  mutations
}
