<template>
  <nav>
   <p>
     <span v-html="$t('paywall.alert.text')"></span>
     <button @click="onClickButton" class="button">{{ $t('paywall.alert.button') }}</button>
   </p>
  </nav>
</template>

<script>
export default {
  name: 'ImportantAlert',
  methods: {
    onClickButton () {
      this.emitter.emit('openQRPaywall')
    }
  },
  components: {
  }
}
</script>

<style scoped>
nav {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: #e74c3c;
  color: white;
  z-index: 4;
  text-align: center;
  display: none;
  height: 60px;
}

.importantAlert nav {
  display: flex;
}

p {
  margin: 0;
  padding: 0;
  margin-left: 50px;
  margin-top: 12px;
}

button {
  position: absolute;
  right: 50px;
  top: 10px;
  padding: 0 10px;
  width: fit-content;
}

</style>
