import axios from 'axios'

const state = {
  campaigns: []
}
const getters = {
  campaigns: (state) => state.campaigns
}
const actions = {
  async fetchCampaigns ({ commit, dispatch, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/campaigns`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setCampaigns', res.data)

    for (const campaign of res.data.campaigns) {
      if (campaign.sent === 0) {
        setTimeout(() => {
          dispatch('fetchCampaigns')
        }, 5000)

        break
      }
    }
  },
  async createCampaign ({ commit, dispatch, rootState }, campaign) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/campaign`, campaign, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchOrganization')
    })
  },
  async deleteCampaign ({ commit, dispatch, rootState }, campaign) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/campaign/${campaign.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchCampaigns')
    })
  }
}
const mutations = {
  setCampaigns: (state, campaigns) => {
    state.campaigns = campaigns
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
