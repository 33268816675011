<template>
  <div class="settings-group">
    <h3>{{ $t('settings.import.excel.title') }}</h3>
    <p v-html="$t('settings.import.excel.description')"></p><a href="https://api.manycontacts.com/import_contacts.xlsx"><i class="fa fa-download" aria-hidden="true"></i> {{ $t('settings.import.excel.download_schema') }}
</a>
    <p>{{ $t('settings.import.excel.description2') }} <button @click="onPickFile" class="button">{{ $t('settings.import.excel.button_import') }}</button></p>
      <input
        type="file"
        id="import-file-selector"
        style="display: none"
        ref="fileInput"
        accept="xlsx/*"
        @change="onFilePicked"/>
    <br>
    <div v-if="importsExcel && importsExcel.length > 0">
      <hr><br>
      <h3>{{ $t('settings.import.excel.title_table') }}</h3>
      <table>
        <thead>
        <tr>
          <th>{{ $t('settings.import.excel.date_import') }}</th>
          <th>{{ $t('settings.import.excel.user') }}</th>
          <th>{{ $t('settings.import.excel.imported_contacts') }}</th>
          <th>{{ $t('settings.import.excel.status') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="importExcel in importsExcel" :key="importExcel.id">
          <td>{{ $moment(importExcel.start_date).format('L LT') }}</td>
          <td>{{ importExcel.user_name }}</td>
          <td>{{ importExcel.number_contacts_imported }}</td>
          <td>{{ $t(`settings.import.excel.status_locale.${importExcel.status}`) }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="settings-group">
    <h3>{{ $t('settings.whatsapp.import.wabusiness.title') }}</h3>
    <div class="form-group">
      <p v-html="$t('settings.whatsapp.import.wabusiness.subtitle')"></p>
      <button @click="openImportWhatsAppBusiness()" class="button" type="submit">{{ $t('settings.whatsapp.import.wabusiness.button') }}</button>
    </div>
    <br><br>
    <table v-if="imports && imports.length > 0">
      <thead>
      <th>{{ $t('settings.whatsapp.import.wabusiness.table.number') }}</th>
      <th>{{ $t('settings.whatsapp.import.wabusiness.table.user') }}</th>
      <th>{{ $t('settings.whatsapp.import.wabusiness.table.created_at') }}</th>
      <th>{{ $t('settings.whatsapp.import.wabusiness.table.status') }}</th>
      </thead>
      <tbody>
      <tr :key="item.id" v-for="item in imports">
        <td>{{ item.number }}</td>
        <td>{{ getUserName(item.user_id) }}</td>
        <td>{{ $moment(item.createdAt).format('L HH:mm') }}</td>
        <td>{{ $t(`settings.whatsapp.import.wabusiness.table.statuses.${item.status}`) }}</td>
      </tr>
      </tbody>
    </table>
  </div>

  <ImportWhatsAppBusiness />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ImportWhatsAppBusiness from '@/components/modals/ImportWhatsAppBusiness'

export default {
  name: 'SectionImport',
  components: { ImportWhatsAppBusiness },
  computed: mapGetters(['importsExcel', 'imports', 'organizationUsers']),
  created () {
    this.fetchimportExcels()
    this.fetchImports()
  },
  methods: {
    ...mapActions(['fetchimportExcels', 'createImportExcel', 'fetchImports']),
    onPickFile () {
      this.$refs.fileInput.click()
    },
    getUserName (userId) {
      if (this.organizationUsers.find(el => el.id === userId)) {
        return this.organizationUsers.find(el => el.id === userId).name
      }

      return ''
    },
    onFilePicked (event) {
      const files = event.target.files
      this.createImportExcel(files[0]).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
        this.fetchimportExcels()
        const fileSelectorEl = document.getElementById('import-file-selector')
        if (fileSelectorEl) {
          fileSelectorEl.value = ''
        }
      })
    },
    openImportWhatsAppBusiness () {
      this.emitter.emit('openImportWhatsAppBusiness')
    }
  }
}
</script>

<style scoped>
.form-group-columns {
  display: flex;
}

.form-group-columns .form-group {
  padding: 0 5px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

button {
  position: relative;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
  margin-left: 10px;
  bottom: unset;
}

.recharge {
  line-height: 50px;
}

.button-light {
  color: #4285f4;
  background-color: #ffffff;
  border: 0;
  padding: 0;
  width: fit-content;
}
</style>
