import axios from 'axios'

const state = {
  conchiUsers: []
}
const getters = {
  conchiUsers: (state) => state.conchiUsers
}
const actions = {
  async createAgentAI ({ commit, rootState }, user) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/ai/agent`, user, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async editAgentAI ({ commit, rootState }, user) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/ai/agent/${user.id}`, user, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async fetchAgentAI ({ commit, rootState }, userId) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/ai/agent/${userId}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      return res.data
    })
  },
  async fetchAgentsAI ({ commit, rootState }) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/ai/agent`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      commit('setConchiUsers', res.data)
    })
  },
  async deleteAgentAI ({ commit, rootState }, user) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/ai/agent/${user.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteConchiUser', user)
    }).catch(() => {
      commit('deleteConchiUser', user)
    })
  },
  async createAgentAIFeedback ({ commit, rootState }, { userId, feedback }) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/ai/agent/${userId}/feedback`, feedback, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  },
  async fetchAgentAIFeedback ({ commit, rootState }, userId) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/ai/agent/${userId}/feedback`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((res) => {
      return res.data
    })
  },
  async deleteAgentAIFeedback ({ commit, rootState }, feedback) {
    return axios.delete(`${process.env.VUE_APP_SERVERHOST}api/ai/agent/${feedback.user_id}/feedback/${feedback.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  }
}
const mutations = {
  setConchiUsers: (state, conchiUsers) => (state.conchiUsers = conchiUsers),
  deleteConchiUser: (state, delUserId) => {
    const index = state.conchiUsers.findIndex(user => user.id === delUserId.id)
    if (index !== -1) {
      state.conchiUsers.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
