<template>
  <div class="settings-group" v-if="organization && organization.features.credits" >
    <h3>{{ $t('conversations.invoicing.title') }} (<a target="_blank" href="https://www.manycontacts.com/blog/whatsapp-business-api-conversaciones/">?</a>)</h3>
    <p v-html="$t('conversations.invoicing.howmany_credits', {credits: organization.credits})"></p>
    <div class="recharge">
      <h4>
        {{ $t('conversations.invoicing.recharge.title') }}
        <button @click="addCredits(1000)" class="button button-small">+1000</button>
        <button @click="addCredits(2000)" class="button button-small">+2000</button>
        <button @click="addCredits(5000)" class="button button-small">+5000</button>
        <button @click="addCredits(10000)" class="button button-small">+10000</button>
      </h4>

    </div>
    <div class="autorecharge">
      <div v-if="organization.autoRecharge === false">
        <h4>
          {{ $t('conversations.invoicing.autorecharge.title') }}
          <button class="button button-small" @click="openEnableAutoRecharge()">{{ $t('conversations.invoicing.autorecharge.enable') }}</button>
        </h4>
        <p v-html="$t('conversations.invoicing.autorecharge.subtitle')"></p>
      </div>
      <div v-if="organization.autoRecharge === true">
        <h4>{{ $t('conversations.invoicing.autorecharge.enable', { credits: organization.autoRechargeCredits }) }}<button class="button button-small button-light" @click="onClickDisableAutoRecharge()">{{ $t('conversations.invoicing.autorecharge.disable') }}</button></h4>
      </div>
    </div>

    <hr>
    <div v-if="billable !== null">
      <h3>{{ $t('conversations.invoicing.stats_current_month.title') }}</h3>
      <div class="numbers">
        <div v-html="$t('conversations.invoicing.stats_current_month.business', { number: billable.business })"></div>
        <div v-html="$t('conversations.invoicing.stats_current_month.user', { number: billable.user })"></div>
        <div v-html="$t('conversations.invoicing.stats_current_month.total', { number: billable.total })"></div>
      </div>
    </div>
  </div>

  <div class="settings-group">
    <h3>{{ $t('settings.invoicing.title') }}</h3>

    <form v-if="organization" @submit="onSubmitOrganization">
      <div class="form-group-columns">
        <div class="form-group" style="width: 75%">
          <label for="vatid">{{ $t('settings.invoicing.fiscal_name') }}</label>
          <input v-model="organization.fiscalName" type="text" id="vatid" name="vatid">
        </div>
        <div class="form-group" style="width: 25%">
          <label for="fiscal-name">{{ $t('settings.invoicing.vatid') }}</label>
          <input v-model="organization.vatId" type="text" id="fiscal-name" name="fiscal-name">
        </div>
      </div>
      <div class="form-group" style="padding: 0 5px;">
        <label for="fiscal-address">{{ $t('settings.invoicing.address') }}</label>
        <input v-model="organization.fiscalAddress" type="text" id="fiscal-address" name="fiscal-address">
      </div>
      <div class="form-group-columns">
        <div class="form-group">
          <label for="postalcode">{{ $t('settings.invoicing.postalcode') }}</label>
          <input v-model="organization.fiscalPostcode" type="text" id="postalcode" name="postalcode">
        </div>
        <div class="form-group" style="width: 50%">
          <label for="city">{{ $t('settings.invoicing.city') }}</label>
          <input v-model="organization.city" type="text" id="city" name="city">
        </div>
        <div class="form-group">
          <label for="country">{{ $t('settings.invoicing.country') }}</label>
          <select v-model="organization.country" id="country" name="country">
            <option v-for="country in countriesList" :key="country.code" :value="country.code">{{ country.name_es }}</option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <button class="button" type="submit">{{ $t('settings.profile.personal.button') }}</button>
      </div>
    </form>
  </div>

  <div class="settings-group">
    <h3>{{ $t('settings.invoices.title') }}</h3>
    <div class="form-group">
      <button @click="openAddMethod" class="button" type="submit">{{ $t('settings.invoices.addmethod') }}</button>
    </div>

    <table v-if="organization && organization.invoices && organization.invoices.length > 0">
      <thead>
      <th style="width: 20%">{{ $t('settings.invoices.table.number') }}</th>
      <th style="width: 20%">{{ $t('settings.invoices.table.amount') }}</th>
      <th style="width: 30%">{{ $t('settings.invoices.table.date') }}</th>
      <th style="width: 10%"></th>
      </thead>
      <tbody>
        <tr :key="invoice.id" v-for="invoice in organization.invoices">
          <td>{{ invoice.number }}</td>
          <td>{{ $n(invoice.total / 100)}}<span v-if="invoice.currency === 'eur'">€</span><span v-if="invoice.currency !== 'eur'">{{invoice.currency}}</span></td>
          <td>{{ $moment.unix(invoice.created).format('L') }}</td>
          <td>
            <a :href="invoice.invoice_pdf">{{ $t('settings.invoices.table.download') }}</a>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="organization && organization.invoices && organization.invoices.length === 0">
      {{ $t('settings.invoices.empty') }}
    </div>
  </div>

  <div v-if="organization && organization.invoices.length === 0"  class="settings-group" style="padding-top: 20px; margin-bottom: 250px">
    <form @submit="onSavePromo">
      <div class="form-group-columns">
        <div class="form-group" style="width: 100%">
          <label for="vatid">{{ $t('settings.invoicing.appsumo') }}</label>
          <input v-model="organization.appsumo" type="text" id="appsumo" name="appsumo">
        </div>
      </div>
      <div class="form-group">
        <button class="button" type="submit">{{ $t('settings.profile.personal.button') }}</button>
      </div>
    </form>
  </div>

  <EnableAutoRecharge />

</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import countries from '@/assets/countries.json'
import EnableAutoRecharge from '@/components/modals/EnableAutoRecharge'

export default {
  name: 'SectionInvoicing',
  components: { EnableAutoRecharge },
  computed: mapGetters(['organization', 'billable']),
  data () {
    return {
      countriesList: countries.countries
    }
  },
  created () {
    this.fetchBillable()
  },
  methods: {
    ...mapActions(['updateOrganization', 'fetchBillable', 'fetchCheckoutSessionLink', 'fetchAddMethodLink']),
    onSubmitOrganization (e) {
      e.preventDefault()

      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onSavePromo (e) {
      e.preventDefault()

      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('settings.invoicing.promo_saved')
        })
      })
    },
    addCredits (number) {
      this.fetchCheckoutSessionLink({ credits: number }).then((url) => {
        location.href = url
      })
    },
    openEnableAutoRecharge () {
      this.emitter.emit('openEnableAutoRecharge')
    },
    onClickDisableAutoRecharge () {
      this.organization.autoRecharge = false
      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    openAddMethod () {
      this.fetchAddMethodLink().then((url) => {
        location.href = url
      })
    }
  }
}
</script>

<style scoped>
.form-group-columns {
  display: flex;
}

.form-group-columns .form-group {
  padding: 0 5px;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

button {
  position: relative;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;
  margin-right: 10px;
  margin-left: 10px;
  bottom: unset;
}

.recharge {
  line-height: 50px;
}

.button-light {
  color: #4285f4;
  background-color: #ffffff;
  border: 0;
  padding: 0;
  width: fit-content;
}
</style>
