import axios from 'axios'

const state = {
  customFields: []
}
const getters = {
  customFields: (state) => state.customFields
}
const actions = {
  async fetchCustomFields ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/custom_fields`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setCustomFields', res.data)
  },
  async emptyCustomFields ({ commit, rootState }) {
    commit('setCustomFields', [])
  },
  async createCustomField ({ commit, rootState }, cf) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/custom_field`, cf, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((newCf) => {
      commit('addCustomField', newCf.data)
    }).catch(() => {})
  },
  async updateCustomField ({ commit, rootState }, cf) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/custom_field/${cf.id}`, cf, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async deleteCustomField ({ commit, rootState }, cf) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/custom_field/${cf.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteCustomField', cf)
    }).catch(() => {})
  }
}
const mutations = {
  setCustomFields: (state, customFields) => (state.customFields = customFields),
  addCustomField: (state, newCustomField) => (state.customFields.push(newCustomField)),
  deleteCustomField: (state, newCustomField) => {
    const index = state.customFields.findIndex(cf => cf.id === newCustomField.id)
    if (index !== -1) {
      state.customFields.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
