<template>
  <div id="contact-list" class="container-list" :class="{selectedContact: selectedContact !== null}">
    <div v-if="[1,2].includes(this.filter)" class="container-list-header">
      <div hidden class="list-order">
        <i @click="onChangeFilters(null,'stream')" :class="{ active: this.sort === 'stream' }" class="fas fa-stream" v-tooltip.top="{ content: $t('contact.sort.stream')}"></i>
        <i @click="onChangeFilters(null, 'az')" :class="{ active: this.sort === 'az' }" class="fas fa-sort-alpha-down" v-tooltip.top="{ content: $t('contact.sort.az')}"></i>
      </div>
      <div v-if="this.filtersOpen === true" @click="this.resetFilters()" class="filters-close">{{ $t('contact.delete_filter') }}</div>
      <div v-if="this.filtersOpen !== true" @click="this.setFiltersOpen(true)" class="filters-close">{{ $t('contact.filter') }}</div>
      <div v-if="this.filtersOpen === true" class="filters">
        <v-select style="margin-top: 5px" @option:selected="onChangeFilters(null)" @option:deselected="onChangeFilters(null)" v-if="tags" multiple v-model="this.selectedTags" :placeholder="$t('contact.tags_placeholder')" :options="tags" label="name"></v-select>
        <v-select v-if="organizationUsers && this.filter === 1" style="margin-top: 5px" @option:selected="onChangeFilters(null)" @option:deselected="onChangeFilters(null)" multiple v-model="this.selectedUsers" :placeholder="$t('contact.users_placeholder')" :options="organizationUsers" label="name"></v-select>
        <v-select multiple v-if="stagesList.length > 0" style="margin-top: 5px" @option:selected="onChangeFilters(null)" @option:deselected="onChangeFilters(null)" v-model="this.selectedStage" :placeholder="$t('settings.funnels.stages')" :reduce="stage => stage.id" :options="stagesList" label="name" :selectable="(option) => option.id !== this.selectedStage && option.id !== 'notSelectable' ">
          <template #option="option">
            <span>
              {{ option.name }}
            </span>
          </template>
        </v-select>
        <VueDatePicker @update:model-value="onChangeDatePicker" :locale="this.loggedUser.locale" format="dd/MM/yyyy" style="margin-top: 5px" range v-model="dateRange" :enable-time-picker="false"></VueDatePicker>
        <div>
          <input @change="onChangeFilters(null)" v-model="onlyClosedConversations" type="checkbox">{{ $t('contact.closed_conversations') }}
          <br>
          <input @change="onChangeFilters(null)" v-model="onlyUnreadConversations" type="checkbox">{{ $t('contact.unread_conversations') }}
          <br>
          <input @change="onChangeFilters(null)" v-model="conversationsWithReminders" type="checkbox">{{ $t('contact.reminder_conversations') }}
          <div>
            <input @change="onChangeFilters(null)" v-model="conversationsWithScheduled" type="checkbox">{{ $t('event.contact.scheduled_conversations') }}
          </div>
          <div v-if="showModule('campaigns')">
            <input @change="onChangeFilters(null)" v-model="conversationsInBlacklist" type="checkbox">{{ $t('event.contact.blacklisted_conversations') }}
          </div>
        </div>
        <div v-if="allContacts && allContacts.length > 0 && loggedUser" class="search-options">
          <div v-if="this.isFiltered || (this.organization && this.organization.features.export === true)" class="search-option button button-free button-small" @click="onChangeFilters('excel')">
            <i class="far fa-file-excel"></i> {{ $t('contact.download_excel') }}
          </div>

          <div v-if="this.isFiltered && this.organization && showModule('campaigns') && this.conversationsInBlacklist === false" class="search-option button button-free button-small" @click="onClickBulkSend()">
            <i class="fas fa-bullhorn"></i> {{ $t('contact.bulk_send') }}
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.filter === 6" class="container-list-header">
      <div class="list-order">
        <router-link @click="saveCalendarPreference('calendar')" to="/calendar">
          <i :class="{ active: this.$route.name === 'Calendar' }" class="far fa-calendar" v-tooltip.top="{ content: $t('event.calendar')}"></i>
        </router-link>
        <router-link @click="onClickFetchContacts(6)" to="/reminders">
          <i :class="{ active: this.filter === 6 && this.$route.name !== 'Calendar' }" class="fas fa-stream" v-tooltip.top="{ content: $t('event.passed')}"></i>
        </router-link>
      </div>

    </div>
    <ul v-infinite-scroll="this.loadMore" infinite-scroll-distance="500" v-if="allContacts && allContacts.length > 0">
      <ListItem :key="contact.id" v-for="contact in allContacts" :contact="contact" />
    </ul>
    <ul v-if="allContacts && allContacts.length === 0">
      <ListItemEmptyResult />
    </ul>
    <ul v-if="!allContacts">
      <ListItemNotLoaded v-for="index in 10" :key="index" />
    </ul>
    <div v-if="filter === 7" class="new-chat-container">
      <div class="new-chat" @click="clickNewMessage" :data-hint="$t('wizard.hints.new_chat')" data-hint-position="bottom-middle">
        <i class="fas fa-plus"></i>
        {{ $t('sidebar.chat_new_internal') }}
      </div>
    </div>
  </div>
</template>

<script>
import ListItem from './ListItem'
import ListItemNotLoaded from './ListItemNotLoaded'
import ListItemEmptyResult from './ListItemEmptyResult'

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'List',
  computed: mapGetters([
    'allContacts', 'filter', 'page',
    'tags', 'organizationUsers', 'selectedContact',
    'filtersOpen', 'sort', 'organization', 'loggedUser',
    'modules', 'organizationModules', 'funnels'
  ]),
  data () {
    return {
      busy: false,
      hasMoreData: true,
      selectedTags: null,
      selectedUsers: null,
      selectedStage: null,
      onlyClosedConversations: false,
      onlyUnreadConversations: false,
      conversationsWithReminders: false,
      conversationsWithScheduled: false,
      conversationsInBlacklist: false,
      isFiltered: false,
      dateRange: null,
      stagesList: []
    }
  },
  methods: {
    ...mapActions([
      'fetchContacts',
      'fetchContactsNextPage',
      'fetchConversation',
      'fetchContact',
      'fetchTeamContacts',
      'toggleNewMessage',
      'setFiltersOpen',
      'setSort',
      'setPage',
      'clearSearch',
      'fetchFunnels'
    ]),
    showModule (key) {
      if (!this.loggedUser) {
        return false
      }

      if (!this.organization) {
        return
      }

      if (!this.modules) {
        return false
      }

      const module = this.modules.find(module => module.key === key)
      if (!module) {
        return false
      }

      if (module.key === 'campaigns' && !this.loggedUser.canSeeCampaigns) {
        return false
      }

      if (!module.type) {
        return this.isModuleEnabled(module.key)
      }

      if (module.type && module.type === this.organization.watype) {
        return this.isModuleEnabled(module.key)
      }

      return false
    },
    isModuleEnabled (key) {
      if (this.organization && this.organizationModules && this.organizationModules.includes(key)) {
        return true
      }

      return false
    },
    loadMore () {
      if (this.busy || (this.page > 0 && !this.hasMoreData)) {
        return
      }

      this.busy = true
      setTimeout(async () => {
        const query = {}

        if (Number.isInteger(this.filter)) {
          query.filter = this.filter
        } else {
          query.filter = 1
          query.team = this.filter
        }

        if (this.$route.params.tagId) {
          query.tags = [this.$route.params.tagId]
        }

        if (this.selectedTags) {
          const tags = []
          for (const tag of this.selectedTags) {
            tags.push(tag.id)
          }
          query.tags = tags
        }

        if (this.selectedUsers) {
          const users = []
          for (const user of this.selectedUsers) {
            users.push(user.id)
          }
          query.users = users
        }

        if (this.selectedStage) {
          query.stage = this.selectedStage
        }

        if (this.onlyClosedConversations === true) {
          query.closed = this.onlyClosedConversations
        }

        if (this.onlyUnreadConversations === true) {
          query.unread = this.onlyUnreadConversations
        }

        if (this.conversationsWithReminders === true) {
          query.reminder = this.conversationsWithReminders
        }

        if (this.conversationsWithScheduled === true) {
          query.scheduled = this.conversationsWithScheduled
        }

        if (this.conversationsInBlacklist === true) {
          query.blacklist = this.conversationsInBlacklist
        }

        if (this.dateRange !== null) {
          query.range = this.dateRange
        }

        const hasMoreData = await this.fetchContactsNextPage(query)
        this.hasMoreData = hasMoreData
        this.busy = false
      }, 1)
    },
    onChangeDatePicker (range) {
      this.dateRange = range
      this.onChangeFilters(null)
    },
    onChangeFilters (download, sort) {
      this.isFiltered = true

      const tags = []
      const users = []

      if (sort) {
        this.setSort(sort)
      }

      if (this.selectedTags) {
        for (const tag of this.selectedTags) {
          tags.push(tag.id)
        }
      }

      if (this.selectedUsers) {
        for (const user of this.selectedUsers) {
          users.push(user.id)
        }
      }

      if (this.selectedStage && Array.isArray(this.selectedStage) && this.selectedStage.length > 1) { // To modificate actions of component v-select
        this.selectedStage = this.selectedStage[1]
      }

      const query = {
        filter: this.filter,
        tags: tags,
        users: users,
        stage: this.selectedStage,
        page: 0,
        closed: this.onlyClosedConversations,
        unread: this.onlyUnreadConversations,
        reminder: this.conversationsWithReminders,
        scheduled: this.conversationsWithScheduled,
        blacklist: this.conversationsInBlacklist,
        range: this.dateRange
      }

      if (download) {
        this.emitter.emit('openDownloadExcel')
        query.download = download
      }

      if (tags.length === 0 &&
        users.length === 0 &&
        this.selectedStage === null &&
        this.onlyClosedConversations === false &&
        this.onlyUnreadConversations === false &&
        this.conversationsWithReminders === false &&
        this.conversationsWithScheduled === false &&
        this.conversationsInBlacklist === false &&
        this.dateRange === null) {
        this.isFiltered = false
      }

      this.hasMoreData = true
      this.fetchContacts(query)
    },
    resetFilters () {
      this.reset()
      this.fetchContacts({ filter: this.filter, page: 0 })
    },
    reset () {
      this.setFiltersOpen(false)
      this.setSort('stream')
      this.selectedTags = null
      this.selectedUsers = null
      this.selectedStage = null
      this.onlyClosedConversations = false
      this.onlyUnreadConversations = false
      this.conversationsWithReminders = false
      this.conversationsWithScheduled = false
      this.conversationsInBlacklist = false
      this.hasMoreData = true
      this.isFiltered = false
      this.dateRange = null
    },
    onClickBulkSend () {
      const tags = []
      const users = []

      if (this.selectedTags) {
        for (const tag of this.selectedTags) {
          tags.push(tag)
        }
      }

      if (this.selectedUsers) {
        for (const user of this.selectedUsers) {
          users.push(user)
        }
      }

      const query = {
        filter: this.filter,
        selectedTags: tags,
        selectedUsers: users,
        onlyClosedConversations: this.onlyClosedConversations,
        onlyUnreadConversations: this.onlyUnreadConversations,
        conversationsWithReminders: this.conversationsWithReminders,
        conversationsWithScheduled: this.conversationsWithScheduled
      }

      this.emitter.emit('openBulkSend', query)
    },
    clickNewMessage () {
      this.toggleNewMessage()
      setTimeout(() => {
        const telInputEl = document.getElementById('tel-input')
        if (telInputEl) {
          telInputEl.focus()
        }
      }, 300)
    },
    onClickFetchContacts (filter) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchContacts({ filter: filter })
    },
    saveCalendarPreference (view) {
      localStorage.calendarUrl = '/' + view
    }
  },
  async created () {
    this.emitter.on('resetFilters', this.reset)
    let filters = {}
    if (this.$route.params.tagId && this.tags) {
      this.selectedTags = [this.$route.params.tagId]
      filters = { filter: this.filter, tags: [this.$route.params.tagId] }
    } else {
      filters = { filter: this.filter }
    }
    if (Number.isInteger(this.filter)) {
      this.fetchContacts(filters).then(() => {
        const contactId = this.$route.params.contactId
        if (undefined !== contactId) {
          const index = this.allContacts.findIndex(contact => contact.id === contactId)
          const messageId = this.$route.params.messageId

          if (index !== -1) {
            this.fetchConversation({ contact: this.allContacts[index], messageId: messageId })
          } else {
            this.fetchContact(contactId).then(contact => {
              this.fetchConversation({ contact: contact, messageId: messageId })
            })
          }
        }
      })
    } else {
      // Teams
      this.fetchTeamContacts(this.filter).then(() => {
        const contactId = this.$route.params.contactId
        if (undefined !== contactId) {
          const index = this.allContacts.findIndex(contact => contact.id === contactId)
          const messageId = this.$route.params.messageId

          if (index !== -1) {
            this.fetchConversation({ contact: this.allContacts[index], messageId: messageId })
          } else {
            this.fetchContact(contactId).then(contact => {
              this.fetchConversation({ contact: contact, messageId: messageId })
            })
          }
        }
      })
    }
    let funnels = this.funnels
    if (this.funnels === null || this.funnels.length === 0) {
      funnels = await this.fetchFunnels()
    }

    this.stagesList = []
    for (const funnel of funnels) {
      this.stagesList.push({
        id: 'notSelectable',
        funnelId: null,
        name: `${funnel.name}`

      })
      for (const stage of funnel.stages) {
        this.stagesList.push({
          id: stage.id,
          funnelId: funnel.id,
          name: `${stage.name}`
        })
      }
    }
  },
  components: {
    ListItem,
    ListItemEmptyResult,
    ListItemNotLoaded
  }
}

</script>

<style scoped>
.container-list {
  width: 330px;
  float: left;
  border-right: 1px solid #EDEFF1;
  height: calc(100vh - 67px);
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
}

.importantAlert .container-list, .unpaidInvoiceAlert .container-list, .mustMigrate .container-list {
  height: calc(100vh - 127px);
}

.mobile .container-list {
  width: 100%;
}

.mobile .selectedContact {
  display: none;
}

.container-list-header {
  border-bottom: 1px solid #EDEFF1;
  padding: 10px 15px;
  text-align: right;
  min-height: 20px;
}

.container-list-header img {
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 13px;
}

.container-list-header .filters-close {
  position: absolute;
  top: 8px;
  right: 10px;
  padding: 4px 15px;
  background-color: #EDEFF1;
  border-radius: 4px;
  cursor: pointer;
}

.container-list-header .filters {
  font-size: 14px;
  text-align: left;
  margin-top: 40px;
}

.container-list-header .filters input[type=checkbox] {
  margin-top: 7px;
  vertical-align: bottom;
}

/*.menu-collapse div {*/
/*  width: 330px;*/
/*}*/

ul {
  margin: 0;
  padding: 0;
}

.new-chat-container {
  width: 100%;
  text-align: center;
}

.new-chat {
  margin: 40px 0px 0px 0px;
  border-radius: 24px;
  color: #5299DC;
  height: 36px;
  box-shadow: 0 0px 0px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  font-size: 16px;
  align-items: center;
  background-color: #fff;
  background-image: none;
  display: inline-flex;
  font-weight: 500;
  overflow: hidden;
  padding: 0 16px;
  cursor: pointer;
}
.new-chat:hover {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.30), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  background-color: #fafafb;
}

.new-chat i {
  margin-right: 10px;
}

.search-options {
  text-align: right;
  position: relative;
  margin: 15px 0 0px;
}

.search-options .search-option {
  width: fit-content;
  right: 0;
  display: inline-flex;
  cursor: pointer;
  margin-left: 5px;
  margin-bottom: 5px;
}

.search-options .search-option i {
  padding-right: 6px;
  line-height: 30px;
}

.list-order {
  position: absolute;
  top: 9px;
  left: 15px;
}

.list-order i {
  margin-right: 5px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  color: #111b21;
}

.list-order i.active {
  background-color: #4285f45e;
  color: #4285f4;
}
</style>

<style>
.dp__input {
  border-radius: 4px !important;
  border: 1px solid rgba(60,60,60,.26) !important;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}
</style>
