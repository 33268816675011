import { createStore } from 'vuex'

import user from '@/store/modules/user'
import organization from '@/store/modules/organization'
import contacts from '@/store/modules/contacts'
import messages from '@/store/modules/messages'
import counters from '@/store/modules/counters'
import modals from '@/store/modules/modals'
import templates from '@/store/modules/templates'
import shortResponses from '@/store/modules/short_responses'
import settings from '@/store/modules/settings'
import invitation from '@/store/modules/invitation'
import tags from '@/store/modules/tags'
import search from '@/store/modules/search'
import teams from '@/store/modules/teams'
import reminders from '@/store/modules/reminders'
import inboundEmails from '@/store/modules/inbound_emails'
import whatsapp from '@/store/modules/whatsapp'
import promote from '@/store/modules/promote'
import routing from '@/store/modules/routing'
import customFields from '@/store/modules/custom_fields'
import sso from '@/store/modules/sso'
import messagesScheduled from '@/store/modules/messages_scheduled'
import alerts from '@/store/modules/alerts'
import campaign from '@/store/modules/campaign'
import funnels from '@/store/modules/funnels'
import imports from '@/store/modules/imports'
import klaviyo from '@/store/modules/klaviyo'
import zadarma from '@/store/modules/zadarma'
import quickActions from '@/store/modules/quick_actions'
import sessions from '@/store/modules/sessions'
import modules from '@/store/modules/modules'
import organizationModules from '@/store/modules/organization_modules'
import admin from '@/store/modules/admin'
import conchiUser from '@/store/modules/conchi_user'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    user,
    organization,
    contacts,
    messages,
    counters,
    modals,
    templates,
    shortResponses,
    settings,
    invitation,
    tags,
    search,
    teams,
    reminders,
    inboundEmails,
    whatsapp,
    promote,
    routing,
    customFields,
    sso,
    messagesScheduled,
    alerts,
    campaign,
    funnels,
    imports,
    klaviyo,
    zadarma,
    quickActions,
    sessions,
    modules,
    organizationModules,
    admin,
    conchiUser
  }
})
