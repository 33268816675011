<template>
  <div class="settings-group">
    <h3>{{ $t('settings.klaviyo.title') }}</h3>
    <p v-html="$t('settings.klaviyo.subtitle')"></p>

    <form v-if="organization" @submit="onSubmit">
      <div class="form-group">
        <label for="klaviyo-apikey">{{ $t('settings.klaviyo.apikey') }}</label>
        <input type="text" id="klaviyo-apikey" name="klaviyo-apikey" v-model="organization.klaviyo">
      </div>
      <div class="form-group">
        <button class="button" type="submit">{{ $t('settings.profile.personal.button') }}</button>
      </div>
    </form>
  </div>

</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionKlaviyo',
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['updateOrganization', 'fetchOrganization']),
    onSubmit (event) {
      event.preventDefault()

      this.updateOrganization(this.organization)
      this.$toast.open({
        message: this.$t('form.saved')
      })
    }
  },
  created () {
    if (!this.organization) {
      this.fetchOrganization()
    }
  }
}
</script>

<style scoped>

h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

p {
  margin-bottom: 10px;
}

textarea {
  height: unset !important;
}

.settings-group.logout {
  border: 0;
  text-align: right;
  min-height: 250px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.form-group.blue {
  background-color: #4285f45e;
  padding: 20px;
  border-radius: 4px;
  min-height: 90px;
}

.help {
  font-size: 9px;
  cursor: pointer;
  border: 1px solid;
  border-radius: 100%;
  height: 15px;
  width: 15px;
  text-align: center;
  line-height: 15px;
  margin-left: 5px;
}

.switch-group {
  position: absolute;
  right: 20px;
  top: 20px;
}

.switch-text {
  float: left;
  text-align: right;
  margin-right: 60px;
  line-height: 35px;
}

</style>
