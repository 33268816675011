<template>
  <div class="content">
    <div class="header">
      <h1 id="module-content-title">{{ $t('integrations.title') }}</h1>
    </div>

    <div class="filter">
      <div>
        <span :class="{ selected: this.seoFilter === 'featured' }">
          <router-link @click="onClickFilter('featured')" :to="{ name: 'IntegrationsLocale', params: { locale: this.$i18n.locale } }">{{ $t('integrations.featured') }}</router-link>
        </span>
      </div>
      <div class="filter-option" v-for="letter in alphabet" :key="letter">
        - <span :class="{ selected: this.seoFilter.toLowerCase() === letter.toLowerCase() }">
          <router-link @click="onClickFilter(letter.toLowerCase())" :to="{ name: 'IntegrationsFilterLocale', params: { filter: letter.toLowerCase(), locale: this.$i18n.locale } }">{{ letter }}</router-link>
        </span>
      </div>
    </div>

    <div class="modules" v-if="modulesSEO">
      <IntegrationModule :module="module" v-for="module in this.modulesSEO" :key="module.id" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import IntegrationModule from '@/components/home/modules/IntegrationModule'

export default {
  name: 'Content_Integrations',
  components: {
    IntegrationModule
  },
  props: {
    filter: String
  },
  data () {
    return {
      seoFilter: 'featured',
      moduleList: [],
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
    }
  },
  computed: mapGetters([
    'organization',
    'modules',
    'organizationModules',
    'modulesSEO'
  ]),
  methods: {
    ...mapActions(['fetchModules', 'fetchModulesTags']),
    onClickFilter (filter) {
      this.seoFilter = filter
      this.fetchModules({ seo: this.seoFilter })
    }
  },
  mounted () {
    if (this.filter) {
      this.seoFilter = this.filter.toLowerCase()
    }

    this.fetchModules({ seo: this.seoFilter })

    if (this.moduleList.length !== 0) {
      return
    }

    if (!this.modules && this.category) {
      this.fetchModules({}).then(() => {
        this.fetchModules({ tag: this.category }).then((modules) => {
          this.moduleList = modules
          this.fetchModulesTags()
        })
      })
    } else {
      this.fetchModules({ tag: this.category }).then((modules) => {
        this.moduleList = modules
        this.fetchModulesTags()
      })
    }

    if (this.category) {
      this.activeTag = this.category
    }
  }
}
</script>

<style scoped>
.content {
  width: calc(100% - 111px);
  margin-left: 71px;
  padding: 20px;
  background-color: #ffffff;
  height: calc(100vh - 107px);
  overflow-y: scroll;
}

.header {
  margin-bottom: 30px;
  width: calc(100% - 40px);
}

.header h1 {
  padding: 0;
  margin: 0;
  /*width: max-content;*/
  font-size: 40px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header p {
  font-weight: 700;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2.seo {
  margin: 50px 0 30px 0;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modules {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-start;
}

.mobile .content, .mobile .hideSidebarChats .content {
  width: 100%;
  margin-left: 0px;
}

.filter {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
}

.mobile .filter {
  flex-wrap: wrap;
  width: calc(100% - 40px);
}

.filter .filter-option {
  margin-left: 5px;
}

.filter span {
  cursor: pointer;
}

.filter a {
  text-decoration: none;
  color: #111b21;
}

.filter span.selected {
  color: #4285f4;
  font-weight: bold;
}

</style>
