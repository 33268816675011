<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3 v-if="!this.newShortResponseData.id">{{ $t('short_responses.new.title') }}</h3>
    <h3 v-if="this.newShortResponseData.id">{{ $t('short_responses.edit.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="sr-title">{{ $t('short_responses.title') }}</label>
      <input type="text" id="sr-title" v-model="this.newShortResponseData.name">
    </div>

    <div class="form-group">
      <label for="sr-text">{{ $t('short_responses.text') }}</label>
      <textarea id="sr-text" v-model="this.newShortResponseData.text"></textarea>
    </div>

    <button :disabled="!this.newShortResponseData.name.trim() || !this.newShortResponseData.text.trim()" class="button" @click="onClickSubmit">{{ $t('short_responses.submit') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewShortResponse',
  computed: mapGetters(['newShortResponseData']),
  methods: {
    ...mapActions(['toggleNewShortResponse', 'createResponse', 'updateResponse', 'emptyNewShortResponseData']),
    onClickSubmit () {
      if (this.newShortResponseData.id === null) {
        this.createResponse(this.newShortResponseData)
      } else {
        this.updateResponse(this.newShortResponseData)
      }

      this.toggleNewShortResponse()
      this.emptyNewShortResponseData()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    close () {
      this.toggleNewShortResponse()
    }
  }
}
</script>

<style scoped>
.modal {
  height: 320px
}
</style>
