<template>
  <div class="content">
    <br>
    <div>
      NAME
      <br>
      <input type="text" id="partner-name" v-model="namePartner" @input="onInputName" />
    </div>
    <br>
    <div>
      MAIN IMAGE (903px width x 968px height)
      <br>
      <input type="file"  id="upload-partner-image" accept="image/png" ref="photo_partner" />
    </div>
    <br>
    <div>
      LOGO (squared)
      <br>
      <input type="file"  id="upload-partner-image-logo" accept="image/png" ref="logo_partner" />
    </div>
    <br>
    <div>
      <button class="button" @click="submitData">Upload</button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'AdminPartnerAdd',
  data () {
    return {
      namePartner: null
    }
  },
  methods: {
    ...mapActions(['savePartnerPhoto']),
    onInputName () {
      this.namePartner = this.namePartner.toLowerCase()
      this.namePartner = this.namePartner.replaceAll(' ', '_')
      this.namePartner = this.namePartner.replace(/\W/g, '')
    },
    submitData () {
      const fileLogo = this.$refs.logo_partner.files[0]
      const filePhoto = this.$refs.photo_partner.files[0]
      const hash = this.$route.query.hash

      if (this.namePartner === '' || this.namePartner === null || fileLogo === null || filePhoto === null) {
        this.$toast.open({
          message: 'Error, check the data',
          type: 'error'
        })
        return
      }

      const formData = new FormData()
      formData.append('photo', filePhoto)
      formData.append('logo', fileLogo)
      formData.append('name', this.namePartner)
      formData.append('hash', hash)

      this.savePartnerPhoto({ formData: formData }).then((success) => {
        this.$toast.open({
          message: 'Upload OK',
          type: 'success'
        })

        // Reset form
        this.namePartner = ''
        document.getElementById('upload-partner-image').value = ''
        document.getElementById('upload-partner-image-logo').value = ''
      }).catch((error) => {
        console.log('error', error)
        this.$toast.open({
          message: 'Error, check the data',
          type: 'error'
        })
      })
    }
  },
  created () {
    if (!this.$route.query.hash) {
      location.href = 'https://www.manycontacts.com'
    }
  }
}
</script>

<style scoped>

.content {
  width: 400px;
  position: absolute;
  left: 50%;
  margin-left: -200px;
}

.button {
  position: relative;
  bottom: unset;
}

</style>
