<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3>{{ $t('settings.permissions.modal_invite.title') }}</h3>
    <p>{{ $t('settings.permissions.modal_invite.subtitle') }}</p>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="sr-name">{{ $t('settings.permissions.modal_invite.name') }}</label>
      <input type="text" id="sr-name" v-model="this.invitation.name">
    </div>

    <div class="form-group">
      <label for="sr-email">{{ $t('settings.permissions.modal_invite.email') }}</label>
      <input type="email" id="sr-email" v-model="this.invitation.email">
    </div>

    <button :disabled="!this.invitation.name || !this.invitation.email" class="button" @click="onClickSubmit">{{ $t('settings.permissions.modal_invite.submit') }}</button>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'NewUserInvite',
  data () {
    return {
      invitation: {
        name: null,
        email: null
      }
    }
  },
  methods: {
    ...mapActions(['toggleNewUserInvite', 'createInvitation']),
    onClickSubmit () {
      this.createInvitation(this.invitation).then(() => {
        this.$toast.open({
          message: this.$t('settings.permissions.modal_invite.sent', { email: this.invitation.email })
        })

        this.invitation.name = null
        this.invitation.email = null

        setTimeout(() => {
          const el = document.getElementById('sr-name')
          if (el) {
            el.focus()
          }
        }, 200)
      }).catch(() => {
        this.$toast.open({
          message: this.$t('settings.permissions.modal_invite.error', { email: this.invitation.email }),
          type: 'error'
        })

        this.invitation.name = null
        this.invitation.email = null
      })
    },
    close () {
      this.toggleNewUserInvite()
    }
  }
}
</script>

<style scoped>
.modal {
  height: 320px
}
</style>
