import axios from 'axios'

const state = {
  promotes: []
}
const getters = {
  promotes: (state) => state.promotes
}
const actions = {
  async fetchPromotes ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/promote`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setPromotes', res.data)
  },
  async emptyPromotes ({ commit }) {
    commit('setPromotes', [])
  },
  async createPromote ({ commit, rootState }, shortResponse) {
    axios.post(`${process.env.VUE_APP_SERVERHOST}api/promote`, shortResponse, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((newResponse) => {
      commit('addPromote', newResponse.data)
    }).catch(() => {})
  },
  async deletePromote ({ commit, rootState }, shortResponse) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/promote/${shortResponse.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deletePromote', shortResponse)
    }).catch(() => {})
  }
}
const mutations = {
  setPromotes: (state, promotes) => (state.promotes = promotes),
  addPromote: (state, newResponse) => (state.promotes.push(newResponse)),
  deletePromote: (state, newResponse) => {
    const index = state.promotes.findIndex(resp => resp.id === newResponse.id)
    if (index !== -1) {
      state.promotes.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
