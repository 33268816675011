<template>
  <div>
    <ImportantAlert />
    <UnpaidInvoiceAlert />
    <Navbar />
    <div class="content">
      <Sidebar />
      <div class="calendar">
        <div class="container-list-header">
          <router-link to="/calendar">
            <i :class="{ active: this.$route.name === 'Calendar' || this.$route.name === 'MyCalendar' }" class="far fa-calendar" v-tooltip.top="{ content: $t('event.calendar')}"></i>
          </router-link>
          <router-link to="/reminders" @click="saveCalendarPreference('reminders'); onClickFetchContacts(6)">
            <i :class="{ active: this.$route.name === 'Reminders' }" class="fas fa-stream" v-tooltip.top="{ content: $t('event.passed')}"></i>
          </router-link>
          <i @click="openVideoHelp($t('help.links.calendar'))" class="help fab fa-youtube" v-tooltip.right="{ content: $t('help.title') }"></i>
          <div v-if="this.loggedUser && this.loggedUser.canSeeAllChats && this.organizationUsers" class="calendar-filters">
            <label>{{ $t('event.assigned_to') }}</label>
            <div class="container">
                <v-select class="user-filter noclear" v-if="organizationUsers" @option:selected="onChangeUserAssigned" :options="getUsers()" label="name" v-model="this.userAssigned" placeholder="Selecciona">
                  <template #option="option">
                    <span>
                      <i v-if="option.type==='user'" class="fas fa-user" aria-hidden="true"></i>
                      <i v-if="option.type==='team'" class="fas fa-users" aria-hidden="true"></i>
                      {{ option.name }}
                    </span>
                  </template>
                </v-select>
            </div>
          </div>
        </div>
        <FullCalendar ref="fullCalendar" class="full-calendar" :options='this.calendarOptions' />
      </div>
    </div>
  </div>
  <MobileSidebar />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import Cookies from 'js-cookie'

import Navbar from '../components/home/Navbar'
import Sidebar from '../components/home/Sidebar'
import NewMessage from '../components/modals/NewMessage'

import MobileSidebar from '../components/mobile/Sidebar'

import { mapActions, mapGetters } from 'vuex'

import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import ImportantAlert from '@/components/home/ImportantAlert'
import UnpaidInvoiceAlert from '@/components/home/UnpaidInvoiceAlert'

export default {
  name: 'Calendar',
  methods: {
    ...mapActions(['fetchCalendar', 'clearSelectedContact', 'clearSearch', 'setPage', 'fetchContacts', 'clearContacts']),
    onClickFetchContacts (filter) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchContacts({ filter: filter })
    },
    openVideoHelp (url) {
      this.emitter.emit('openVideoHelp', url)
    },
    getUsers () {
      return [
        {
          id: 'all',
          name: this.$t('event.all_users')
        },
        ...this.getUsersAndTeams()
      ]
    },
    onChangeUserAssigned () {
      const calendarUrl = '/calendar/' + this.userAssigned.id
      this.$router.push(calendarUrl)
      localStorage.calendarUrl = calendarUrl

      this.$refs.fullCalendar.getApi().refetchEvents()
    },
    saveCalendarPreference (view) {
      localStorage.calendarUrl = '/' + view
    },
    getUsersAndTeams () {
      const usersAndTeams = []
      if (this.organizationUsers) {
        for (const user of this.organizationUsers) {
          user.type = 'user'
          usersAndTeams.push(user)
        }
      }

      if (this.teams) {
        for (const team of this.teams) {
          team.type = 'team'
          usersAndTeams.push(team)
        }
      }

      return usersAndTeams
    }
  },
  props: {
    filterView: Number,
    page: Number
  },
  computed: mapGetters([
    'newMessageOpen',
    'organizationUsers',
    'loggedUser',
    'teams'
  ]),
  data: function () {
    let locale = Cookies.get('ma-locale')
    if (!locale) {
      if (navigator.languages !== undefined) {
        locale = navigator.languages[0]
      }

      if (!locale) {
        locale = navigator.language
      }

      if (locale) {
        locale = locale.substr(0, 2)
      }
    }

    if (!locale) {
      locale = 'es'
    }

    let userAssignedId = null
    if (this.loggedUser) {
      userAssignedId = this.loggedUser.id
    }

    if (this.$route.params.userAssigned) {
      userAssignedId = this.$route.params.userAssigned
    }

    if (!userAssignedId && this.$store.getters.loggedUser) {
      userAssignedId = this.$store.getters.loggedUser.id
    }

    const self = this
    return {
      userAssigned: { id: userAssignedId },
      userOwner: null,
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        height: 'calc(100vh - 125px)',
        width: '100%',
        firstDay: 1,
        events: [],
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false
        },
        locale: locale,
        eventSources: [
          {
            events (event, successCallback, failureCallback) {
              self.fetchCalendar({ start: event.start, end: event.end, user: self.userAssigned.id }).then(data => {
                successCallback(
                  Array.prototype.slice.call(data).map(function (event) {
                    let eventTitle = ''
                    if (event.notification) {
                      eventTitle += '🕔 '
                    }
                    eventTitle += `${event.contactName} - ${event.text}`
                    return {
                      title: eventTitle,
                      start: event.date,
                      extendedProps: {
                        contactId: event.contactId,
                        contactName: event.contactName,
                        description: event.text
                      },
                      color: event.done ? '#97DCFC' : '#fab1a0',
                      className: event.done ? 'event-done' : 'event-undone'
                    }
                  })
                )
              })
            },
            textColor: 'black'
          }
        ],
        eventDidMount (e) {
          const content = `<strong>👬 ${e.event.extendedProps.contactName}</strong><br/>📄 ${e.event.extendedProps.description}`
          tippy(e.el, {
            content: content,
            arrow: true,
            allowHTML: true,
            interactive: true
          })
        },
        eventClick (e) {
          self.emitter.emit('openContactOverlay', e.event.extendedProps.contactId)
        }
      }
    }
  },
  mounted () {
    this.clearSelectedContact()
    this.clearContacts()
    const self = this
    this.emitter.on('refreshCalendar', () => {
      self.$refs.fullCalendar.getApi().refetchEvents()
    })
    if (this.userAssigned && this.teams && this.organizationUsers) { // TODO: Fix this
      const userInitial = [
        {
          id: 'all',
          name: this.$t('event.all_users')
        },
        ...this.getUsersAndTeams()
      ]
      this.userAssigned = userInitial.find(u => u.id === this.userAssigned.id)
    }
  },
  unmounted () {
    this.emitter.off('refreshCalendar')
  },
  watch: {
    loggedUser (n, o) {
      if (!this.$route.params.userAssigned) {
        this.userAssigned = n
      }
    },
    organizationUsers (n, o) {
      if (this.$route.params.userAssigned) {
        this.userAssigned = { id: this.$route.params.userAssigned }
      }
      if (n && n.length > 0) {
        const userInitial = [
          {
            id: 'all',
            name: this.$t('event.all_users')
          },
          ...this.getUsersAndTeams()
        ]
        this.userAssigned = userInitial.find(u => u.id === this.userAssigned.id)
      }
    },
    teams (n, o) {
      if (this.$route.params.userAssigned) {
        this.userAssigned = { id: this.$route.params.userAssigned }
      }
      if (n && n.length > 0) {
        const userInitial = [
          {
            id: 'all',
            name: this.$t('event.all_users')
          },
          ...this.getUsersAndTeams()
        ]
        this.userAssigned = userInitial.find(u => u.id === this.userAssigned.id)
      }
    }
  },
  components: {
    UnpaidInvoiceAlert,
    ImportantAlert,
    Navbar,
    Sidebar,
    NewMessage,
    MobileSidebar,
    FullCalendar
  }
}
</script>

<style>
.noclear .vs__clear {
  display: none;
}
.content {
  margin-top: 67px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}

.event-done, .event-undone {
  cursor: pointer;
}

.event-done {
  color: #adadad;
  text-decoration: line-through !important;
}

.event-done .fc-event-title {
  font-weight: 400;
}

.fc .fc-toolbar.fc-header-toolbar {
  font-size: 8px;
}
</style>

<style scoped>

.calendar {
  margin-left: 71px;
  margin-top: 65px;
}

.full-calendar {
  width: 100%;
  padding: 20px 15px 0 15px;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.5em;
  font-size: 6px;
}

.container-list-header {
  padding: 10px 15px;
  text-align: left;
  min-height: 20px;
  border-bottom: 1px solid #EDEFF1;

}

.container-list-header img {
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  top: 13px;
}

.container-list-header i {
  margin-right: 5px;
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  color: #111b21;
}

.container-list-header i.active {
  background-color: #4285f45e;
  color: #4285f4;
}

.calendar-filters{
  position: absolute;
  right: 10px;
  top: 72px;
  display: inline-flex;
}

.importantAlert .calendar-filters, .unpaidInvoiceAlert .calendar-filters, .mustMigrate .calendar-filters {
  top: 132px;
}

.calendar-filters label {
  line-height: 30px;
}

.user-filter {
  width: 200px;
  height: 30px;
  margin-left: 10px;
}

.help {
  font-size: 25px;
  cursor: pointer !important;
  text-align: center;
  line-height: 15px;
  margin-left: 5px;
  position: absolute;
  right: 365px;
  top: 76px;
}

.importantAlert .help, .unpaidInvoiceAlert .help, .mustMigrate .help {
  top: 135px;
}

</style>
