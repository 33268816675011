<template>
  <div v-if="modalOpen" class="overlay"></div>
  <div v-if="modalOpen" class="modal">
    <div style="overflow: scroll; height: 100%">
      <h3 v-if="options.title">{{ $t('paywall.title')}}</h3>
      <div v-if="options.close" @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      <p v-html="$t('paywall.subtitle')"></p>
      <div class="prices">
        <div class="price free">
          <ul>
            <li class="title">{{ $t('paywall.free.title') }}</li>
            <li class="amount"><span>{{ $t('paywall.free.price') }}</span></li>
            <li v-html="$t('paywall.premium.f1')"></li>
            <li v-html="$t('paywall.premium.f2')"></li>
            <li v-html="$t('paywall.premium.f3')"></li>
            <li v-html="$t('paywall.premium.f4')"></li>
            <li v-html="$t('paywall.premium.f5')"></li>
            <li v-html="$t('paywall.premium.f6')"></li>
            <li v-html="$t('paywall.premium.f7')"></li>
            <li v-html="$t('paywall.premium.f8')"></li>
            <li v-html="$t('paywall.premium.f9')"></li>
          </ul>
        </div>
        <div class="arrow">
          <i class="fa-solid fa-arrow-right"></i>
        </div>
        <div class="price premium">
          <ul>
            <li class="title">{{ $t('paywall.premium.title') }}</li>
            <li class="amount"><span>{{ $t('paywall.premium.price') }}</span></li>
            <li v-html="$t('paywall.premium.f1')"></li>
            <li v-html="$t('paywall.premium.f2')"></li>
            <li v-html="$t('paywall.premium.f3')"></li>
            <li v-html="$t('paywall.premium.f4')"></li>
            <li v-html="$t('paywall.premium.f5')"></li>
            <li v-html="$t('paywall.premium.f6')"></li>
            <li v-html="$t('paywall.premium.f7')"></li>
            <li v-html="$t('paywall.premium.f8')"></li>
            <li v-html="$t('paywall.premium.f9')"></li>
          </ul>
        </div>
      </div>
      <div>
        <a :href="this.link"><button class="button">{{ $t('paywall.cta') }}</button></a>
        <a href="https://wa2.link/dx59JcWmOoIo" target="_blank"><button class="button support">{{ $t('paywall.support') }}</button></a>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'QRPaywall',
  data () {
    return {
      modalOpen: false,
      link: '',
      options: {
        title: true,
        close: false
      }
    }
  },
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['fetchCheckoutSessionLink']),
    close () {
      this.modalOpen = false
    },
    open (options) {
      if (options) {
        if (options.title !== undefined) {
          this.options.title = options.title
        }
        if (options.title !== undefined) {
          this.options.close = options.close
        }
      }

      this.fetchCheckoutSessionLink({ credits: null }).then((link) => {
        this.link = link
        this.modalOpen = true
      })
    }
  },
  created () {
    this.emitter.on('openQRPaywall', this.open)
    this.emitter.on('closeQRPaywall', this.close)
  },
  unmounted () {
    this.emitter.off('openQRPaywall')
    this.emitter.off('closeQRPaywall')
  }
}
</script>

<style scoped>
.overlay {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 1000001;
  background-color: #ffffff;
  position: fixed;
  top: 20px;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  max-height: 655px;
  height: calc(100% - 40px);
  padding: 20px;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.modal h3 {
  margin-top: 0;
}

.prices {
  margin-top: 30px;
  display: inline-flex;
}

.prices .price {
  padding: 20px;
  border: 1px solid;
  border-radius: 10px;
  text-align: center;
  width: 280px;
}

.prices .price .title {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.prices .arrow {
  width: 80px;
  font-size: 35px;
  margin-top: 210px;
  color: #404040;
}

.prices ul {
  margin: 0;
  padding: 0;
}

.prices ul li {
  margin: 10px 0;
}

.price.free {
  border-color: #EDEFF1;
  color: #757575;
}

.price.premium {
  background: -webkit-gradient(linear, left top, left bottom, from(#3056d3), to(#179bee));
  background: linear-gradient(180deg, #3056d3 0%, #179bee 100%);
  border: 2px solid #d4deff;
  color: #ffffff;
}

.price li.amount {
  margin-bottom: 25px;
}

.price.free .amount span {
  font-size: 20px;
  background-color: #5e5e5e;
  color: white;
  font-weight: 700;
  border-radius: 30px;
  padding: 5px 15px;
}

.price.premium .amount span {
  font-size: 20px;
  background-color: #13c296;
  font-weight: 700;
  border-radius: 30px;
  padding: 5px 15px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  position: relative;
  margin-top: 12px;
  width: 100%;
  top: 0;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.button.support {
  background-color: #25D366;
}

.button.support:hover {
  background-color: #27ae60;
}

.button.support:disabled {
  background-color:#009688;
  cursor: not-allowed;
}
</style>
