<template>
  <div v-if="modalOpen" class="overlay image-overlay" tabindex="0" @keydown.left="getRelated('prev')" @keydown.right="getRelated('next')" @keydown.esc="close">
    <div class="modal">
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      <a :href="getDownloadUrl()">
        <div class="modal-download" v-tooltip.bottom="{ content: $t('messages.body.download') }"><i class="fas fa-download"></i></div>
      </a>
      <inner-image-zoom class="image-zoom" :src="getMediaUrl()" />
      <div class="arrows">
        <div :hidden="'prev' === this.disabled" @click="getRelated('prev')" class="arrow arrow-left"><i class="fas fa-arrow-left"></i></div>
        <div :hidden="'next' === this.disabled" @click="getRelated('next')" class="arrow arrow-right"><i class="fas fa-arrow-right"></i></div>
      </div>
    </div>
  </div>
</template>

<script>
import InnerImageZoom from 'vue-inner-image-zoom'
import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css'
import { mapGetters } from 'vuex'

export default {
  name: 'ImageOverlay',
  components: {
    'inner-image-zoom': InnerImageZoom
  },
  data () {
    return {
      contact: null,
      message: null,
      modalOpen: false,
      disabled: null
    }
  },
  computed: mapGetters(['contactMessages']),
  methods: {
    close () {
      this.modalOpen = false
      this.disabled = null
    },
    open ({ message, contact }) {
      this.message = message
      this.contact = contact
      this.modalOpen = true

      setInterval(() => {
        const el = document.getElementsByClassName('image-overlay')
        if (el.length > 0) {
          el[0].focus()
        }
      }, 200)
    },
    getRelated (direction) {
      if (this.disabled === direction) {
        return
      }

      const mediaMessages = this.contactMessages.filter(m => m.mediafile_id && m.mediatype === 'image')
      const index = mediaMessages.findIndex(m => m.id === this.message.id)
      if (index === -1) {
        this.disabled = direction
        return
      }

      const newIndex = direction === 'next' ? index + 1 : index - 1
      if (newIndex <= 0 || newIndex >= mediaMessages.length - 1) {
        this.disabled = direction
      } else {
        this.disabled = null
      }

      try {
        if (newIndex < 0) {
          return
        }
        if (newIndex >= mediaMessages.length) {
          return
        }
        this.message = mediaMessages[newIndex]
      } catch (e) {
        this.disabled = direction
      }
    },
    getMediaUrl () {
      return `${process.env.VUE_APP_SERVERHOST}api/message/${this.contact.id}/media/${this.message.mediafile_id}`
    },
    getDownloadUrl () {
      return this.getMediaUrl() + '/download'
    }
  },
  created () {
    this.emitter.on('openImageOverlay', this.open)
  }
}
</script>

<style scoped>
 .modal {
   width: 80%;
   height: unset;
   margin: 0 auto;
   position: relative;
   top: 30px;
   bottom: 30px;
   left: 0;
   text-align: center;
 }

 .modal a {
   color: #111b21;
 }

 .overlay {
   opacity: 1;
   background-color: rgba(32,33,36,0.3);
   z-index: 5000010;
 }

 .modal .modal-download {
   position: absolute;
   top: 20px;
   right: 50px;
   line-height: 20px;
   width: 20px;
   height: 20px;
   text-align: center;
   cursor: pointer;
 }

 .arrows {
   width: 100%;
 }

 .arrows .arrow.arrow-left {
   position: absolute;
   bottom: 20px;
   left: 20px;
 }

 .arrows .arrow.arrow-right {
   position: absolute;
   bottom: 20px;
   right: 20px;
 }

 .arrows .arrow {
   cursor: pointer;
   background-color: #ffffff;
   padding: 5px;
 }

 .arrows .arrow i {
   font-size: 30px;
 }
</style>

<style>
.image-overlay .iiz__img{
  max-width: 100%;
  max-height: calc(100vh - 200px);
}

.image-overlay .image-zoom{
  margin-top: 50px;
}
</style>
