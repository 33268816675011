<template>
  <div class="funnel-stage">
    <div class="stage-header">
      <div v-if="this.index !== 0" class="arrow left"></div>
      <div v-if="this.index !== 0" class="arrow left inside"></div>
      <div class="stage-name">
        {{ stage.name }}
        <span v-if="stageContacts.length < 50"> ({{ stageContacts.length }}) </span>
        <span v-if="stageContacts.length >= 50"> (+50) </span>
      </div>
      <div v-if="selectedFunnel.stages.length !== this.index + 1" class="arrow right"></div>
      <div v-if="selectedFunnel.stages.length !== this.index + 1" class="arrow right inside"></div>
    </div>
    <div class="stage-contacts" :id="`stage-column-${stage.id}`" style="height: calc(100vh - 220px); overflow: scroll; scrollbar-width: thin;" v-on:scroll="scrollFunction">
      <div class="stage-background">
        <div v-for="index in getNumberOfFakes()" :key="index" class="stage-fake"></div>
      </div>
      <ul>
        <draggable :sort="false" style="height: calc(100vh - 220px);" group="contacts" @change="onChange" v-model="this.stageContacts" item-key="id">
          <template #item="{element}">
            <StageContact :key="element.id" :contact="element" />
          </template>
        </draggable>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import StageContact from '@/components/home/pipelines/StageContact'

export default {
  name: 'Stage',
  props: {
    stage: Object,
    index: Number
  },
  data () {
    return {
      stageContacts: [],
      busy: false,
      page: 0,
      hasMore: true
    }
  },
  computed: mapGetters(['selectedFunnel', 'selectedContact', 'funnelFilter']),
  methods: {
    ...mapActions(['fetchFunnelStageContacts', 'updateFunnelStageContact', 'fetchFunnelStageContactsNextPage']),
    onChange (event) {
      if (event && event.added && event.added.element) {
        this.updateFunnelStageContact({
          contactId: event.added.element.id,
          funnelId: this.selectedFunnel.id,
          stageId: this.stage.id
        })
      }
    },
    updateStage (stage) {
      if (!stage || stage === this.stage.id) {
        this.fetchFunnelStageContacts({
          funnel: this.selectedFunnel,
          stage: this.stage,
          funnelFilter: this.funnelFilter
        }).then((stageContacts) => {
          this.stageContacts = stageContacts
        })
        this.page = 0
      }
    },
    getNumberOfFakes () {
      const total = this.selectedFunnel.stages.length
      return total - this.index
    },
    scrollFunction (event) {
      if (!this.hasMore) {
        return
      }

      const funnelContacts = document.getElementById(`stage-column-${this.stage.id}`)
      if (!funnelContacts) {
        return
      }

      if (parseInt(event.currentTarget.scrollHeight) <= parseInt(event.currentTarget.scrollTop + funnelContacts.clientHeight + 300)) {
        if (this.busy) {
          return
        }

        this.busy = true
        this.page += 1

        this.fetchFunnelStageContactsNextPage({
          funnel: this.selectedFunnel,
          stage: this.stage,
          funnelFilter: this.funnelFilter,
          page: this.page
        }).then((stageContacts) => {
          this.stageContacts = this.stageContacts.concat(stageContacts)
          if (stageContacts.length === 0) {
            this.hasMore = false
          }
          this.busy = false
        })
      }
    },
    deleteArchiveContact (contact) {
      const index = this.stageContacts.findIndex(el => el.id === contact.id)
      if (index !== -1) {
        this.stageContacts.splice(index, 1)
      }
    },
    updateSocketContactAssignedChange (data) {
      if (data.contactId && data.message && data.message.type === 'assigned') {
        const contact = this.stageContacts.find(el => el.id === data.contactId)
        if (!contact) {
          return
        }

        if (contact.last_user_id !== data.message.userId) {
          contact.last_user_id = data.message.userId
        }

        if (this.funnelFilter.filter.includes('all')) {
          return
        }

        if (!this.funnelFilter.filter.includes('all') && !this.funnelFilter.filter.includes(contact.last_user_id)) {
          const index = this.stageContacts.findIndex(el => el.id === data.contactId)
          if (index !== -1) {
            this.stageContacts.splice(index, 1)
          }
        }
      }
    },
    updateSocketContactClosed (data) {
      if (data.contactId && data.message && data.message.type === 'closed') {
        const index = this.stageContacts.findIndex(contact => contact.id === data.contactId)
        if (index !== -1) {
          this.stageContacts.splice(index, 1)
        }
      }
    },
    updateSocketContactStageChange (data) {
      if (data.message && ['funnel-stage', 'assigned'].includes(data.message.type) && data.stageId) {
        if (data.stageId === this.stage.id) {
          this.updateStage()
          return
        }

        const index = this.stageContacts.findIndex(contact => contact.id === data.contactId)
        if (index !== -1) {
          this.stageContacts.splice(index, 1)
        }
      }
    }
  },
  mounted () {
    this.updateStage()
  },
  created () {
    this.emitter.on('updateFunnelStage', this.updateStage)
    this.emitter.on('deleteFunnelArchivedContact', this.deleteArchiveContact)
  },
  unmounted () {
    this.emitter.off('updateFunnelStage')
    this.emitter.off('deleteFunnelArchivedContact')
  },
  components: {
    StageContact
  },
  sockets: {
    newMessage (data) {
      this.updateSocketContactAssignedChange(data)
      this.updateSocketContactClosed(data)
      this.updateSocketContactStageChange(data)
    }
  }
}
</script>

<style scoped>
.funnel-stage {
  width: 240px;
  min-width: 240px;
  background-color: white;
  min-height: calc(100vh - 186px);
  margin-right: 6px;
  padding: 10px 0;
  border: 1px solid #EDEFF1;
  position: relative;
}

.funnel-stage ul {
  padding: 0;
  margin: 0;
}

.funnel-stage .stage-header {
  height: 40px;
  padding: 0 12px;
  border-bottom: 1px solid #4285f4;
  position: relative;
}

.funnel-stage .stage-header .stage-name {
  font-size: 14px;
  font-weight: 700;
}

.stage-background {
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
}

.stage-background .stage-fake {
  border: 1px dashed #EDEFF1;
  height: 60px;
  margin: 7px 7px;
  border-radius: 3px;
}

.arrow.right {
  text-decoration: none;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  height: 0;
  right: -6px;
  top: -10px;
  border-bottom: 25px solid #EDEFF1;
  border-top: 25px solid #EDEFF1;
  border-left: 6px solid white;
}

.arrow.right.inside {
  text-decoration: none;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  height: 0;
  right: -7px;
  top: -10px;
  border-bottom: 25px solid #f8f9fa;
  border-top: 25px solid #f8f9fa;
  border-left: 6px solid transparent;
}

.arrow.left {
  text-decoration: none;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  height: 0;
  top: -10px;
  border-bottom: 25px solid white;
  border-top: 25px solid white;
  border-left: 6px solid #EDEFF1;
  left: 0px;
}

.arrow.left.inside {
  text-decoration: none;
  line-height: 0;
  font-size: 50px;
  position: absolute;
  height: 0;
  top: -10px;
  border-bottom: 25px solid transparent;
  border-top: 25px solid transparent;
  border-left: 6px solid #f8f9fa;
  left: -1px;
}

</style>
