<template>
  <div class="settings-group">
    <h3>{{ $t('settings.tags.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.tags.description') }}</p>
      <button @click="newTag" class="button" type="submit">{{ $t('settings.tags.button_create') }}</button>
    </div>
    <table v-if="tags && tags.length > 0">
      <thead>
      <th style="width: 72%">{{ $t('settings.tags.name') }}</th>
      <th style="width: 20%">{{ $t('settings.tags.created_at') }}</th>
      <th style="width: 8%"></th>
      </thead>
      <tbody>
      <tr :key="tag.id" v-for="tag in tags">
        <td><div class="tag-name" :style="{ backgroundColor: tag.color }">{{ tag.name }}</div></td>
        <td>{{ $moment(tag.createdAt).format('L') }}</td>
        <td>
          <i @click="editTag(tag)" class="far fa-edit"></i>
          <i @click="delTag(tag)" class="far fa-trash-alt"></i>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionTags',
  computed: mapGetters(['tags']),
  methods: {
    ...mapActions([
      'fetchTags',
      'emptyTags',
      'toggleNewTagOpen',
      'setNewTagData',
      'emptyNewTagData',
      'deleteTag'
    ]),
    newTag () {
      this.emptyNewTagData()
      this.toggleNewTagOpen()
    },
    editTag (tag) {
      this.setNewTagData(tag)
      this.toggleNewTagOpen()
    },
    delTag (tag) {
      if (confirm(this.$t('settings.tags.delete.confirm', { name: tag.name }))) {
        this.deleteTag(tag)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    }
  },
  created () {
    this.fetchTags('')
  },
  beforeUnmount () {
    this.emptyTags()
  }
}
</script>

<style scoped>
p {
  width: calc(100% - 200px);
  max-width: 600px;
  float: left;
  margin: 0;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

tr .tag-name {
  width: fit-content;
  padding: 4px;
  border-radius: 4px;
  font-weight: 500;
}
</style>
