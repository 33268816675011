<template>
  <div class="contact">
    <div class="contact-name">{{ selectedEmail.subject }}</div>
    <div v-if="selectedEmail.content.attachments.length > 0" class="email-attachments"><i class="fas fa-paperclip"></i></div>
    <div class="email-recipient">
      <div v-if="selectedEmail.contact_id" class="whatsapp-logo">
        <img src="../../../../assets/whatsapp-logo.svg" @click="onClickOpenContact(selectedEmail.contact_id)" v-tooltip.right="{ content: $t('emails.go_to_whatsapp') }"/>
      </div>
      {{ selectedEmail.recipient.name }}
    </div>
    <div class="email-date">{{ $moment(selectedEmail.timestamp).format('DD MMMM YYYY HH:mm') }}</div>
    <div v-if="selectedEmail.managed_user_name === null" class="contact-open" @click="onClickMarkAsManaged" v-tooltip.top="{ content: $t('emails.mark_as_managed') }"><i class="fas fa-check"></i></div>
    <div v-if="selectedEmail.managed_user_name !== null" class="contact-close" @click="onClickMarkAsUnmanaged" v-tooltip.top="{ content: $t('emails.mark_as_unmanaged') }"><i class="fas fa-check"></i></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Header_Email',
  computed: mapGetters(['selectedEmail', 'organizationUsers']),
  data () {
    return {
      timer: null
    }
  },
  methods: {
    ...mapActions(['markInboundEmailAsManaged', 'markInboundEmailAsUnmanaged', 'fetchInboundEmails']),
    onClickMarkAsManaged () {
      this.markInboundEmailAsManaged(this.selectedEmail).then(() => {
        this.fetchInboundEmails()
      })
    },
    onClickMarkAsUnmanaged () {
      this.markInboundEmailAsUnmanaged(this.selectedEmail).then(() => {
        this.fetchInboundEmails()
      })
    },
    onClickOpenContact (contactId) {
      this.emitter.emit('openContactOverlay', contactId)
    }
  }
}
</script>

<style>
.contact .contact-assingto ul.dropdown-menu > li:first-of-type {
  border-bottom: 1px solid #EDEFF1 !important;
}
</style>

<style scoped>
  .contact {
    position: absolute;
    top: 0;
    height: 50px;
    width: 100%;
    background-color: rgba(242, 245, 245, 1);
    border-bottom: 1px solid #E3E8EA;
    z-index: 1;
  }

  .contact .contact-avatar {
    position: absolute;
    top: 5px;
    left: 10px;
    width: 40px;
    height: 40px;
    background-color: #DFE5E7;
    /*-webkit-mask: url(../../../assets/contact.svg) no-repeat center / contain;*/
    /*mask: url(../../../assets/contact.svg) no-repeat center / contain;*/
  }

  .contact .contact-name {
    position: absolute;
    top: 5px;
    left: 15px;
    max-width: 270px;
    width: 350px;
    font-weight: bold;
  }

  .email-recipient {
    position: absolute;
    top: 25px;
    left: 15px;
    max-width: 350px;
    width: 350px;
  }

  .email-attachments {
    position: absolute;
    top: 5px;
    right: 60px;
  }

  .email-date {
    position: absolute;
    top: 5px;
    right: 60px;
  }

  .whatsapp-logo {
    float: left;
    margin-top: 1px;
    margin-right: 5px;
    cursor: pointer;
  }

  .whatsapp-logo img  {
    width: 15px;
  }

  .contact .contact-assingto {
    position: absolute;
    top: 26px;
    left: 60px;
    font-size: 14px;
  }

  .contact .contact-close,
  .contact .contact-open {
    position: absolute;
    right: 10px;
    top: 9px;
    padding: 5px 10px;
    border-radius: 4px;
    color: #667781;
    cursor: pointer;
  }

  .contact .contact-close {
    background-color: #ff7675;
    color: #ffffff;
    border-color: #ff7675;
  }

  .contact .contact-unassign {
    position: absolute;
    right: 50px;
    top: 9px;
    padding: 5px 10px;
    border-radius: 4px;
    color: #667781;
    cursor: pointer;
  }

  .contact .contact-close:hover,
  .contact .contact-unassign:hover {
    background-color: #ff7675;
    color: #ffffff;
    border-color: #ff7675;
  }

  .contact .contact-open:hover {
    background-color: #55efc4;
    color: #ffffff;
    border-color: #55efc4;
  }
</style>
