import axios from 'axios'

const state = {
  teams: []
}
const getters = {
  teams: (state) => state.teams
}
const actions = {
  async fetchTeams ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/teams`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setTeams', res.data)
  },
  async emptyTeams ({ commit }) {
    commit('setTeams', [])
  },
  async createTeam ({ commit, rootState }, team) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/team`, team, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((response) => {
      commit('addTeam', response.data)
    }).catch(() => {})
  },
  async updateTeam ({ commit, rootState }, team) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/team/${team.id}`, team, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async deleteTeam ({ commit, rootState }, team) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/team/${team.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteTeam', team)
      commit('deleteLoggedUserTeam', team)
    }).catch(() => {})
  },
  async addTeamUser ({ commit, rootState }, { team, user }) {
    await axios.post(`${process.env.VUE_APP_SERVERHOST}api/team/${team.id}/user/${user.id}`, {}, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('addTeamUser', { team, user })
  },
  async deleteTeamUser ({ commit, rootState }, { team, user }) {
    await axios.delete(`${process.env.VUE_APP_SERVERHOST}api/team/${team.id}/user/${user.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('removeTeamUser', { team, user })
  }
}
const mutations = {
  setTeams: (state, teams) => (state.teams = teams),
  addTeam: (state, newTeam) => (state.teams.push(newTeam)),
  deleteTeam: (state, newTeam) => {
    const index = state.teams.findIndex(resp => resp.id === newTeam.id)
    if (index !== -1) {
      state.teams.splice(index, 1)
    }
  },
  addTeamUser: (state, { team, user }) => {
    const index = state.teams.findIndex(resp => resp.id === team.id)
    if (index !== -1) {
      state.teams[index].members.push(user)
    }
  },
  removeTeamUser: (state, { team, user }) => {
    const indexTeam = state.teams.findIndex(resp => resp.id === team.id)
    if (indexTeam !== -1) {
      const indexUser = state.teams[indexTeam].members.findIndex(resp => resp.id === user.id)
      if (indexUser !== -1) {
        state.teams[indexTeam].members.splice(indexUser, 1)
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
