<template>
    <div class="module">
      <img class="whatsapp-logo" src="../../../assets/WhatsApp_icon.svg">
      <h3 style="margin-top: 0; margin-left: 27px">{{ $t('onboarding.title') }}</h3>
      <p style="margin: 0">{{ $t('onboarding.scan_qr') }}</p>
      <button @click="this.getQR()" class="button button-scan">{{ $t('onboarding.scan_qr_button') }}</button>
      <hr style="margin: 15px">
      <router-link to="/settings/whatsapp">
        <button class="button button-light">{{ $t('onboarding.other_options_button') }}</button>
      </router-link>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'WhatsAppBusinessModule',
  components: {
  },
  computed: mapGetters([]),
  methods: {
    ...mapActions(['getNewQR']),
    onClickModule (module) {
      // this.emitter.emit('openModulePopup', module)
    },
    getQR () {
      return this.getNewQR()
    }
  },
  props: {
    module: Object
  }
}
</script>

<style scoped>
.module {
  position: relative;
  height: 235px;
  width: 360px;
  padding: 20px;
  background: #c9dfff;
  box-shadow: 0px 1px 2px rgba(17, 24, 39, 0.06), 0px 1px 3px rgba(17, 24, 39, 0.1);
  margin-bottom: 20px;
  margin-right: 15px;
  border-radius: 4px;
  cursor: pointer;
}

.icon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #EDEFF1;
}

.name {
  margin-top: 20px;
  font-weight: 500;
  font-size: 16px;
}

.short_description {
  margin-top: 10px;
  font-size: 14px;
}

.price {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 16px;
  color: #4285f4;
}

.active {
  position: absolute;
  top: 20px;
  left: 70px;
  background-color: rgb(82, 204, 167,.15);
  font-weight: 400;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 4px;
  margin-left: 10px;
  color: #1a202c;
}

.active i {
  color: #7bed9f;
}

.tags {
  position: absolute;
  top: 50px;
  right: 20px;
  text-align: right;
}

.tags .tag {
  border-radius: 4px;
  height: 18px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 2px 10px;
  background-color: #EDEFF1;
  margin-left: 5px;
  float: left;
  font-size: 13px;
  line-height: 16px;
}

.connect-qr-mobile {
  display: none;
}

.mobile .connect-qr-mobile {
  display: block;
}

.mobile .connect-qr {
  display: none;
}

.overlay {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 1000000;
  background-color: #c9dfff;
  position: fixed;
  top: unset;
  left: unset;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: unset;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.modal.centered {
  left: 50%;
  margin-left: -150px;
  top: 20%;
  bottom: unset;
}

.modal h3 {
  margin-top: 0;
}

.form-group {
  position: relative;
}

input {
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: .2px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  width: 260px;
  position: absolute;
  right: 6px;
  top: 7px;
}

.button.button-white {
  background-color: #ffffff;
  color: #4285f4;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button.button-white:hover {
  background-color: rgba(66, 133, 244, 0.20);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.embedded-signup {
  text-decoration: none;
  font-size: 14px;
}

.embedded-signup.button {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  padding: 0 20px;
}

.photo {
  height: 85px;
  border-radius: 100%;
  float: left;
  margin-right: 20px;
  margin-top: 0px;
}

.help {
  width: 100%;
  bottom: 20px;
  margin-top: 100px;
}

.wa-qr .video {
  position: absolute;
  background: #95c0ff82;
  width: 100%;
  margin-left: -20px;
  top: 256px;
  padding: 20px;
  text-align: center;
}

.wa-qr .video video {
  margin: 0 auto;
  width: 430px;
}

.wa-api {
  bottom: 0px;
  position: absolute;
  width: calc(100% - 40px);
}

.wa-api p {
  width: 70%;
  float: left;
}

.mobile .wa-api p {
  width: 65%;
}

.back {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}

.mobile .modal {
  top: 0px;
  height: calc(100% - 40px);
}

.mobile .button-connect-api {
  top: 35px
}

.mobile .button-apikey-validate {
  width: fit-content;
}

.language-selector {
  width: fit-content;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.button {
  position: relative;
  bottom: unset;
  width: 100%;
  right: unset;
  top: unset;
}

.button-scan {
  margin-top: 12px;
}

.button-light, .button-light:hover {
  color: #4285f4;
  background-color: transparent;
  border: 0;
  padding: 0;
  height: 20px;
  line-height: 20px;
  margin-bottom: 40px;
}

.whatsapp-logo {
  position: absolute;
  width: 20px;
  left: 20px;
  top: 20px;
}

</style>
