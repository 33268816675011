<template>
  <div v-if="organization" class="logo-wrapper" @click="onClickLogo()">
    <div v-if="this.loggedUser && this.loggedUser.canSeeSettings" class="logo-tooltip" v-tooltip.top="{ content: $t('clickToEdit') }"></div>
    <div class="personal-logo">
      <div>
        <div v-if="!organization.logo">
          <img v-if="!organization.partner" src="../../assets/manycontacts-logo.svg" class="logo-default"/>
          <img v-if="organization.partner" :src="`https://s3.amazonaws.com/wa-avatars.manycontacts.com/partners/${organization.partner}/logo.png`" class="logo" onerror="this.style.display = 'none'"/>
        </div>
        <div v-if="organization.logo">
          <img :src="organization.logo" class="logo"/>
        </div>
      </div>
      <div>
        <div v-if="!organization.logo && organization.partner">
          <div class="organization-name">
            {{ getTitleCase(organization.partner) }}
          </div>
          <div class="by-manycontacs">
            Powered by <img src="../../assets/manycontacts-logo.svg" class="logo-default-mini"/>
          </div>
        </div>
        <div v-if="organization.logo">
          <div v-if="organization.name" class="organization-name">
            {{ organization.name }}
          </div>
          <div v-if="!organization.name && organization.number" class="organization-name">
            {{ organization.number }}
          </div>
          <div class="by-manycontacs">
            Powered by <img src="../../assets/manycontacts-logo.svg" class="logo-default-mini"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LogoOrganization',
  computed: {
    ...mapGetters(['loggedUser', 'organization'])
  },
  methods: {
    onClickLogo () {
      if (this.loggedUser && this.loggedUser.canSeeSettings) {
        this.$router.push('/settings/profile')
      }
    },
    getTitleCase (str) {
      const splitStr = str.toLowerCase().replaceAll('-', ' ').replaceAll('_', ' ').split(' ')
      for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
      }
      return splitStr.join(' ')
    }
  }
}
</script>

<style scoped>
.logo-wrapper {
  position: relative;
  min-width: 185px;
  max-width: 300px;
}

.logo-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.logo {
  padding: 0;
  margin: 0;
  border-radius: 35px;
  width: 35px;
  height: 35px;
}

.personal-logo {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
  flex-wrap: nowrap;
  flex-direction: row;
}

.organization-name {
  text-decoration: none;
  padding: 0 0 0 15px;
  color: #111b21;
  font-weight: 700;
  font-size: 19px;
  max-width: 252px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.by-manycontacs {
  padding: 0 0 0 15px;
  color: #5299DC;
  font-size: 8px;
  line-height: 15px;
  position: relative;
}

.logo-default-mini {
  height: 15px;
  position: absolute;
  top: 0;
  margin-left: 2px;
}

.logo-default {
  height: 35px;
}
</style>
