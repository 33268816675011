<template>
  <div v-if="modalOpen" class="overlay"></div>
  <div v-if="modalOpen" class="modal">
    <h3 >{{ $t('messages.crm.export.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
        <button @click="onClickDownload" style="bottom: unset; position: relative; width: 100%" class="button">
          <i class="fas fa-file-download"></i>&nbsp;&nbsp;{{ $t('messages.crm.export.download') }}
        </button>
    </div>

    <hr>
    <h3 >{{ $t('messages.crm.export.email.title') }}</h3>
    <div class="form-group">
      <label for="export-subject">{{ $t('messages.crm.export.email.subject') }}</label>
      <input @input="onInput" type="text" id="export-subject" v-model="this.email.subject">
    </div>

    <div class="form-group">
      <label for="export-to">{{ $t('messages.crm.export.email.to') }}</label>
      <input @input="onInput" type="text" id="export-to" v-model="this.email.to">
    </div>

    <div class="form-group">
      <input style="width: fit-content; float: left" type="checkbox" v-model="this.email.save"> {{ $t('messages.crm.export.email.save') }}
    </div>

    <button :disabled="!this.email.to || !this.email.subject || !this.email.to.trim() || !this.email.subject.trim() || !this.validateEmail(this.email.to)" class="button" @click="onClickSendEmail">{{ $t('messages.crm.export.email.send') }}</button>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ExportConversation',
  computed: mapGetters(['organization']),
  data () {
    return {
      modalOpen: false,
      contactId: null,
      email: {
        to: '',
        subject: '',
        save: false
      }
    }
  },
  methods: {
    ...mapActions(['exportContactConversationDownload', 'exportContactConversationEmail']),
    onClickDownload () {
      this.exportContactConversationDownload({
        contactId: this.contactId
      })
      this.close()
    },
    onClickSendEmail () {
      this.exportContactConversationEmail({
        contactId: this.contactId,
        data: this.email
      }).then(() => {
        this.$toast.open({
          message: this.$t('messages.crm.export.email.ok')
        })
      })
      this.close()
    },
    open (contactId) {
      this.modalOpen = true
      this.contactId = contactId
      this.email = {
        to: this.organization.exportTo,
        subject: this.organization.exportSubject,
        save: false
      }
    },
    close () {
      this.modalOpen = false
      this.contactId = null
    },
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    }
  },
  created () {
    this.emitter.on('openExportConversation', this.open)
    this.emitter.on('closeExportConversation', this.close)
  },
  unmounted () {
    this.emitter.off('openExportConversation')
    this.emitter.off('closeExportConversation')
  }
}
</script>

<style scoped>
.modal {
  height: 400px;
}
</style>
