<template>
  <div tabindex="0" @keydown.esc="close" v-if="this.modalOpen" class="overlay calendar-contact">
    <div class="modal slide">
      <div @click="close" class="modal-close"><i class="fas fa-times"></i>{{ $t('event.close').toUpperCase() }}</div>
      <Panel />
      <Crm />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Panel from '@/components/home/chat/Panel'
import Crm from '@/components/home/Crm'

export default {
  name: 'ContactOverlay',
  components: {
    Crm,
    Panel
  },
  computed: mapGetters(['selectedContact']),
  data () {
    return {
      modalOpen: false
    }
  },
  methods: {
    ...mapActions(['fetchContact', 'fetchConversation', 'clearSelectedContact', 'clearSearch']),
    close () {
      this.clearSelectedContact()
      this.modalOpen = false
      this.emitter.emit('refreshCalendar')
    },
    open (contactId) {
      this.modalOpen = true
      this.fetchContact(contactId).then(contact => { this.fetchConversation({ contact: contact, messageId: null }) })

      setTimeout(() => {
        const el = document.getElementById('message-input')
        if (el) {
          el.focus()
        }
      }, 500)

      this.clearSearch()
      this.$emit('clearSearch')
    }
  },
  created () {
    this.emitter.on('openContactOverlay', this.open)
    this.emitter.on('closeContactOverlay', this.close)
  },
  unmounted () {
    this.emitter.off('openContactOverlay')
    this.emitter.off('closeContactOverlay')
  }
}
</script>

<style scoped>
.calendar-contact .slide {
  top: 0;
  bottom: 0;
  height: unset;
  right: 0;
  width: 80%;
  margin-left: unset;
  left: unset;
}

.calendar-contact .modal {
  padding: 0;
}

.calendar-contact .modal-close {
  top: 10px;
  left: -100px;
  width: fit-content;
  right: unset;
  background-color: white;
  padding: 5px 10px;
  border-radius: 20px;
  cursor: pointer;
  display: inline;
}

.modal-close i {
  margin-right: 5px;
}

.calendar-contact.overlay {
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}
</style>

<style>
.calendar-contact .content-panel {
  margin-left: 0;
  height: 100% !important;
  width: calc(100% - 250px);
}

.calendar-contact .crm {
  top: 0px !important;
  height: 100%;
}

.calendar-contact .crm .contact-locked{
  bottom: 0px;
}
</style>
