<template>
  <div v-if="this.modalOpen" class="templates-container">
    <div class="templates">
      <div @click="close" class="close"><i class="fas fa-times"></i></div>
      <div class="label">{{ $t('messages.responses.select') }}:</div>
      <button @click="onClickNewQuickAction" class="button button-small">{{ $t('settings.short_responses.button_create') }}</button>
      <div class="templates-list">
        <div @click="onClick(quickAction)" class="template" :key="quickAction.id" v-for="quickAction in quickActions">
          <div v-if="quickAction.type === 'template'" class="tag template"><i class="far fa-comment-dots"></i></div>
          <div v-if="quickAction.type === 'short_response'" class="tag short_response"> <i class="fas fa-bolt"></i></div>
          <div class="text"><b>{{ quickAction.name }}</b> - {{ quickAction.text }}</div>
        </div>
      </div>
    </div>
  </div>

  <NewShortResponse v-if="this.newShortResponseOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewShortResponse from '@/components/modals/NewShortResponse'

export default {
  name: 'QuickActions',
  components: { NewShortResponse },
  props: {
    inputText: String,
    filter: String
  },
  data () {
    return {
      modalOpen: false,
      quickActions: null
    }
  },
  computed: mapGetters(['newShortResponseOpen']),
  methods: {
    ...mapActions(['fetchQuickActions', 'toggleNewShortResponse']),
    onClickNewQuickAction () {
      console.log('click')
      this.toggleNewShortResponse()
    },
    onClick (response) {
      let inputEl = null
      if (document.getElementById('message-input')) {
        inputEl = document.getElementById('message-input')
      } else if (document.getElementById('reminder-input')) {
        inputEl = document.getElementById('reminder-input')
      }

      if (inputEl) {
        inputEl.focus()
      }

      if (response.type === 'short_response') {
        this.$emit('changeText', response.text)
        this.close()

        setTimeout(() => {
          if (!inputEl) {
            return
          }

          inputEl.style.height = inputEl.scrollHeight + 'px'

          const inputControlsEl = document.getElementById('input-controls')
          const conversationEl = document.getElementById('conversation')
          if (inputControlsEl && conversationEl) {
            const controlsHeight = inputControlsEl.offsetHeight
            conversationEl.style.height = 'calc(100% - ' + controlsHeight + 'px)'

            const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
            if (conversationContainerEl) {
              conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight

              inputEl.focus()
            }
          }
        }, 100)
      }

      if (response.type === 'template') {
        this.emitter.emit('openSendTemplate', response)
        this.close()
      }
    },
    close () {
      this.$emit('changeText', null)
      this.quickActions = false
      this.modalOpen = false
    },
    open (filter) {
      this.fetchQuickActions(filter).then((response) => {
        if (this.filter === 'all') {
          this.quickActions = response
        } else {
          this.quickActions = response.filter((obj) => obj.type === this.filter)
        }
      })
      this.modalOpen = true
    }
  },
  created () {
    this.emitter.on('openQuickActions', this.open)
    this.emitter.on('closeQuickActions', this.close)
  },
  unmounted () {
    this.emitter.off('openQuickActions')
    this.emitter.off('closeQuickActions')
  }
}
</script>

<style scoped>
.templates-container {
  position: absolute;
  bottom: 50px;
  left: 0px;
  width: 100%;
  z-index: 1001;
}

.templates {
  background: rgba(252, 253, 253, 1);
  border: 1px solid rgba(199, 199, 199, 1);
}

.templates-container .templates .label {
  font-size: 14px;
  margin: 10px 0 10px 10px;
}

.templates-container .templates .templates-list {
  border-top: 1px solid #EDEFF1;
  max-height: calc(100vh - 205px);
  overflow: scroll;
}

.templates .template {
  padding: 3px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.templates .template:hover {
  background-color: #4285f45e;
}

.templates-container .template-input {
  background: rgba(252, 253, 253, 1);
  border: 1px solid rgba(199, 199, 199, 1);
  width: 350px;
  padding: 20px 10px 10px 10px;
}

.templates-container .template-input .label {
  font-size: 14px;
}

.templates-container .template-input input {
  height: 30px;
  font-size: 16px;
  border: 1px solid #adacac;
  border-radius: 4px;
  width: calc(100% - 15px);
  padding: 1px 7px
}

.templates-container .template-input .result {
  margin-top: 10px;
  margin-bottom: 60px;
  min-height: 100px;
  background: rgba(242, 245, 245, 0.8);
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  padding: 5px;
}

.templates-container .template-input .button {
  width: calc(100% - 22px);
}

.templates-container .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.templates-container .tag {
  width: fit-content;
  float: left;
  margin-right: 7px;
  background-color: aquamarine;
  padding: 3px 5px;
  border-radius: 4px;
  line-height: 15px;
  color: #3a7662;
}

.templates-container .tag.short_response {
  background-color: #f5c6cb;
  color: #721c24;
}

.templates-container .tag.template {
  background-color: #d4edda;
  color: #155724;
}

.templates-container .text {
  line-height: 15px;
  padding: 3px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.templates-container button.button-small {
  position: absolute;
  bottom: unset;
  height: 26px;
  line-height: 26px;
  top: 7px;
  right: 30px;
  width: fit-content;
}
</style>
