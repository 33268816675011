<template>
  <div class="fa-3x loading">
    <i class="fas fa-circle-notch fa-spin"></i>

    <div v-if="error === true" class="error">
      Se ha producido un error, cierra esta ventana e inténtalo de nuevo.
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'SSO',
  props: {
    partner: String,
    token: String
  },
  data () {
    return {
      error: false
    }
  },
  methods: {
    ...mapActions(['sso'])
  },
  created () {
    if (!this.partner || !this.token) {
      this.error = true
      return
    }

    this.sso({ partner: this.partner, token: this.token })
      .then((accessToken) => {
        Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
        sessionStorage.removeItem('accessTokensSession')
        sessionStorage.setItem('accessTokensSession', accessToken)

        window.location.href = process.env.VUE_APP_APP_HOST + '/chats'
      }).catch(() => {
        this.error = true
      })
  }
}
</script>

<style scoped>
.loading {
  text-align: center;
  margin-top: 100px;
  color: #2c3e50;
}

.error {
  margin-top: 100px;
  font-size: 14px;
  background-color: red;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 10px;
  color: white;
  border-radius: 4px;
}
</style>
