<template>
  <div class="templates-container">
    <div class="templates" v-if="this.users.length > 0">
      <!--
      <div @click="onClickClose" class="close"><i class="fas fa-times"></i></div>
      <div class="label">{{ $t('messages.responses.select') }}:</div>
      -->
      <div class="templates-list">
        <div @click="onClick(user)" class="template" :key="user.id" v-for="user in users">
          {{ user.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Mentions',
  props: {
    users: Array
  },
  methods: {
    onClick (user) {
      this.$emit('setMention', user)
    }
  }
}
</script>

<style scoped>
.templates-container {
  position: absolute;
  bottom: 50px;
  left: 100px;
  z-index: 5;
}

.templates {
  background: rgba(252, 253, 253, 1);
  border: 1px solid rgba(199, 199, 199, 1);
  max-width: 370px;
}

.templates-container .templates .label {
  font-size: 14px;
  margin: 10px 0 10px 10px;
}

.templates-container .templates .templates-list {
  border-top: 1px solid #EDEFF1;
}

.templates .template {
  padding: 5px 10px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.templates .template:hover {
  background-color: #4285f45e;
}

.templates-container .template-input {
  background: rgba(252, 253, 253, 1);
  border: 1px solid rgba(199, 199, 199, 1);
  width: 350px;
  padding: 20px 10px 10px 10px;
}

.templates-container .template-input .label {
  font-size: 14px;
}

.templates-container .template-input input {
  height: 30px;
  font-size: 16px;
  border: 1px solid #adacac;
  border-radius: 4px;
  width: calc(100% - 15px);
  padding: 1px 7px
}

.templates-container .template-input .result {
  margin-top: 10px;
  margin-bottom: 60px;
  min-height: 100px;
  background: rgba(242, 245, 245, 0.8);
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  padding: 5px;
}

.templates-container .template-input .button {
  width: calc(100% - 22px);
}

.templates-container .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
</style>
