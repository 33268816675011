import axios from 'axios'

const state = {
}
const getters = {
}
const actions = {
  async fetchQuickActions ({ commit, rootState }, filter) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/quick_actions?filter=${filter}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    return res.data
  }
}
const mutations = {
}

export default {
  state,
  getters,
  actions,
  mutations
}
