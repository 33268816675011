<template>
  <div v-if="modalOpen" class="overlay"></div>
  <div v-if="modalOpen" class="modal">
    <h3>{{ $t('messages.templates.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
    <div v-if="templateHasMediaHeader()" class="form-group">
      <input id="file-selector-template" type="file" @change="onChangeFile($event)" hidden
             accept="image/jpeg,image/png,video/mp4,video/3gpp,.pdf">

      <label>Archivo adjunto:</label>
      <button v-if="!this.mediafile" @click="openFileSelector" class="button button-upload">
        <i class="fas fa-upload"></i>
        <span v-if="this.template.hasDocument">{{ $t('messages.templates.upload.document') }} </span>
        <span v-if="this.template.hasImage">{{ $t('messages.templates.upload.image') }} </span>
        <span v-if="this.template.hasVideo">{{ $t('messages.templates.upload.video') }} </span>
      </button>

      <div v-if="this.mediafile" class="mediafile">
        <div class="mediafile-name">{{ this.mediafile.name }}</div>
        <div @click="onClickRemoveMediafile" class="mediafile-remove"><i class="fas fa-times"></i></div>
      </div>
    </div>

    <div v-if="templateHasVariable()" class="form-group">
      <label>{{ $t('messages.templates.variable') }}</label>
      <input v-on:keyup.enter="onClickSendTemplate" id="template-var-input" @input="onChangeVariable" v-model="variableValue" type="text">
    </div>

    <div class="form-group">
      <div class="template-input">
        <div class="result">{{ example }}</div>
      </div>
    </div>

    <button @click="onClickSendTemplate" :disabled="!canSendTemplate()" class="button">{{ $t('messages.templates.sent') }} <i class="fas fa-paper-plane"></i></button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SendTemplate',
  data () {
    return {
      modalOpen: false,
      template: null,
      variableValue: null,
      example: null,
      mediafile: null
    }
  },
  computed: mapGetters(['selectedContact']),
  methods: {
    ...mapActions(['sendTemplateMessage', 'sendTemplateMessageWithMediaFile']),
    templateHasVariable () {
      return this.template.text.includes('{{1}}')
    },
    templateHasMediaHeader () {
      if (this.template.hasImage) {
        return true
      }

      if (this.template.hasDocument) {
        return true
      }

      if (this.template.hasVideo) {
        return true
      }

      return false
    },
    onChangeVariable () {
      if (this.variableValue === '') {
        this.variableValue = null
        this.example = this.template.text
        return
      }

      this.example = this.template.text.replace('{{1}}', this.variableValue)
    },
    open (template) {
      const messageInputEl = document.getElementById('message-input')
      if (messageInputEl) {
        messageInputEl.value = ''
      }

      this.modalOpen = true
      this.template = template
      this.example = this.template.text

      if (this.templateHasMediaHeader()) {
        setTimeout(() => {
          const fileSelectorEl = document.getElementById('file-selector-template')
          if (fileSelectorEl) {
            this.openFileSelector()
          }
        }, 200)
        return
      }

      setTimeout(() => {
        const templeVarInputEl = document.getElementById('template-var-input')
        if (templeVarInputEl) {
          templeVarInputEl.focus()
        }
      }, 200)
    },
    close () {
      this.modalOpen = false
      this.template = null
      this.variableValue = null
      this.example = null
      this.mediafile = null
    },
    onClickSendTemplate () {
      if (this.templateHasMediaHeader()) {
        const formData = new FormData()
        formData.append('file', this.mediafile)
        formData.append('text', this.variableValue)

        this.sendTemplateMessageWithMediaFile({
          templateId: this.template.id,
          contact: this.selectedContact,
          formData: formData,
          templateText: this.example
        })
      } else {
        this.sendTemplateMessage({
          templateId: this.template.id,
          variable: this.variableValue,
          contact: this.selectedContact,
          templateText: this.example
        })
      }

      this.close()
    },
    openFileSelector (type) {
      const fileSelectorEl = document.getElementById('file-selector-template')

      if (this.template.hasDocument) {
        fileSelectorEl.setAttribute('accept', '.pdf')
      } else if (this.template.hasImage) {
        fileSelectorEl.setAttribute('accept', 'image/jpeg,image/png')
      } else if (this.template.hasVideo) {
        fileSelectorEl.setAttribute('accept', 'video/mp4,video/3gpp')
      } else {
        return
      }

      fileSelectorEl.click()
    },
    onChangeFile (event) {
      if (event.target.files.length === 0) {
        return
      }

      this.mediafile = event.target.files[0]

      setTimeout(() => {
        const templeVarInputEl = document.getElementById('template-var-input')
        if (templeVarInputEl) {
          templeVarInputEl.focus()
        }
      }, 200)
    },
    onClickRemoveMediafile () {
      this.mediafile = null
      const fileSelectorEl = document.getElementById('file-selector-template')
      if (fileSelectorEl) {
        fileSelectorEl.value = ''
      }
    },
    canSendTemplate () {
      if ((this.template.hasDocument || this.template.hasImage || this.template.hasVideo) && this.mediafile === null) {
        return false
      }

      if (this.templateHasVariable() && (this.variableValue === null || this.variableValue === '')) {
        return false
      }

      return true
    }
  },
  created () {
    this.emitter.on('openSendTemplate', this.open)
    this.emitter.on('closeSendTemplate', this.close)
  },
  unmounted () {
    this.emitter.off('openSendTemplate')
    this.emitter.off('closeSendTemplate')
  }
}
</script>

<style scoped>

.modal {
  height: 370px;
}

.template-input .result {
  margin-top: 10px;
  margin-bottom: 60px;
  min-height: 100px;
  background: rgba(242, 245, 245, 0.8);
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  padding: 5px;
}

.mediafile {
  background: rgba(242, 245, 245, 0.8);
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  padding: 0 5px;
  display: flex;
  align-items: center;
  height: 38px;
}

.mediafile-name {
  flex: 1;
  padding: 5px;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mediafile-remove {
  padding-right: 5px;
  cursor: pointer;
}

#file-selector-template {
  display: none;
}

.button-upload {
  position: relative;
  width: 100%;
  bottom: unset;
}

.button-upload i {
  margin-top: 10px;
  margin-right: 10px;
}
</style>
