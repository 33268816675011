<template>
  <div v-if="modalOpen" class="overlay">
    <div v-if="module" class="modal module">
      <div class="header">
        <div class="icon">
          <div :class="module.key" class="icon-wrapper">
            <img style="border-radius: 4px;" :src="$t(`integrations.${module.key}.icon`)" />
          </div>
        </div>

        <h1>{{ $t(`integrations.${module.key}.title`) }}</h1>
        <p class="short_description">{{ $t(`integrations.${module.key}.subtitle`) }}</p>
        <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      </div>
      <div class="body">
        <div class="left">
          <div class="body-html" v-html="this.body"></div>
          <br>
          <h4 style="margin-bottom: 0; text-align: center">{{ $t('modules.related_modules') }}</h4>
          <div class="random-modules">
            <IntegrationModule :module="module" v-for="module in this.randomModules" :key="module.id" />
          </div>
        </div>
        <div class="right" style="width: 20%">
          <div class="mobile-register-button" v-if="!organization">
            <button @click="openRegister">{{ $t('modules.create_account') }}</button>
            <hr>
          </div>
          <div class="right-wrapper" style="width: 100%;">
            <div class="main-register-button" v-if="!organization">
              <button @click="openRegister">{{ $t('modules.create_account') }}</button>
              <hr>
            </div>
            <a v-if="module.zapierUrl" :href="module.zapierUrl" target="_blank">
              <button class="button-zapier">Zapier <i style="margin-left: 5px" class="fas fa-external-link-alt"></i></button>
            </a>
            <a v-if="module.pabblyConnectUrl" :href="module.pabblyConnectUrl" target="_blank">
              <button class="button-pabbly">Pabbly Connect <i style="margin-left: 5px" class="fas fa-external-link-alt"></i></button>
            </a>
            <a v-if="module.toolUrl" :href="module.toolUrl" target="_blank">
              <button class="button-tool">{{ getDomain(module.toolUrl) }} <i style="margin-left: 5px" class="fas fa-external-link-alt"></i></button>
            </a>
          </div>
          <h4 v-if="module.tags.length > 0">{{ $t('modules.tags.title') }}</h4>
          <div v-if="module.tags.length > 0" class="tags">
            <div class="tag" v-for="tag in module.tags" :key="tag.id">{{$t(`modules.tags.${tag.name}`)}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'js-cookie'

import { mapActions, mapGetters } from 'vuex'

import IntegrationModule from '@/components/home/modules/IntegrationModule'

export default {
  name: 'ModuleIntegration',
  components: { IntegrationModule },
  data () {
    return {
      modalOpen: false,
      module: null,
      redirectUrl: null,
      body: null,
      randomModules: []
    }
  },
  computed: mapGetters(['modules', 'organization', 'loggedUser']),
  methods: {
    ...mapActions([
      'fetchModulesRandom',
      'fetchModuleByKey'
    ]),
    openRegister () {
      this.modalOpen = false
      this.$router.push('/register')
    },
    close () {
      this.modalOpen = false
      if (!this.organization) {
        const emailEl = document.getElementById('email')
        if (emailEl) {
          emailEl.focus()
        }
      }
    },
    async open (module, redirectUrl) {
      if (!module) {
        return
      }

      if (typeof module === 'string') {
        if (this.modules) {
          this.module = this.modules.find(m => m.key === module)
        }

        if (!this.module) {
          this.module = await this.fetchModuleByKey(module)
        }
      } else {
        this.module = module
      }

      if (!this.module) {
        return
      }

      this.redirectUrl = redirectUrl
      this.modalOpen = true

      const key = this.module.key

      let locale = Cookies.get('ma-locale')
      if (!locale) {
        if (navigator.languages !== undefined) {
          locale = navigator.languages[0]
        }

        if (!locale) {
          locale = navigator.language
        }

        if (locale) {
          locale = locale.substr(0, 2)
        }
      }

      if (!locale) {
        locale = 'es'
      }

      const timestamp = (new Date()).getTime()
      axios.get(`${process.env.VUE_APP_PUBLIC_SERVERHOST}/integrations_body/${locale}/${key}.html?ver=${timestamp}`)
        .then((res) => {
          this.body = res.data
        }).catch(() => {
          axios.get(`${process.env.VUE_APP_PUBLIC_SERVERHOST}/integrations_body/en/${key}.html?ver=${timestamp}`)
            .then((res) => {
              this.body = res.data
            }).catch(() => {
              this.body = `Not exists integrations_body/${locale}/${key}.html`
            })
        })

      this.fetchModulesRandom(this.module).then((modules) => {
        this.randomModules = modules
      })
    },
    getDomain (url) {
      return new URL(url).hostname
    }
  },
  created () {
    this.emitter.on('openModuleIntegrationPopup', this.open)
    this.emitter.on('closeModuleIntegrationPopup', this.close)
  },
  unmounted () {
    this.emitter.off('openModuleIntegrationPopup')
    this.emitter.off('closeModuleIntegrationPopup')
  }
}
</script>

<style scoped>
.overlay {
  z-index: 9000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  z-index: 9000001;
  box-shadow: 0 0 0 1px rgba(0,0,0,.1), 0 4px 14px 0 rgba(0,0,0,.2);
  background-color: #fff;
  border-radius: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;
  width: 90%;
  left: unset;
  right: unset;
  margin: 0 auto;
  position: relative;
}

.module {
  max-width: 1000px;
}

.modal-close {
  cursor: pointer;
}

.header {
  height: 115px;
  padding: 20px;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,.1);
  background-color: #f1f1f1;
  border-radius: 4px 4px 0 0;
}

.header h1 {
  padding: 0;
  margin: 0;
  line-height: 40px;
}

.mobile .header h1 {
  margin-top: 14px;
}

.left {
  border-right: 1px solid rgba(0,0,0,.1);
  white-space: normal;
  overflow: scroll;
  width: 80%
}

.left, .right {
  padding: 20px
}

.icon {
  border-radius: 4px;
  border: 1px solid #EDEFF1;
  float: left;
  margin-right: 15px;
  padding: 15px;
}

.icon img {
  width: 85px;
}

.icon-wrapper {
  width: 85px;
  height: 85px;
  border-radius: 6px;
  /*background: linear-gradient(to bottom right, #F1F8FF,#C4E0FD);*/
  color: #4285f4;
  font-size: 45px;
  text-align: center;
  line-height: 85px;
  /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
}

.name {
  margin-top: 10px;
  font-weight: 500;
  font-size: 16px;
}

.short_description {
  margin-top: 10px;
  font-size: 14px;
  width: calc(100% - 175px);
  margin-left: 135px;
  line-height: 20px;
  max-height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.price {
  bottom: 20px;
  right: 20px;
  font-size: 16px;
  color: #4285f4;
}

.active {
  margin-right: 10px;
  background-color: rgb(82, 204, 167,.15);
  font-weight: 400;
  font-size: 12px;
  padding: 3px 6px;
  border-radius: 4px;
  color: #1a202c;
}

.active i {
  color: #7bed9f;
}

.tags {
  top: 50px;
  right: 20px;
  text-align: left;
}

.tags .tag {
  padding: 1px 7px;
  text-decoration: none;
  margin: 13px 0 0px;
  border-radius: 6px;
  border: 1px solid;
  background-color: #fff;
  box-sizing: border-box;
  max-width: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  transform: translateZ(0);
  font-size: 11px;
  line-height: 18px;
  font-weight: 500;
  color: #757575;
}

.mobile .tags {
  display: flex;
}

.mobile .tags .tag {
  margin: 0px 10px 0px 0px;
}

.body {
  height: calc(100% - 160px);
  width: 100%;
  display: inline-flex;
}

.mobile .body {
  flex-direction: column-reverse;
}

.mobile .right {
  -webkit-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 4px 8px -1px rgba(0, 0, 0, 0.05);
  width: unset !important;
}

.mobile .right-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-around;
}

.mobile .right a {
  margin-right: 5px;
}

.mobile .right button {
  height: 90px;
  margin-bottom: 0px;
}

.mobile .right h4 {
  margin-top: 0px;
}

.mobile .left {
  width: 100% !important;
  padding: 0;
}

.mobile .left .body-html {
  padding: 20px;
}

.mobile-register-button {
  display: none;
}

.mobile-register-button button {
  height: 40px;
}

.mobile .mobile-register-button {
  display: block;
}

.mobile .main-register-button {
  display: none;
}

button {
  background-color: #4285f4;
  color: #ffffff;
  width: 100%;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  padding: 10px 15px;
  border-radius: 4px;
  border: 0;
  margin-right: 15px;
  font-weight: 600;
}

button.uninstall {
  background-color: #ff7675;
  margin-right: 0;
}

.random-modules {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 20px 0 40px 20px;
}

.random-modules .module {
  zoom: 0.85
}

.mobile .random-modules {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  margin: 40px 20px 40px 20px;
}

.mobile .random-modules .module {
  zoom: 1;
  height: 275px;
  margin-bottom: 20px;
  border-radius: 6px;
  position: relative;
}

.mobile .module {
  width: 100%;
  height: 100%;
  margin: 0;
  top: 0;
  border-radius: 0;
  position: fixed;
}

.mobile .short_description {
  display: none;
}

button.button-zapier {
  background-color: rgba(255, 79, 0, 1) !important;
  margin-bottom: 20px;
}

button.button-pabbly {
  background-color: #22B276 !important;
  margin-bottom: 20px;
}

button.button-tool {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

</style>

<style>
.modal.module .body .left p {
  padding: 10px 20px;
  line-height: 20px;
  margin: 0;
}

.mobile .module iframe {
  zoom: 0.6;
}

.left .body-html a {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: #111b21;
}
</style>
