<template>
  <div></div>
</template>

<script>
export default {
  name: 'ChangeUser',
  props: {
    token: String
  },
  created () {
    sessionStorage.setItem('accessTokensSession', this.token)
    window.location.href = process.env.VUE_APP_APP_HOST + '/chats'
  }
}
</script>

<style scoped>
</style>
