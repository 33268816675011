<template>
  <div class="overlay" v-if="mustShow()">
    <div class="modal">
      <div class="wrapper">
        <div class="left" :class="{ step1: this.qrTemp === null, step2: this.qrTemp !== null, es: this.locale === 'es'}">
          <img style="position: relative; width: 100%;" v-if="this.partner" :src="`https://s3.amazonaws.com/wa-avatars.manycontacts.com/partners/${this.partner}/partner_cta.png`" onerror="this.style.display = 'none'">
          <img class="meta-business-partner" src="../../assets/meta-business-partner-badge-dark-1.webp" />
        </div>
        <div class="right">
          <div v-if="this.qrTemp === null && this.mobileStep2 === false">
            <h3 style="margin-bottom: 0">{{ $t('pages.register.title') }}</h3>
            <h4 style="margin-top: 10px; margin-bottom: 0; font-size: 17px;">{{ $t('pages.register.subtitle') }}</h4>

            <div style="margin-bottom: 20px" v-html="$t('pages.register.do_login')"></div>

            <!--          <GoogleLogin :callback="callback" :button-config="{ locale: 'es' }"/>-->
            <!--          <hr>-->

            <div class="form-group">
              <label for="email">{{ $t('pages.register.email') }}</label>
              <input type="email" id="email" name="name" v-model="email" placeholder="ramon@tuempresa.com">
            </div>
            <div class="form-group">
              <label for="name">{{ $t('pages.register.name') }}</label>
              <input type="text" id="name" name="name" v-model="name" :placeholder="$t('pages.register.name_placeholder')">
            </div>
            <div class="form-group">
              <label for="password">{{ $t('pages.register.password') }}</label>
              <input type="password" id="password" name="password" v-model="password">
            </div>
            <div class="form-group">
              <label for="password">{{ $t('pages.register.user_number') }}</label>
              <div class="number-of-users">
                <div class="users-selector" :class="{active: this.numberOfUsers === 1}" @click="this.numberOfUsers = 1">
                  1
                </div>
                <div class="users-selector" :class="{active: this.numberOfUsers === 2}" @click="this.numberOfUsers = 2">
                  2-5
                </div>
                <div class="users-selector" :class="{active: this.numberOfUsers === 3}" @click="this.numberOfUsers = 3">
                  +5
                </div>
              </div>
            </div>

            <div class="form-group phone-group">
              <label for="name">{{ $t('pages.register.phone') }}</label>
              <vue-tel-input id="phone" name="phone" v-model="phone" mode="international" enabledCountryCode="true" :inputOptions="{ showDialCode: true, placeholder: $t('pages.register.phone') }"></vue-tel-input>
            </div>

            <div class="form-group">
              <button v-if="!this.loading" @click="submit($event)" :disabled="!this.numberOfUsers || !this.name || !this.email || !this.password || this.password.length < 8" class="button">
                {{ $t('pages.register.submit2') }}
              </button>
              <button v-if="this.loading" :disabled="true" class="button">
                <i class="fas fa-circle-notch fa-spin"></i>
              </button>
            </div>

            <div class="language-selector">
              <select v-model="locale" @change="onChangeLocale">
                <option value="es">🇪🇸 ES</option>
                <option value="en">🇬🇧 EN</option>
                <option value="it">🇮🇹 IT</option>
                <option value="pt">🇵🇹🇧🇷 PT</option>
              </select>
            </div>
          </div>

          <div class="step2-mobile" v-if="this.mobileStep2 == true">
            <h3>{{ $t('pages.register.mobile_step2.title') }}</h3>️
            <div v-html="$t('pages.register.mobile_step2.body')"></div>
            <a target="_blank" :href="$t('pages.register.call_an_expert_link')">
              <button class="button">{{ $t('pages.register.call_an_expert') }}</button>
            </a>
          </div>

          <div v-if="this.qrTemp !== null">
            <h3>{{ $t('pages.register.scan_qr')}}</h3>
            <p class="qr-subtitle" v-html="$t('onboarding.integrations.qr.subtitle')"></p>
            <qrcode-vue style="margin-left: 85px" v-if="qrTemp" :value="qrTemp" size="200" level="H"></qrcode-vue>
            <hr>
            <button class="button button-light" @click="onClickSkip()">{{ $t('onboarding.continue') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'
import store from '@/store'

export default {
  name: 'RegisterModal',
  data () {
    return {
      name: null,
      email: null,
      password: null,
      companyName: null,
      locale: null,
      numberOfUsers: null,
      phone: null,
      loading: false,
      windowWidth: window.innerWidth,
      mobileStep2: false,
      modalOpen: false
    }
  },
  computed: mapGetters(['qrTemp', 'partner']),
  methods: {
    ...mapActions(['register', 'getNewQR', 'fetchOrganization', 'setAuthToken', 'loadSession']),
    mustShow () {
      if (this.modalOpen && ['Register', 'RegisterSector', 'RegisterReferral'].includes(this.$route.name)) {
        return true
      }

      return false
    },
    submit (event) {
      this.loading = true
      event.preventDefault()

      let partner = this.partner
      if (!partner && this.$route.query.via) {
        partner = this.$route.query.via
        window.promotekit_referral = partner
      }

      const self = this
      this.register({
        name: this.name,
        email: this.email,
        password: this.password,
        locale: this.locale,
        phone: this.phone,
        referral: partner,
        type: this.numberOfUsers
      }).then(async (response) => {
        // eslint-disable-next-line no-undef
        fbq('trackCustom', 'Register-Step2', { em: this.email })

        if (response.promotekit && window.promotekit) {
          window.promotekit.refer(response.promotekit.email, response.promotekit.id)
        }

        await this.setAuthToken(null)
        sessionStorage.removeItem('accessTokensSession')
        Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })

        await this.setAuthToken(response.token)
        sessionStorage.setItem('accessTokensSession', response.token)

        await this.loadSession(response.token)

        if (this.windowWidth <= 600) { // mobile
          this.mobileStep2 = true
          return
        }

        let stringConection = ''
        if (process.env.VUE_APP_WSHOST === 'localhost:3000') {
          stringConection = `ws://${process.env.VUE_APP_WSHOST}`
        } else {
          stringConection = `wss://${process.env.VUE_APP_WSHOST}`
        }

        const SocketInstance = new VueSocketIO({
          debug: true,
          connection: SocketIO(stringConection, {
            transports: ['websocket'],
            forceNew: true,
            query: `token=${response.token}`
          }),
          vuex: {
            store,
            actionPrefix: 'SOCKET_',
            mutationPrefix: 'SOCKET_'
          }
        })

        self.$.appContext.app.use(SocketInstance)

        self.fetchOrganization().then(() => {
          self.getNewQR()
        })
      }).catch((err) => {
        console.log(err)
        this.loading = false
        if (err.response.status === 401) {
          this.$toast.open({
            message: this.$t('pages.register.error.exists'),
            type: 'error'
          })
        } else {
          this.$toast.open({
            message: this.$t('pages.register.error.generic'),
            type: 'error'
          })
        }
      })
    },
    onChangeLocale () {
      window.fetchTranslationsFor(this.locale, this.setLocale(this))
    },
    setLocale () {
      Cookies.remove('ma-locale')
      Cookies.set('ma-locale', this.locale)
      this.$i18n.locale = this.locale
    },
    open () {
      this.modalOpen = true
      setTimeout(() => {
        const emailEl = document.getElementById('email')
        if (emailEl) {
          emailEl.focus()
        }
      }, 500)
    },
    close () {
      this.modalOpen = false
    },
    onClickSkip () {
      const sessionToken = sessionStorage.getItem('accessTokensSession')
      Cookies.remove('accessToken', { path: '/', domain: process.env.VUE_APP_COOKIE_HOST })
      Cookies.set('accessToken', sessionToken, { expires: 7, path: '/', domain: process.env.VUE_APP_COOKIE_HOST })

      if (window.location &&
          window.location.host &&
          window.location.host === 'www.manycontacts.com') {
        sessionStorage.removeItem('accessTokensSession')
        localStorage.removeItem('accessTokens')
      }

      window.location.href = process.env.VUE_APP_APP_HOST + '/'
    }
  },
  mounted () {
    const localeCookie = Cookies.get('ma-locale')
    if (localeCookie) {
      this.locale = localeCookie
    }

    if (!this.locale) {
      if (navigator.languages !== undefined) {
        this.locale = navigator.languages[0]
      }

      if (!this.locale) {
        this.locale = navigator.language
      }

      if (this.locale) {
        this.locale = this.locale.substr(0, 2)
      }
    }

    if (!this.locale) {
      this.locale = 'es'
    }

    if (this.$route.query.hsp) {
      this.phone = atob(this.$route.query.hsp)
    }
  },
  created () {
    this.emitter.on('openRegisterPopup', this.open)
    this.emitter.on('closeRegisterPopup', this.close)
  },
  unmounted () {
    this.emitter.off('openRegisterPopup')
    this.emitter.off('closeRegisterPopup')
  }
}
</script>

<style scoped>

.overlay {
  background-color: rgb(17, 27, 33, 0.3) !important;
  opacity: 1 !important;
  z-index: 1000000 !important;
}

.modal {
  height: 490px;
  margin-left: -415px;
  left: 50%;
  top: 175px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: fit-content;
  padding: 0px;
  background-image: linear-gradient(83.84deg, #2c51a0 -6.87%, #5399dd 26.54%, #25D366 58.58%);
  border-radius: 10px;
}

.mobile .modal {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  border-radius: 0;
  height: 100%;
  width: 100%;
}

.mobile .modal .left {
  display: none;
}

.mobile .modal .right {
  width: 100%;
}

.phone-group {
  display: none;
}

.mobile .phone-group {
  display: block;
}

.number-of-users {
  display: flex;
}

h3 {
  font-size: 24px;
  color: #25D366;
}

.left {
  width: 450px;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
}

.left.step1 {
  background-image: url("https://cdn.manycontacts.com/assets/home/main_cover_en.png");
}

.left.step1.es {
  background-image: url("https://cdn.manycontacts.com/assets/home/main_cover_es.png");
}

.left.step2 {
  background-image: url("../../assets/perro2.jpeg");
  background-position: right top;
}

.right {
  width: 370px;
  padding: 15px;
  position: relative;
  height: calc(100% - 30px);
}

.button {
  width: calc(100% - 30px);
  background-color: #25D366;
}

.button.button-light {
  color: #25D366;
  background-color: transparent;
}

.button:disabled {
  background-color: #25d36682;
}

.wrapper {
  margin: 5px;
  height: calc(100% - 10px);
  border-radius: 5px;
  background-color: white;
  display: flex;
  overflow: hidden;
}

.number-of-users .users-selector {
  width: 33%;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  margin: 0 5px;
  cursor: pointer;
}

.number-of-users .users-selector:hover {
  background-color: #ffffff;

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.number-of-users .users-selector.active {
  background-color: rgba(51, 75, 250, 0.07);

  border-color: #334bfa;
  box-shadow: 0 4px 14px 0 rgba(75,94,180,.1);
}

.number-of-users .users-selector:first-of-type {
  margin-left: 0;
}

.number-of-users .users-selector:last-of-type {
  margin-right: 0;
}

.language-selector {
  position: absolute;
  top: 55px;
  right: 15px;
}

.step2-mobile div {
  font-size: 20px;
  margin: 20px 0;
}

</style>

<style>
.qr-subtitle {
  margin: 0;
  padding: 0;
  line-height: 20px;
}

.qr-subtitle ul {
  margin: 0;
  padding: 0;
}

.qr-subtitle ul li {
  margin-bottom: 15px;
}

.vue-tel-input input {
  border: 0;
}

.meta-business-partner {
  position: absolute;
  bottom: 12px;
  left: 2px;
  width: 170px;
}
</style>
