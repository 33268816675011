<template>
  <div class="form-group">
    <label>{{ $t('settings.auto_reply.away.type') }}:</label>
    <select @change="onChangeValue" v-model="schedule.type">
      <option value="10">{{ $t('settings.auto_reply.away.types.workdays') }}</option>
      <option value="20">{{ $t('settings.auto_reply.away.types.weekend') }}</option>
      <option value="1">{{ $t('settings.auto_reply.away.types.monday') }}</option>
      <option value="2">{{ $t('settings.auto_reply.away.types.tuesday') }}</option>
      <option value="3">{{ $t('settings.auto_reply.away.types.wednesday') }}</option>
      <option value="4">{{ $t('settings.auto_reply.away.types.thursday') }}</option>
      <option value="5">{{ $t('settings.auto_reply.away.types.friday') }}</option>
      <option value="6">{{ $t('settings.auto_reply.away.types.saturday') }}</option>
      <option value="0">{{ $t('settings.auto_reply.away.types.sunday') }}</option>
    </select>
    <label>{{ $t('settings.auto_reply.away.from') }}:</label>
    <select @change="onChangeValue" v-model="schedule.from">
      <OrganizationSchedulePickerOptions :key="hour" :hour="hour" v-for="hour in hours" />
    </select>
    <label>{{ $t('settings.auto_reply.away.to') }}:</label>
    <select @change="onChangeValue" v-model="schedule.to">
      <OrganizationSchedulePickerOptions :key="hour" :hour="hour" v-for="hour in hours" />
    </select>
    <i @click="onDelete" class="far fa-trash-alt"></i>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import OrganizationSchedulePickerOptions from '@/components/common/OrganizationSchedulePickerOptions'

export default {
  name: 'OrganizationSchedulePicker',
  props: {
    element: Number,
    elementData: Object
  },
  data () {
    return {
      hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24'],
      schedule: {
        type: 10,
        from: '09:00',
        to: '18:30'
      }
    }
  },
  mounted () {
    this.schedule = this.elementData
  },
  computed: mapGetters(['organizationSchedule']),
  methods: {
    onDelete () {
      this.organizationSchedule.splice(this.element, 1)
    },
    onChangeValue () {
      this.organizationSchedule[this.element] = this.schedule
    }
  },
  components: {
    OrganizationSchedulePickerOptions
  }
}
</script>

<style scoped>
.form-group {
  padding: 30px 20px 20px 20px;
  border-radius: 3px;
  border: 1px solid #EDEFF1;
}

.form-group select {
  min-width: 180px;
  margin-bottom: 10px;
}

.form-group .fa-trash-alt {
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
}
</style>
