<template>
  <div class="modal modal-onboarding">
    <div class="no-apikey" v-if="!organization.hasApiKey">
      <div v-if="!qrTemp">
        <img class="whatsapp-logo" src="../../assets/WhatsApp_icon.svg">
        <h3>{{ $t('onboarding.title') }}</h3>
        <div class="language-selector">
          <label>{{ $t('pages.register.locale') }}: </label>
          <select v-model="locale" @change="onChangeLocale">
            <option value="es">🇪🇸 ES</option>
            <option value="en">🇬🇧 EN</option>
            <option value="it">🇮🇹 IT</option>
            <option value="pt">🇵🇹🇧🇷 PT</option>
          </select>
        </div>

        <p style="margin: 0">{{ $t('onboarding.scan_qr') }}</p>
        <button @click="this.getQR();" class="button button-scan">{{ $t('onboarding.scan_qr_button') }}</button>

        <hr style="margin: 10px">

        <router-link to="/settings/whatsapp">
          <button class="button button-light">{{ $t('onboarding.other_options_button') }}</button>
        </router-link>
      </div>
      <div class="wa-qr" v-if="qrTemp">
        <p v-html="$t('onboarding.integrations.qr.subtitle')" style=""></p>
        <qrcode-vue v-if="qrTemp" :value="qrTemp" size="200" level="H"></qrcode-vue>
      </div>
    </div>
    <div class="not-approved" v-if="organization.hasApiKey && !organization.emptyTemplate">
      <h3>🕓 {{ $t('onboarding.not_approved.title') }}</h3>
      <p v-html="$t('onboarding.not_approved.subtitle')">
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Cookies from 'js-cookie'

export default {
  name: 'Onboarding',
  data () {
    return {
      apiKey: null,
      loading: false,
      apiKeyTemp: false,
      showApikeyInput: false,
      locale: null,
      name: '',
      phone: '',
      requested: false,
      showQR: false
    }
  },
  mounted () {
    const localeCookie = Cookies.get('ma-locale')
    if (localeCookie) {
      this.locale = localeCookie
    }

    if (!this.locale) {
      if (navigator.languages !== undefined) {
        this.locale = navigator.languages[0]
      }

      if (!this.locale) {
        this.locale = navigator.language
      }

      if (this.locale) {
        this.locale = this.locale.substr(0, 2)
      }
    }

    if (!this.locale) {
      this.locale = 'es'
    }
  },
  computed: mapGetters(['organization', 'qrTemp', 'loggedUser']),
  methods: {
    ...mapActions(['getNewQR', 'updateUser']),
    getQR () {
      return this.getNewQR()
    },
    onChangeLocale () {
      this.loggedUser.locale = this.locale

      this.updateUser(this.loggedUser).then(() => {
        location.reload()
      })
    }
  }
}
</script>

<style scoped>

.connect-qr-mobile {
  display: none;
}

.mobile .connect-qr-mobile {
  display: block;
}

.mobile .connect-qr {
  display: none;
}

.overlay {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 1000000;
  background-color: #c9dfff;
  position: fixed;
  top: unset;
  left: unset;
  bottom: 20px;
  right: 20px;
  width: 300px;
  height: unset;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.modal.centered {
  left: 50%;
  margin-left: -150px;
  top: 20%;
  bottom: unset;
}

.modal h3 {
  margin-top: 0;
}

.form-group {
  position: relative;
}

input {
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: .2px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  width: 260px;
  position: absolute;
  right: 6px;
  top: 7px;
}

.button.button-white {
  background-color: #ffffff;
  color: #4285f4;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button.button-white:hover {
  background-color: rgba(66, 133, 244, 0.20);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.embedded-signup {
  text-decoration: none;
  font-size: 14px;
}

.embedded-signup.button {
  position: relative;
  margin: 0 auto;
  width: fit-content;
  padding: 0 20px;
}

.photo {
  height: 85px;
  border-radius: 100%;
  float: left;
  margin-right: 20px;
  margin-top: 0px;
}

.help {
  width: 100%;
  bottom: 20px;
  margin-top: 100px;
}

.wa-qr .video {
  position: absolute;
  background: #95c0ff82;
  width: 100%;
  margin-left: -20px;
  top: 256px;
  padding: 20px;
  text-align: center;
}

.wa-qr .video video {
  margin: 0 auto;
  width: 430px;
}

.wa-api {
  bottom: 0px;
  position: absolute;
  width: calc(100% - 40px);
}

.wa-api p {
  width: 70%;
  float: left;
}

.mobile .wa-api p {
  width: 65%;
}

.back {
  position: absolute;
  bottom: 20px;
  cursor: pointer;
}

.mobile .modal {
  top: 0px;
  height: calc(100% - 40px);
}

.mobile .button-connect-api {
  top: 35px
}

.mobile .button-apikey-validate {
  width: fit-content;
}

.language-selector {
  width: fit-content;
  position: absolute;
  right: 20px;
  bottom: 20px;
}

.button {
  position: relative;
  bottom: unset;
  width: 100%;
  right: unset;
  top: unset;
}

.button-scan {
  margin-top: 12px;
}

.button-light, .button-light:hover {
  color: #4285f4;
  background-color: transparent;
  border: 0;
  padding: 0;
  height: 20px;
  line-height: 20px;
  margin-bottom: 40px;
}

.whatsapp-logo {
  position: absolute;
  width: 20px;
  left: 20px;
  bottom: 20px;
}

</style>

<style>
.wa-qr p {
  margin-top: 0;
  padding: 0;
}

.wa-qr p ul {
  margin: 0;
  padding: 0;
}

.wa-qr p ul li {
  margin-bottom: 10px;
}

</style>
