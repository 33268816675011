import axios from 'axios'

const state = {
  alerts: []
}
const getters = {
  alerts: (state) => state.alerts
}
const actions = {
  async fetchAlerts ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/alerts`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setAlerts', res.data)
  },
  async readAlert ({ commit, dispatch, rootState }, alertId) {
    axios.put(`${process.env.VUE_APP_SERVERHOST}api/alert/${alertId}`, null, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      dispatch('fetchAlerts')
    }).catch(() => {})
  }
}
const mutations = {
  setAlerts: (state, alerts) => (state.alerts = alerts)
}

export default {
  state,
  getters,
  actions,
  mutations
}
