<template>
  <div v-if="show" class="overlay" @click="close">
    <div class="modal">
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
      <iframe
        :src="url"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoOverlay',
  data () {
    return {
      show: false,
      url: null
    }
  },
  methods: {
    close () {
      this.show = false
    },
    open (url) {
      this.url = url
      this.show = true
    }
  },
  created () {
    this.emitter.on('openVideoHelp', this.open)
  }
}
</script>

<style scoped>
 .modal {
   width: 80%;
   height: 80%;
   margin: 0 auto;
   position: relative;
   top: 30px;
   left: 0;
 }

 iframe {
   margin-top: 30px;
   width: 100%;
   height: calc(100% - 30px);
 }

 .overlay {
   opacity: 1;
   background-color: rgba(32,33,36,0.3);
 }
</style>
