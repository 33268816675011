import axios from 'axios'

const state = {
  templates: []
}
const getters = {
  templates: (state) => state.templates
}
const actions = {
  async fetchTemplates ({ commit, rootState }, { query, all }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/templates?filter=${query}&all=${all}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setTemplates', res.data)
  },
  async emptyTemplates ({ commit }) {
    commit('setTemplates', [])
  },
  async sendTemplateMessage ({ commit, dispatch, rootState }, { templateId, variable, contact, templateText }) {
    commit('showDropdown', false)

    if (contact.open === false) {
      contact.open = true
      commit('setSelectedContact', contact)
    }

    if (contact.last_user_id !== rootState.user.loggedUser.id) {
      contact.last_user_id = rootState.user.loggedUser.id
      commit('setSelectedContact', contact)

      dispatch('assignContactToLoggedUser', contact)
      dispatch('setCurrentContactMessagesAsRead')
    }

    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/template/${templateId}/${contact.id}`, {
      text: variable
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    const newMessage = res.data
    newMessage.text = templateText

    commit('setModeNoteActive', false)
    commit('addMessage', newMessage)
    setTimeout(() => { commit('showDropdown', true) }, 500)
  },
  async sendTemplateMessageWithMediaFile ({ commit, dispatch, rootState }, { templateId, contact, formData, templateText }) {
    commit('showDropdown', false)

    if (contact.open === false) {
      contact.open = true
      commit('setSelectedContact', contact)
    }

    if (contact.last_user_id !== rootState.user.loggedUser.id) {
      contact.last_user_id = rootState.user.loggedUser.id
      commit('setSelectedContact', contact)

      dispatch('assignContactToLoggedUser', contact)
      dispatch('setCurrentContactMessagesAsRead')
    }

    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/template/${templateId}/${contact.id}/media`, formData, {
      headers: {
        token: rootState.sessions.userIsAutenticate,
        'Content-Type': 'multipart/form-data'
      }
    })

    const newMessage = res.data
    newMessage.text = templateText

    commit('setModeNoteActive', false)
    commit('addMessage', newMessage)
    setTimeout(() => { commit('showDropdown', true) }, 500)
  },
  async syncTemplates ({ commit, dispatch, rootState }, query) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/templates/sync`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    dispatch('fetchOrganization')
    return res.data
  }
}
const mutations = {
  setTemplates: (state, templates) => {
    for (const template of templates) {
      template.label = `[${template.code}] (${template.name}) ${template.text}`
    }

    state.templates = templates
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
