const state = {
  section: null
}
const getters = {
  section: (state) => state.section
}
const actions = {
  async setSection ({ commit }, value) {
    commit('setSection', value)
  }
}
const mutations = {
  setSection: (state, section) => (state.section = section)
}

export default {
  state,
  getters,
  actions,
  mutations
}
