<template>
  <div v-if="this.openModal" class="overlay">
    <div class="modal">
      <h3>{{ $t('settings.funnels.delete.title', { name: stage.name }) }}</h3>
      <p v-html="$t('settings.funnels.delete.subtitle', { name: stage.name })"></p>
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

      <div class="form-group">
        <select v-model="this.newStageId">
          <option v-for="stage in getStages()" :key="stage.id" :data-funnel-id="stage.funnelId" :value="stage.id">{{ stage.name }}</option>
        </select>
      </div>

      <button :disabled="!this.newStageId" class="button" @click="onClickSubmit">{{ $t('settings.funnels.new.submit') }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'DeleteFunnelStage',
  computed: mapGetters([]),
  data () {
    return {
      funnel: null,
      stage: null,
      newStageId: null,
      openModal: false
    }
  },
  methods: {
    ...mapActions(['deleteFunnelStage']),
    onClickSubmit () {
      this.deleteFunnelStage({
        funnel: this.funnel,
        stage: this.stage,
        newStageId: this.newStageId
      }).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
      this.close()
    },
    close () {
      this.openModal = false
    },
    getStages () {
      const stages = []

      for (const stage of this.funnel.stages) {
        if (stage.id !== this.stage.id) {
          stages.push(stage)
        }
      }

      stages.push({
        id: 'DELETE',
        name: '------ ' + this.$t('settings.funnels.delete.out_of_funnel') + ' ------'
      })

      return stages
    },
    open ({ funnel, stage }) {
      this.funnel = funnel
      this.stage = stage
      this.openModal = true
    }
  },
  created () {
    this.emitter.on('openDeleteFunnelStage', this.open)
  }
}
</script>

<style scoped>

.overlay {
  z-index: 2000000;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  height: 220px
}

select {
  width: 100% !important;
}
</style>
