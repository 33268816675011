<template>
  <div>
    <NavbarFake />
    <div>
      <SidebarFake />
      <Content :sector="this.sector"/>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import Content from '@/components/home/modules/Content'
import NavbarFake from '../components/home/register/NavbarFake'
import SidebarFake from '@/components/home/register/SidebarFake'

export default {
  name: 'RegisterHome',
  computed: mapGetters(['showTimer']),
  methods: {
    ...mapActions(['setPartner'])
  },
  props: {
    sector: String,
    partner: String
  },
  created () {
    if (this.partner) {
      this.setPartner(this.partner)
    }

    if (!this.partner && this.$route.query.via) {
      this.setPartner(this.$route.query.via)
    }

    this.emitter.emit('openRegisterPopup')
  },
  components: {
    SidebarFake,
    Content,
    NavbarFake
  }
}
</script>

<style>

</style>
