<template>
  <div class="settings-group">
    <h3>{{ $t('settings.promote.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.promote.description') }}</p>
      <button @click="newPromote" class="button" type="submit">{{ $t('settings.promote.button_create') }}</button>
    </div>
    <table v-if="promotes && promotes.length > 0">
      <thead>
        <th style="width: 13%">{{ $t('settings.promote.created_at') }}</th>
        <th style="width: 20%">{{ $t('settings.promote.number') }}</th>
        <th style="width: 57%">{{ $t('settings.promote.text') }}</th>
        <th style="width: 10%"></th>
      </thead>
      <tbody>
        <tr :key="promote.id" v-for="promote in promotes">
          <td>{{ $moment(promote.createdAt).format('L') }}</td>
          <td>{{ promote.number }}</td>
          <td>
            <div class="response-text">
              {{ promote.text }}
            </div>
          </td>
          <td>
            <i @click="getPromoteInfo(promote)" class="fas fa-link"></i>
            <i @click="getPromoteInfo(promote)" class="fas fa-qrcode"></i>
            <i @click="onClickDeletePromote(promote)" class="far fa-trash-alt"></i>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <NewPromote v-if="this.newPromoteOpen"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import NewPromote from '@/components/modals/NewPromote'

export default {
  name: 'SectionPromote',
  components: {
    NewPromote
  },
  computed: mapGetters(['promotes', 'newPromoteOpen', 'organization']),
  methods: {
    ...mapActions([
      'fetchOrganization',
      'fetchPromotes',
      'emptyPromotes',
      'toggleNewPromote',
      'setNewPromoteData',
      'emptyNewPromoteData',
      'deletePromote'
    ]),
    newPromote () {
      this.emptyNewPromoteData(this.organization.number.replace('+', ''))
      this.toggleNewPromote()
    },
    getPromoteInfo (promote) {
      this.emitter.emit('openShowPromote', promote)
    },
    onClickDeletePromote (response) {
      if (confirm(this.$t('settings.promote.delete.confirm', { number: response.number }))) {
        this.deletePromote(response)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
      }
    }
  },
  created () {
    if (this.organization === null) {
      this.fetchOrganization().then(() => {
        this.fetchPromotes()
      })
    } else {
      this.fetchPromotes()
    }
  },
  beforeUnmount () {
    this.emptyPromotes()
  }
}
</script>

<style scoped>
  p {
    width: calc(100% - 200px);
    max-width: 600px;
    float: left;
    margin: 0;
  }

  table {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
  }

  thead {
    height: 40px;
    text-align: left;
    border-bottom: 1px solid #EDEFF1;
  }

  tr {
    height: 50px;
    border-bottom: 1px solid #EDEFF1;
  }

  tr i {
    margin-left: 10px;
    cursor: pointer;
  }

  .response-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 370px;
  }
</style>
