<template>
  <div class="content">
    <List />
    <Panel />
  </div>
</template>

<script>
import List from './list/List'
import Panel from './reader/Panel'
import { mapGetters } from 'vuex'

export default {
  name: 'Content_Email',
  components: {
    Panel,
    List
  },
  computed: mapGetters(['selectedEmail'])
}
</script>

<style scoped>
  .content {
    width: calc(100% - 71px);
    margin-left: 71px;
  }
</style>
