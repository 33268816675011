<template>
  <div>
    <ImportantAlert />
    <UnpaidInvoiceAlert />
    <Navbar v-if="loggedUser" />
    <NavbarFake v-if="!loggedUser"/>
    <div>
      <Sidebar v-if="loggedUser"/>
      <SidebarFake v-if="!loggedUser"/>
      <Content :sector="this.sector" :category="this.category" />
    </div>
  </div>
  <MobileSidebar v-if="loggedUser" />
  <NewMessage v-if="this.newMessageOpen"/>
</template>

<script>
import Navbar from '../components/home/Navbar'
import NewMessage from '../components/modals/NewMessage'

import MobileSidebar from '../components/mobile/Sidebar'

import { mapActions, mapGetters } from 'vuex'

import ImportantAlert from '@/components/home/ImportantAlert'
import UnpaidInvoiceAlert from '@/components/home/UnpaidInvoiceAlert'
import Content from '@/components/home/modules/Content'
import Sidebar from '@/components/home/Sidebar'
import NavbarFake from '@/components/home/register/NavbarFake'
import SidebarFake from '@/components/home/register/SidebarFake'

export default {
  name: 'Home',
  methods: {
    ...mapActions(['toggleNewUserInvite', 'setPartner'])
  },
  props: {
    filterView: Number,
    page: Number,
    sector: String,
    partner: String,
    category: String
  },
  computed: mapGetters(['newMessageOpen', 'loggedUser']),
  created () {
    if (this.partner) {
      this.setPartner(this.partner)
    }
  },
  components: {
    SidebarFake,
    NavbarFake,
    Sidebar,
    Content,
    UnpaidInvoiceAlert,
    ImportantAlert,
    Navbar,
    NewMessage,
    MobileSidebar
  }
}
</script>

<style>
.content {
  margin-top: 67px;
}

.importantAlert .content, .unpaidInvoiceAlert .content {
  margin-top: 127px;
}

.blink {
  margin-top: 5px;
  height: 10px;
  width: 100px;
  background-color: #DFE5E7;
  animation: blink 3s linear infinite;
}

@keyframes blink {
  50% { opacity: 0; }
}

hr {
  height: 1px;
  opacity: 0.1;
  margin: 1rem 0;
  color: inherit;
  background-color: #111b21;
  border: 0;
}
</style>
