<template>
  <aside v-if="selectedContact && this.isRegularChat()" class="crm" :class="{ openCRM: openCRM }">
    <div class="back-button" @click="onClickBack">
      <i class="fas fa-chevron-left"></i>
    </div>
    <div style="min-height:30px; border-bottom: 0px;" v-tooltip.left="{ content: $t('messages.crm.user_assigned') }" v-if="organization && organizationUsersToAssign" class="group">
      <i style="margin-top: 9px" class="fas fas fa-user"></i>
      <v-select class="noclear" @option:selected="onUpdateAssigned" @option:selecting="onBeforeUpdateAssigned" style="width: calc(100% - 27px); float: right" v-model="this.selectedContact.last_user_id" :reduce="user => user.id" :options="this.organizationUsersToAssign" label="name" :placeholder="$t('messages.crm.user_assigned_placeholder')">
        <template #option="option">
          <span>
            <i v-if="!option.ai && ![0, -1].includes(option.id)" class="fas fa-user" aria-hidden="true" style="margin-left: 3px;  margin-right: 4px"></i>
            <i v-if="option.ai" class="fas fa-robot" aria-hidden="true" style="margin-right: 4px"></i>
            {{ option.name }}
          </span>
        </template>
      </v-select>
    </div>
    <div style="min-height:30px; border-bottom: 0px;" v-tooltip.left="{ content: $t('messages.crm.teams') }" v-if="organization && teams && teams.length > 0" class="group">
      <i style="margin-top: 9px" class="fas fas fa-users"></i>
      <v-select style="width: calc(100% - 27px); float: right" @option:selected="onAddContactTeam" v-model="this.selectedContactTeam" :placeholder="$t('messages.crm.teams_placeholder')" :options="this.teamList" label="name" :selectable="(option) => !selectedContact.teams.find(tag => tag.team_id === option.id)">
        <template #option="option">
          <span>
            <i v-if="option.id !== 'NEW'" class="fas fa-users" aria-hidden="true" style="margin-right: 4px"></i>
            {{ option.name }}
          </span>
        </template>
      </v-select>
      <div class="tags">
        <div :key="team.id" v-for="team in getTeams()" class="tag" :style="{ backgroundColor: '#cecece' }">
          {{ this.teams.find(el => el.id === team.team_id).name }}
          <i @click="onDeleteContactTeam({id: team.team_id})" v-tooltip.top="{ content: $t('messages.crm.teams_delete') }" class="fas fa-times"></i>
        </div>
      </div>
    </div>
    <div style="min-height:30px; border-bottom: 0px;" v-tooltip.left="{ content: $t('settings.funnels.stages') }" v-if="organization && organization.features.funnels && funnels && funnels.length > 0 && stagesList.length > 0" class="group pipelines">
      <i style="margin-top: 9px" class="fas fa-filter"></i>
      <v-select class="noclear" style="width: calc(100% - 27px); float: right" @option:selecting="onUnselectStage" @option:selected="onChangeStage" v-model="selectedContact.stage_id" placeholder="" :reduce="stage => stage.id" :options="stagesList" label="name" :selectable="(option) => option.id !== this.selectedContact.stage_id && option.id !== 'notSelectable' && option.funnelId !== 'notSelectable'">
        <template #option="option">
          <span style="display: flex">
            <div v-if="option.funnelId === 'notSelectable'" style="margin-top: 5px;"></div>
            <i v-if="option.id === 'notSelectable'" style="margin-top: 4px; margin-right: 5px; font-size: 14px" class="fas fa-filter"></i>
            <div v-if="option.id !== 'notSelectable'" style="font-size: 14px; margin-left: 13px">{{ option.name }}</div>
            <div v-if="option.id === 'notSelectable'" style="font-size: 14px;">{{ option.name }}</div>
          </span>
        </template>
      </v-select>
    </div>
    <div class="group" v-tooltip.left="{ content: $t('messages.crm.tags.title') }">
      <i style="margin-top: 9px" class="fas fas fa-tags"></i>
      <v-select style="width: calc(100% - 27px); float: right" @option:selected="onSelectAddTag" v-model="this.selectedTag" :placeholder="$t('messages.crm.tags.placeholder')" :options="tagList" label="name" :selectable="(option) => !selectedContact.tags.find(tag => tag.id === option.id)">
        <template #option="option">
          <span>
            <div style="display: flex">
              <div>
                <div class="tag-bubble-color" :style="{ backgroundColor: option.color }"></div>
              </div>
              <div>
                {{ option.name }}
              </div>
            </div>
          </span>
        </template>
      </v-select>
      <div class="tags">
        <div :key="tag.id" v-for="tag in selectedContact.tags" class="tag" :tag="tag" :style="{ backgroundColor: tag.color }">
          {{ tag.name }}
          <i @click="delContactTag(tag)" v-tooltip.top="{ content: $t('messages.crm.tags.tooltip') }" class="fas fa-times"></i>
        </div>
      </div>
    </div>
    <div v-if="selectedContact.isGroup !== 1" class="group">
      <div class="call"><i class="fas fa-phone"></i></div>
      <div class="email"><i class="far fa-envelope"></i></div>
      <div v-if="this.isRegularChat()" @click="onClickStarred(selectedContact)" class="message-star" :class="{starred: selectedContact.starred}"><i class="fas fa-star"></i></div>

      <div style="margin-left: 25px" class="content-editable">
        <input @input="onContentEditable" type="text" v-model="selectedContact.name" v-tooltip.top="{ content: $t('clickToEdit') }">
      </div>
      <div class="phone"><a @click="onClickPhone($event)" :href="'tel:'+selectedContact.number">{{selectedContact.number}}</a></div>

      <div style="margin-left: 25px;" class="content-editable crm-contact-email">
        <input @input="onContentEditable" type="text" v-model="selectedContact.email" :placeholder="$t('messages.crm.email_placeholder')" v-tooltip.top="{ content: $t('clickToEdit') }">
      </div>
      <div style="margin: 15px 4px 10px" v-if="contactFilesCount && contactFilesCount > 0">
        <i style="margin-right: 5px" class="fas fa-paperclip"></i> {{ $t('contact.mediafiles.title') }} ({{ contactFilesCount }}) <i @click="toggleAttachmentListOpen()" style="position: absolute; right: 0px; cursor: pointer" class="fas fa-arrow-right"></i>
      </div>
      <div style="margin: 15px 4px 10px" class="contact-events">
        <i style="margin-right: 5px" class="far fa-calendar"></i> {{ $t('event.contact.events_of', { name: this.selectedContact.name}) }} <i @click="toggleEventsListOpen()" style="position: absolute; right: 0px; cursor: pointer" class="fas fa-arrow-right"></i>
      </div>
      <div v-if="selectedContact.isGroup !== 1">
        <div style="margin: 15px 4px 10px; cursor: pointer" class="contact-events" v-on:click="editNotesShow()" v-if="!showNotesEditor()">
          <i style="margin-right: 5px;" class="fas fa-pencil"></i> {{$t('messages.crm.editor_notes')}}
        </div>
      </div>
    </div>
    <div v-if="selectedContact.isGroup !== 1">
      <div v-if="showNotesEditor()" class="group-editor">
        <vue-editor @keyup="onNotesChange" v-model="selectedContact.notes" :editorToolbar="customToolbar" :placeholder="$t('messages.crm.editor_placeholder')"></vue-editor>
      </div>
    </div>
    <div class="group custom-fields">
      <div style="height: 40px;" v-if="Object.keys(getCustomFields()).length === 0"><router-link to="/settings/custom_fields"><button class="button" type="submit">{{ $t('settings.custom_fields.button_create') }}</button></router-link></div>
      <div v-for="(value, key) in getCustomFields()" :key="key" class="content-editable custom-field">
        <label>{{ getCustomFieldName(key) }}</label>
        <input @input="onContentEditableCustomField" type="text" :data-key="key" :value="value" v-tooltip.top="{ content: $t('clickToEdit') }" :placeholder="$t('messages.crm.custom_fields.placeholder') + ' ' + getCustomFieldName(key) + ' ...'">
        <a class="external" :href="value" target="_blank" v-if="isValidUrl(value)"><i class="fas fa-external-link-alt"></i></a>
      </div>
    </div>
    <div v-if="selectedContact.isGroup !== 1" style="margin: 15px 4px 0px; display: inline-flex">
      <i style="margin: 6px 0 0 14px;" class="fa-solid fa-ban"></i>
      <Switch v-on:update:checked="onContentEditable" v-model:checked="selectedContact.locked" :text="$t('contact.locked')" alignWa="right" iconTransform="true"/>
    </div>
    <div v-if="organization && !organization.features.conversationDeleteBlock" v-on:click="onDeleteContact(selectedContact.id)" style="margin: 13px 4px 10px; cursor: pointer">
      <a><i style="margin-right: 10px; margin-left: 15px;" class="fa-solid fa-trash"></i></a> {{ $t('messages.crm.delete_contact') }}
    </div>
    <div @click="onClickExportConversation" style="margin: 15px 4px 10px; cursor: pointer">
      <a><i style="margin-right: 10px; margin-left: 15px;" class="fa-solid fa-file-export"></i></a>{{ $t('messages.crm.export.title') }}
    </div>
  </aside>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VueEditor } from 'vue3-editor'
import Switch from '@/components/common/Switch'

export default {
  name: 'Crm',
  components: {
    VueEditor,
    Switch
  },
  computed: {
    ...mapGetters([
      'selectedContact',
      'tags',
      'openCRM',
      'customFields',
      'contactFilesCount',
      'funnels',
      'organization',
      'organizationUsersToAssign',
      'teams',
      'organizationModules'
    ])
  },
  data () {
    return {
      customToolbar: [
        ['bold', 'italic', 'underline'],
        ['link']
      ],
      timer: null,
      selectedTag: null,
      tagList: [],
      teamList: [],
      lastContactId: null,
      stagesList: [],
      selectedContactTeam: null,
      previousStage: null,
      previousLastUserId: null,
      showEditor: false
    }
  },
  methods: {
    ...mapActions([
      'setConversationContactNotes',
      'updateContact',
      'fetchTags',
      'addContactTag',
      'deleteContactTag',
      'setOpenCRM',
      'toggleAttachmentListOpen',
      'toggleEventsListOpen',
      'updateFunnelStageContact',
      'deleteContactFromFunnels',
      'fetchFunnels',
      'unassignContact',
      'assignContactToUser',
      'addContactTeam',
      'deleteContactTeam',
      'deleteContact',
      'toggleNewTagOpen',
      'toggleNewUserInvite',
      'fetchTeams',
      'toggleNewTeamOpen',
      'clearSelectedContact',
      'toggleStarredContact'
    ]),
    onClickStarred (contact) {
      const updContact = Object.assign({}, contact)
      updContact.starred = !updContact.starred
      this.selectedContact.starred = updContact.starred
      this.toggleStarredContact(updContact)
    },
    getTeams () {
      return this.selectedContact.teams.filter(team => this.teams.find(t => t.id === team.team_id))
    },
    isModuleEnabled (key) {
      if (this.organization && this.organizationModules && this.organizationModules.includes(key)) {
        return true
      }

      return false
    },
    onClickPhone (e) {
      if (typeof window.MCCall === 'function') {
        e.preventDefault()
        e.stopPropagation()

        window.MCCall(`+${this.selectedContact.number}`)
      }
    },
    onBeforeUpdateAssigned () {
      this.previousLastUserId = this.selectedContact.last_user_id
    },
    onUpdateAssigned (selectedUser) {
      if (selectedUser.id === 0) {
        this.unassignContact(this.selectedContact).then(() => {
          this.emitter.emit('updateFunnelStage', this.selectedContact.stage_id) // FIXME: Delete this line and get this info from the socket.
        })

        return
      }

      if (selectedUser.id === -1) {
        if (!this.isModuleEnabled('multi-user')) {
          this.emitter.emit('openModulePopup', 'multi-user')
          this.selectedContact.last_user_id = this.previousLastUserId
          return
        }

        this.toggleNewUserInvite()
        this.selectedContact.last_user_id = this.previousLastUserId
        return
      }

      this.assignContactToUser({ contact: this.selectedContact, user: selectedUser })
    },
    onAddContactTeam (team) {
      if (team.id === 'NEW') {
        if (!this.isModuleEnabled('multi-user')) {
          this.emitter.emit('openModulePopup', 'multi-user')
          this.selectedContactTeam = null
          return
        }

        this.toggleNewTeamOpen()
        this.selectedContactTeam = null
        return
      }

      this.addContactTeam({ contact: this.selectedContact, team: team }).then(() => {
        this.selectedContactTeam = null
      })
    },
    onDeleteContactTeam (team) {
      this.deleteContactTeam({ contact: this.selectedContact, team: team })
    },
    onDeleteContact (contactId) {
      if (confirm(this.$t('messages.crm.delete_contact_confirm', { name: this.selectedContact.name }))) {
        this.deleteContact(this.selectedContact)
        this.$toast.open({
          message: this.$t('form.deleted')
        })
        this.emitter.emit('closeContactOverlay')
      }
    },
    onContentEditable () {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.updateContact(this.selectedContact)
      }, 200)
    },
    delContactTag (tag) {
      this.deleteContactTag({ contact: this.selectedContact, tag: tag })
    },
    onSelectAddTag (tag) {
      if (tag.id === 'NEW') {
        this.toggleNewTagOpen()
        this.selectedTag = null
      } else {
        this.addContactTag({ contact: this.selectedContact, tag: tag })
        this.selectedTag = null
      }
    },
    onNotesChange () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.setConversationContactNotes({ contact: this.selectedContact })
      }, 200)
    },
    onClickBack () {
      this.setOpenCRM(false)
    },
    editNotesShow () {
      this.showEditor = true
    },
    showNotesEditor () {
      if (this.showEditor === true) {
        return true
      }

      if (this.selectedContact.notes === null) {
        return false
      }

      if (this.selectedContact.notes.length < 8) {
        return false
      }

      return true
    },
    isRegularChat () {
      return this.selectedContact.user_id === null
    },
    isValidUrl (urlString) {
      const urlPattern = new RegExp('^(https?:\\/\\/)?' + // validate protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
        '(\\#[-a-z\\d_]*)?$', 'i') // validate fragment locator
      return !!urlPattern.test(urlString)
    },
    getCustomFields () {
      const result = {}

      if (this.customFields) {
        for (const cf of this.customFields) {
          result[cf.name] = ''
        }
      }

      if (this.selectedContact && this.selectedContact.customFields) {
        for (const [key, value] of Object.entries(this.selectedContact.customFields)) {
          result[key] = value
        }
      }

      return result
    },
    onContentEditableCustomField (event) {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        const customFields = this.selectedContact.customFields || {}
        customFields[event.target.getAttribute('data-key')] = event.target.value

        this.selectedContact.customFields = customFields

        this.updateContact(this.selectedContact)
      }, 200)
    },
    getCustomFieldName (key) {
      const words = key.replaceAll('_', ' ').split(' ')
      return words.map((word) => {
        if (!word || word === '') {
          return ''
        }

        return word[0].toUpperCase() + word.substring(1)
      }).join(' ')
    },
    onUnselectStage () {
      this.previousStage = this.selectedContact.stage_id
    },
    onChangeStage () {
      const selected = this.stagesList.find(el => el.id === this.selectedContact.stage_id)

      if (selected && selected.id === '0') {
        this.deleteContactFromFunnels({
          contactId: this.selectedContact.id
        }).then(() => {
          this.emitter.emit('updateFunnelStage', this.previousStage)
        })
      } else {
        this.updateFunnelStageContact({
          contactId: this.selectedContact.id,
          funnelId: selected.funnelId,
          stageId: selected.id
        }).then(() => {
          this.emitter.emit('updateFunnelStage', this.previousStage)
          this.emitter.emit('updateFunnelStage', selected.id)
        })
      }
    },
    refreshTags () {
      this.fetchTags('').then(() => {
        this.tagList = this.tags.slice()

        this.tagList.push({
          id: 'NEW',
          name: this.$t('settings.tags.button_create')
        })
      })
    },
    refreshTeams () {
      this.fetchTeams().then(() => {
        this.teamList = this.teams.slice()

        this.teamList.push({
          id: 'NEW',
          name: this.$t('settings.teams.button_create')
        })
      })
    },
    onClickExportConversation () {
      this.emitter.emit('openExportConversation', this.selectedContact.id)
    }
  },
  mounted () {
    this.emitter.on('crmRefreshTags', this.refreshTags)
    this.emitter.on('crmRefreshTeams', this.refreshTeams)
  },
  unmounted () {
    this.emitter.off('crmRefreshTags')
    this.emitter.off('crmRefreshTeams')
  },
  async created () {
    // TAGS
    if (this.tags.length === 0) {
      this.fetchTags('').then(() => {
        this.tagList = this.tags.slice()

        this.tagList.push({
          id: 'NEW',
          name: this.$t('settings.tags.button_create')
        })
      })
    } else {
      this.tagList = this.tags.slice()

      this.tagList.push({
        id: 'NEW',
        name: this.$t('settings.tags.button_create')
      })
    }

    // TEAMS
    if (this.teams.length === 0) {
      this.fetchTeams().then(() => {
        this.teamList = this.teams.slice()

        this.teamList.push({
          id: 'NEW',
          name: this.$t('settings.teams.button_create')
        })
      })
    } else {
      this.teamList = this.teams.slice()

      this.teamList.push({
        id: 'NEW',
        name: this.$t('settings.teams.button_create')
      })
    }

    // FUNNELS
    let funnels = this.funnels
    if (this.funnels === null || this.funnels.length === 0) {
      funnels = await this.fetchFunnels()
    }

    this.stagesList = []
    for (const funnel of funnels) {
      this.stagesList.push({
        id: 'notSelectable',
        funnelId: null,
        name: `${funnel.name}`

      })
      for (const stage of funnel.stages) {
        this.stagesList.push({
          id: stage.id,
          funnelId: funnel.id,
          name: `${stage.name}`
        })
      }
    }

    this.stagesList.push({
      id: '0',
      funnelId: 'notSelectable',
      name: ' '
    })

    this.stagesList.push({
      id: '0',
      funnelId: null,
      name: '🚫 ' + this.$t('settings.funnels.delete.out_of_funnel')
    })
  },
  watch: {
    selectedContact () {
      this.showEditor = false
    }
  }
}
</script>

<style scoped>
>>> {
  --vs-state-disabled-color: #111b21;
  --vs-state-disabled-bg: #e9ecef;
}

.tag-bubble-color {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 7px;
  margin-top: 5px;
}

.placeholder {
  color:#6F6F70;
}

aside {
  top: 67px;
  right: 0px;
  width: 250px;
  background: white;
  position: fixed;
  border-left: 1px solid #EDEFF1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: calc(100% - 67px);
}

.importantAlert aside, .unpaidInvoiceAlert aside, .mustMigrate aside {
  top: 127px;
  height: calc(100% - 60px);
}

.mobile aside {
  display: none;
}

.mobile aside.openCRM {
  display: block;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.back-button {
  display: none;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 18px;
  width: 50px;
  cursor: pointer;
}

.mobile .back-button {
  display: block;
}

aside .content-editable {
  width: 175px;
}

aside .content-editable input {
  border: 1px solid transparent;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  text-overflow: ellipsis;
}

aside .content-editable.crm-contact-email input {
  font-size: 14px;
}

aside .content-editable:after {
  display: none;
  position: absolute;
  content: "\f044";
  font: normal normal normal 14px/1 'Font Awesome 5 Free';
  right: -5px;
  color: #667781;
  top: 4px;
  z-index: 1;
}

aside .content-editable:hover input {
  border: 1px solid #667781;
  border-radius: 4px;
}

aside .content-editable:hover:after {
  display: block;
}

aside div.phone,
.crm-contact-email {
  margin-top: 10px;
  font-size: 14px;
}

aside .group {
  padding: 10px 15px;
  border-bottom: 1px solid #EDEFF1;
}

aside .group-editor {
  border-bottom: 1px solid #EDEFF1;
}

aside div {
  position: relative;
}

aside .phone {
  width: 90%;
  padding-left: 28px;
}

aside .call,
aside .email,
aside .user-circle {
  position: absolute;
  left: 18px;
  font-size: 14px;
  top: 44px;
}

aside .email {
  top: 73px;
}

aside .user-circle {
  top: 14px;
}

aside .call a {
  color: #111b21;
}

aside .send-email {
  position: absolute;
  right: 20px;
  font-size: 14px;
  cursor: pointer;
  bottom: 17px;
  color: #111b21;
  top: 70px;
}

h4 {
  padding-top: 0;
  margin-top: 0;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  width: 100%;
  padding-left: 27px;
}

.tags .tag {
  border-radius: 7.5px;
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
}

.tags .tag i {
  color: rgba(60,60,60,0.4);
  cursor: pointer;
}

.contact-locked {
  width: 100%;
  background-color: white;
}

.group.custom-fields {
  overflow-x: hidden;
  overflow-y: scroll;
}

.group.custom-fields button,
.group.export button {
  bottom: unset;
  width: 100%;
  color: #4285f4;
  background-color: #ffffff;
}

.group.custom-fields button:hover,
.group.export button:hover {
  color: #4285f4;
  background-color: #ffffff;
}

.custom-field.content-editable {
  margin-bottom: 5px;
  width: 200px;
}

.custom-field.content-editable label {
  font-size: 14px;
  font-weight: 500;
}

.custom-field.content-editable input {
  font-size: 14px;
  width: 195px;
  margin-left: -2px;
}

.custom-field.content-editable:after {
  top: 20px;
  right: 5px;
  font-size: 12px;
}

.custom-field.content-editable .external {
  position: absolute;
  right: -20px;
  font-size: 14px;
  cursor: pointer;
  bottom: 1px;
  color: #111b21;
}

.contact-events {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 35px !important;
}

.hidePipelines .pipelines {
  display: none;
}

.message-star {
  position: absolute;
  left: 16px;
  font-size: 15px;
  top: 14px;
  color: #CFD0D0;
  cursor: pointer;
}

.message-star:hover {
  color: #959495;
  cursor: pointer;
}

.message-star.starred {
  color: #F3C967;
  cursor: pointer;
}

</style>

<style>
/*.quillWrapper {*/
/*  height: 400px;*/
/*}*/

.noclear .vs__clear {
  display: none;
}

.crm .vs__selected-options {
  flex-wrap: nowrap;
}

.crm .vs__selected-options .vs__selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 145px;
}

.quillWrapper .ql-toolbar,
.quillWrapper .ql-container {
  border: 0 !important;
}

.quillWrapper .ql-container {
  height: 150px;
}

.quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin-bottom: 3px;
}

.quillWrapper .ql-snow.ql-toolbar button svg {
  height: 15px !important;
}

.quillWrapper .ql-toolbar.ql-snow {
  border: 0;
}

.quillWrapper .ql-editor {
  font-size: 14px;
  min-height: 150px;
}

.ql-editor:focus {
  background: rgba(242, 245, 245, 0.8);
}

.vs__search::-webkit-input-placeholder{color: rgba(60,60,60,.5) !important;}
.vs__search::-moz-placeholder{color: rgba(60,60,60,.5) !important;}
.vs__search:-ms-input-placeholder{color: rgba(60,60,60,.5) !important;}
.vs__search::-ms-input-placeholder{color: rgba(60,60,60,.5) !important;}
.vs__search::placeholder{color: rgba(60,60,60,.5) !important;}

aside .group select {
  display: inline;
  width: calc(100% - 27px);
  padding: 6px 0px;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  margin-left: 12px;
}

.pipelines .vs__dropdown-option--disabled{
  font-weight: 700;
  font-size: 12px;
  background-color: rgba(242, 245, 245, 1);
  color: #111b21;
  padding-bottom: 3px;
}

.pipelines .vs__dropdown-option--selected.vs__dropdown-option--disabled{
  font-size: 14px;
  background-color: #ffffff;
  color: lightgrey;
}

</style>
