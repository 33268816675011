<template>
  <div class="menu-collapse">
    <aside style="overflow: hidden">
      <div class="sidebar-scrollable">
        <ul class="main-menu">
          <router-link class="sidebar-home" v-if="loggedUser && loggedUser.canSeeSettings" to="/" v-tooltip.right="{ content: $t('sidebar.home') }">
            <li :class="[['Home', 'HomeSector', 'HomePartner', 'HomeCategory', 'FeatureRedirect', 'FeatureRedirectLocale','IntegrationRedirect','IntegrationRedirectLocale','Integrations','IntegrationsFilter'].includes(this.$route.name) ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-home"></i></div>
            </li>
          </router-link>
          <div v-if="showModule('pipeline')" @click="onClickModule('pipeline', onClickFetchPipelines)" :class="{ disabled: !isModuleEnabled('pipeline')}" class="module sidebar-pipelines" v-tooltip.right="{ content: $t('sidebar.pipelines') }">
            <li :class="[['PipelinesAll','PipelineFiltered','PipelineFilteredAssigned','PipelineRedirect'].includes(this.$route.name) ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-filter"></i></div>
              <div v-if="counters && getFunnelCounterUnassined()" class="unread-badge unread-badge-important unread-badge-small"></div>
              <div v-if="counters && getFunnelCounter() > 0" class="unread-badge">{{ getFunnelCounter() }}</div>
            </li>
          </div>
          <router-link :to="getChatsUrl()" class="chats-unassigned sidebar-chats" @click="onClickChats()" v-tooltip.right="{ content: $t('sidebar.chats') }">
            <li :class="[['Chats', 'ChatsTeam', 'ChatsAll', 'ChatsMy', 'ChatsStarred', 'Conversation', 'ConversationMessage', 'ChatsTag'].includes(this.$route.name) && (Number.isInteger(this.filter) && parseInt(this.filter) !== 7) ? 'active' : '']">
              <div class="sidebar-icon"><i class="far fa-comment"></i></div>
              <div v-if="counters && (counters.unassigned > 0 || counters.all > 0 || counters.starred[loggedUser.id] > 0 || counters.assigned[loggedUser.id] > 0)" class="unread-badge unread-badge-important unread-badge-small"></div>
              <div v-if="counters && (counters.all > 0)" class="unread-badge">{{ counters.all }}</div>
            </li>
          </router-link>
          <div v-if="showModule('calendar')" @click="onClickModule('calendar', onClickFetchContacts, [6])"  class="module" v-tooltip.right="{ content: $t('sidebar.your_reminders') }">
            <li :class="{ active: ['Reminders','Calendar','MyCalendar'].includes(this.$route.name) }">
              <div class="sidebar-icon"><i class="far fa-calendar"></i></div>
              <div v-if="loggedUser && counters && counters.reminders[loggedUser.id] > 0 && counters.reminders[loggedUser.id] <= 99" class="unread-badge">{{ counters.reminders[loggedUser.id] }}</div>
              <div v-if="loggedUser && counters && counters.reminders[loggedUser.id] > 0 && counters.reminders[loggedUser.id] > 99" class="unread-badge">+99</div>
            </li>
          </div>
          <div v-if="showModule('multi-user')" @click="onClickModule('multi-user', onClickFetchContacts, [4])" :class="{ disabled: !isModuleEnabled('multi-user')}" class="module" v-tooltip.right="{ content: $t('sidebar.your_mentions') }">
            <li :class="[parseInt(this.filter) === 4 && !['PipelinesAll','PipelineFiltered','PipelineFilteredAssigned','PipelineRedirect'].includes(this.$route.name) ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-at"></i></div>
              <div v-if="loggedUser && counters && counters.mentions[loggedUser.id] > 0 && counters.mentions[loggedUser.id] <= 99" class="unread-badge">{{ counters.mentions[loggedUser.id] }}</div>
              <div v-if="loggedUser && counters && counters.mentions[loggedUser.id] > 0 && counters.mentions[loggedUser.id] > 99" class="unread-badge">+99</div>
            </li>
          </div>
          <div v-if="showModule('internal-chat')" @click="onClickModule('internal-chat', onClickFetchContacts, [7])" :class="{ disabled: !isModuleEnabled('internal-chat')}" class="module" v-tooltip.right="{ content: $t('sidebar.internal') }">
            <li :class="[Number.isInteger(this.filter) && parseInt(this.filter) === 7 ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-th-large"></i></div>
              <div v-if="loggedUser && counters && counters.internal && counters.internal[loggedUser.id] > 0 && counters.internal[loggedUser.id] <= 99" class="unread-badge">{{ counters.internal[loggedUser.id] }}</div>
              <div v-if="loggedUser && counters && counters.internal && counters.internal[loggedUser.id] > 0 && counters.internal[loggedUser.id] > 99" class="unread-badge">+99</div>
            </li>
          </div>
        </ul>

        <ul class="top-separate">
          <!-- FIXME: v-if="organization && organization.number && (organization.id === '1' || organization.id === '3' || organization.id === '64917db6-01cb-4bb6-a2f6-fe484904e24e' || organization.id === '2d14e289-0bc5-4d6b-8fd9-e9dcf6e99e80')" -->
          <div v-if="showModule('emailbox')" @click="onClickModule('emailbox', onClickFetchEmails)" :class="{ disabled: !isModuleEnabled('emailbox')}" class="module" v-tooltip.right="{ content: $t('sidebar.your_emails_tooltip', { number: organization.number.replace('+','')}) }">
            <li :class="[this.$route.name === 'Emails' ? 'active' : '']">
              <div class="sidebar-icon"><i class="far fa-envelope"></i></div>
              <div v-if="loggedUser && counters && counters.emails > 0 && counters.emails <= 99" class="unread-badge unread-badge-important">{{ counters.emails }}</div>
              <div v-if="loggedUser && counters && counters.emails > 0 && counters.emails > 99" class="unread-badge unread-badge-important">+99</div>
            </li>
          </div>
          <div v-if="showModule('campaigns')" @click="onClickModule('campaigns', onClickCampaigns)" :class="{ disabled: !isModuleEnabled('campaigns')}" class="module" v-tooltip.right="{ content: $t('campaign.campaigns') }">
            <li :class="[this.$route.name === 'Campaigns' ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-bullhorn"></i></div>
            </li>
          </div>
        </ul>
      </div>
      <div class="sidebar-bottom-fixed">
        <ul v-if="loggedUser && loggedUser.canSeeSettings" style="padding-top:20px !important; border-top: 1px solid #EDEFF1;" class="top-separate">
          <router-link v-if="organization && organization.watype === 'api'" to="/stats" v-tooltip.right="{ content: $t('sidebar.stats') }">
            <li :class="[this.$route.name === 'Stats' ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-chart-line"></i></div>
            </li>
          </router-link>
          <router-link to="/settings" v-tooltip.right="{ content: $t('settings.title') }">
            <li :class="[this.$route.name === 'Settings' || this.$route.name === 'SettingsSection' ? 'active' : '']">
              <div class="sidebar-icon"><i class="fas fa-cog"></i></div>
            </li>
          </router-link>
          <router-link id="support-chat" v-if="organization && organization.watype !== null && organization.id !== '1'" to="/chats" @click="onClickSupport" v-tooltip.right="{ content: $t('sidebar.support') }">
            <li class="help">
              <div class="sidebar-icon"><span class="emoji">Ⓜ️</span></div>
            </li>
          </router-link>
          <a id="support-chat-mailto" v-if="organization && organization.watype === null && organization.id !== '1'"  href="mailto:support@manycontacts.com" target="_blank" v-tooltip.right="{ content: $t('sidebar.support') }">
            <li class="help">
              <div class="sidebar-icon"><span class="emoji">Ⓜ️</span></div>
            </li>
          </a>
          <a class="menu-help" :href="$t('help.links.main')" target="_blank" v-tooltip.right="{ content: $t('help.title') }">
            <li class="help">
              <div class="sidebar-icon"><i class="help fab fa-youtube"></i></div>
            </li>
          </a>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Sidebar',
  computed: mapGetters([
    'counters',
    'loggedUser',
    'filter',
    'section',
    'locale',
    'organization',
    'allContacts',
    'modules',
    'organizationModules'
  ]),
  methods: {
    ...mapActions([
      'fetchContacts',
      'fetchCounters',
      'fetchFunnels',
      'clearSearch',
      'setPage',
      'setFilterView',
      'fetchInboundEmails',
      'getSupportContact',
      'fetchConversation',
      'setSelectedEmail',
      'clearSelectedContact',
      'fetchMentions',
      'fetchTeamContacts'
    ]),
    showModule (key) {
      if (!this.loggedUser) {
        return false
      }

      if (!this.organization) {
        return
      }

      if (!this.modules) {
        return false
      }

      const module = this.modules.find(module => module.key === key)
      if (!module) {
        return false
      }

      if (module.key === 'campaigns' && !this.loggedUser.canSeeCampaigns) {
        return false
      }

      if (!module.type) {
        return this.isModuleEnabled(module.key)
      }

      if (module.type && module.type === this.organization.watype) {
        return this.isModuleEnabled(module.key)
      }

      return false
    },
    isModuleEnabled (key) {
      if (this.organization && this.organizationModules && this.organizationModules.includes(key)) {
        return true
      }

      return false
    },
    onClickModule (key, callback, params, redirectUrl) {
      if (this.isModuleEnabled(key)) {
        callback.apply(this, params)
      } else {
        this.emitter.emit('openModulePopup', key, redirectUrl)
      }
    },
    onClickCampaigns () {
      this.$router.push('/campaigns')
    },
    async onClickFetchContacts (filter) {
      if (filter === 6) {
        await this.$router.push(this.getCalendarUrl())
      } else if (filter === 4) {
        await this.$router.push('/mentions')
      } else if (filter === 7) {
        await this.$router.push('/internal')
      }

      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchContacts({ filter: filter })
    },
    async onClickFetchEmails () {
      await this.$router.push('/emails')

      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()
      this.clearSelectedContact()
      this.setSelectedEmail(null)

      this.setFilterView(null)
      this.fetchInboundEmails()
    },
    onClickFetchPipelines () {
      this.$router.push(this.getFunnelUrl()).then(() => {
        this.$emit('clearSearch')
        this.emitter.emit('resetFilters')
        this.clearSearch()
        this.clearSelectedContact()
        this.setSelectedEmail(null)
        this.setFilterView(null)

        this.fetchFunnels()
      })
    },
    onClickSupport () {
      this.getSupportContact().then(contact => {
        this.fetchContacts({ filter: 1, page: 0 }).then(() => {
          this.fetchConversation({ contact: contact, messageId: null }).then(() => {
            this.emitter.emit('onDiscardFile')
          })
        })
      })
    },
    getCalendarUrl () {
      if (localStorage.calendarUrl) {
        return localStorage.calendarUrl
      }

      return '/calendar'
    },
    getChatsUrl () {
      if (localStorage.chatsUrl) {
        return localStorage.chatsUrl
      }

      return '/chats'
    },
    getChatsFilter () {
      if (localStorage.chatsFilter) {
        return parseInt(localStorage.chatsFilter)
      }

      return 0
    },
    getFunnelUrl () {
      if (localStorage.funnelUrl) {
        return localStorage.funnelUrl
      }

      return '/pipelines'
    },
    getFunnelCounter () {
      if (!localStorage.funnelUrl) {
        return 0
      }

      if (!this.counters) {
        return 0
      }

      if (localStorage.funnelUrl.split('?').length === 1) {
        return this.counters.all
      }

      const url = new URLSearchParams(localStorage.funnelUrl.split('?')[1])

      let counter = 0
      url.forEach((value, key) => {
        if (key !== 'filter[]') {
          return
        }

        if (value === 'all') {
          counter = this.counters.all
          return
        }

        if (this.counters.assigned[value]) {
          counter += this.counters.assigned[value]
          return
        }

        if (this.counters.teams[value]) {
          counter += this.counters.teams[value]
        }
      })

      return counter
    },
    getFunnelCounterUnassined () {
      if (!localStorage.funnelUrl) {
        return false
      }

      if (!this.counters) {
        return false
      }

      if (localStorage.funnelUrl.search('assigned') !== -1) {
        return false
      }

      if (this.counters.unassigned > 0) {
        return true
      }

      return false
    },
    onClickFetchMentions (filter) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.fetchMentions(filter)
    },
    onClickFetchTeamContacts (team) {
      this.$emit('clearSearch')
      this.emitter.emit('resetFilters')
      this.clearSearch()

      this.setPage(0)

      this.fetchTeamContacts(team.id)
    },
    onClickChats () {
      if (localStorage.chatsTeam !== undefined && localStorage.chatsTeam !== null && localStorage.chatsTeam !== 'null') {
        this.onClickFetchTeamContacts({ id: localStorage.chatsTeam })
      } else {
        this.onClickFetchContacts(this.getChatsFilter())
      }
    }
  },
  created () {
    const tokenSession = sessionStorage.getItem('accessTokensSession')
    if (!tokenSession) {
      return
    }

    setTimeout(() => {
      this.fetchCounters()
    }, 1000)
  }
}
</script>

<style scoped>
  .mobile aside {
    display: none;
  }

  aside {
    top: 66px;
    margin-left: -200px;
    left: 200px;
    width: 200px;
    position: fixed;
    height: calc(100% - 66px);
    overflow-y: auto;
    text-align: left;
    border-right: 1px solid #EDEFF1;
  }

  .importantAlert aside, .unpaidInvoiceAlert aside, .mustMigrate aside {
    top: 126px;
    height: calc(100% - 126px);
  }

  .menu-collapse aside {
    top: 66px;
    margin-left: -70px;
    left: 70px;
    width: 70px;
  }

  .importantAlert .menu-collapse aside, .unpaidInvoiceAlert .menu-collapse aside, .mustMigrate .menu-collapse aside {
    top: 126px;
  }

  .menu-collapse ul {
    padding-left: 10px;
    font-size: 20px;
  }

  .menu-collapse ul li {
    padding: 5px 0 0 4px;
    min-height: 43px;
    border-radius: 100%;
    width: 44px;
    text-align: center;
    margin-bottom: 2px;
    line-height: 40px;
  }

  .menu-collapse ul li.team {
    padding-left: 4px;
  }

  .menu-collapse ul li.team .sidebar-icon {
    display: block;
  }

  .menu-collapse ul li span {

  }

  .menu-collapse ul li .sidebar-icon {
    width: 40px;
    float: left;
  }

  aside .new-chat {
    -webkit-border-radius: 24px;
    border-radius: 24px;
    color: #5299DC;
    height: 48px;
    margin: 16px 0 26px 20px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    font-size: 16px;
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff;
    background-image: none;
    display: inline-flex;
    font-weight: 500;
    overflow: hidden;
    padding: 0 24px 0 24px;
    cursor: pointer;
  }
  aside .new-chat:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.30), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    background-color: #fafafb;
  }

  aside .new-chat i {
    margin-right: 10px;
  }

  .menu-collapse aside .new-chat {
    width: 34px;
    min-width: 34px;
    margin-left: 0px;
    padding: 0 0 0 14px;
    font-size: 23px;
    margin-left: 11px;
  }

  .menu-collapse aside .new-chat i{
    margin-right: 30px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px 10px 0 0;
  }

  ul.top-separate {
    margin-top: 25px;
  }

  ul li {
    position: relative;
    padding: 5px 0px 5px 25px;
    -webkit-border-top-right-radius: 50px;
    -webkit-border-bottom-right-radius: 50px;
    -moz-border-radius-topright: 50px;
    -moz-border-radius-bottomright: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .module.disabled {
    opacity: 0.08;
  }

  ul li .sidebar-icon {
    width: 25px;
    float: left;
  }

  ul li .unread-badge {
    margin-right: 8px;
  }

  ul li:hover {
    background-color: #F1F3F4;
  }

  ul li.active {
    background-color: #4285f45e;
    color: #4285f4;
    font-weight: bold;
  }

  ul li.team {
    padding-left: 50px;
  }

  ul li.team .sidebar-icon {
    display: none;
  }

  ul li.team .team-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 105px;
  }

  ul a {
    color: #111b21;
    text-decoration: none;
  }

  .unread-badge {
    box-sizing: border-box;
    min-width: 21px;
    border-radius: 20px;
    background-color: transparent;
    color: #4285f4;
    border: 1px solid #4285f4;
    background-color: #ffffff;
    font-weight: bold;
    text-align: center;
    float: right;
    font-size: 10px;
    height: 20px;
    line-height: 18px;
    padding: 0 1px;
    position: absolute;
    left: 23px;
    top: 8px;
  }

  .unread-badge.unread-badge-important {
    background-color: #4285f4;
    color: #ffffff;
  }

  .unread-badge.unread-badge-small {
    height: 10px;
    width: 10px;
    padding: 0;
    left: 10px;
    right: unset;
    top: 14px;
    min-width: 10px;
  }

  .menu-collapse .group-settings {
    bottom: 15px;
  }

  .group-settings {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .group-settings a {
    color: #111b21;
  }

  .menu-collapse .group-settings {
    height: 37px;
  }

  .button-settings {
    position: absolute;
    left: 25px;
    top: 15px;
    cursor: pointer;
  }

  .menu-collapse .button-settings {
    font-size: 20px;
    bottom: -22px;
    top: unset;
  }

  .emoji {
    font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
  }

  .pro {
    color: #111b21;
    background-color: #fab1a0;
    border-radius: 3.5px;
    font-size: 7px;
    font-weight: 600;
    padding: 3px;
    box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
    position: absolute;
    bottom: 8px;
    left: 28px;
    height: 7px;
    line-height: 7px;
  }

  .new {
    background-color: #55efc4;
  }

  .sidebar-scrollable {
    height: calc(100vh - 67px - 250px); /* 250px = heigth of the .sidebar-botton-fixed */
    overflow: scroll;
    padding-top: 10px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .sidebar-scrollable::-webkit-scrollbar {
    display: none;
  }

  .sidebar-bottom-fixed {
    position: absolute;
    bottom: 20px;
  }
</style>
