<template>
  <div class="content">
    <List />
    <Panel />
  </div>
</template>

<script>
import List from './list/List'
import Panel from './chat/Panel'

export default {
  name: 'Content',
  components: {
    List,
    Panel
  }
}
</script>

<style scoped>
  .content {
    width: calc(100% - 250px - 200px);
    margin-left: 271px;
  }

  .hideSidebarChats .content {
    width: calc(100% - 250px);
    margin-left: 71px;
  }

  .mobile .content, .mobile .hideSidebarChats .content {
    width: 100%;
    margin-left: 0px;
  }
</style>
