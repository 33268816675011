<template>
  <div class="back-button" @click="onClickBack">
    <i class="fas fa-chevron-left"></i>
  </div>
  <div class="info" @click="onClickInfo">
    <i class="fas fa-info-circle"></i>
  </div>
  <div class="contact">
    <div class="contact-avatar" :class="{ group: selectedContact.isGroup === 1 }" @click="openAvatar" v-tooltip.top="{ content: $t('messages.body.preview') }">
      <img v-if="selectedContact.avatar" :src="selectedContact.avatar" onerror="this.style.display='none'"/>
      <img v-if="organization && organization.watype=== 'api' && !selectedContact.avatar && selectedContact.number" :src="getAvatarUrl(selectedContact)" onerror="this.style.display='none'"/>
    </div>
    <div class="content-editable contact-name">
      <input v-if="this.isRegularChat()" @input="onContentEditable" type="text" v-model="selectedContact.name" v-tooltip.top="{ content: $t('clickToEdit') }">
      <div v-if="!this.isRegularChat()">{{ selectedContact.name }}</div>
    </div>
    <div v-if="showDropdown && this.isRegularChat()" class="contact-assingto">
      <div class="btn-group" :class="[teams.length === 0 ? 'only-users': '']">
        <li class="dropdown-toggle">
          <i v-if="this.selectedContact.last_user_id && this.organizationUsersToAssign" class="fas selected-item" :class="{ 'fa-user': !isAiUser(this.selectedContact.last_user_id), 'fa-robot': isAiUser(this.selectedContact.last_user_id) }"></i>

          <div class="user-name" v-if="organizationUsersToAssign && organizationUsersToAssign.length > 0 && this.selectedContact.last_user_id && this.selectedContact.last_user_id !== 0">{{ organizationUsersToAssign.find(el => el.id === this.selectedContact.last_user_id).name }}</div>
          <span style="padding-left: 9px;" v-if="this.selectedContact.teams.length === 0 && (!this.selectedContact.last_user_id || this.selectedContact.last_user_id === 0)">{{ $t('messages.header.assignedToNobody') }}</span>

          <span v-if="teams.length > 0 && this.selectedContact.teams.length > 0">
            <i v-if="this.selectedContact.teams.length > 0" class="fas fa-users selected-item"></i>
            <span class="teamin" v-for="(team, index) in getTeams()" :key="team.team_id">
              {{ teams.find(el => el.id === team.team_id).name }}
              <span v-if="(index + 1) !== this.selectedContact.teams.length" class="comma">,</span>&nbsp;
            </span>
          </span>

        </li>
      </div>
    </div>
    <div v-if="showDropdown && this.isRegularChat()" class="header-actions">
      <div v-if="counters && !counters.grouped[selectedContact.id] && contactMessages && contactMessages.length > 0" class="contact-mark-as-unread" @click="onClickMaskAsUnread" v-tooltip.top="{ content: $t('messages.header.markAsUnread') }"><div>1</div></div>
      <div v-if="selectedContact.open" class="contact-close" @click="onClickClose" v-tooltip.top="{ content: $t('messages.header.close') }"><i class="fas fa-archive"></i></div>
      <div v-if="selectedContact.open && selectedContact.last_user_id" class="contact-unassign" @click="onClickCloseAndUnassign" v-tooltip.top="{ content: $t('messages.header.closeAndUnassign') }"><i class="fas fa-user-times"></i></div>
      <div v-if="!selectedContact.open" class="contact-open" @click="onClickOpen" v-tooltip.top="{ content: $t('messages.header.open') }"><i class="far fa-folder-open"></i></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Header',
  computed: mapGetters([
    'selectedContact',
    'organizationUsersToAssign',
    'showDropdown',
    'teams',
    'counters',
    'contactMessages',
    'organization'
  ]),
  data () {
    return {
      timer: null
    }
  },
  methods: {
    ...mapActions([
      'fetchOrganizationUsers',
      'assignContactToUser',
      'unassignContact',
      'closeContact',
      'openContact',
      'updateContact',
      'addContactTeam',
      'deleteContactTeam',
      'clearSelectedContact',
      'setOpenCRM',
      'markAsUnread',
      'toggleAvatarImage'
    ]),
    getAvatarUrl (contact) {
      if (!contact.number) {
        return
      }

      let number = contact.number
      number = number.replace('+', '')
      number = number.replaceAll(' ', '')

      return `https://avatar.manycontacts.com/${this.organization.id}/${number}.jpg`
    },
    getTeams () {
      return this.selectedContact.teams.filter(team => this.teams.find(t => t.id === team.team_id))
    },
    openAvatar () {
      this.toggleAvatarImage()
    },
    onUpdateAssigned (selectedUser) {
      if (selectedUser.id === 0) {
        this.unassignContact(this.selectedContact)

        return
      }

      this.assignContactToUser({ contact: this.selectedContact, user: selectedUser })
    },
    onAddContactTeam (team) {
      this.addContactTeam({ contact: this.selectedContact, team: team })
    },
    onDeleteContactTeam (team) {
      this.deleteContactTeam({ contact: this.selectedContact, team: team })
    },
    onClickClose () {
      let selectNext = true
      if (['PipelinesAll', 'PipelineFiltered', 'PipelineFilteredAssigned', 'PipelineRedirect'].includes(this.$route.name)) {
        selectNext = false
      }

      this.emitter.emit('deleteFunnelArchivedContact', this.selectedContact)
      this.closeContact({ contact: this.selectedContact, selectNext: selectNext })
      this.emitter.emit('closeContactOverlay')
    },
    onClickOpen () {
      this.openContact(this.selectedContact)
      const messageInputEl = document.getElementById('message-input')
      if (messageInputEl) {
        messageInputEl.focus()
      }
    },
    onClickCloseAndUnassign () {
      let selectNext = true
      if (['PipelinesAll', 'PipelineFiltered', 'PipelineFilteredAssigned', 'PipelineRedirect'].includes(this.$route.name)) {
        selectNext = false
      }

      this.unassignContact(this.selectedContact)
      this.emitter.emit('deleteFunnelArchivedContact', this.selectedContact)
      this.closeContact({ contact: this.selectedContact, selectNext: selectNext })
      this.emitter.emit('closeContactOverlay')
    },
    onClickMaskAsUnread () {
      this.markAsUnread(this.selectedContact)
    },
    onContentEditable () {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.updateContact(this.selectedContact)
      }, 500)
    },
    onClickBack () {
      this.clearSelectedContact()
      this.setOpenCRM(false)
      window.history.back()
    },
    onClickInfo () {
      this.setOpenCRM(true)
    },
    isRegularChat () {
      return this.selectedContact.user_id === null
    },
    isAiUser (userId) {
      const user = this.organizationUsersToAssign.find(user => user.id === userId)

      if (!user) {
        return false
      }

      if (user.ai) {
        return true
      }

      return false
    }
  }
}
</script>

<style>
.contact .contact-assingto ul.dropdown-menu > li:first-of-type {
  border-bottom: 1px solid #EDEFF1 !important;
}
</style>

<style scoped>

  .contact {
    position: absolute;
    top: 0;
    height: 50px;
    width: 100%;
    background-color: rgba(242, 245, 245, 1);
    border-bottom: 1px solid #E3E8EA;
    z-index: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact .contact-avatar {
    position: absolute;
    top: 5px;
    left: 10px;
    width: 40px;
    height: 40px;
    background: url(../../../assets/individual.png) no-repeat center / contain;
    background-size: 35px;
    cursor:pointer;
  }

  .contact .contact-avatar.group {
    background: url(../../../assets/group.png) no-repeat center / contain;
    background-size: 35px;
  }

  .contact .contact-avatar img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
  }

  .contact .contact-name {
    position: absolute;
    top: 5px;
    left: 56px;
    width: calc(100% - 175px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .contact .contact-name:after {
    display: none;
    position: absolute;
    content: "\f044";
    font: normal normal normal 14px/1 'Font Awesome 5 Free';
    right: -20px;
    color: #667781;
    top: 2px;
    z-index: 1;
  }

  .contact .contact-name:hover:after {
    display: block;
  }

  .contact .contact-name > div {
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
    text-overflow: ellipsis;
    background-color: transparent;
    width: 100%;
    padding: 2px 2px;
  }

  .contact .contact-assingto {
    position: absolute;
    top: 27px;
    left: 50px;
    font-size: 14px;
  }

  .mobile .contact-assingto > span {
    display: none;
  }

  .contact .header-actions {
    position: absolute;
    right: 0px;
    text-align: right;
    z-index: 1;
    overflow: hidden;
    border-radius: 4px;
    background-color: rgba(242, 245, 245, 1);
    width: 100px;

  }

  .contact .contact-mark-as-unread,
  .contact .contact-close,
  .contact .contact-unassign,
  .contact .contact-open {
    margin-top: 10px;
    margin-right: 4px;
    position: relative;
    padding: 5px 6px;
    border-radius: 4px;
    color: #667781;
    cursor: pointer;
    float: left;
  }

  .contact .contact-close:hover,
  .contact .contact-unassign:hover {
    background-color: #ff7675;
    color: #ffffff;
    border-color: #ff7675;
  }

  .contact .contact-mark-as-unread:hover,
  .contact .contact-open:hover {
    background-color: #55efc4;
    color: #ffffff;
    border-color: #55efc4;
  }

  .contact .contact-mark-as-unread > div {
    border: 1px solid;
    border-radius: 100%;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    width: 12px;
    margin-top: 2px;
  }

  .my-dropdown-toggle {
    font-size: 14px;
    text-align: left;
  }

  .mobile .my-dropdown-toggle {
    margin-left: -10px;
  }

  ::v-deep .btn-group {
    min-width: unset;
    margin: 0;
    height: unset;
    vertical-align: bottom;
  }

  ::v-deep .dropdown-toggle {
    color: inherit;
    padding: inherit;
    font-weight: inherit;
    background: none;
    min-width: unset;
    margin-bottom: 0;
  }

  ::v-deep .caret {
    top: 8px;
    right: -10px;
    color: #667781;
  }

  .content-editable input {
    border: 1px solid transparent;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
    text-overflow: ellipsis;
    background-color: transparent;
    width: calc(100% - 10px);
  }

  .content-editable:after {
    display: none;
    position: absolute;
    content: "\f044";
    font: normal normal normal 14px/1 'Font Awesome 5 Free';
    right: -5px;
    color: #667781;
    top: 4px;
    z-index: 1;
  }

  .content-editable:hover input {
    border: 1px solid #ccced1;
    border-radius: 4px;
  }

  .content-editable:hover:after {
    display: block;
  }

  .back-button {
    display: none;
    height: 50px;
    position: absolute;
    z-index: 3;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    background-color: rgba(242, 245, 245, 1);
    border-bottom: 1px solid #E3E8EA;
    width: 50px;
    cursor: pointer;
  }

  .mobile .back-button {
    display: block;
  }

  .info {
    display: none;
    left: 50px;
    height: 50px;
    position: absolute;
    z-index: 3;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    background-color: rgba(242, 245, 245, 1);
    border-bottom: 1px solid #E3E8EA;
    width: 50px;
    cursor: pointer;
  }

  .mobile .info {
    display: block;
  }

  .mobile .contact {
    width: calc(100% - 50px);
    left: 50px;
  }

  .contact-assingto i {
    margin-left: 10px;
    margin-right: 5px;
  }

  .contact-assingto i.selected-item {
    margin-left: 10px;
    margin-right: 5px;
  }

  .contact-assingto .user-name {
    max-width: 109px;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
  }

  .contact-assingto .btn-group {
    min-width: 160px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
  }

  .contact-assingto .dropdown-toggle {
    color: #667781;
    min-width: 160px;
    text-transform: none;
    font-weight: 300;
    margin-bottom: 7px;
    border: 0;
    transition: background 0s ease-out;
    float: none;
    box-shadow: none;
    border-radius: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>
