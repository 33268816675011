<template>
  <div v-if="modalOpen" class="overlay" @click="close"></div>
  <div v-if="modalOpen" class="modal">
    <div>
      <h3>{{ $t('campaign.title')}}</h3>
      <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
    </div>
    <div class="content-bulk">
      <div class="left">
        <div class="filters">

          <h3>{{ $t('campaign.new.step1.title') }}</h3>

          <div class="tabs">
            <div v-if="this.organization && this.organization.klaviyo !== null && this.organization.klaviyo !== ''" class="tab" :class="{active: this.tabSelected === 'klaviyo'}" @click="this.tabSelected = 'klaviyo'; this.count = this.countFilter; this.templateVariableValue = 'name';">{{ $t('campaign.new.step1.tabs.klaviyo') }}</div>
            <div class="tab" :class="{active: this.tabSelected === 'filter'}" @click="this.tabSelected = 'filter'; this.count = this.countFilter; this.templateVariableValue = 'name';">{{ $t('campaign.new.step1.tabs.filter') }}</div>
            <div class="tab" :class="{active: this.tabSelected === 'import'}" @click="this.tabSelected = 'import'; this.count = this.countImport; this.templateVariableValue = 'name';">{{ $t('campaign.new.step1.tabs.import') }}</div>
          </div>

          <div v-if="this.tabSelected === 'klaviyo'">
            <div>
              <v-select style="margin-top: 5px" @option:selected="onChangeAudience" v-if="klaviyoAudiences" v-model="this.filterData.selectedAudience" :placeholder="$t('contact.tags_placeholder')" :options="klaviyoAudiences" :getOptionLabel="getKlaviyoLabel"></v-select>
            </div>
            <br><br>

            <div>
              <b>{{ $t('campaign.new.step1.total') }}: {{this.count}}</b>
              <div hidden v-if="this.count > 0" class="search-option button button-small" @click="downloadExcel()">
                <i class="far fa-file-excel"></i> {{ $t('contact.download_excel') }}
              </div>
            </div>
          </div>

          <div v-if="this.tabSelected === 'filter'">
            <div>
              <v-select style="margin-top: 5px" @option:selected="onChangeFilters(null)" @option:deselected="onChangeFilters(null)" v-if="tags" multiple v-model="this.filterData.selectedTags" :placeholder="$t('contact.tags_placeholder')" :options="tags" label="name"></v-select>
              <v-select style="margin-top: 5px" @option:selected="onChangeFilters(null)" @option:deselected="onChangeFilters(null)" v-if="organizationUsers" multiple v-model="this.filterData.selectedUsers" :placeholder="$t('contact.users_placeholder')" :options="organizationUsers" label="name"></v-select>
              <div style="margin: 15px 0">
                <input @change="onChangeFilters(null)" v-model="filterData.onlyClosedConversations" type="checkbox">{{ $t('contact.closed_conversations') }}
                <br>
                <input @change="onChangeFilters(null)" v-model="filterData.onlyUnreadConversations" type="checkbox">{{ $t('contact.unread_conversations') }}
                <br>
                <input @change="onChangeFilters(null)" v-model="filterData.conversationsWithReminders" type="checkbox">{{ $t('contact.reminder_conversations') }}
                <div>
                  <input @change="onChangeFilters(null)" v-model="filterData.conversationsWithScheduled" type="checkbox">{{ $t('event.contact.scheduled_conversations') }}
                </div>
              </div>
            </div>

            <div>
              <b>{{ $t('campaign.new.step1.total') }}: {{this.count}}</b>
              <div hidden v-if="this.count > 0" class="search-option button button-small" @click="downloadExcel()">
                <i class="far fa-file-excel"></i> {{ $t('contact.download_excel') }}
              </div>
            </div>
          </div>

          <div v-if="this.tabSelected === 'import'">
            <p v-html="$t('campaign.new.step1.import')"></p>
            <textarea v-model="importTextareaText" @input="onChangeImportTextarea()" style="height: 150px; margin-bottom: 20px" placeholder="+34666123123;Pepe Gomez&#10;+1555123456;John Smith&#10;+34722012012"></textarea>

            <div>
              <b>{{ $t('campaign.new.step1.total') }}: {{this.count}}</b>
            </div>
          </div>
        </div>
        <br>
        <div class="name form-group">
          <h3>{{ $t('campaign.new.step3.title') }}</h3>
          <input maxlength="250" type="text" id="sr-name" :placeholder="$t('campaign.new.step3.placeholder')" v-model="this.name">
        </div>
        <br>
        <div class="moment">
          <h3>{{ $t('campaign.new.step4.title') }}</h3>
          <div class="form-group">
            <input type="datetime-local" :min="(new Date().toISOString().slice(0,new Date().toISOString().lastIndexOf(':')))" v-model="this.campaignDate">
          </div>
        </div>
      </div>

      <div class="right">
        <div class="template">
          <h3>{{ $t('campaign.new.step2.title') }}  <router-link @click="close()" to="/settings/templates">({{ $t('campaign.new.step2.link') }})</router-link></h3>
          <v-select v-if="templates" v-model="this.selectedTemplate" :placeholder="$t('campaign.new.step2.placeholder')" :options="templates" label="label"></v-select>

          <div class="message-container" id="campaignMessageContainer">
            <div class="message sent" v-if="this.selectedTemplate !== null">
            <span class="data">
              <div v-if="this.selectedTemplate.hasImage && this.templateImageUrl">
                <img @error="imageLoaded = false" @load="onLoadImage" :src="this.templateImageUrl" />
              </div>
               <div v-if="this.selectedTemplate.hasVideo && this.templateImageUrl">
                <video @load="onLoadImage" control style="width: 100%">
                  <source :src="this.templateImageUrl" type="video/mp4">
                </video>
              </div>
               <div v-if="this.selectedTemplate.hasDocument && this.templateImageUrl">
                 <embed :src="this.templateImageUrl" type="application/pdf">
              </div>
              <span class="text">{{ getTemplateText()}}</span>
            </span>
              <div class="metadata">
                <span class="time">{{ $moment(new Date()).format('HH:mm') }}</span>
                <span class="tick">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" id="msg-dblcheck-ack" x="2063" y="2076">
                  <path d="M15.01 3.316l-.478-.372a.365.365 0 0 0-.51.063L8.666 9.88a.32.32 0 0 1-.484.032l-.358-.325a.32.32 0 0 0-.484.032l-.378.48a.418.418 0 0 0 .036.54l1.32 1.267a.32.32 0 0 0 .484-.034l6.272-8.048a.366.366 0 0 0-.064-.512zm-4.1 0l-.478-.372a.365.365 0 0 0-.51.063L4.566 9.88a.32.32 0 0 1-.484.032L1.892 7.77a.366.366 0 0 0-.516.005l-.423.433a.364.364 0 0 0 .006.514l3.255 3.185a.32.32 0 0 0 .484-.033l6.272-8.048a.365.365 0 0 0-.063-.51z" fill="#4fc3f7">
                  </path>
                </svg>
              </span>
              </div>
            </div>
          </div>

          <div v-if="showExample()" class="form-group template-variable">
            <label for="sr-example">{{ $t('campaign.new.step2.variable') }}</label>
            <select style="width: 100%" id="sr-example" v-model="this.templateVariableValue" name="locale">
              <option v-for="option in getTemplateFields()" :key="option" :value="option.id">
                <span v-if="option.id === 'name'"> {{ option.name }}</span>
                <span v-if="option.id !== 'name'"> -- {{ $t('campaign.new.step2.custom_field') }}: {{ getCustomFieldName(option.name) }}</span>
              </option>
            </select>
            <div style="font-size: 10px; margin-top: 10px">{{ $t('campaign.new.step2.disclaimer') }}</div>
          </div>
          <div v-if="this.selectedTemplate && this.selectedTemplate.hasImage" class="form-group template-variable">
            <label for="sr-imageurl">{{ $t('campaign.new.step2.image_url.title') }}</label>
            <input maxlength="255" type="text" id="sr-imageurl" :placeholder="$t('campaign.new.step2.image_url.placeholder')" v-model="this.templateImageUrl">
            <div style="font-size: 10px; margin-top: 10px">{{ $t('campaign.new.step2.image_url.disclaimer') }}</div>
          </div>
          <div v-if="this.selectedTemplate && this.selectedTemplate.hasVideo" class="form-group template-variable">
            <label for="sr-imageurl">{{ $t('campaign.new.step2.video_url.title') }}</label>
            <input maxlength="255" type="text" id="sr-videourl" :placeholder="$t('campaign.new.step2.video_url.placeholder')" v-model="this.templateImageUrl">
            <div style="font-size: 10px; margin-top: 10px">{{ $t('campaign.new.step2.video_url.disclaimer') }}</div>
          </div>
          <div v-if="this.selectedTemplate && this.selectedTemplate.hasDocument" class="form-group template-variable">
            <label for="sr-imageurl">{{ $t('campaign.new.step2.document_url.title') }}</label>
            <input maxlength="255" type="text" id="sr-documenturl" :placeholder="$t('campaign.new.step2.document_url.placeholder')" v-model="this.templateImageUrl">
            <div style="font-size: 10px; margin-top: 10px">{{ $t('campaign.new.step2.document_url.disclaimer') }}</div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="shouldBuyCredit()" class="form-group campaign-name">
      <h3 style="color:#e74c3c;">{{ $t('campaign.new.credits.no_credits') }}</h3>
      <p>{{ $t('campaign.new.credits.you_have') }} {{organization.credits}}, {{ $t('campaign.new.credits.you_need') }} {{this.count}}</p>

      <div class="credits-buttons">
        <button @click="addCredits(1000)" class="button button-small">+1000</button>
        <button @click="addCredits(2000)" class="button button-small">+2000</button>
        <button @click="addCredits(5000)" class="button button-small">+5000</button>
        <button @click="addCredits(10000)" class="button button-small">+10000</button>
      </div>
    </div>

    <div v-if="!shouldBuyCredit() && this.organization.features.credits" class="form-group campaign-name">
      <p>{{ $t('campaign.new.credits.this_camp1') }} {{this.count}} {{ $t('campaign.new.credits.this_camp2') }} {{organization.credits}}. </p>
    </div>

    <button @click="onClickSave" :disabled="stopSend()" class="button button-main">{{ $t('campaign.new.send') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewCampaign',
  data () {
    return {
      modalOpen: false,
      selectedTemplate: null,
      count: 0,
      countFilter: 0,
      countImport: 0,
      name: null,
      campaignDate: null,
      importTextareaText: '',
      filterData: {
        filter: null,
        selectedTags: null,
        selectedUsers: null,
        onlyClosedConversations: false,
        onlyUnreadConversations: false,
        conversationsWithReminders: false,
        conversationsWithScheduled: false,
        selectedAudience: null
      },
      tabSelected: 'filter',
      templateVariableOptions: [],
      templateVariableValue: 'name',
      templateImageUrl: null,
      imageLoaded: false
    }
  },
  computed: mapGetters(['templates', 'organization', 'tags',
    'organizationUsers', 'allContacts', 'customFields', 'klaviyoAudiences']),
  methods: {
    ...mapActions(['fetchTemplates', 'emptyTemplates', 'fetchContacts',
      'createCampaign', 'fetchCheckoutSessionLink', 'fetchTags',
      'fetchKlaviyoAudiences', 'fetchKlaviyoAudienceCount', 'fetchCampaigns']),
    getKlaviyoLabel (option) {
      return `(${option.type}) ${option.name}`
    },
    close () {
      this.modalOpen = false
      this.selectedTemplate = null
      this.count = 0
      this.countFilter = 0
      this.countImport = 0
      this.name = null
      this.link = null
      this.campaignDate = null
      this.tabSelected = 'filter'
      this.importTextareaText = ''
      this.templateVariableValue = 'name'
      this.filterData = {
        filter: null,
        selectedTags: null,
        selectedUsers: null,
        onlyClosedConversations: false,
        onlyUnreadConversations: false,
        conversationsWithReminders: false,
        conversationsWithScheduled: false,
        selectedAudience: null
      }
      this.emptyTemplates()
    },
    open (filterData) {
      this.filterData = filterData

      this.fetchTemplates({ query: '', all: false }).then(() => {
        this.fetchTags('')
      })

      if (this.organization.klaviyo !== null && this.organization.klaviyo !== '') {
        this.fetchKlaviyoAudiences()
        this.tabSelected = 'klaviyo'
      }

      if (this.tabSelected === 'filter') {
        this.getContactsCount()
      }

      this.modalOpen = true
    },
    showExample () {
      if (this.selectedTemplate !== null && this.selectedTemplate.text.includes('{{1}}')) {
        return true
      }

      return false
    },
    getTemplateText () {
      if (this.selectedTemplate && this.templateVariableValue && this.selectedTemplate.text.includes('{{1}}')) {
        const replacement = '{' + this.getCustomFieldName(this.templateVariableOptions.find(el => el.id === this.templateVariableValue).name) + '}'
        return this.selectedTemplate.text.replace('{{1}}', replacement)
      }

      return this.selectedTemplate.text
    },
    getTemplateFields () {
      if (this.tabSelected === 'filter') {
        return this.templateVariableOptions
      }

      return [{ id: 'name', name: this.$t('campaign.new.contact_name') }]
    },
    stopSend () {
      if (this.selectedTemplate === null) {
        return true
      }

      if (this.selectedTemplate.text.includes('{{1}}') && !this.templateVariableValue) {
        return true
      }

      if (this.count === 0) {
        return true
      }

      if (!this.name || this.name.trim().length === 0) {
        return true
      }

      if (this.campaignDate === null) {
        return true
      }

      if ((this.selectedTemplate.hasImage || this.selectedTemplate.hasVideo || this.selectedTemplate.hasDocument) && !this.templateImageUrl) {
        return true
      }

      if ((this.selectedTemplate.hasImage) && !this.imageLoaded) {
        return true
      }

      if ((this.selectedTemplate.hasImage || this.selectedTemplate.hasVideo || this.selectedTemplate.hasDocument) && this.templateImageUrl) {
        const urlSplitted = this.templateImageUrl.split('.')

        if (urlSplitted.length === 0) {
          return true
        }

        const extension = urlSplitted[urlSplitted.length - 1]
        if (this.selectedTemplate.hasImage && !['jpeg', 'jpg', 'png'].includes(extension)) {
          return true
        }

        if (this.selectedTemplate.hasVideo && !['mp4'].includes(extension)) {
          return true
        }

        if (this.selectedTemplate.hasDocument && !['pdf'].includes(extension)) {
          return true
        }
      }

      if (this.shouldBuyCredit()) {
        return true
      }

      return false
    },
    getContactsCount () {
      const tags = []
      const users = []

      if (this.filterData.selectedTags) {
        for (const tag of this.filterData.selectedTags) {
          tags.push(tag.id)
        }
      }

      if (this.filterData.selectedUsers) {
        for (const user of this.filterData.selectedUsers) {
          users.push(user.id)
        }
      }

      const query = {
        filter: this.filterData.filter,
        tags: tags,
        users: users,
        page: 0,
        closed: this.filterData.onlyClosedConversations,
        unread: this.filterData.onlyUnreadConversations,
        reminder: this.filterData.conversationsWithReminders,
        scheduled: this.filterData.conversationsWithScheduled,
        count: true
      }

      this.fetchContacts(query).then((count) => {
        this.count = count
        this.countFilter = count
      })
    },
    downloadExcel () {
      const tags = []
      const users = []

      if (this.filterData.selectedTags) {
        for (const tag of this.filterData.selectedTags) {
          tags.push(tag.id)
        }
      }

      if (this.filterData.selectedUsers) {
        for (const user of this.filterData.selectedUsers) {
          users.push(user.id)
        }
      }

      const query = {
        filter: this.filterData.filter,
        tags: tags,
        users: users,
        page: 0,
        closed: this.filterData.onlyClosedConversations,
        unread: this.filterData.onlyUnreadConversations,
        reminder: this.filterData.conversationsWithReminders,
        scheduled: this.filterData.conversationsWithScheduled,
        download: 'excel'
      }

      this.fetchContacts(query)
      this.emitter.emit('openDownloadExcel')
    },
    onClickSave () {
      const tags = []
      const users = []

      if (this.filterData.selectedTags) {
        for (const tag of this.filterData.selectedTags) {
          tags.push(tag.id)
        }
      }

      if (this.filterData.selectedUsers) {
        for (const user of this.filterData.selectedUsers) {
          users.push(user.id)
        }
      }

      const query = {
        filter: this.filterData.filter,
        tags: tags,
        users: users,
        page: 0,
        closed: this.filterData.onlyClosedConversations,
        unread: this.filterData.onlyUnreadConversations,
        reminder: this.filterData.conversationsWithReminders,
        scheduled: this.filterData.conversationsWithScheduled,
        klaviyoAudience: this.filterData.selectedAudience
      }

      let templateVariableFieldName = 'name'
      if (this.templateVariableValue !== 'name') {
        templateVariableFieldName = this.templateVariableOptions.find(el => el.id === this.templateVariableValue).name
      }

      this.createCampaign({
        type: this.tabSelected,
        contactListText: this.importTextareaText,
        query: query,
        template: this.selectedTemplate.id,
        name: this.name.trim(),
        date: this.$moment(this.campaignDate).toDate(),
        total: this.count,
        templateVariableValue: templateVariableFieldName,
        templateImageUrl: this.templateImageUrl
      }).then(() => {
        this.close()
        this.fetchCampaigns()
      })
    },
    shouldBuyCredit () {
      if (this.organization && !this.organization.features.credits) {
        return false
      }

      if (this.organization && this.organization.features.credits === false) {
        return false
      }

      if (this.organization && this.organization.credits < this.count) {
        return true
      }

      return false
    },
    onChangeAudience () {
      this.fetchKlaviyoAudienceCount({
        type: this.filterData.selectedAudience.type,
        id: this.filterData.selectedAudience.id
      }).then((count) => {
        this.count = count
        this.countFilter = count
      })
    },
    onChangeFilters () {
      this.getContactsCount()
    },
    addCredits (number) {
      this.fetchCheckoutSessionLink({ credits: number }).then((url) => {
        location.href = url
      })
    },
    onChangeImportTextarea () {
      const splitted = this.importTextareaText.split('\n')
      const notEmpty = []

      for (const line of splitted) {
        if (line.trim() !== '') {
          notEmpty.push(line)
        }
      }

      this.count = notEmpty.length
      this.countImport = notEmpty.length
    },
    getCustomFieldName (key) {
      const words = key.replaceAll('_', ' ').split(' ')
      return words.map((word) => {
        return word[0].toUpperCase() + word.substring(1)
      }).join(' ')
    },
    onLoadImage () {
      const campaignMessageContainerEl = document.getElementById('campaignMessageContainer')
      if (campaignMessageContainerEl) {
        campaignMessageContainerEl.scrollTop = campaignMessageContainerEl.scrollHeight
      }
      this.imageLoaded = true
    }
  },
  created () {
    this.emitter.on('openBulkSend', this.open)
    this.emitter.on('closeBulkSend', this.close)
  },
  unmounted () {
    this.emitter.off('openBulkSend')
    this.emitter.off('closeBulkSend')
  },
  watch: {
    customFields (n, o) {
      this.templateVariableOptions = [
        ...[{ id: 'name', name: this.$t('campaign.new.contact_name') }],
        ...this.customFields
      ]
    }
  }
}
</script>

<style scoped>
.overlay {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 3000001;
  background-color: #ffffff;
  position: fixed;
  top: 20px;
  left: 50%;
  margin-left: -400px;
  width: 800px;
  max-height: calc(100% - 80px);
  padding: 20px;
  overflow: scroll;
  height: unset;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.button.button-main {
  position: relative;
  bottom: unset;
  margin-top: 20px;
  width: 100%;
  left: unset;
}

.modal h3, .modal h4 {
  margin-top: 0;
}

.content-bulk {
  display: flex;
}

.left {
  width: 40%;
}

.right {
  margin-left: 40px;
  width: 47%;
}

.filters, .name, .moment {
  width: 100%;
  border: 1px solid #EDEFF1;
  padding: 20px;
}

.template  {
  margin-left: 20px;
  width: 100%;
  border: 1px solid #EDEFF1;
  padding: 20px;
}

.filters textarea {
  width: 100%;
}

.campaign-name {
  margin-top: 20px;
  border: 1px solid #EDEFF1;
  padding: 20px;
}

.form-group {
  margin-bottom: 0;
  position: relative;
}

.form-group a {
  position: absolute;
  right: 290px;
  bottom: 0px;
}

.group-crm-tags {
  margin-bottom: 15px;
}

.tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
}

.tags .tag {
  border-radius: 7.5px;
  padding: 5px;
  margin-right: 10px;
  margin-top: 10px;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
}

.template-variable {
  margin-top: 20px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 20px;
  left: 20px;
}

.button-buy-credits {
  position: absolute;
  width: 250px;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.message-container {
  position: relative;
  margin-top: 20px;
  height: 293px;
  background-color: rgba(240, 224, 199, 0.38);
  background-image:url(../../assets/whatsapp-bg.png);
  background-repeat:repeat;
  background-size: 412.5px 749.25px;
  padding: 5px 0;
  overflow: scroll;
}

.message {
  bottom: 10px;
  right: 20px;

  color: #111b21;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  margin: 0 0 5px 0;
  max-width: 85%;
  word-wrap: break-word;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
}

.message .data img {
  width: 100%;
}

.message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

.metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -4px;
}

.metadata .time {
  color: rgba(0, 0, 0, .45);
  font-size: 11px;
  display: inline-block;
}

.metadata .tick {
  display: inline-block;
  margin-right: 15px;
  position: relative;
  top: 6px;
  height: 16px;
}

.metadata .tick svg {
  position: absolute;
  transition: .5s ease-in-out;
}

.metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

.metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

.metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

.metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

.sent {
  background: #d9fdd3;
  border-radius: 7.5px 0px 7.5px 7.5px;
  width: fit-content;
  margin-left: 20px;
}

.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.search-option {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
  position: relative;
  left: unset;
  bottom: unset;
  width: fit-content;
  margin-top: 10px;
}

.search-option i {
  padding-right: 6px;
}

.tabs {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  margin-bottom: 20px;
}

.tabs .tab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #EDEFF1;
  padding: 8px;
  cursor: pointer;
}

.tabs .tab.active {
  font-weight: 500;
  border-top: 1px solid #EDEFF1;
  border-left: 1px solid #EDEFF1;
  border-right: 1px solid #EDEFF1;
  border-bottom: 0;

  -webkit-border-top-left-radius: 4px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-topleft: 4px;
  -moz-border-radius-topright: 4px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.credits-buttons {
  position: absolute;
  right: 40px;
  bottom: 0px;
  display: inline-flex;
}

.credits-buttons .button {
  position: relative;
  margin-left: 15px;
  width: fit-content;
}
</style>

<style>
.content-bulk .template .vs__selected {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 305px;
}
</style>
