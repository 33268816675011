<template>
  <div class="settings-group">
    <br>
    <zapier-full-experience
      client-id="azA4RuczFTWT4eyJolAL6vkjZRLCbwuo1kmrS7YC"
      theme="light"
      intro-copy-display="hide"
      app-selection-display="hide"
      zap-limit="0"
      zap-create-from-scratch-display="hide"
    ></zapier-full-experience>
    <br>
    <zapier-zap-templates
      theme="light"
      apps="manycontacts"
      create-without-template="hide"
      limit="5"
      link-target="new-tab"
      presentation="row"
      use-this-zap="show"
    ></zapier-zap-templates>
  </div>
  <div class="settings-group">
    <h3>{{ $t('settings.pabbly.title') }}</h3>
    <p v-html="$t('settings.pabbly.subtitle')"></p>
  </div>

  <link rel="stylesheet" href="https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.css"/>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionZapier',
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['fetchOrganization']),
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    }
  },
  created () {
    if (this.organization === null) {
      this.fetchOrganization()
    }

    const zapierElementsEl = document.createElement('script')
    zapierElementsEl.setAttribute('type', 'module')
    zapierElementsEl.setAttribute('src', 'https://cdn.zapier.com/packages/partner-sdk/v0/zapier-elements/zapier-elements.esm.js')
    document.head.appendChild(zapierElementsEl)
  }
}
</script>

<style scoped>
h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

.form-group.value {
  width: 50px;
}

.form-group textarea {
  height: 200px;
  z-index: 10;
}

.form-group select {
  width: 100%;
  height: 35px;
}

p {
  margin-bottom: 10px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.routing-options {
  margin-top: 100px;
}

.routing-options p {
  margin: 5px 0;
}

.routing-options textarea {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
}

.member {
  display: inline-block;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  width: fit-content;
  background-color: #EDEFF1;
  margin-bottom: 5px;
}

.add-member {
  width: 200px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}
</style>

<style>
zapier-full-experience h2 {
  display: none;
}
</style>
