import axios from 'axios'

const actions = {
  async savePartnerPhoto ({ commit, rootState }, { formData }) {
    const res = await axios.post(`${process.env.VUE_APP_SERVERHOST}api/admin/partners/photos`, formData, {})
    return res.data
  },
  async getOrganizationsToMigrate ({ commit, rootState }, hash) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/admin/migration/wabas?hash=${hash}`)
  },
  async startMigration ({ commit, rootState }, params) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/admin/migration/waba/start`, params)
  },
  async finishMigration ({ commit, rootState }, params) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/admin/migration/waba/finish`, params)
  }
}

export default {
  actions
}
