import axios from 'axios'

const state = {}
const getters = {}

const actions = {
  async sso ({ commit }, { partner, token }) {
    return axios.get(`${process.env.VUE_APP_SERVERHOST}api/sso/${partner}/${token}`, {})
      .then((res) => {
        return res.data.token
      })
  }
}
const mutations = {}

export default {
  state,
  getters,
  actions,
  mutations
}
