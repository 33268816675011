import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { createI18n } from 'vue-i18n/index'
import VueCookies from 'vue3-cookies'
import VueSocketIO from 'vue-3-socket.io'
import SocketIO from 'socket.io-client'
import VTooltipPlugin from 'v-tooltip'
import Vue3NativeNotification from 'vue3-native-notification'
import infiniteScroll from 'vue3-infinite-scroll-good'

import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

import Cookies from 'js-cookie'

import { Vue3ProgressPlugin } from '@marcoschulte/vue3-progress'

import VCalendar from 'v-calendar'
import 'v-calendar/dist/style.css'

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'

import VueQRCodeComponent from 'vue-qrcode-component'
import QrcodeVue from 'qrcode.vue'

import VueCountdown from '@chenfengyuan/vue-countdown'

import Vue3ColorPicker from 'vue3-colorpicker'
import 'vue3-colorpicker/style.css'

import Draggable from 'vuedraggable'

import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'

// import vue3GoogleLogin from 'vue3-google-login'

import mitt from 'mitt'
import moment from 'moment'
import { version } from '../package.json'

if (window.location &&
    window.location.host &&
    window.location.host === 'www.manycontacts.com') {
  sessionStorage.removeItem('accessTokensSession')
  localStorage.removeItem('accessTokens')
}

const accessTokenCookie = Cookies.get('accessToken')
if (accessTokenCookie) {
  sessionStorage.removeItem('accessTokensSession')
}

if (location.protocol !== 'https:' && process.env.VUE_APP_WSHOST !== 'localhost:3000') {
  location.replace(`https:${location.href.substring(location.protocol.length)}`)
}

let accessToken = sessionStorage.getItem('accessTokensSession')
if (location.href.split('/').includes('invite') ||
    location.href.split('/').includes('register') ||
    location.href.split('/').includes('sso') ||
    location.href.split('/').includes('login')) {
  sessionStorage.removeItem('accessTokensSession')

  accessToken = undefined
} else if ((accessToken === undefined || accessToken === 'null') && process.env.VUE_APP_WSHOST !== 'localhost:3000') {
  location.href = process.env.VUE_APP_WEB_HOST_LOGIN
}

let locale = Cookies.get('ma-locale')
if (!locale) {
  if (navigator.languages !== undefined) {
    locale = navigator.languages[0]
  }

  if (!locale) {
    locale = navigator.language
  }

  if (locale) {
    locale = locale.substr(0, 2)
  }
}

if (!locale) {
  locale = 'es'
}

const i18n = createI18n({
  locale: locale,
  fallbackLocale: 'es',
  globalInjection: true,
  legacy: false,
  messages: {}
})

window.fetchTranslationsFor = async function (locale, callback) {
  let url = `${process.env.VUE_APP_LOCALES_HOST}${locale}.json?version=${version}`
  if (process.env.NODE_ENV === 'development') {
    url = `${process.env.VUE_APP_LOCALES_HOST}${locale}.json?timestamp=${new Date().getTime()}`
  }

  fetch(url).then((response) => {
    return response.json()
  }).then((data) => {
    i18n.global.mergeLocaleMessage(locale, data)
    if (callback) {
      callback()
    }

    let url = `${process.env.VUE_APP_LOCALES_HOST}modules/featured_${locale}.json?version=${version}`
    if (process.env.NODE_ENV === 'development') {
      url = `${process.env.VUE_APP_LOCALES_HOST}modules/featured_${locale}.json?timestamp=${new Date().getTime()}`
    }
    fetch(url).then((response) => {
      return response.json()
    }).then((data) => {
      i18n.global.mergeLocaleMessage(locale, data)
    })
  })
}

window.fetchTranslationsFor(locale)
window.i18n = i18n

let SocketInstance = null
let stringConection = ''
if (process.env.VUE_APP_WSHOST === 'localhost:3000') {
  stringConection = `ws://${process.env.VUE_APP_WSHOST}`
} else {
  stringConection = `wss://${process.env.VUE_APP_WSHOST}`
}

if (accessToken) {
  SocketInstance = new VueSocketIO({
    debug: true,
    connection: SocketIO(stringConection, {
      transports: ['websocket'],
      forceNew: true,
      query: `token=${accessToken}`
    }),
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    }
  })
}

const app = createApp(App)

moment.locale(locale)
app.config.globalProperties.$moment = moment

const emitter = mitt()
app.config.globalProperties.emitter = emitter
window.emitter = emitter

app.use(i18n)
  .use(store)
  .use(router)
  .use(VueCookies)
  .use(SocketInstance)
  .use(VTooltipPlugin)
  .use(VueTelInput)
  .use(VueToast, { position: 'top' })
  .use(Vue3NativeNotification, { requestOnNotify: true })
  .use(Vue3ProgressPlugin)
  .use(infiniteScroll)
  .use(VCalendar, {})
  .use(Vue3ColorPicker)
  // .use(vue3GoogleLogin, {
  //   clientId: '219555661732-m14bvedob9aeareaungkhh1fv9r6fehu.apps.googleusercontent.com'
  // })
  .component('v-select', vSelect)
  .component('qr-code', VueQRCodeComponent)
  .component('qrcode-vue', QrcodeVue)
  .component(VueCountdown.name, VueCountdown)
  .component('draggable', Draggable)
  .component('VueDatePicker', VueDatePicker)
  .mount('#app')
