<template>
  <div v-if="organization" class="settings-group wa_type">
    <div class="form-group" v-if="[null, 'business'].includes(organization.watype)">
      <h4>{{ $t('settings.integrations.qr.title') }} <span v-if="organization.watype === 'business'" class="connected">{{ $t('settings.integrations.connected') }}</span> </h4>
      <p v-if="organization.watype === null">{{ $t('settings.integrations.qr.subtitle') }}</p>

      <div v-if="organization.watype === null" style="margin-bottom: 30px;">
        <div v-if="!showQR">
          <button @click="this.getQR(); showQR = true" class="button button-scan">{{ $t('onboarding.scan_qr_button') }}</button>
        </div>

        <div class="wa-qr" v-if="showQR">
          <p v-html="$t('onboarding.integrations.qr.subtitle')" style=""></p>
          <qrcode-vue v-if="qrTemp" :value="qrTemp" size="200" level="H"></qrcode-vue>
        </div>
      </div>
      <div v-if="organization.watype === 'business'" class="form-group" style="margin-bottom: 80px;">
        <button class="button" @click="onClickDeleteQr" type="submit">{{ $t('settings.integrations.qr.disconnect') }}</button>
      </div>
    </div>

    <div v-if="organization && !organization.features.metaCloudApi">
      <div class="form-group" v-if="organization.gateway_type !== 'cloud-api-facebook'">
        <h4>{{ $t('settings.integrations.api.title') }} <span v-if="organization.watype === 'api' && (['on-premise','cloud-api'].includes(organization.gateway_type))" class="connected">{{ $t('settings.integrations.connected') }}</span></h4>
        <p v-if="organization.watype !== 'api'">{{ $t('settings.integrations.api.subtitle') }}</p>

        <div v-if="organization.watype !== 'api'" class="form-group">
          <div v-if="!showAPI">
            <button @click="showAPI = true" class="button button-scan">{{ $t('onboarding.get_api') }}</button>
          </div>
          <div v-if="showAPI">
            <div v-if="requested === false">
              <p v-html="$t('onboarding.subtitle_not_apikey')"></p>

              <div class="form-group">
                <label for="phone">{{ $t('pages.register.phone') }}</label>
                <input type="text" id="phone" name="phone" v-model="phone" placeholder="+34666123123">
              </div>

              <div class="form-group">
                <label for="name">{{ $t('pages.register.companyName') }}</label>
                <input type="text" id="name" name="name" v-model="name" :placeholder="$t('pages.register.companyName_placeholder')">
              </div>

              <button :disabled="this.name.trim().length === 0 || this.phone.trim().length === 0" @click="sendWAAPIRequest()" class="embedded-signup button">{{ $t('onboarding.get_key') }}</button>
            </div>
            <div v-if="requested === true">
              <br>
              <h2>{{ $t('onboarding.integrations.api.request.title') }}</h2>
              <p v-html="$t('onboarding.integrations.api.request.subtitle')"></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" v-if="organization && organization.features.metaCloudApi">
      <h4>{{ $t('settings.integrations.api_facebook.title') }}<span v-if="organization.watype === 'api' && organization.gateway_type==='cloud-api-facebook' && organization.number" class="connected">{{ $t('settings.integrations.connected') }}</span></h4>
      <div class="form-group" v-if="!organization.gateway_type !== 'cloud-api-facebook' && !organization.fbNumberId">
        <p>{{ $t('settings.integrations.api_facebook.subtitle') }}</p>
        <button @click="launchWhatsAppSignup($event)" class="button" >{{ $t('settings.integrations.api_facebook.activate') }}</button>
      </div>

      <div v-if="organization.fbNumberId && organization.gateway_type==='cloud-api-facebook'">
        <div v-if="businessAccount">
          <p style="font-weight: bold;"> {{ $t('settings.integrations.api_facebook.status_account')}} </p>
          <div>
            <div>{{ $t('settings.integrations.api_facebook.verified_name') }}: <span><b>{{ businessAccount.verified_name }}</b></span></div>
          </div>
          <div>
            <div>{{ $t('settings.integrations.api_facebook.display_phone_number') }}: <span><b>{{ businessAccount.display_phone_number }}</b></span></div>
          </div>
          <div>
            <div style="">{{ $t('settings.integrations.api_facebook.quality_rating') }}:
              <span class="quality-rating" :class="businessAccount.quality_rating"> <b>{{ businessAccount.quality_rating }}</b></span>
            </div>
          </div>
        </div>
        <div>
          <p style="font-weight: bold; margin-bottom: 0"> {{ $t('settings.integrations.api_facebook.update_method_pay')}}</p>
          <i class="fa-regular fa-credit-card fa-2xl"></i>
          <button @click="newWindowPay"  class="button button-payments"> {{ $t('settings.integrations.api_facebook.open_pay') }}</button>
        </div>
        <div>
          <p style="font-weight: bold; margin-bottom: 0"> {{ $t('settings.integrations.api_facebook.check_status_api_description') }} </p>
          <i class="fa fa-check-circle fa-2xl" aria-hidden="true"></i>
          <button @click="newWindowsCheckStatus" class="button button-payments"> {{ $t('settings.integrations.api_facebook.check_status_api') }}</button>
        </div>
      </div>
    </div>
  </div>

  <div v-if="organization && organization.watype === 'api'">
    <div class="settings-group">
      <h3>{{ $t('settings.whatsapp.photo.title') }}</h3>
      <div class="form-group">
        <p>{{ $t('settings.whatsapp.photo.subtitle') }}</p>
      </div>

      <form>
        <img @click="onClickImage" id="profile-img" class="profile-img" :src="getProfileUrl()" onerror="this.src='/profile-empty.png';"/>
        <input type="file" hidden id="upload-profile-image" accept="image/jpeg,image/png" @change="onChangeFile($event)">
      </form>
    </div>

    <div class="settings-group">
      <h3>{{ $t('settings.whatsapp.about.title') }}</h3>

      <form @submit="onSubmitAbout">
        <div class="form-group">
          <label for="about">{{ $t('settings.whatsapp.about.status') }}</label>
          <input v-model="about" type="text" id="about" name="old">
        </div>
        <div class="form-group">
          <button class="button" type="submit">{{ $t('settings.whatsapp.save') }}</button>
        </div>
      </form>
    </div>

    <div class="settings-group">
      <h3>{{ $t('settings.whatsapp.business.title') }}</h3>

      <form @submit="onSubmitBusiness">
        <div class="form-group">
          <label for="description">{{ $t('settings.whatsapp.business.description') }}</label>
          <input maxlength="256" v-model="business.description" type="text" id="description" name="description">
        </div>
        <div class="form-group">
          <label for="email">{{ $t('settings.whatsapp.business.email') }}</label>
          <input maxlength="120" v-model="business.email" type="text" id="email" name="email">
        </div>
        <div class="form-group">
          <label for="websites">{{ $t('settings.whatsapp.business.websites') }}</label>
          <input maxlength="256" v-model="business.websites" type="text" id="websites" name="websites">
        </div>
        <div class="form-group">
          <label for="address">{{ $t('settings.whatsapp.business.address') }}</label>
          <input maxlength="256" v-model="business.address" type="text" id="address" name="address">
        </div>
        <div class="form-group">
          <button class="button" type="submit">{{ $t('settings.whatsapp.save') }}</button>
        </div>
      </form>
    </div>
  </div>
  <div v-if="organization && organization.watype !== 'api'" class="settings-group" style="padding-bottom: 70px; margin-bottom: 300px;">
    <h3>{{ $t('settings.profile.empty_data.title') }}</h3>
    <div class="form-group">
      <p v-html="$t('settings.profile.empty_data.subtitle')" style="width: 100%; max-width: unset;"></p>
      <button @click="onClickEmptyAccount" class="button" type="submit">{{ $t('settings.profile.empty_data.button') }}</button>
    </div>
  </div>

  <MetaCloudPopupOpened />
  <MetaCloudSelectNumber />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MetaCloudPopupOpened from '@/components/modals/MetaCloudPopupOpened'
import MetaCloudSelectNumber from '@/components/modals/MetaCloudSelectNumber'

export default {
  name: 'SectionWhatsapp',
  components: { MetaCloudSelectNumber, MetaCloudPopupOpened },
  data () {
    return {
      about: null,
      showQR: false,
      showAPI: false,
      requested: false,
      showApikeyInput: false,
      loading: false,
      apiKey: null,
      business: [],
      name: '',
      phone: '',
      selectedNumber: 'Select number',
      businessAccount: null
    }
  },
  computed: mapGetters(['organization', 'qrTemp', 'organizationUsers']),
  methods: {
    ...mapActions([
      'fetchOrganization',
      'fetchWhatsappProfileAbout',
      'fetchWhatsappProfileBusiness',
      'saveWhatsappProfilePhoto',
      'saveWhatsappProfileAbout',
      'saveWhatsappProfileBusiness',
      'getNewQR',
      'newWabaRequest',
      'syncTemplates',
      'deleteQr',
      'saveFacebookAPI',
      'emptyAccount',
      'fetchBusinessAccount'
    ]),
    getQR () {
      return this.getNewQR()
    },
    sendWAAPIRequest () {
      this.requested = true
      this.newWabaRequest({
        phone: this.phone,
        name: this.name
      })
    },
    onClickImage () {
      document.getElementById('upload-profile-image').click()
    },
    onChangeFile (event) {
      if (event.target.files.length === 0) {
        return
      }
      const file = event.target.files[0]
      const formData = new FormData()
      formData.append('file', file)

      this.saveWhatsappProfilePhoto({ formData: formData }).then((success) => {
        if (!success) {
          this.$toast.open({
            message: this.$t('settings.whatsapp.photo.error'),
            type: 'error'
          })

          return
        }

        if (FileReader && file) {
          const fr = new FileReader()
          fr.onload = function () {
            document.getElementById('profile-img').src = fr.result
          }
          fr.readAsDataURL(file)
        }
      }).catch(() => {
        this.$toast.open({
          message: this.$t('settings.whatsapp.photo.error'),
          type: 'error'
        })
      })
    },
    getProfileUrl () {
      const token = sessionStorage.getItem('accessTokensSession')
      return `${process.env.VUE_APP_SERVERHOST}api/whatsapp/profile/photo/${token}`
    },
    onSubmitAbout (event) {
      event.preventDefault()

      this.saveWhatsappProfileAbout(this.about).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onSubmitBusiness (event) {
      event.preventDefault()

      this.saveWhatsappProfileBusiness(this.business).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onClickDeleteQr () {
      if (confirm(this.$t('settings.integrations.qr.confirm'))) {
        this.deleteQr()
      }
    },
    onClickEmptyAccount () {
      if (confirm(this.$t('settings.profile.empty_data.confirm'))) {
        this.emptyAccount().then(() => {
          this.$toast.open({
            message: this.$t('settings.profile.empty_data.done')
          })
        })
      }
    },
    launchWhatsAppSignup (event) {
      event.preventDefault()

      const FB = window.FB
      const self = this
      FB.login(function (response) {
        if (response.authResponse) {
          const code = response.authResponse.code
          self.saveFacebookAPI(code).then((response) => {
            if (!response.data) {
              self.$toast.open({
                message: self.$t('settings.integrations.api_facebook.error'),
                type: 'error'
              })
              self.emitter.emit('closeMetaCloudPopupOpened')

              return
            }

            if (response.data.finalized === true) {
              self.$toast.open({
                message: self.$t('form.saved')
              })
              self.fetchOrganization().then(() => {
                self.fetchBusinessAccount().then((response) => {
                  self.businessAccount = response
                  self.emitter.emit('closeMetaCloudPopupOpened')
                })
              })
              return
            }

            if (response.data.finalized === false) {
              self.emitter.emit('closeMetaCloudPopupOpened')
              self.emitter.emit('openMetaCloudSelectNumber', response.data.listNumbers)
            }
          }).catch(() => {
            console.log('catch')
            self.$toast.open({
              message: self.$t('settings.integrations.api_facebook.error'),
              type: 'error'
            })

            self.emitter.emit('closeMetaCloudPopupOpened')
          })
        } else {
          self.$toast.open({
            message: self.$t('onboarding.error'),
            type: 'error'
          })
          self.emitter.emit('closeMetaCloudPopupOpened')
        }
      }, {
        config_id: '1432539327659373',
        response_type: 'code',
        override_default_response_type: true
      })

      this.emitter.emit('openMetaCloudPopupOpened')
    },
    newWindowPay () {
      const fbWabaId = this.organization.fbWabaId
      const fbBusinessId = this.organization.fbBusinessId
      const url = `https://business.facebook.com/billing_hub/accounts/details/?business_id=${fbBusinessId}&asset_id=${fbWabaId}`

      window.open(url, '_blank')
    },
    newWindowsCheckStatus () {
      const url = 'https://metastatus.com/whatsapp-business-api'
      window.open(url, '_blank')
    }
  },
  mounted () {
    if (this.organization === null) {
      this.fetchOrganization().then((organization) => {
        if (organization && organization.watype === 'api') {
          this.fetchWhatsappProfileAbout().then((about) => {
            this.about = about
            this.fetchWhatsappProfileBusiness().then((business) => {
              this.business = business
              if (organization.gateway_type === 'cloud-api-facebook' && organization.number) {
                this.fetchBusinessAccount().then((response) => {
                  this.businessAccount = response
                })
              }
            })
          })
        }
      })
    } else {
      if (this.organization && this.organization.watype === 'api') {
        this.fetchWhatsappProfileAbout().then((about) => {
          this.about = about
          this.fetchWhatsappProfileBusiness().then((business) => {
            this.business = business
            if (this.organization.gateway_type === 'cloud-api-facebook' && this.organization.number) {
              this.fetchBusinessAccount().then((response) => {
                this.businessAccount = response
              })
            }
          })
        })
      }
    }
    window.fbAsyncInit = function () {
      const FB = window.FB
      FB.init({
        appId: '141361195248669',
        cookie: true,
        xfbml: true,
        version: 'v19.0'
      })
    };

    (function (d, s, id) {
      var js; var fjs = d.getElementsByTagName(s)[0]
      if (d.getElementById(id)) return
      js = d.createElement(s); js.id = id
      js.src = 'https://connect.facebook.net/en_US/sdk.js'
      fjs.parentNode.insertBefore(js, fjs)
    }(document, 'script', 'facebook-jssdk'))
  }
}
</script>

<style scoped>
.settings-group.wa_type {
  min-height: unset;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  cursor: pointer;
}

.connected {
  background-color: #7bed9f;
  font-weight: 400;
  font-size: 12px;
  padding: 3px 10px;
  border-radius: 10px;
  margin-left: 10px;
}

select {
  width: 100% !important;
  height: 39px;
}

.button {
  position: relative;
  bottom: unset;
  width: 100%;
  right: unset;
  top: unset;
  margin-top: 12px;
}

button.phone-number {
  width: 50%;
  left: 10px;
  top: unset;
  margin-top:-1px;
}

.facebook-api-data {
  display: inline-flex;
}

.facebook-api-data div {
  margin-top: 12px;
}

.quality-rating {
}

.quality-rating.GREEN {
  color: #7bed9f;
}

.quality-rating.RED {
  color: #ff7675;
}

.quality-rating.YELLOW {
  color: #ffeaa7;
}

button.button-payments {
  width: 300px;
  margin-top: 12px;
  position: relative;
  bottom: unset;
  right: unset;
  top: unset;
  left: 10px;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}
</style>
