<template>
  <option :value="this.hour + ':00'">{{ this.hour }}:00</option>
  <option v-if='this.hour != 24' :value="this.hour + ':15'">{{ this.hour }}:15</option>
  <option v-if='this.hour != 24' :value="this.hour + ':30'">{{ this.hour }}:30</option>
  <option v-if='this.hour != 24' :value="this.hour + ':45'">{{ this.hour }}:45</option>
</template>

<script>
export default {
  props: {
    hour: String
  }
}
</script>
