<template>
  <div class="overlay" @click="close"></div>
  <div class="modal slide">
    <h3 v-if="this.selectedContact">{{ $t('contact.mediafiles.title') }} {{ $t('contact.mediafiles.of') }} {{ this.selectedContact.name}}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>
    <div class="fileslist">
      <a v-for="file in filesList" :key="file.id" :href="getDownloadUrl(file.id)">
        <div class="file" :class="{ image: file.mimetype.includes('image') }">
            <i v-if="file.mimetype === 'application/pdf'" class="far fa-file-pdf"></i>
            <i v-else-if="file.mimetype === 'text/csv'" class="far fa-file-excel"></i>
            <i v-else-if="file.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'" class="far fa-file-word"></i>
            <i v-else-if="file.mimetype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.sheet'" class="far fa-file-excel"></i>
            <i v-else-if="file.mimetype === 'text/xml'" class="far fa-file-code"></i>
            <i v-else-if="file.mimetype.includes('image')"></i>
            <i v-else-if="file.mimetype.includes('video')" class="far fa-file-video"></i>
            <i v-else class="far fa-file"></i>
          <img v-if="file.mimetype.includes('image')" loading="lazy" :src="getMediaUrl(file.id)" />
          <div v-if="file.filename" class="filename">{{ file.filename }}</div>
          <div class="filedate" v-if="new Date(file.createdAt) > this.today">{{ $moment(file.createdAt).format('HH:mm') }}</div>
          <div class="filedate" v-else-if="new Date(file.createdAt) > this.newYear">{{ $moment(file.createdAt).format('DD MMM - HH:mm') }}</div>
          <div class="filedate" v-else>{{ $moment(file.createdAt).format('L - HH:mm') }}</div>
          <i class="fas fa-download" v-tooltip.top="{ content: $t('messages.body.download') }"></i>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContactAttachmentsList',
  computed: mapGetters(['selectedContact']),
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      filesList: [],
      today: today,
      newYear: newYear
    }
  },
  methods: {
    ...mapActions(['toggleAttachmentListOpen', 'getContactFileList']),
    close () {
      this.toggleAttachmentListOpen()
    },
    getMediaUrl (mediafileId) {
      return `${process.env.VUE_APP_SERVERHOST}api/message/${this.selectedContact.id}/media/${mediafileId}`
    },
    getDownloadUrl (mediafileId) {
      return this.getMediaUrl(mediafileId) + '/download'
    }
  },
  mounted () {
    if (this.selectedContact) {
      this.filesList = []
      this.getContactFileList(this.selectedContact)
        .then((fileslist) => {
          this.filesList = fileslist
        }).catch(err => console.log(err))
    }
  }
}
</script>

<style scoped>
h3 {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding-right: 35px !important;
}

.slide {
  top: 0;
  bottom: 0;
  height: unset;
  right: 0;
  width: 504px;
  margin-left: unset;
  left: unset;
}

.fileslist {
  overflow-y: scroll;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.file {
  background-color: rgba(242, 245, 245, 1);
  border: 1px solid #E3E8EA;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 10px;
  position: relative;
  height: 35px;
  width: 482px;
  margin-right: 20px;
}

.file.image {
  width: 200px;
  height: 200px;
  padding: 20px;
  text-align: center;
}

.file.image > img {
  max-width: 100%;
  max-height: 100%;
}

.file > i {
  font-size: 30px;
  position: absolute;
  color: black;
  text-decoration: none;
  left: 20px;
  top: 12px;
}

.file > i.fa-download {
  left: unset;
  right: 20px;
  font-size: 17px;
  top: 18px;
}

.file .filename {
  position: absolute;
  color: black;
  text-decoration: none;
  left: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 400px;
}

.file .filedate {
  position: absolute;
  color: black;
  text-decoration: none;
  left: 60px;
  top: 30px;
}

.file.image .filename {
  width: 200px;
  left: 20px;
}

.file.image .filedate {
  left: 20px;
  bottom: 20px;
  top: unset;
}

.file.image > i.fa-download {
  left: unset;
  right: 20px;
  font-size: 17px;
  top: unset;
  bottom: 20px;
}
</style>
