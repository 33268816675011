<template>
  <div class="settings-group">
    <h3>{{ $t('settings.templates.title') }}</h3>
    <div class="form-group">
      <p>{{ $t('settings.templates.description') }}</p>
      <button v-if="organization && organization.fbBusinessId && organization.fbWabaId" @click="newTemplateFB" class="button" type="submit">
        <img style="height: 15px; vertical-align: text-bottom;" src="../../../../assets/meta-logo-facebook-svgrepo-com.svg" />
        {{ $t('settings.templates.button_create_fb') }}
      </button>
    </div>
    <table v-if="templates && templates.length > 0">
      <thead>
        <th style="width: 8%"></th>
        <th style="width: 20%">{{ $t('templates.title') }}</th>
        <th style="width: 72%">{{ $t('templates.text') }}</th>
      </thead>
      <tbody>
        <tr :key="template.id" v-for="template in templates">
          <td>
            <div class="status" :class="getStatusClass(template)" v-tooltip.left="{ content: $t(`templates.tooltip.${getStatusClass(template)}`) }"></div>
          </td>
          <td>[{{template.code}}] {{ template.name }}</td>
          <td>
            <div class="response-text">
              {{ template.text }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionTemplates',
  computed: mapGetters(['templates', 'organization']),
  methods: {
    ...mapActions([
      'fetchTemplates',
      'emptyTemplates'
    ]),
    newTemplateFB () {
      const url = `https://business.facebook.com/wa/manage/message-templates/?business_id=${this.organization.fbBusinessId}&waba_id=${this.organization.fbWabaId}`
      window.open(url, '_blank')
    },
    getStatusClass (template) {
      if (template.status === 0) {
        return 'submitted'
      } else if (template.status === 1) {
        return 'pending'
      } else if (template.status === 2) {
        return 'approved'
      } else if (template.status === 3) {
        return 'rejected'
      }
    }
  },
  created () {
    this.fetchTemplates({ query: '', all: true })
  },
  beforeUnmount () {
    this.emptyTemplates()
  }
}
</script>

<style scoped>
  p {
    width: calc(100% - 200px);
    max-width: 600px;
    float: left;
    margin: 0;
  }

  table {
    width: 100%;
    margin-top: 50px;
    border-collapse: collapse;
  }

  thead {
    height: 40px;
    text-align: left;
    border-bottom: 1px solid #EDEFF1;
  }

  tr {
    height: 50px;
    border-bottom: 1px solid #EDEFF1;
  }

  tr i {
    margin-left: 10px;
    cursor: pointer;
  }

  .response-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 550px;
  }

  .status {
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
  }

  .status.pending, .status.submitted {
    background-color: #fdcb6e;
  }

  .status.rejected {
    background-color: #ff7675;
  }

  .status.approved {
    background-color: #55efc4;
  }
</style>
