<template>
  <div v-if="modalOpen" class="overlay" @click="closeOverlay"></div>
  <div v-if="modalOpen" class="modal">
    <div @click="close" class="modal-close"><i class="fas fa-times" style="font-size: 16px"></i></div>
    <div>
        <div v-if="this.message">
          <div style="margin-top: 15px; margin-bottom: 8px; font-weight: bold;">
            {{ $t('settings.agent_ai.training.original_message') }}
          </div>
          <div style="margin-bottom: 7px;">
            <div class="message sent">
              {{ message.text }}
            </div>
          </div>
          <div style="margin-bottom: 7px; font-weight: bold;">
            {{ $t('settings.agent_ai.training.title') }}
          </div>
          <div class="input-background">
            <div class="input-container">
              <textarea :maxlength="feedbackMaxCharacters" v-model="feedbackText" class="block-input" :placeholder="$t('settings.agent_ai.training.placeholder')"/>
            </div>

            <div class="char-counter">{{ feedbackText.length }} / {{ feedbackMaxCharacters }}</div>
          </div>
          <div>
            <div>
              <button :disabled="!feedbackText || busy" @click="saveAgentAI" style="bottom: 80px" class="button"> {{ $t('settings.agent_ai.training.submit') }}</button>
            </div>
            <div style="color: #6E7A8A; position: absolute; bottom: 55px; left: 200px" >{{ $t('settings.agent_ai.training.or') }}</div>
            <div>
              <button @click="editPrompt"  style="width: 250px; right:100px; height: 30px; background-color: #4285f49c; line-height: 30px;" class="button"> {{ $t('settings.agent_ai.training.go_edit_prompt') }}</button>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'editAgentAI',
  data () {
    return {
      feedbackMaxCharacters: 200,
      feedbackText: '',
      message: null,
      modalOpen: false,
      feedbacks: null,
      busy: false
    }
  },
  computed: mapGetters(['selectedContact', 'contactMessages']),
  methods: {
    ...mapActions([
      'fetchAgentAI',
      'createAgentAIFeedback'
    ]),
    close () {
      this.modalOpen = false
      this.feedbackText = ''
    },
    closeOverlay (event) {
      if (event.target.classList.contains('overlay')) {
        this.close()
      }
    },
    editPrompt () {
      this.emitter.emit('openNewConchiUser', { id: this.message.conchi_user_id, tab: 'step2' })
      this.close()
    },
    saveAgentAI () {
      this.busy = true

      // FIXME: this must be calculated in the backend
      let requestMessageId = ''
      let requestMessageText = ''
      for (const message of this.contactMessages) {
        if (message.id !== this.message.id) {
          requestMessageId = message.id
          requestMessageText = message.text
        } else {
          break
        }
      }

      const feedback = {
        contact_id: this.selectedContact.id,
        user_id: this.message.conchi_user_id,
        request_message_id: requestMessageId,
        request_message_text: requestMessageText,
        response_message_id: this.message.id,
        response_message_text: this.message.text, // FIXME: this must be obtained in the backend
        feedback_text: this.feedbackText
      }

      this.createAgentAIFeedback({ userId: this.message.conchi_user_id, feedback: feedback }).then(() => {
        this.busy = false
        this.$toast.open({
          message: this.$t('settings.agent_ai.training.success')
        })
        this.close()
      }).catch(() => {
        this.busy = false
        this.$toast.open({
          message: this.$t('settings.agent_ai.training.error'),
          type: 'error'
        })
      })
    },
    open (message) {
      this.message = message
      this.fetchAgentAI(message.conchi_user_id).then(() => {
        this.modalOpen = true
      }).catch(() => {
        this.$toast.open({
          message: this.$t('settings.agent_ai.training.not_active'),
          type: 'error'
        })
      })
    }
  },
  created () {
    this.emitter.on('openTrainingModelAI', this.open)
    this.emitter.on('closeTrainingModelAI', this.close)
  },
  unmounted () {
    this.emitter.off('openTrainingModelAI')
    this.emitter.off('closeTrainingModelAI')
  }
}
</script>

<style scoped>
.overlay {
  z-index: 5000003;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 5000004;
  background-color: #ffffff;
  position: fixed;
  top: 50px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  height: 440px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.mobile .modal {
  width: calc(100% - 40px);
  left: unset;
  margin-left: unset;
}

.modal h3 {
  margin-top: 0;
}

.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}
.block-input {
  width: calc(100% - 5px);
  height: 100px;
  min-height: 160px;
  border: 1px solid #EDEFF1;
  border-radius: 4px;
  resize: none;
}

.char-counter{
  text-align: right;
  font-size: 12px;
  color: #6E7A8A;
  margin-top: 5px;
  margin-right: 5px;
}

.message {
  color: #111b21;
  clear: both;
  line-height: 18px;
  font-size: 14px;
  padding: 8px;
  position: relative;
  margin: 0 0 5px 0;
  max-width: 85%;
  word-wrap: break-word;
  box-shadow: 0 1px 0.5px rgba(11,20,26,.13);
}

.sent {
  background: #d9fdd3;
  border-radius: 7.5px 0px 7.5px 7.5px;
  width: fit-content;
  margin-left: auto;
  max-height: 90px;
  overflow-y: auto;
}

.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

.input-container {
  position: relative;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.input-container textarea {
  resize: none;
  overflow: hidden;
  width: 90%;
  border: 0;
  padding: 8px 15px 0 15px;
  background: transparent;
  outline: none;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  min-height: 20px;
  max-height: 200px;
  overflow-y: scroll;
}

.input-container input {
  resize: none;
  overflow: hidden;
  width: calc(100% - 50px);
  border: 0;
  padding: 8px 15px 0 15px;
  background: transparent;
  outline: none;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  min-height: 20px;
}

.input-background{
  background-color: rgba(242, 245, 245, 1);
  min-height: 120px;
  border-radius: 8px;
  padding: 10px 10px 4px 10px;
  margin-top: 10px;
}

</style>
