import axios from 'axios'

const state = {
  zadarmaSettings: {}
}
const getters = {
  zadarmaSettings: (state) => state.zadarmaSettings
}
const actions = {
  async fetchZadarmaSettings ({ commit, rootState }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/zadarma`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    if (res.data.key && res.data.secret) {
      const scriptId = 'zadarmaCaller'

      if (document.getElementById(scriptId) === null) {
        const script = document.createElement('script')
        script.setAttribute('src', `https://www.manycontacts.com/api/zadarma/oid/${res.data.organization_id}/uid/${res.data.user_id}`)
        script.setAttribute('id', scriptId)
        document.head.appendChild(script)
      }
    }

    commit('setZadarmaSettings', res.data)
  },
  async setZadarmaSettings ({ commit, rootState }) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/zadarma`, {
      key: state.zadarmaSettings.key,
      secret: state.zadarmaSettings.secret
    }, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })
  }
}

const mutations = {
  setZadarmaSettings: (state, zadarmaSettings) => (state.zadarmaSettings = zadarmaSettings)
}

export default {
  state,
  getters,
  actions,
  mutations
}
