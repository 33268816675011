<template>
  <div class="level" v-if="data.deleted !== true">
    <div class="line"></div>
    <div class="level-group">
      <div class="link-line"></div>
      <i @click="onDelete" class="far fa-trash-alt"></i>
      <div class="form-group value">
        <label>{{ $t('settings.routing_advanced.fields.value') }}</label>
        <input @change="onChangeInput" min="1" step="1" type="number" name="value" v-model="data.value">
      </div>
      <div class="form-group">
        <label>{{ $t('settings.routing_advanced.fields.type') }}</label>
        <select @change="onChangeInput" name="type" v-model="data.type">
          <option disabled selected value>-</option>
          <option value="user">{{ $t('settings.routing_advanced.assign_to.person') }}</option>
          <option value="team">{{ $t('settings.routing_advanced.assign_to.team') }}</option>
          <option value="text">{{ $t('settings.routing_advanced.assign_to.text') }}</option>
        </select>
      </div>
      <div v-if="data.type==='user'" class="form-group">
        <label>{{ $t('settings.routing_advanced.fields.person') }}</label>
        <select @change="onChangeInput" name="users" v-model="data.assign_id">
          <option disabled selected value>-</option>
          <option v-for="user in organizationUsers" :key="user.id" :value="user.id">{{ user.name }}</option>
        </select>
      </div>
      <div v-if="data.type==='team'" class="form-group">
        <label>{{ $t('settings.routing_advanced.fields.group') }}</label>
        <select @change="onChangeInput" name="users" v-model="data.assign_id">
          <option disabled selected value>-</option>
          <option v-for="team in teams" :key="team.id" :value="team.id">{{ team.name }}</option>
        </select>
      </div>
      <div v-if="data.type==='user' || data.type==='team'" class="form-group">
        <label>{{ $t('settings.routing_advanced.fields.close_message') }}</label>
        <textarea style="height: 40px" @input="onChangeInput" v-model="data.text"></textarea>
      </div>
      <div v-if="data.type ==='text'" class="form-group">
        <label>{{ $t('settings.routing_advanced.fields.message') }}</label>
        <textarea @input="onChangeInput" v-model="data.text" :placeholder="$t('settings.routing_advanced.main.placeholder')"></textarea>
      </div>
      <div v-if="step.children.length === 0" class="form-group" style="width: 100%; display: flex;">
        <Switch v-on:update:checked="onChangeInput" style="width: 55px; margin-top: -3px" v-model:checked="data.endStep" /> {{ $t('settings.routing_advanced.endStep') }}
      </div>
    </div>
    <div class="steps-container" v-if="data.endStep !== true">
      <RoutingStep v-for="item in step.children" :key="item.index" :step="item"/>
      <button @click="addStep" v-if="data.type ==='text'" class="button-rounded-small" :class="{ 'level-empty': step.children.length === 0 }" v-tooltip.right="{ content: $t('settings.routing_advanced.add_level') }"><i class="fas fa-plus"></i></button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import uuid from 'uuid'
import Switch from '@/components/common/Switch'

export default {
  name: 'RoutingStep',
  components: {
    Switch
  },
  props: {
    step: Object
  },
  data () {
    return {
      data: {}
    }
  },
  computed: mapGetters(['organizationUsers', 'teams', 'routingSteps']),
  mounted () {
    this.data = this.step
  },
  methods: {
    onChangeInput () {
      this.routingSteps[this.data.index] = this.data
      this.emitter.emit('routingStepUpdated')
    },
    addStep () {
      this.routingSteps.push({
        id: uuid(),
        index: this.routingSteps.length,
        parent_id: this.step.id,
        value: null,
        type: null,
        assign_id: null,
        text: null,
        level: this.step.level + 1,
        deleted: false
      })
      this.emitter.emit('refreshRoutingSteps')
    },
    onDelete () {
      const clonedStep = Object.assign({}, this.routingSteps[this.step.index])
      clonedStep.deleted = true

      this.routingSteps.splice(this.step.index, 1, clonedStep)
      this.emitter.emit('refreshRoutingSteps')
    }
  }
}
</script>

<style scoped>
.level {
  padding-left: 35px;
  position: relative;
}

.level-group {
  display: flex;
  border-radius: 4px;
  border: 1px solid #EDEFF1;
  margin-top: 10px;
  padding: 10px;
  position: relative;
  flex-wrap: wrap;
  background-color: white;
  z-index: 1000;
}

.level-group textarea {
  min-width: 400px;
}

.level-group .form-group {
  padding: 0 5px;
}

.line {
  width: 1px;
  height: calc(100% + 50px);
  position: absolute;
  top: -15px;
  left: 15px;
  border-left: 1px solid #ced4da;
}

.link-line {
  width: 20px;
  height: calc(100% + 20px);
  position: absolute;
  bottom: 50px;
  border-end-start-radius: 6px;
  border-bottom: 1px solid #ced4da;
  left: -21px;
}

.form-group.value {
  width: 80px;
}

.form-group textarea {
  height: 200px;
  z-index: 10;
}

.form-group select {
  width: 100%;
  height: 35px;
}

.fa-trash-alt {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}
</style>
