<template>
<!--  <div v-if="isWABusiness()" class="wabusiness-restricted">-->
<!--    {{ $t('wabusiness.restricted.title') }}-->
<!--    <br>-->
<!--    {{ $t('wabusiness.restricted.subtitle') }}-->
<!--    <br><br>-->
<!--    <router-link to="/chats" @click="onClickSupport">-->
<!--      <button class="button" >{{ $t('wabusiness.restricted.contact') }}</button>-->
<!--    </router-link>-->
<!--  </div>-->
  <div class="settings-group">
    <h3>Chatbot</h3>
    <div class="form-group">
      <p v-html="$t('settings.routing_advanced.description')"></p>
    </div>
    <br>
    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.routing_advanced.switch.title') }}</h4>
      <p>{{ $t('settings.routing_advanced.switch.description') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'autoRoutingAdvanced')" v-model:checked="organization.autoRoutingAdvanced" />
      <div style="position:relative; margin-top: 50px" v-if="organization">
        <textarea v-model="organization.autoRoutingAdvancedText" @input="onChangeValue" :placeholder="$t('settings.routing_advanced.main.placeholder')"></textarea>
      </div>
      <div class="steps-container">
        <RoutingSteps v-if="organization"/>
      </div>

      <div v-if="organization" class="routing-options">
        <h4>{{ $t('settings.routing_advanced.error.title') }}</h4>
        <p>{{ $t('settings.routing_advanced.error.subtitle') }}</p>
        <textarea class="small-textarea" v-model="organization.autoRoutingAdvancedError" @input="onChangeValue" :placeholder="$t('settings.routing_advanced.error.placeholder')"></textarea>
        <h4>{{ $t('settings.routing_advanced.end.title') }}</h4>
        <p>{{ $t('settings.routing_advanced.end.subtitle') }}</p>
        <textarea v-model="organization.autoRoutingAdvancedEnd" @input="onChangeValue" :placeholder="$t('settings.routing_advanced.end.placeholder')"></textarea>

        <hr>
        <div class="form-group">
          <span class="pro new">NEW</span><h4>{{ $t('settings.routing_advanced.retarded.title') }}</h4>
          <p>{{ $t('settings.routing_advanced.retarded.description') }}</p>
          <Switch v-on:update:checked="onChangeSwitch($event, 'autoRoutingNoReplyEnabled')" v-model:checked="organization.autoRoutingNoReplyEnabled" />
          <textarea v-if="organization.autoRoutingNoReplyEnabled" v-model="organization.autoRoutingNoReplyText" @input="onChangeValue" :placeholder="$t('settings.routing_advanced.retarded.placeholder')"></textarea>
          <label v-if="organization.autoRoutingNoReplyEnabled">{{ $t('settings.routing_advanced.retarded.minutes') }}</label>
          <input @input="onChangeValue" v-if="organization.autoRoutingNoReplyEnabled" type="number" style="width: 50px" min="1" maxlength="60" v-model="this.organization.autoRoutingNoReplyMinutes"/>
        </div>

        <hr>
        <div class="form-group">
          <h4>{{ $t('settings.routing_advanced.onetime.title') }}</h4>
          <p>{{ $t('settings.routing_advanced.onetime.description') }}</p>
          <Switch v-on:update:checked="onChangeSwitch($event, 'autoRoutingAdvancedOnetime')" v-model:checked="organization.autoRoutingAdvancedOnetime" />
        </div>
      </div>
    </div>

<!--    <div v-if="isWABusiness()" class="wabusiness-overlay"></div>-->
  </div>
</template>

<script>
import Switch from '@/components/common/Switch'
import RoutingSteps from '@/components/common/RoutingSteps'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionAutoReply',
  components: {
    Switch,
    RoutingSteps
  },
  data () {
    return {
      timer: null,
      selectedUserOrTeam: null
    }
  },
  computed: mapGetters(['organization', 'organizationUsers', 'teams', 'routingSteps']),
  methods: {
    ...mapActions(['fetchOrganization', 'updateOrganization', 'updateUser', 'fetchOrganizationUsers', 'updateTeam']),
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    onChangeSwitch (value, key) {
      this.organization[key] = value

      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    },
    onChangeValue (event) {
      clearTimeout(this.timer)

      // if (event.currentTarget.value === '' || event.currentTarget.value === '0') {
      //   return
      // }

      this.timer = setTimeout(() => {
        this.updateOrganization(this.organization).then(() => {
          this.$toast.open({
            message: this.$t('form.saved')
          })
        })
      }, 1000)
    }
  },
  created () {
    if (this.organization === null) {
      this.fetchOrganization()
    }
    if (this.organizationUsers === null) {
      this.fetchOrganizationUsers(this.$t)
    }
  }
}
</script>

<style scoped>
h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

.form-group.value {
  width: 50px;
}

.form-group textarea {
  height: 200px;
  z-index: 10;
}

.form-group select {
  width: 100%;
  height: 35px;
}

p {
  margin-bottom: 10px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.routing-options {
  margin-top: 100px;
}

.routing-options p {
  margin: 5px 0;
}

.routing-options textarea {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
}

.member {
  display: inline-block;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  width: fit-content;
  background-color: #EDEFF1;
  margin-bottom: 5px;
}

.add-member {
  width: 200px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}

.pro {
  color: #111b21;
  background-color: #fab1a0;
  border-radius: 3.5px;
  font-size: 7px;
  font-weight: 600;
  padding: 3px;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
  float: left;
  margin-right: 10px;
}

.new {
  background-color: #55efc4;
}
</style>
