<template>
  <li @click="loadConversation">
    <div class="message-avatar"></div>
    <img class="whatsapp-logo" src="../../../assets/whatsapp-logo.svg" />
    <div class="message-name">
      {{ contact.name }}
    </div>
    <div class="message-phonenumber">
      +{{ contact.number }}
    </div>
    <div class="message-time">
      <div v-if="new Date(contact.updatedAt) > this.today">{{ $moment(contact.updatedAt).format('HH:mm') }}</div>
      <div v-else-if="new Date(contact.updatedAt) > this.newYear">{{ $moment(contact.updatedAt).format('DD MMM') }}</div>
      <div v-else>{{ $moment(contact.updatedAt).format('L') }}</div>
    </div>
    <div v-if="contact.tags" class="message-tags">
      <div v-tooltip.top="{ content: tag.name }" :key="tag.id" v-for="tag in contact.tags" class="tag" :style="{ backgroundColor: tag.color }"></div>
    </div>

    <div v-if="counters && counters.grouped[contact.id] && counters.grouped[contact.id] <= 99" class="unread-badge">{{ counters.grouped[contact.id] }}</div>
    <div v-if="counters && counters.grouped[contact.id] && counters.grouped[contact.id] > 99" class="unread-badge">+99</div>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ListItemContact',
  methods: {
    ...mapActions(['fetchConversation', 'clearSearch']),
    loadConversation () {
      if (location.pathname.includes('chats') ||
        location.pathname.includes('conversation')) {
        this.fetchConversation({ contact: this.contact, messageId: null }).then(() => {
          this.emitter.emit('onDiscardFile')
          this.clearSearch()
          this.$emit('clearSearch')
        })
      } else {
        this.emitter.emit('openContactOverlay', this.contact.id)
      }
    }
  },
  computed: mapGetters(['counters']),
  props: {
    contact: Object
  },
  data () {
    const today = new Date()
    today.setHours(0, 0, 0, 0)

    const newYear = new Date(new Date().getFullYear(), 0, 1)
    newYear.setHours(0, 0, 0, 0)

    return {
      today: today,
      newYear: newYear
    }
  }
}
</script>

<style scoped>
li {
  list-style-type: none;
  height: 50px;
  color: #111b21;
  padding: 10px 15px;
  -webkit-box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  box-shadow: inset 0 -1px 0 0 rgba(100, 121, 143, 0.12);
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  position: relative;
  cursor: pointer;
}

li:hover {
  background: rgba(242, 245, 245, 0.8);
  /*box-shadow: inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);*/
  z-index: 2;
}

li .message-avatar {
  position: absolute;
  left: 10px;
  width: 40px;
  height: 40px;
  background-color: #DFE5E7;
  -webkit-mask: url(../../../assets/contact.svg) no-repeat center / contain;
  mask: url(../../../assets/contact.svg) no-repeat center / contain;
}

li .whatsapp-logo {
  width: 18px;
  height: 18px;
  /*z-index: 100;*/
  position: absolute;
  left: 32px;
  top: 30px;
}

li .message-name {
  position: absolute;
  left: 60px;
  width: 140px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 16px;
  text-overflow: ellipsis;
}

li .message-phonenumber {
  position: absolute;
  left: 60px;
  top: 28px;
  width: 140px;
  color: #667781;
}

li .message-time {
  position: absolute;
  right: 15px;
  color: #667781;
}

li .message-tags {
  position: absolute;
  left: 60px;
  top: 52px;
  color: #667781;
  max-width: 180px;
  overflow: hidden;
  max-height: 10px;
}

li .message-tags .tag {
  border-radius: 4px;
  width: 20px;
  height: 10px;
  margin-right: 10px;
  float: left;
}

li .unread-badge {
  position: absolute;
  bottom: 9px;
  right: 15px;
}

.unread-badge {
  box-sizing: border-box;
  width: 21px;
  border-radius: 100%;
  background-color: transparent;
  color: #4285f4;
  border: 1px solid #4285f4;
  font-weight: bold;
  text-align: center;
  float: right;
  font-size: 10px;
  height: 20px;
  line-height: 18px;
}
</style>
