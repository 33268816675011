import axios from 'axios'

const state = {
  tags: []
}
const getters = {
  tags: (state) => state.tags
}
const actions = {
  async fetchTags ({ commit, rootState }, query) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/tags?filter=${query}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setTags', res.data)
  },
  async emptyTags ({ commit, rootState }) {
    commit('setTags', [])
  },
  async createTag ({ commit, rootState }, tag) {
    return axios.post(`${process.env.VUE_APP_SERVERHOST}api/tag`, tag, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then((response) => {
      commit('addTag', response.data)
    }).catch(() => {})
  },
  async updateTag ({ commit, rootState }, tag) {
    return axios.put(`${process.env.VUE_APP_SERVERHOST}api/tag/${tag.id}`, tag, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {}).catch(() => {})
  },
  async deleteTag ({ commit, rootState }, tag) {
    axios.delete(`${process.env.VUE_APP_SERVERHOST}api/tag/${tag.id}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    }).then(() => {
      commit('deleteTag', tag)
    }).catch(() => {})
  }
}
const mutations = {
  setTags: (state, tags) => (state.tags = tags),
  addTag: (state, newTag) => (state.tags.unshift(newTag)),
  deleteTag: (state, newTag) => {
    const index = state.tags.findIndex(resp => resp.id === newTag.id)
    if (index !== -1) {
      state.tags.splice(index, 1)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
