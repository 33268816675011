<template>
  <div class="overlay" @click="closeOverlay"></div>
  <div class="modal">
    <h3>{{ selectedContact.name }}</h3>

    <div @click="close" class="modal-close"><i class="fas fa-times" style="font-size: 16px"></i></div>
    <div class="contact-avatar" :class="{ group: selectedContact.isGroup === 1 }">
      <img v-if="selectedContact.avatar" :src="selectedContact.avatar" onerror="this.style.display='none'"/>
      <img v-if="organization && organization.watype=== 'api' && !selectedContact.avatar && selectedContact.number" :src="getAvatarUrl(selectedContact)" onerror="this.style.display='none'"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'AvatarImage',
  data () {
    return {

    }
  },
  computed: mapGetters(['selectedContact', 'organization']),
  beforeMount () {
  },
  methods: {
    ...mapActions(['toggleAvatarImage']),
    close () {
      this.toggleAvatarImage()
    },
    closeOverlay (event) {
      if (event.target.classList.contains('overlay')) {
        this.close()
      }
    },
    getAvatarUrl (contact) {
      if (!contact.number) {
        return
      }

      let number = contact.number
      number = number.replace('+', '')
      number = number.replaceAll(' ', '')

      return `https://avatar.manycontacts.com/${this.organization.id}/${number}.jpg`
    }
  }
}
</script>

<style>

</style>

<style scoped>
.overlay {
  z-index: 5000003;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 5000004;
  background-color: #ffffff;
  position: fixed;
  top: 20px;
  left: 50%;
  margin-left: -200px;
  width: 400px;
  height: 440px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.mobile .modal {
  width: calc(100% - 40px);
  left: unset;
  margin-left: unset;
}

.modal h3 {
  margin-top: 0;
}

.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

.contact-avatar {
  position: absolute;
  top: 5px;
  left: 10px;
  width: 400px;
  height: 400px;
  background: url(../../assets/individual.png) no-repeat center / contain;
  background-size: 35px;
}

.contact-avatar.group {
  background: url(../../assets/group.png) no-repeat center / contain;
  background-size: 35px;
}

.contact-avatar img {
  width: 400px;
  height: 400px;
  margin-top: 52px;
  margin-left: 10px;
  border-radius: 4px;
}

</style>
