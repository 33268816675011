<template>
  <button @click="addStep" v-if="this.normalized.length === 0" class="button button-no-steps" v-tooltip.right="{ content: $t('settings.routing_advanced.add_level') }"><i class="fas fa-plus"></i> {{ $t('settings.routing_advanced.add_level') }}</button>
  <button @click="addStep" v-if="this.normalized.length !== 0" class="button-rounded-small" v-tooltip.right="{ content: $t('settings.routing_advanced.add_level') }"><i class="fas fa-plus"></i></button>

  <RoutingStep v-for="step in this.normalized" :step="step" :key="step.index"/>
  <div v-if="this.updated" class="form-group button-save">
    <button @click="onClickSave" class="button" type="submit">{{ $t('settings.profile.personal.button') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RoutingStep from './RoutingStep'
import uuid from 'uuid'

export default {
  name: 'RoutingSteps',
  computed: mapGetters(['routingSteps']),
  components: {
    RoutingStep
  },
  data () {
    return {
      normalized: [],
      updated: false
    }
  },
  created () {
    this.fetchRoutingSteps().then(() => {
      this.getNormalizedSteps()
    })

    this.emitter.on('refreshRoutingSteps', () => {
      this.getNormalizedSteps()
      this.updated = true
    })
    this.emitter.on('routingStepUpdated', () => {
      this.updated = true
    })
  },
  unmounted () {
    this.emitter.off('refreshRoutingSteps')
    this.emitter.off('routingStepUpdated')
  },
  methods: {
    ...mapActions(['fetchRoutingSteps', 'saveRoutingSteps']),
    getNormalizedSteps () {
      this.normalized = []

      setTimeout(() => {
        this.routingSteps.sort(function compareNumbers (a, b) {
          return a.level - b.level
        })

        this.routingSteps.forEach((step, index) => {
          if (step.parent_id === null) {
            if (step.deleted !== true) {
              this.normalized.push({
                id: step.id,
                parent_id: null,
                level: 0,
                index: index,
                value: step.value,
                type: step.type,
                assign_id: step.assign_id,
                text: step.text,
                deleted: step.deleted || false,
                endStep: step.endStep,
                children: []
              })
            }
          } else {
            this.findParentElementAndInsert(this.normalized, step, index, 1)
          }
        })
      }, 1)
    },
    findParentElementAndInsert (elements, step, index, level) {
      if (step.deleted === true) {
        return
      }

      for (const parent of elements) {
        if (parent.id === step.parent_id) {
          parent.children.push({
            id: step.id,
            parent_id: parent.id,
            level: level,
            index: index,
            value: step.value,
            type: step.type,
            assign_id: step.assign_id,
            text: step.text,
            deleted: step.deleted || false,
            endStep: step.endStep,
            children: []
          })
        } else {
          this.findParentElementAndInsert(parent.children, step, index, level + 1)
        }
      }
    },
    onClickSave () {
      this.saveRoutingSteps(this.normalized).then(() => {
        this.updated = false
        this.$toast.open({
          message: this.$t('form.saved')
        })
      }).catch(() => {
        this.$toast.open({
          message: this.$t('errors.generic'),
          type: 'error'
        })
      })
    },
    addStep () {
      this.routingSteps.push({
        id: uuid(),
        index: this.routingSteps.length,
        parent_id: null,
        value: null,
        type: null,
        assign_id: null,
        text: null,
        level: 0,
        endStep: false,
        deleted: false
      })
      this.emitter.emit('refreshRoutingSteps')
    }
  }
}
</script>

<style scoped>
button[type=submit] {
  margin-top: 20px;
}

.button-no-steps {
  position: absolute;
  top: 10px;
  width: 130px;
}

.form-group.button-save {
  position: absolute !important;
  bottom: -80px !important;
  right: 0 !important;
}

</style>
