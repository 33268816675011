<template>
  <div :hidden="!this.fileLoaded && !this.voiceRecordLoaded" class="file-preview">
    <div class="contact-avatar"></div>
    <div class="content-editable contact-name">{{ selectedContact.name }}</div>
    <div @click="onDiscardFile" class="preview-close" v-tooltip.left="{ content: $t('messages.input.preview.close_tooltip') }"><i class="fas fa-times"></i></div>
    <div id="preview-content"></div>
    <div v-if="this.isDocument === false && this.voiceRecordLoaded === false" class="caption">
      <div class="input-container">
        <textarea @input="onInputCaption" v-on:keydown.enter="onEnterCaption" rows="1" maxlength="1999" v-model="text" id="message-caption" :placeholder="$t('messages.input.placeholder')"/>
      </div>
    </div>
    <div @click="onSendFile" id="file-send">
      <img src="../../../assets/whatsapp_send_white.png">
      <i class="fas fa-circle-notch fa-spin"></i>
    </div>
  </div>
  <div id="input-controls" class="controls input-controls">
    <QuickActions @changeText="setResponseText" :filter="getQuickActionsFilter()"/>
    <Mentions :users="users" @setMention="setMention" />
    <div class="control-buttons">
      <div v-tooltip.top="{ content: $t('messages.new.addEmoji') }" class="control-button emoji-control">
        <i @click="onClickToggleEmojis" class="far fa-smile"></i>
        <div :class="{ show: this.showEmojis }" class="emoji-picker">
          <VuemojiPicker :isDark="false" @emojiClick="handleEmojiClick" />
        </div>
      </div>
      <div v-if="isLast24hMessage()" class="control-button attach">
        <i @click="this.openAttach = !this.openAttach" class="fas fa-paperclip"></i>
        <div v-if="this.openAttach" class="attach-options">
          <div @click="openFileSelector" class="attach-option attach-contact">
            <div class="half"></div>
            <i class="fas fa-user"></i>
          </div>
          <div @click="openFileSelector('document')" class="attach-option attach-file" v-tooltip.right="{ content: $t('messages.new.document') }">
            <div class="half"></div><i class="fas fa-file"></i>
          </div>
          <div @click="openFileSelector('media')" class="attach-option attach-image" v-tooltip.right="{ content: $t('messages.new.image') }">
            <div class="half"></div>
            <i class="fas fa-image"></i>
          </div>
        </div>
        <input id="file-selector" type="file" @change="onChangeFile($event)" hidden
               accept="image/jpeg,image/png,audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg,video/mp4,video/3gpp,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.xml,.txt">
      </div>
      <div v-if="this.isRegularChat()" v-tooltip.top="{ content: $t('messages.new.addNote') }" @click="onClickToggleNoteMode" class="control-button">
        <i v-if="!this.modeNoteActive" class="far fa-sticky-note"></i>
        <i v-if="this.modeNoteActive" class="fas fa-sticky-note"></i>
      </div>
      <div class="control-button">
        <i v-tooltip.top="{ content: $t('messages.new.addReminder') }" @click="onClickModule('calendar', onClickAddReminder)" v-if="!this.modeReminderActive" class="far fa-calendar-plus"></i>
        <i v-tooltip.top="{ content: $t('messages.new.cancelReminder') }" @click="this.setModeNoteActive(false); this.modeReminderActive = false; this.modeScheduledActive = false; this.scheduledDate = null" v-if="this.modeReminderActive" class="fas fa-calendar-plus"></i>
      </div>
      <div class="control-button" v-if="this.isWhatsAppChat()">
        <div>
          <img style="height: 17px; margin-top: 2px;" v-tooltip.top="{ content: $t('event.scheduled_send') }" @click="onClickModule('scheduled-sent', onClickScheduledSent)" v-if="!this.modeScheduledActive && !this.scheduledDate" src="../../../assets/send-scheduled.svg" />
          <img style="height: 17px; margin-top: 2px;" v-tooltip.top="{ content: $t('event.cancel_scheduled_send') }" @click="onCloseSendScheduledMessage" v-if="this.modeScheduledActive || this.scheduledDate != null" src="../../../assets/send-scheduled_black.svg" />
        </div>
      </div>
      <div class="control-button">
        <i v-tooltip.top="{ content: $t('messages.responses.select') }" @click="onClickOpenQuickActions" class="fas fa-bolt"></i>
      </div>
      <RemindersDateTimePicker v-if="this.modeReminderActive" @cancel="this.modeReminderActive = !this.modeReminderActive" @select="onSelectReminderDate"></RemindersDateTimePicker>
      <ScheduledDateTimePicker v-if="this.modeScheduledActive" @cancel="this.modeScheduledActive = !this.modeScheduledActive" @select="onSelectScheduledDate"></ScheduledDateTimePicker>
    </div>
    <div v-if="!this.scheduledDate" class="input-container" :class="{ note: this.modeNoteActive }">
      <textarea @paste="onPaste" @input="onInput" rows="1" maxlength="1999" v-model="text" v-on:keydown.enter="onEnter" id="message-input" :placeholder="modeNoteActive ? $t('messages.input.placeholder_note') : $t('messages.input.placeholder')"/>
      <div class="control-button-input">
        <img @click="onEnter" v-if="this.text" class="send-button" src="../../../assets/whatsapp_send.png">
        <div v-if="this.isRegularChat()">
          <i v-tooltip.top="{ content: $t('messages.new.voice') }" @click="onClickRecordStart" v-if="isLast24hMessage() && !this.text && !this.recording" class="fas fa-microphone"></i>
          <i v-tooltip.top="{ content: $t('messages.new.voice_stop') }" @click="onClickRecordStop" v-if="isLast24hMessage() && !this.text && this.recording" class="fas fa-microphone recording"></i>
          <div @click="openPolicy()" v-if="!isWABusiness() && !isLast24hMessage() && !this.text" class="whatsapp-policy">+24h</div>
        </div>
      </div>
    </div>
    <div v-if="this.scheduledDate" class="input-container reminder">
      <textarea rows="1" maxlength="1999" v-model="text" @input="onInput" v-on:keydown.enter="onEnterScheduled" id="reminder-input" :placeholder="$t('messages.input.placeholder_reminder')"/>
      <div class="control-button-input">
        <img @click="onEnterScheduled" v-if="this.text" class="send-button" src="../../../assets/whatsapp_send.png">
      </div>
      <div class="reminder-date">{{ $moment(this.scheduledDate).format('DD MMM YYYY HH:mm') }} <i @click="onCloseSendScheduledMessage" class="fas fa-times"></i></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { VuemojiPicker } from 'vuemoji-picker'
import Mentions from './Mentions'
import RemindersDateTimePicker from '../../common/RemindersDateTimePicker'
import ScheduledDateTimePicker from '../../common/ScheduledDateTimePicker'
import QuickActions from '@/components/home/chat/QuickActions'

export default {
  name: 'Input',
  props: {
  },
  data () {
    return {
      text: '',
      timer: null,
      mentionStartPosition: null,
      responseStartPosition: null,
      responseFilter: null,
      users: [],
      recorder: null,
      gumStream: null,
      recording: false,
      openAttach: false,
      fileLoaded: false,
      voiceRecordLoaded: false,
      isDocument: false,
      modeReminderActive: false,
      modeScheduledActive: false,
      scheduledDate: null
    }
  },
  components: {
    QuickActions,
    Mentions,
    VuemojiPicker,
    RemindersDateTimePicker,
    ScheduledDateTimePicker
  },
  mounted () {
    this.emitter.on('onDiscardFile', this.onDiscardFile)
    this.emitter.on('loadFile', (file) => { this.loadPreview(file) })
  },
  unmounted () {
    this.emitter.off('onDiscardFile')
    this.emitter.off('loadFile')
  },
  computed: mapGetters([
    'selectedContact',
    'modeNoteActive',
    'showEmojis',
    'contactMessages',
    'organization',
    'organizationModules',
    'loggedUser'
  ]),
  methods: {
    ...mapActions([
      'sendMessageText',
      'sendMessageMedia',
      'sendNote',
      'toggleNoteMode',
      'setModeNoteActive',
      'toggleEmojis',
      'fetchOrganizationUsersByName',
      'fetchContact',
      'createMessageScheduled'
    ]),
    onCloseSendScheduledMessage () {
      this.setModeNoteActive(false)
      this.modeScheduledActive = false
      this.modeReminderActive = false
      this.modeScheduledActive = false
      this.scheduledDate = null

      setTimeout(() => {
        const messageInputEl = document.getElementById('message-input')
        if (messageInputEl) {
          messageInputEl.focus()
        }
        document.getElementById('message-input').style.height = '20px'

        const inputControlsEl = document.getElementById('input-controls')
        const conversationEl = document.getElementById('conversation')
        if (inputControlsEl && conversationEl) {
          const controlsHeight = inputControlsEl.offsetHeight
          conversationEl.style.height = 'calc(100% - ' + controlsHeight + 'px)'

          const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
          if (conversationContainerEl) {
            conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
            document.getElementById('message-input').focus()
          }
        }
      }, 200)
    },
    getQuickActionsFilter () {
      if (this.scheduledDate) {
        return 'short_response'
      }
      return 'all'
    },
    showModule (key) {
      if (!this.loggedUser) {
        return false
      }

      if (!this.organization) {
        return
      }

      if (!this.modules) {
        return false
      }

      const module = this.modules.find(module => module.key === key)
      if (!module) {
        return false
      }

      if (!module.type) {
        return this.isModuleEnabled(module.key)
      }

      if (module.type && module.type === this.organization.watype) {
        return this.isModuleEnabled(module.key)
      }

      return false
    },
    isModuleEnabled (key) {
      if (this.organization && this.organizationModules && this.organizationModules.includes(key)) {
        return true
      }

      return false
    },
    onClickModule (key, callback, params, redirectUrl) {
      if (this.isModuleEnabled(key)) {
        callback.apply(this, params)
      } else {
        this.emitter.emit('openModulePopup', key, redirectUrl)
      }
    },
    onClickAddReminder () {
      this.setModeNoteActive(false)
      this.modeReminderActive = true
      this.scheduledDate = null
      this.modeScheduledActive = false
    },
    onClickScheduledSent () {
      this.setModeNoteActive(false)
      this.modeScheduledActive = true
      this.modeReminderActive = false
    },
    onPaste (event) {
      const items = (event.clipboardData || event.originalEvent.clipboardData).items
      for (const index in items) {
        const item = items[index]
        if (item.kind === 'file' &&
          this.selectedContact !== null &&
          this.isLast24hMessage()) {
          event.preventDefault()
          event.stopPropagation()

          const blob = item.getAsFile()
          this.loadPreview(blob)
        }
      }
    },
    onClickToggleNoteMode () {
      this.modeReminderActive = false
      this.modeScheduledActive = false
      this.scheduledDate = null

      this.toggleNoteMode()
      setTimeout(() => {
        const messageInputEl = document.getElementById('message-input')
        if (messageInputEl) {
          messageInputEl.focus()
        }
      }, 200)
    },
    onEnter (event) {
      if (event.shiftKey === true) {
        return
      }

      event.preventDefault()

      if (this.text === null || this.text === '') {
        return
      }

      if (this.modeNoteActive === true && this.users.length === 1) {
        this.setMention(this.users[0])
        return
      }

      if (this.modeNoteActive === true && this.users.length > 1) {
        return
      }

      if (this.modeNoteActive === true) {
        this.sendNote({ contact: this.selectedContact, text: this.text })
      } else {
        this.sendMessageText({ contact: this.selectedContact, text: this.text })
      }

      this.text = ''
      this.mentionStartPosition = null
      this.users = []

      document.getElementById('message-input').style.height = '20px'

      const inputControlsEl = document.getElementById('input-controls')
      const conversationEl = document.getElementById('conversation')
      if (inputControlsEl && conversationEl) {
        const controlsHeight = inputControlsEl.offsetHeight
        conversationEl.style.height = 'calc(100% - ' + controlsHeight + 'px)'

        const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
        if (conversationContainerEl) {
          conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
          document.getElementById('message-input').focus()
        }
      }
    },
    onClickToggleEmojis () {
      this.toggleEmojis()
    },
    handleEmojiClick (emoji) {
      this.toggleEmojis()
      this.text += emoji.unicode
      document.getElementById('message-input').focus()
    },
    onInput (e) {
      e.target.style.height = '20px'
      e.target.style.height = (e.target.scrollHeight - 13) + 'px'

      const inputControlsEl = document.getElementById('input-controls')
      const conversationEl = document.getElementById('conversation')
      if (inputControlsEl && conversationEl) {
        const controlsHeight = inputControlsEl.offsetHeight
        conversationEl.style.height = 'calc(100% - ' + controlsHeight + 'px)'

        const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
        if (conversationContainerEl) {
          conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
          e.target.focus()
        }
      }

      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        const caretPosition = e.target.selectionStart
        // TEXT: QUICK ACTIONS
        if (this.modeNoteActive === false) {
          if (!this.text || this.text === '') {
            this.emitter.emit('closeQuickActions')
            this.responseStartPosition = null
            return
          }

          if (this.responseStartPosition && (caretPosition - this.responseStartPosition) < 0) {
            this.emitter.emit('closeQuickActions')
            this.responseStartPosition = null
            return
          }

          if (this.text.substr(caretPosition - 1, 1) === '/' && this.text.length > 1) {
            if (this.text.substr(caretPosition - 2, 1) !== ' ') {
              this.emitter.emit('closeQuickActions')
              this.responseStartPosition = null
              return
            }
          }

          if (this.text.substr(caretPosition - 1, 1) === '/' || this.responseStartPosition !== null) {
            if (this.responseStartPosition === null) {
              this.responseStartPosition = caretPosition
            }

            const filter = this.text.substr(this.responseStartPosition, caretPosition - this.responseStartPosition)

            this.responseFilter = filter
            this.emitter.emit('openQuickActions', filter)
          } else {
            this.responseStartPosition = null
          }
        }

        // NOTE: MENTIONS
        if (this.modeNoteActive === true && this.mentionStartPosition === null && this.text.substr(caretPosition - 1, 1) === '@') {
          this.mentionStartPosition = caretPosition
        }
        if (this.modeNoteActive === true && this.mentionStartPosition !== null) {
          if (this.text.substr(this.mentionStartPosition - 1) === '') {
            this.mentionStartPosition = null
            this.users = []
          } else {
            const userName = this.text.substr(this.mentionStartPosition)
            this.fetchOrganizationUsersByName(userName)
              .then((users) => {
                this.users = users
              }).catch(() => {})
          }
        }
      }, 100)
    },
    onClickOpenQuickActions () {
      this.emitter.emit('openQuickActions')
    },
    onInputCaption (e) {
      e.target.style.height = '20px'
      e.target.style.height = (e.target.scrollHeight - 13) + 'px'
    },
    onEnterCaption (event) {
      if (event.shiftKey === true) {
        return
      }

      const messageCaptionEl = document.getElementById('message-caption')
      if (messageCaptionEl) {
        messageCaptionEl.blur()
      }

      event.preventDefault()
      this.onSendFile()
    },
    setResponseText (text) {
      if (text === null) {
        this.responseStartPosition = null
        return
      }

      let responseFilterLength = 0
      if (this.responseFilter) {
        responseFilterLength = this.responseFilter.length
      }

      this.text = [this.text.slice(0, this.responseStartPosition - 1), text, this.text.slice(this.responseStartPosition + responseFilterLength)].join('')
      this.responseStartPosition = null

      setTimeout(() => {
        const messageInputEl = document.getElementById('message-input')
        if (messageInputEl) {
          messageInputEl.focus()
        }

        const inputControlsEl = document.getElementById('input-controls')
        const conversationEl = document.getElementById('conversation')
        if (inputControlsEl && conversationEl) {
          const controlsHeight = inputControlsEl.offsetHeight
          conversationEl.style.height = 'calc(100% - ' + controlsHeight + 'px)'

          const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
          if (conversationContainerEl) {
            conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
            document.getElementById('message-input').focus()
          }
        }
      }, 200)
    },
    setMention (user) {
      const userName = this.text.substr(this.mentionStartPosition - 1)
      document.getElementById('message-input').focus()
      this.text = this.text.replace(userName, '@' + user.name.replaceAll(' ', '.').toLowerCase() + ' ')
      this.users = []
      this.mentionStartPosition = null
    },
    openFileSelector (type) {
      this.openAttach = false

      const fileSelectorEl = document.getElementById('file-selector')

      if (type === 'document') {
        fileSelectorEl.setAttribute('accept', '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.xml,.txt')
      } else if (type === 'media') {
        fileSelectorEl.setAttribute('accept', 'image/jpeg,image/png,audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg,video/mp4,video/3gpp')
      } else {
        fileSelectorEl.setAttribute('accept', 'image/jpeg,image/png,audio/aac,audio/mp4,audio/amr,audio/mpeg,audio/ogg,video/mp4,video/3gpp,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.csv,.xml,.txt')
      }

      fileSelectorEl.click()
    },
    onChangeFile (event) {
      if (event.target.files.length === 0) {
        return
      }

      const file = event.target.files[0]
      this.loadPreview(file)
    },
    loadPreview (file) {
      if (file.size > 10 * 1024 * 1024) {
        this.$toast.open({
          message: this.$t('messages.input.max_file_size'),
          type: 'error'
        })
      }

      this.isDocument = false
      this.fileLoaded = file
      const previewEl = document.getElementById('preview-content')
      if (file.type.startsWith('image/')) {
        const img = document.createElement('img')
        img.classList.add('obj')
        img.file = file

        previewEl.appendChild(img)

        const reader = new FileReader()
        reader.onload = (function (aImg) { return function (e) { aImg.src = e.target.result } })(img)
        reader.readAsDataURL(file)
      } else if (file.type.startsWith('audio/')) {
        const video = document.createElement('audio')
        video.classList.add('obj')
        video.file = file
        video.controls = true

        previewEl.appendChild(video)

        const reader = new FileReader()
        reader.onload = (function (aVideo) { return function (e) { aVideo.src = e.target.result } })(video)
        reader.readAsDataURL(file)
      } else if (file.type.startsWith('video/')) {
        const video = document.createElement('video')
        video.classList.add('obj')
        video.file = file
        video.controls = true

        previewEl.appendChild(video)

        const reader = new FileReader()
        reader.onload = (function (aVideo) { return function (e) { aVideo.src = e.target.result } })(video)
        reader.readAsDataURL(file)
      } else {
        this.isDocument = true

        const dataDiv = document.createElement('div')
        dataDiv.classList.add('file-preview-document')

        const icon = document.createElement('i')
        icon.classList.add('far')

        if (file.type === 'application/pdf') {
          icon.classList.add('fa-file-pdf')
        } else if (file.type === 'text/csv') {
          icon.classList.add('fa-file-excel')
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
          icon.classList.add('fa-file-word')
        } else if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.sheet') {
          icon.classList.add('fa-file-excel')
        } else if (file.type === 'text/xml') {
          icon.classList.add('fa-file-code')
        } else {
          icon.classList.add('fa-file')
        }

        dataDiv.innerText = file.name
        dataDiv.appendChild(icon)
        previewEl.appendChild(dataDiv)
      }

      setTimeout(() => {
        const messageCaptionEl = document.getElementById('message-caption')
        if (messageCaptionEl) {
          messageCaptionEl.focus()
        }
      }, 500)
    },
    onDiscardFile () {
      this.fileLoaded = false
      this.voiceRecordLoaded = false

      const fileSelectorEl = document.getElementById('file-selector')
      if (fileSelectorEl) {
        fileSelectorEl.value = ''
      }

      const previewEl = document.getElementById('preview-content')
      if (previewEl) {
        previewEl.innerHTML = ''
      }

      const fileSendEl = document.getElementById('file-send')
      if (fileSendEl) {
        fileSendEl.classList.remove('loading')
        fileSendEl.disabled = false
      }
    },
    onSendFile () {
      const fileSendEl = document.getElementById('file-send')
      if (fileSendEl) {
        fileSendEl.classList.add('loading')
        fileSendEl.disabled = true
      }

      if (this.fileLoaded !== false) {
        const formData = new FormData()
        formData.append('file', this.fileLoaded)

        if (this.isDocument === false &&
          this.text !== undefined &&
          this.text !== null &&
          this.text !== '') {
          formData.append('caption', this.text)
        }

        this.sendMessageMedia({ contact: this.selectedContact, formData: formData, voice: false, caption: this.text }).then(() => {
          this.onDiscardFile()

          const messageCaptionEl = document.getElementById('message-caption')
          if (messageCaptionEl) {
            messageCaptionEl.style.height = '20px'
          }

          this.text = ''
        }).catch(() => {
          this.onDiscardFile()
          this.$toast.open({
            message: this.$t('messages.input.max_file_size'),
            type: 'error'
          })
        })

        return
      }

      if (this.voiceRecordLoaded !== false) {
        const formData = new FormData()
        formData.append('file', this.voiceRecordLoaded.blob, this.voiceRecordLoaded.filename)

        this.sendMessageMedia({ contact: this.selectedContact, formData: formData, voice: true, caption: this.text }).then(() => {
          this.onDiscardFile()

          fileSendEl.classList.remove('loading')
          fileSendEl.disabled = false
        })
      }
    },
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    isLast24hMessage () {
      if (this.isWABusiness()) {
        return true
      }

      if (this.selectedContact.isInternalSupport === 1) {
        return true
      }

      if (this.selectedContact.user_id) {
        return true
      }

      if (!this.contactMessages || this.contactMessages.length === 0) {
        return false
      }

      return new Date(this.selectedContact.lastInbound).getTime() > (new Date().getTime() - (1 * 24 * 60 * 60 * 1000))
    },
    isRegularChat () {
      return this.selectedContact.user_id === null
    },
    isWhatsAppChat () {
      return this.selectedContact.user_id === null && this.selectedContact.isInternalSupport === 0
    },
    openPolicy () {
      document.getElementsByClassName('whatsapp-policy')[0].style.display = 'block'
    },
    onClickRecordStart () {
      let extension
      const chunks = []

      if (MediaRecorder.isTypeSupported('audio/webm;codecs=opus')) {
        extension = 'webm'
      } else {
        extension = 'ogg'
      }

      const self = this
      navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then(function (stream) {
        self.gumStream = stream

        const options = {
          mimeType: 'audio/' + extension
        }

        self.recorder = new MediaRecorder(stream, options)

        // when data becomes available add it to our attay of audio data
        self.recorder.ondataavailable = function (e) {
          if (e.data.size > 0) {
            chunks.push(e.data)
          }

          if (self.recorder.state === 'inactive') {
            // convert stream data chunks to a 'webm' audio format as a blob
            const blob = new Blob(chunks, { type: 'audio/' + extension })
            createDownloadLink(blob)
          }
        }

        self.recorder.onerror = function (e) {
          console.log(e.error)
          self.recording = false
        }

        // start recording using 1 second chunks
        // Chrome and Firefox will record one long chunk if you do not specify the chunck length
        self.recorder.start(1000)
        self.recording = true

        // recorder.start();
      }).catch(function (err) {
        console.log(err)
        self.recording = false
      })

      const createDownloadLink = function (blob) {
        self.voiceRecordLoaded = {
          filename: new Date().toISOString() + '.' + extension,
          blob: blob
        }

        self.rec = null
        self.gumStrem = null

        const url = URL.createObjectURL(blob)
        const previewEl = document.getElementById('preview-content')
        const audio = document.createElement('audio')
        audio.classList.add('obj')
        audio.src = url
        audio.controls = true

        previewEl.appendChild(audio)
      }
    },
    onClickRecordStop () {
      this.recording = false
      this.recorder.stop()
      this.gumStream.getAudioTracks()[0].stop()
    },
    onClickToggleReminderMode () {
      this.modeReminderActive = !this.modeReminderActive
      this.scheduledDate = null
    },
    onSelectReminderDate (date) {
      this.modeReminderActive = !this.modeReminderActive
      this.emitter.emit('openNewCalendarEvent', { event: null, date: date })
    },
    onSelectScheduledDate (date) {
      this.modeScheduledActive = !this.modeScheduledActive
      this.scheduledDate = date

      setTimeout(() => {
        const reminderInputEl = document.getElementById('reminder-input')
        if (reminderInputEl) {
          reminderInputEl.focus()
        }
      }, 200)
    },
    onEnterScheduled (event) {
      if (event.shiftKey === true) {
        return
      }

      event.preventDefault()

      if (this.text === null || this.text === '') {
        return
      }

      this.createMessageScheduled({ contactId: this.selectedContact.id, text: this.text, date: this.scheduledDate }).then(() => {
        this.fetchContact(this.selectedContact.id)
      })

      this.text = ''
      this.scheduledDate = null
      this.modeScheduledActive = false

      setTimeout(() => {
        const messageInputEl = document.getElementById('message-input')
        if (messageInputEl) {
          messageInputEl.focus()
          document.getElementById('message-input').style.height = '20px'
        }

        const inputControlsEl = document.getElementById('input-controls')
        const conversationEl = document.getElementById('conversation')
        if (inputControlsEl && conversationEl) {
          const controlsHeight = inputControlsEl.offsetHeight
          conversationEl.style.height = 'calc(100% - ' + controlsHeight + 'px)'

          const conversationContainerEl = document.getElementsByClassName('chat conversation-container')[0]
          if (conversationContainerEl) {
            conversationContainerEl.scrollTop = conversationContainerEl.scrollHeight
            document.getElementById('message-input').focus()
          }
        }
      }, 200)
    },
    isAllowedForCompany () {
      if (this.organization &&
        (
          this.organization.id === '1' // ||
          // this.organization.id === '3' ||
          // this.organization.id === 'c6b58b68-4624-4340-bf62-b3020536782b' // Tau
        )) {
        return true
      }

      return false
    }
  }
}
</script>

<style scoped>
.controls {
  position: absolute;
  bottom: 0;
  min-height: 50px;
  width: 100%;
  background-color: rgba(242, 245, 245, 1);
  border-top: 1px solid #E3E8EA;
}

.mobile .controls {
  position: fixed;
}

.controls .control-buttons {
  float: left;
  position: relative;
  font-size: 16px;
  color: #667781;
  padding: 15px 7px 0 0px;
  margin-left: 5px;
}

.controls .control-buttons .control-button {
  float: left;
  cursor: pointer;
  margin-left: 6px;
  margin-right: 6px;
  position: relative;
}

.input-container {
  position: relative;
  background: white;
  min-height: 36px;
  margin-top: 6px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: 10px;
}

.input-container textarea {
  resize: none;
  overflow: hidden;
  width: 90%;
  border: 0;
  padding: 8px 15px 0 15px;
  background: transparent;
  outline: none;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  min-height: 20px;
  max-height: 200px;
  overflow-y: scroll;
}

.input-container input {
  resize: none;
  overflow: hidden;
  width: calc(100% - 50px);
  border: 0;
  padding: 8px 15px 0 15px;
  background: transparent;
  outline: none;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  min-height: 20px;
}

input#reminder-input {
  width: calc(100% - 50px - 170px);
}

.controls .input-container.note {
  background-color: #ffeaa791
}

.controls .input-container .control-button-input {
  position: absolute;
  top: 8px;
  right: 15px;
  font-size: 16px;
  color: #667781;
  cursor: pointer;
}

.controls .input-container .control-button-input .send-button {
  height: 15px;
  cursor: pointer;
}

.attach-options {
  position: absolute;
  bottom: 50px;
}

.attach-options .attach-option {
  position: relative;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  border-radius: 100%;
  margin-bottom: 10px;
  margin-left: -16px;
  padding-left: 1px;
  color: white;
  cursor: pointer;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.attach-options .attach-option.attach-contact {
  background-color: #0296DC;
  display: none;
}

.attach-options .attach-option.attach-file {
  background-color: #5157AE;
}

.attach-options .attach-option.attach-image {
  background-color: rgb(172, 68, 207);
}

.attach-options .attach-option .half {
  height: 50%;
  background: rgba(255,255,255,0.15);
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.emoji-picker {
  position: absolute;
  top: -400px;
  left: -6px;
  z-index: 4;
}

.emoji-picker:not(.show) {
  display: none;
}

.recording {
  color: red;
}

.whatsapp-policy {
  color: #111b21;
  background-color: #d9fdd3;
  position: absolute;
  right: -10px;
  bottom: -21px;
  z-index: 1000;
  border-radius: 7.5px;
  font-size: 14px;
  font-weight: 600;
  padding: 3px;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
}

.file-preview {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;
  border-right: 1px solid #EDEFF1;
  border-left: 1px solid #EDEFF1;
}

.file-preview .contact-avatar {
  position: absolute;
  top: 5px;
  left: 50px;
  width: 40px;
  height: 40px;
  background-color: #DFE5E7;
  -webkit-mask: url(../../../assets/contact.svg) no-repeat center / contain;
  mask: url(../../../assets/contact.svg) no-repeat center / contain;
}

.file-preview .contact-name {
  position: absolute;
  top: 15px;
  left: 96px;
  max-width: 350px;
  width: 350px;
}

.file-preview .preview-close {
  position: absolute;
  top: 15px;
  left: 20px;
  cursor: pointer;
}

.file-preview #file-send {
  position: absolute;
  bottom: 20px;
  right: 20px;
  height: 60px;
  width: 60px;
  background-color: #4285f4;
  border-radius: 100%;
  cursor: pointer;
  color: white;
  line-height: 60px;
  text-align: center;
  font-size: 20px;
}

.file-preview #file-send.loading img {
  display: none
}

.file-preview #file-send.loading i {
  display: inline-block;
}

.file-preview #file-send img  {
  height: 20px;
  top: 20px;
  position: absolute;
  left: 20px;
}

.file-preview #file-send i {
  display: none;
}

.file-preview #preview-content {
  height: 60%;
  text-align: center;
  margin-top: 60px;
}

.pro {
  color: #111b21;
  background-color: #fab1a0;
  border-radius: 3.5px;
  font-size: 7px;
  font-weight: 600;
  padding: 3px;
  box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
  position: absolute;
  top: -10px;
  left: -5px;
}

.new {
  background-color: #55efc4;
}
</style>

<style>
.file-preview #preview-content img,
.file-preview #preview-content video {
  max-height: 100%;
  max-width: 100%;
}

.file-preview #preview-content audio {
  margin-top: 100px;
}

.file-preview-document {
  max-width: 320px;
  height: 70px;
  border-radius: 4px;
  background-color: #EDEFF1;
  line-height: 70px;
  padding-left: 60px;
  position: relative;
  margin-top: 100px;
  margin-right: auto;
  margin-left: auto;
  text-align: left;
  width: fit-content;
  padding-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-preview-document i {
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 30px;
}

.file-preview .caption {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
}

.file-preview .caption .input-container {
  background-color: #EDEFF1;
  max-width: 400px;
  margin-left: 20px;
  margin-right: 100px;
}

.controls .input-container.reminder {
  background-color: #d9fdd3;
}

.controls .input-container.reminder .reminder-date {
  color: #111b21;
  background-color: #78b66e;
  position: absolute;
  left: 14px;
  top: 10px;
  z-index: 1000;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  padding: 3px;
  width: 160px;
  text-align: left;
  line-height: 19px;
}

.controls .input-container.reminder .reminder-date i {
  position: absolute;
  right: 5px;
  top: 6px;
}

.controls .input-container.reminder textarea {
  margin-top: 40px;
  padding-bottom: 5px;
  min-height: 52px;
  overflow: scroll;
}

.controls .input-container.reminder .control-button-input .send-button {
  height: 15px;
  cursor: pointer;
  position: absolute;
  right: -5px;
  top: 8px;
}

.mobile .emoji-control {
  display: none;
}

.is-today {
  background-color: #f2f5f5;
  border-radius: 100%;
}
</style>
