<template>
  <div v-if="this.openModal" class="overlay">
    <div class="modal">
      <h3>{{ $t('settings.integrations.api_facebook.select_number_popup.title') }}</h3>
      <p>{{ $t('settings.integrations.api_facebook.select_number_popup.subtitle') }}</p>

      <div class="form-group">
        <select v-model="this.selectedNumber">
          <option v-for="number in numbers" :key="number.fbNumberId" :value="number.fbNumberId">{{ number.number }}</option>
        </select>
      </div>

      <button class="button" :disabled="working" @click="onClickSubmit">{{ $t('settings.integrations.api_facebook.select_number_popup.button') }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'MetaCloudSelectNumber',
  computed: mapGetters([]),
  data () {
    return {
      openModal: false,
      numbers: [],
      selectedNumber: null,
      working: false
    }
  },
  methods: {
    ...mapActions(['fetchOrganization', 'fetchBusinessAccount', 'saveFacebookAPINumber']),
    async onClickSubmit () {
      this.working = true

      const selected = this.numbers.find((number) => number.fbNumberId === this.selectedNumber)
      const success = await this.saveFacebookAPINumber(selected)

      if (!success) {
        this.$toast.open({
          message: this.$t('onboarding.error'),
          type: 'error'
        })

        this.working = false
        return
      }

      this.fetchOrganization().then(() => {
        this.fetchBusinessAccount().then((response) => {
          this.businessAccount = response

          this.close()
        })
      })
    },
    close () {
      this.working = false
      this.numbers = []
      this.selectedNumber = null

      this.openModal = false
    },
    open (numbers) {
      if (!numbers || numbers.length === 0) {
        return
      }

      this.numbers = numbers
      this.selectedNumber = numbers[0].fbNumberId
      this.openModal = true
    }
  },
  created () {
    this.emitter.on('openMetaCloudSelectNumber', this.open)
  },
  unmounted () {
    this.emitter.off('openMetaCloudSelectNumber')
  }
}
</script>

<style scoped>

.overlay {
  z-index: 2000000;
  opacity: 1;
  background-color: rgba(32,33,36,0.3);
}

.modal {
  height: 220px
}

select {
  width: 100% !important;
}
</style>
