import axios from 'axios'

const state = {
  searchResults: null
}
const getters = {
  searchResults: (state) => state.searchResults
}

const actions = {
  async querySearch ({ commit, rootState }, { search, tagIds }) {
    const res = await axios.get(`${process.env.VUE_APP_SERVERHOST}api/search?queryString=${search}&tags=${tagIds}`, {
      headers: {
        token: rootState.sessions.userIsAutenticate
      }
    })

    commit('setSearchResults', res.data)
  },
  async clearSearch ({ commit }) {
    document.getElementById('input-search').value = ''
    commit('setSearchResults', null)
  }
}
const mutations = {
  setSearchResults: (state, results) => (state.searchResults = results)
}

export default {
  state,
  getters,
  actions,
  mutations
}
