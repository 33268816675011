<template>
  <div class="overlay" @click="close"></div>
  <div class="modal">
    <h3 v-if="!this.newFunnelData.id">{{ $t('settings.funnels.new.title') }}</h3>
    <h3 v-if="this.newFunnelData.id">{{ $t('settings.funnels.edit.title', { name: this.newFunnelData.name }) }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times"></i></div>

    <div class="form-group">
      <label for="funnel-name">{{ $t('settings.custom_fields.new.name') }}</label>
      <input type="text" id="funnel-name" v-model="this.newFunnelData.name">
    </div>

    <button :disabled="!this.newFunnelData.name.trim()" class="button" @click="onClickSubmit">{{ $t('settings.funnels.new.submit') }}</button>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'NewFunnel',
  computed: mapGetters(['newFunnelData']),
  methods: {
    ...mapActions(['toggleNewFunnel', 'createFunnel', 'updateFunnel', 'emptyNewFunnelData']),
    onClickSubmit () {
      if (this.newFunnelData.id === null) {
        this.createFunnel(this.newFunnelData)
      } else {
        this.updateFunnel(this.newFunnelData)
      }

      this.toggleNewFunnel()
      this.emptyNewFunnelData()
      this.$toast.open({
        message: this.$t('form.saved')
      })
    },
    close () {
      this.toggleNewFunnel()
    }
  }
}
</script>

<style scoped>
.modal {
  height: 320px
}
</style>
