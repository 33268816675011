<template>
  <nav>
    <LogoOrganization />
    <InputSearch :hidden="this.hideSearch"/>
    <div class="new-chat" @click="clickNewMessage" :data-hint="$t('wizard.hints.new_chat')" data-hint-position="bottom-middle">
      <i class="fas fa-plus"></i>
      {{ $t('sidebar.chat_new') }}
    </div>
    <div class="coins-wrapper">
      <router-link v-if="organization && this.organization.features.credits" class="coins" to="/settings/invoicing" :class="{warning: organization.credits <= 250}" v-tooltip.bottom="{ content: $t('conversations.credits') }">
        <div style="margin-right: 10px" v-if="this.organization && this.organization.features.credits">
          <span style="font-size: 14px; margin-right: 7px;">{{ organization.credits }}</span>
          <i class="fas fa-coins"></i>
        </div>

        <router-link v-if="organization && organization.credits <= 250" to="/settings/invoicing">
          <button class="button button-small button-free">Comprar créditos</button>
        </router-link>
      </router-link>
    </div>
    <div class="options">
      <div v-if="this.organization && this.organizationModules" @click="onClickModule('multi-user', openNewInvite)" class="invite" v-tooltip.bottom="{ content: $t('settings.permissions.description') }">
        <i class="fas fa-user-plus"></i>
      </div>
      <div @click="openAlerts()" class="alerts" v-tooltip.bottom="{ content: $t('alerts.title') }">
        <div class="pending" v-if="hasPendingAlerts() > 0">{{ hasPendingAlerts() }}</div>
        <i class="fas fa-bell"></i>
      </div>
      <div hidden v-if="loggedUser" class="switch-group">
        <div class="switch-text" v-if="loggedUser.isActive === true">{{ $t('navbar.status.active') }}</div>
        <div class="switch-text" v-if="loggedUser.isActive === false">{{ $t('navbar.status.ooo') }}</div>
        <Switch v-on:update:checked="onChangeUserStatus" v-model:checked="loggedUser.isActive" />
      </div>
      <router-link @click="openUsers()" class="user_profile" to="" v-tooltip.bottom="{ content: $t('settings.profile.title') }">
        <div v-if="loggedUser && loggedUser.name" class="avatar">{{ loggedUser.name.substring(0,1) }}</div>
      </router-link>
    </div>
    <ListSearch v-if="searchResults" />
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Switch from '../common/Switch'
import InputSearch from './search/InputSearch'
import ListSearch from '@/components/home/search/ListSearch'
import LogoOrganization from '@/components/common/LogoOrganization.vue'

export default {
  name: 'Navbar',
  props: {
    hideSearch: Boolean
  },
  computed: {
    ...mapGetters(['loggedUser', 'alerts', 'organization', 'organizationUsers', 'searchResults', 'organizationModules'])
  },
  methods: {
    ...mapActions(['updateUserStatus', 'toggleNewUserInvite', 'toggleNewMessage']),
    onChangeUserStatus (value) {
      this.updateUserStatus(value)
    },
    openAlerts () {
      this.emitter.emit('openAlerts')
    },
    openUsers () {
      this.emitter.emit('openUsers')
    },
    hasPendingAlerts () {
      let pending = 0
      if (this.alerts.length > 0) {
        for (const alert of this.alerts) {
          if (alert.pending === null) {
            pending++
          }
        }
      }

      return pending
    },
    openNewInvite () {
      if (this.organization.watype === 'business' && this.organization.appsumo !== null && this.organizationUsers.length >= 2) {
        this.emitter.emit('openQRPaywall')
        return
      }

      this.toggleNewUserInvite()
    },
    clickNewMessage () {
      this.toggleNewMessage()
      setTimeout(() => {
        const telInputEl = document.getElementById('tel-input')
        if (telInputEl) {
          telInputEl.focus()
        }
      }, 300)
    },
    isModuleEnabled (key) {
      if (this.organization && this.organizationModules && this.organizationModules.includes(key)) {
        return true
      }

      return false
    },
    onClickModule (key, callback, params, redirectUrl) {
      if (this.isModuleEnabled(key)) {
        callback.apply(this, params)
      } else {
        this.emitter.emit('openModulePopup', key, redirectUrl)
      }
    }
  },
  components: {
    ListSearch,
    InputSearch,
    Switch,
    LogoOrganization
  }
}
</script>

<style scoped>
nav {
  height: 34px;
  position: fixed;
  width: calc(100% - 32px);
  top: 0;
  border-bottom: 1px solid #EDEFF1;
  z-index: 3;
  padding: 16px 16px;
  display: flex;
}

.importantAlert nav, .unpaidInvoiceAlert nav, .mustMigrate nav {
  top: 60px;
}

.expand {
  font-size: 16px;
  padding: 7px 12px;
  margin: 0 17px 0 0;
  border-radius: 100%;
  background-color: rgb(245, 245, 245);
  color: #5299DC;
  cursor: pointer;
  height: 22px;
}

.expand:hover {
  background-color: #4285f45e;
  color: #4285f4;
}

.options {
  display: flex !important;
  flex-basis: auto;
  flex-grow: 1;
  align-items: center;
}

.logo, .isotype {
  height: 35px;
}

.isotype {
  display: none;
}

ul {
  flex-direction: row;
  display: flex;
  padding-left: 0;
  margin: 0;
  list-style: none;
}

li.nav-item {
  display: list-item;
  margin-right: 25px;
  cursor: pointer;
  font-size: 20px;
  color: #667781;
}

.pending {
  color: white;
  background: #e74c3c;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  font-size: 8px;
  text-align: center;
  line-height: 14px;
  font-weight: bold;
  position: absolute;
  top: -5px;
  right: -5px;
}

.alerts, .invite, .campaigns, .coins {
  font-size: 17px;
  position: absolute;
  right: 70px;
  color: #4285f45e;
  cursor: pointer;
  text-decoration: none;
}

.coins-wrapper {
  position: relative;
  display: inline-flex;
  width: 200px;
  margin-top: -3px;
}

.coins {
  display: inline-flex;
  right: unset;
  padding: 0 10px 0 15px;
  border: 0;
  height: 40px;
  border-radius: 6px;
  line-height: 40px;
  width: fit-content;
}

.coins.warning, .coins.warning .button {
  color: #e74c3c;
}

.coins .button {
  background-color: transparent;
  color: #4285f45e;
  padding: 0;
  bottom: unset;
  width: fit-content;
  position: relative;
}

.invite {
  right: 100px;
}

.campaigns {
  right: 140px;
}

.alerts:hover, invite:hover, .campaigns:hover, a.coins:hover, a.coins:hover .warning, .coins:hover .button {
  color: #4285f4;
}

.avatar {
  height: 36px;
  border-radius: 100%;
  cursor: pointer;
  width: 36px;
  border: 1px solid #EDEFF1;
  line-height: 36px;
  text-align: center;
  font-size: 17px;
  background-color: #4285f45e;
  color: #4285f4;
  font-weight: bold;
}

.switch-group {
  position: absolute;
  right: 175px;
  top: 21px;
}

.switch-text {
  float: left;
  text-align: right;
  margin-right: 10px;
  line-height: 25px;
}

Switch {
  margin-right: 20px;
}

.user_profile {
  position: absolute;
  right: 15px;
  text-decoration: none;
  color: unset;
}

.mobile .burger, .mobile .coins, .mobile .alerts, .mobile .new-chat {
  display: none;
}

.mobile .logo {
  display: none;
}

.mobile .isotype {
  display: block;
}

.mobile .options {
  display: none !important;
}

.buycredits {
  position: absolute;
  top: 14px;
  right: 245px;
}

.buycredits button {
  position: relative;
  bottom: unset;
  width: fit-content;
  padding: 0 10px;
}

.new-chat {
  border-radius: 24px;
  color: #5299DC;
  height: 36px;
  margin: 0px 0 0px 20px;
  box-shadow: 0 0px 0px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  font-size: 16px;
  align-items: center;
  background-color: #fff;
  background-image: none;
  display: inline-flex;
  font-weight: 500;
  overflow: hidden;
  padding: 0 16px;
  cursor: pointer;
}

.new-chat:hover {
  box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.30), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
  background-color: #fafafb;
}

.new-chat i {
  margin-right: 10px;
}

</style>
