<template>
  <div class="overlay"></div>
  <div class="modal">
    <div>
      <h3>{{ $t('unpaid.title') }}</h3>
      <p v-html="$t('unpaid.subtitle')"></p>

      <div class="form-group">
        <button @click="openAddMethod" class="button" type="submit">{{ $t('settings.invoices.addmethod') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Unpaid',
  computed: mapGetters(['organization']),
  methods: {
    ...mapActions(['fetchAddMethodLink']),
    openAddMethod () {
      this.fetchAddMethodLink().then((url) => {
        location.href = url
      })
    }
  }
}
</script>

<style scoped>
.overlay {
  z-index: 1000000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 1000001;
  background-color: #ffffff;
  position: fixed;
  top: 100px;
  left: 50%;
  margin-left: -350px;
  width: 700px;
  height: 415px;
  padding: 20px;

  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.modal h3 {
  margin-top: 0;
}

.form-group {
  position: relative;
}

input {
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 16px;
  letter-spacing: .2px;
}

.button {
  background-color: #4285f4;
  color: #ffffff;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
  border: 0;
  width: 260px;
  position: absolute;
  right: 6px;
  top: 7px;
}

.button:hover {
  background-color: rgba(66, 133, 244, 0.80);
}

.button:disabled {
  background-color: rgba(66, 133, 244, 0.30);
  cursor: not-allowed;
}

.embedded-signup {
  text-decoration: none;
  font-size: 14px;
}

.embedded-signup .button {
  position: relative;
  margin: 0 auto;
}

.photo {
  height: 85px;
  border-radius: 100%;
  float: left;
  margin-right: 20px;
  margin-top: 0px;
}
</style>
