<template>
  <div class="menu-collapse" @click="openRegister($event)">
    <aside style="overflow: hidden">
      <div class="sidebar-scrollable">
        <ul class="main-menu">
          <router-link class="home" :to="{ name: 'Home' }" v-tooltip.right="{ content: $t('sidebar.home') }">
            <li class="active home">
              <div class="sidebar-icon home"><i class="fas home fa-home"></i></div>
            </li>
          </router-link>
          <div class="module" v-tooltip.right="{ content: $t('sidebar.pipelines') }">
            <li>
              <div class="sidebar-icon"><i class="fas fa-filter"></i></div>
              <div class="unread-badge unread-badge-important unread-badge-small"></div>
              <div class="unread-badge">9</div>
            </li>
          </div>
          <div class="chats-unassigned" v-tooltip.right="{ content: $t('sidebar.chats') }">
            <li>
              <div class="sidebar-icon"><i class="far fa-comment"></i></div>
              <div class="unread-badge unread-badge-important unread-badge-small"></div>
              <div class="unread-badge">11</div>
            </li>
          </div>
          <div class="module" v-tooltip.right="{ content: $t('sidebar.your_reminders') }">
            <li>
              <div class="sidebar-icon"><i class="far fa-calendar"></i></div>
              <div class="unread-badge">4</div>
            </li>
          </div>
          <div class="module" v-tooltip.right="{ content: $t('sidebar.your_mentions') }">
            <li>
              <div class="sidebar-icon"><i class="fas fa-at"></i></div>
              <div class="unread-badge">2</div>
            </li>
          </div>
          <div v-tooltip.right="{ content: $t('sidebar.internal') }">
            <li>
              <div class="sidebar-icon"><i class="fas fa-th-large"></i></div>
            </li>
          </div>
        </ul>

        <ul class="top-separate">
          <div class="module">
            <li>
              <div class="sidebar-icon"><i class="far fa-envelope"></i></div>
              <div class="unread-badge unread-badge-important">+99</div>
            </li>
          </div>
          <div class="module" v-tooltip.right="{ content: $t('campaign.campaigns') }">
            <li>
              <div class="sidebar-icon"><i class="fas fa-bullhorn"></i></div>
            </li>
          </div>
        </ul>
        <ul style="padding-top:20px !important; border-top: 1px solid #EDEFF1;" class="top-separate">
          <div v-tooltip.right="{ content: $t('sidebar.stats') }">
            <li>
              <div class="sidebar-icon"><i class="fas fa-chart-line"></i></div>
            </li>
          </div>
          <div v-tooltip.right="{ content: $t('settings.title') }">
            <li>
              <div class="sidebar-icon"><i class="fas fa-cog"></i></div>
            </li>
          </div>
          <div id="support-chat" v-tooltip.right="{ content: $t('sidebar.support') }">
            <li class="help">
              <div class="sidebar-icon"><span class="emoji">Ⓜ️</span></div>
            </li>
          </div>
          <a class="menu-help" :href="$t('help.links.main')" target="_blank" v-tooltip.right="{ content: $t('help.title') }">
            <li class="help">
              <div class="sidebar-icon"><i class="help fas fa-question"></i></div>
            </li>
          </a>
        </ul>
      </div>
    </aside>
  </div>
</template>

<script>
export default {
  name: 'SidebarFake',
  methods: {
    openRegister (event) {
      if (event.target.classList.contains('home')) {
        return
      }

      this.$router.push('/register')
    }
  }
}
</script>

<style scoped>
  .mobile aside {
    display: none;
  }

  aside {
    top: 66px;
    margin-left: -200px;
    left: 200px;
    width: 200px;
    position: fixed;
    height: calc(100% - 66px);
    overflow-y: auto;
    text-align: left;
    border-right: 1px solid #EDEFF1;
  }

  .importantAlert aside, .unpaidInvoiceAlert aside, .mustMigrate aside {
    top: 126px;
    height: calc(100% - 126px);
  }

  .menu-collapse aside {
    top: 66px;
    margin-left: -70px;
    left: 70px;
    width: 70px;
  }

  .importantAlert .menu-collapse aside, .unpaidInvoiceAlert .menu-collapse aside, .mustMigrate .menu-collapse aside {
    top: 126px;
  }

  .menu-collapse ul {
    padding-left: 10px;
    font-size: 20px;
  }

  .menu-collapse ul li {
    padding: 5px 0 0 4px;
    min-height: 43px;
    border-radius: 100%;
    width: 44px;
    text-align: center;
    margin-bottom: 2px;
    line-height: 40px;
  }

  .menu-collapse ul li.team {
    padding-left: 4px;
  }

  .menu-collapse ul li.team .sidebar-icon {
    display: block;
  }

  .menu-collapse ul li span {

  }

  .menu-collapse ul li .sidebar-icon {
    width: 40px;
    float: left;
  }

  aside .new-chat {
    -webkit-border-radius: 24px;
    border-radius: 24px;
    color: #5299DC;
    height: 48px;
    margin: 16px 0 26px 20px;
    box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.30), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
    font-size: 16px;
    -webkit-align-items: center;
    align-items: center;
    background-color: #fff;
    background-image: none;
    display: inline-flex;
    font-weight: 500;
    overflow: hidden;
    padding: 0 24px 0 24px;
    cursor: pointer;
  }
  aside .new-chat:hover {
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.30), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
    background-color: #fafafb;
  }

  aside .new-chat i {
    margin-right: 10px;
  }

  .menu-collapse aside .new-chat {
    width: 34px;
    min-width: 34px;
    margin-left: 0px;
    padding: 0 0 0 14px;
    font-size: 23px;
    margin-left: 11px;
  }

  .menu-collapse aside .new-chat i{
    margin-right: 30px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0px 10px 0 0;
  }

  ul.top-separate {
    margin-top: 25px;
  }

  ul li {
    position: relative;
    padding: 5px 0px 5px 25px;
    -webkit-border-top-right-radius: 50px;
    -webkit-border-bottom-right-radius: 50px;
    -moz-border-radius-topright: 50px;
    -moz-border-radius-bottomright: 50px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .module.disabled {
    opacity: 0.08;
  }

  ul li .sidebar-icon {
    width: 25px;
    float: left;
  }

  ul li .unread-badge {
    margin-right: 8px;
  }

  ul li:hover {
    background-color: #F1F3F4;
  }

  ul li.active {
    background-color: #4285f45e;
    color: #4285f4;
    font-weight: bold;
  }

  ul li.team {
    padding-left: 50px;
  }

  ul li.team .sidebar-icon {
    display: none;
  }

  ul li.team .team-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 105px;
  }

  ul a {
    color: #111b21;
    text-decoration: none;
  }

  .unread-badge {
    box-sizing: border-box;
    min-width: 21px;
    border-radius: 20px;
    background-color: transparent;
    color: #4285f4;
    border: 1px solid #4285f4;
    background-color: #ffffff;
    font-weight: bold;
    text-align: center;
    float: right;
    font-size: 10px;
    height: 20px;
    line-height: 18px;
    padding: 0 1px;
    position: absolute;
    left: 23px;
    top: 8px;
  }

  .unread-badge.unread-badge-important {
    background-color: #4285f4;
    color: #ffffff;
  }

  .unread-badge.unread-badge-small {
    height: 10px;
    width: 10px;
    padding: 0;
    left: 10px;
    right: unset;
    top: 14px;
    min-width: 10px;
  }

  .menu-collapse .group-settings {
    bottom: 15px;
  }

  .group-settings {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
  }

  .group-settings a {
    color: #111b21;
  }

  .menu-collapse .group-settings {
    height: 37px;
  }

  .button-settings {
    position: absolute;
    left: 25px;
    top: 15px;
    cursor: pointer;
  }

  .menu-collapse .button-settings {
    font-size: 20px;
    bottom: -22px;
    top: unset;
  }

  .emoji {
    font-family: apple color emoji, segoe ui emoji, noto color emoji, android emoji, emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol;
  }

  .pro {
    color: #111b21;
    background-color: #fab1a0;
    border-radius: 3.5px;
    font-size: 7px;
    font-weight: 600;
    padding: 3px;
    box-shadow: 0 1px 0.5px rgb(11 20 26 / 13%);
    position: absolute;
    bottom: 8px;
    left: 28px;
    height: 7px;
    line-height: 7px;
  }

  .new {
    background-color: #55efc4;
  }

  .sidebar-scrollable {
    height: calc(100vh - 67px);
    overflow: scroll;
    padding-top: 10px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .sidebar-scrollable::-webkit-scrollbar {
    display: none;
  }
</style>
