<template>
  <div :class="{
    mobile: windowWidth <= 600,
    importantAlert: (organization && organization.watype !== 'api' && organization.payAlert === true),
    unpaidInvoiceAlert: organization && organization.unpaidUrl !== null,
    hidePipelines: organization && organizationModules && !organizationModules.includes('pipeline')
  }">
    <vue3-progress-bar></vue3-progress-bar>
    <router-view/>
    <Onboarding v-if="showPopup()"/>
    <VideoOverlay />
    <ImageOverlay />
    <ShowPromote />
    <DownloadExcel />
    <Alerts />
    <Users />
    <Unpaid v-if="organization && organization.watype === 'api' && organization.unpaid === true" />
    <ContactAttachmentsList v-if="attachmentListOpen"/>
    <ContactEventsList v-if="eventsListOpen"/>
    <QRPaywall />
    <NewCampaign />
    <ContactOverlay />
    <NewTag v-if="this.newTagOpen"/>
    <NewUserInvite v-if="this.newUserInviteOpen"/>
    <NewTeam v-if="this.newTeamOpen"/>
    <ExportConversation />
    <SendTemplate />
    <Module />
    <ModuleIntegration />
    <QRConnecting />
    <AvatarImage v-if="this.openAvatarImage"/>
    <NewConchiUser />
    <TrainingAgentAI />
    <RegisterModal />

    <!-- FIXME: move to a component -->
    <div class="qr-importing" v-if="showImportingQr">
      {{ $t('pages.register.qr_sync.title') }}
      <sub>{{ $t('pages.register.qr_sync.subtitle') }}</sub>
    </div>
    <!-- end FIXME -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import 'v-tooltip/dist/v-tooltip.css'
import axios from 'axios'

import { useProgress } from '@marcoschulte/vue3-progress'
import Onboarding from '@/components/modals/Onboarding'
import VideoOverlay from '@/components/modals/VideoOverlay'
import ShowPromote from '@/components/modals/ShowPromote'
import DownloadExcel from '@/components/modals/DownloadExcel'
import Unpaid from '@/components/modals/Unpaid'
import ContactAttachmentsList from '@/components/modals/ContactAttachmentsList'

import { useRoute } from 'vue-router'
import ContactEventsList from '@/components/modals/ContactEventsList'
import Alerts from '@/components/modals/Alerts'
import NewUserInvite from '@/components/modals/NewUserInvite'
import QRPaywall from '@/components/modals/QRPaywall'
import NewCampaign from '@/components/modals/NewCampaign'
import ContactOverlay from '@/components/modals/ContactOverlay'
import ImageOverlay from '@/components/modals/ImageOverlay'
import NewTag from '@/components/modals/NewTag'
import NewTeam from '@/components/modals/NewTeam'
import ExportConversation from '@/components/modals/ExportConversation'
import SendTemplate from '@/components/modals/SendTemplate'

import Users from '@/components/modals/Users'
import Module from '@/components/modals/Module'
import ModuleIntegration from '@/components/modals/ModuleIntegration'
import QRConnecting from '@/components/modals/QRConnecting'

import AvatarImage from '@/components/modals/AvatarImage'
import NewConchiUser from '@/components/modals/NewConchiUser'
import TrainingAgentAI from '@/components/modals/TrainingAgentAI'
import RegisterModal from '@/components/modals/RegisterModal'

const progresses = []

axios.interceptors.request.use(config => {
  progresses.push(useProgress().start())
  return config
})

axios.interceptors.response.use(resp => {
  progresses.pop().finish()
  return resp
}, (error) => {
  progresses.pop().finish()
  return Promise.reject(error)
})

export default {
  name: 'App',
  components: {
    TrainingAgentAI,
    NewConchiUser,
    AvatarImage,
    RegisterModal,
    QRConnecting,
    Module,
    ModuleIntegration,
    SendTemplate,
    ExportConversation,
    ImageOverlay,
    ContactOverlay,
    NewCampaign,
    QRPaywall,
    Alerts,
    ContactEventsList,
    ShowPromote,
    VideoOverlay,
    Onboarding,
    Unpaid,
    ContactAttachmentsList,
    DownloadExcel,
    NewUserInvite,
    NewTag,
    NewTeam,
    Users
  },
  computed: mapGetters([
    'userIsAutenticate',
    'loggedUser',
    'organization',
    'allContacts',
    'selectedContact',
    'counters',
    'filter',
    'attachmentListOpen',
    'eventsListOpen',
    'newUserInviteOpen',
    'organizationUsers',
    'customFields',
    'newTagOpen',
    'newTeamOpen',
    'organizationModules',
    'openAvatarImage'
  ]),
  data () {
    return {
      windowWidth: window.innerWidth,
      showImportingQr: false
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })

    this.getOrganization()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    ...mapActions([
      'fetchLoggedUser',
      'fetchCounters',
      'fetchTeams',
      'fetchOrganizationUsers',
      'fetchOrganization',
      'syncTemplates',
      'fetchContact',
      'fetchContacts',
      'fetchConversation',
      'fetchCustomFields',
      'fetchAlerts',
      'fetchFunnels',
      'toggleNewUserInvite',
      'fetchZadarmaSettings',
      'fetchModules',
      'fetchOrganizationModules'
    ]),
    onResize () {
      this.windowWidth = window.innerWidth
    },
    getOrganization () {
      const accessToken = sessionStorage.getItem('accessTokensSession')
      if (!accessToken) {
        return
      }

      setTimeout(() => {
        this.fetchOrganization().then(() => {
          this.getOrganization()
        })
      }, 120000)
    },
    performSyncTemplates () {
      this.syncTemplates().then((organizationUpd) => {
        if (organizationUpd.hasApiKey && !organizationUpd.emptyTemplate) {
          setTimeout(this.performSyncTemplates, 60000)
        }
      })
    },
    openContact (self, data) {
      const contactId = data.contactId
      const messageId = data.message.id

      if (undefined !== contactId) {
        const index = self.allContacts.findIndex(contact => contact.id === contactId)
        if (index !== -1) {
          self.fetchConversation({ contact: self.allContacts[index], messageId: messageId })
        } else {
          self.fetchContact(contactId).then(contact => {
            self.fetchConversation({ contact: contact, messageId: messageId })
          })
        }
      }
    },
    showPopup () {
      const route = useRoute()
      const path = route.name

      if (path === 'Register' ||
        path === 'SSO' ||
        path === 'Login') {
        return false
      }

      if (this.organization && this.organization.watype === 'api' && this.organization.hasApiKey && !this.organization.emptyTemplate) {
        return true
      }

      return this.organization && this.organization.watype === null && (!this.organization.hasApiKey || !this.organization.emptyTemplate)
    },
    mustOpenInvitePopup () {
      if (this.organization.watype === null) {
        return
      }

      if (this.organizationUsers.length > 1) {
        return
      }

      if (localStorage.openInviteCount && localStorage.openInviteCount > 2) {
        return
      }

      const twoDaysAgo = new Date()
      twoDaysAgo.setDate(twoDaysAgo.getDate() - 2)
      if (localStorage.openInviteCount && localStorage.openInviteCount <= 2 && new Date(localStorage.openInviteLastDate) > twoDaysAgo) {
        return
      }

      this.toggleNewUserInvite()

      let openCount = localStorage.openInviteCount || 0
      openCount++
      localStorage.openInviteCount = openCount
      localStorage.openInviteLastDate = new Date()
    }
  },
  created () {
    const accessToken = sessionStorage.getItem('accessTokensSession')
    if (!accessToken) {
      return
    }

    if (this.$route.name === 'AdminMigrateWABA') {
      return
    }

    const self = this
    setTimeout(() => {
      if (self.$route.query.ft) {
        self.showImportingQr = true
        setTimeout(() => {
          self.showImportingQr = false
        }, 120000)
      }
    }, 2000)

    this.fetchLoggedUser().then(() => {
      if (this.filter === 1 && !this.loggedUser.canSeeAllChats) {
        location.href = '/chats/my-chats'
        return
      }
      this.fetchOrganization().then((organizationUpd) => {
        if (organizationUpd.hasApiKey && !organizationUpd.emptyTemplate) {
          this.performSyncTemplates()
        }

        this.fetchModules({}).then(() => {
          this.fetchOrganizationModules()
        })

        this.fetchOrganizationUsers(this.$t).then(() => {
          this.fetchTeams().then(() => {
            this.fetchCustomFields().then(() => {
              this.fetchFunnels().then(() => {
                this.fetchZadarmaSettings()
              })
            })
          })

          // setTimeout(() => {
          //   this.mustOpenInvitePopup()
          // }, 3000)
        })
      })
    })

    setTimeout(() => {
      this.fetchAlerts()
    }, 3000)

    if (!location.pathname.includes('stripe')) {
      setTimeout(() => {
        if (this.organization &&
          this.organization.watype === 'business' &&
          this.organization.unpaid === true) {
          this.emitter.emit('openQRPaywall')
        }
      }, 1000)
    }
  },
  sockets: {
    connect: function () {
      console.log('connected')

      const accessToken = sessionStorage.getItem('accessTokensSession')
      if (!accessToken) {

      }

      // this.fetchCounters()
    },
    newMessage: function (data) {
      const self = this

      if (self.selectedContact && self.selectedContact.id === data.contactId) {
        // Do nothing
      } else if (data.message.type !== 'received') {
        // Do nothing
      } else {
        if (data.lastUserId !== undefined) {
          if (self.loggedUser && self.loggedUser.notifyMine === true) {
            if (self.loggedUser && data.lastUserId === self.loggedUser.id) {
              this.$notification.show(
                this.$t('settings.notifications.browser.new_message'),
                {
                  body: data.message.text,
                  icon: '/favicon.ico'
                },
                {
                  onclick: () => {
                    self.openContact(self, data)
                  }
                }
              )
            }
          }
        }
      }
    },
    updateCounters (data) {
      if (this.counters) {
        if (this.counters.unassigned < data.unassigned) {
          if (this.loggedUser && this.loggedUser.notifyUnassigned === true) {
            if (!this.selectedContact || this.selectedContact.last_user_id !== null) {
              this.$notification.show(
                this.$t('settings.notifications.browser.new_unassigned'),
                {
                  body: this.$t('settings.notifications.browser.new_unassigned_total', { total: data.unassigned }),
                  icon: '/favicon.ico'
                },
                {
                  onclick: () => {
                    window.location.href = process.env.VUE_APP_APP_HOST + '/chats'
                  }
                }
              )
            }
          }
        } else if (this.loggedUser) {
          if (data.mentions[this.loggedUser.id] &&
            (!this.counters.mentions[this.loggedUser.id] || this.counters.mentions[this.loggedUser.id] < data.mentions[this.loggedUser.id])) {
            if (this.loggedUser.notifyMentions === true) {
              this.$notification.show(
                this.$t('settings.notifications.browser.new_mentions'),
                {
                  body: this.$t('settings.notifications.browser.new_mentions_total', { total: data.mentions[this.loggedUser.id] }),
                  icon: '/favicon.ico'
                },
                {
                  onclick: () => {
                    window.location.href = process.env.VUE_APP_APP_HOST + '/mentions'
                  }
                }
              )
            }
          }

          if (data.reminders[this.loggedUser.id] &&
            (!this.counters.reminders[this.loggedUser.id] || this.counters.reminders[this.loggedUser.id] < data.reminders[this.loggedUser.id])) {
            if (this.loggedUser.notifyReminders === true) {
              this.$notification.show(
                this.$t('settings.notifications.browser.new_reminders'),
                {
                  body: this.$t('settings.notifications.browser.new_reminders_total', { total: data.reminders[this.loggedUser.id] }),
                  icon: '/favicon.ico'
                },
                {
                  onclick: () => {
                    window.location.href = process.env.VUE_APP_APP_HOST + '/reminders'
                  }
                }
              )
            }
          }
        }
      }
    }
  }
}
</script>

<style>
@import "~@marcoschulte/vue3-progress/dist/index.css";

.vue3-progress-bar {
  background-color: #4285f4 !important;
}

body {
  margin: 0;
  overflow: hidden;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: .2px;
  color: #111b21;
}

#app {

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.v-popper--theme-tooltip {
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', Roboto, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: .2px;
}

li {
  list-style: none;
}

.timer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000000000000;
  padding: 100px 0;
  background-color: #f0f2f5;
  text-align: center;
}

.timer .progress-bar {
  height: 4px;
  border-radius: 4px;
  width: 350px;
  margin: 0 auto;
  background-color: #d2d6da;
}

.timer .progress-bar .progress-bar-progress{
  background-color: #42cba5;
  width: 3px;
  height: 100%;
}

.timer h4 {
  font-size: 15px;
  font-weight: 500;
}

.timer h5 {
  font-size: 11px;
  font-weight: 400;
}

.timer img {
  width: 350px;
}

.timer span {
  font-size: 20px;
  font-weight: 600;
}

.v-popper__popper {
  z-index: 10000000000;
}

.v-toast{
  z-index: 5000000 !important;
}

.qr-importing {
  position: absolute;
  left: 500px;
  display: grid;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #55efc4;
  padding: 10px;
  top: 0;
  -webkit-border-bottom-right-radius: 8px;
  -webkit-border-bottom-left-radius: 8px;
  -moz-border-radius-bottomright: 8px;
  -moz-border-radius-bottomleft: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

strong {
  font-weight: 600;
}
</style>
