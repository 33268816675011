<template>
  <div v-if="modalOpen" class="overlay" @click="closeOverlay"></div>
  <div v-if="modalOpen" class="modal">
    <h3>{{ $t('settings.agent_ai.edit.feedback.title') }}</h3>
    <div @click="close" class="modal-close"><i class="fas fa-times" style="font-size: 16px"></i></div>
    <div v-if="!feedbacks">
      <div class="form-group">
        <label>{{ $t('settings.agent_ai.edit.feedback.without_feedback') }}</label>
      </div>
    </div>
    <div class="scrolleable" v-if="feedbacks">
      <div class="form-group">
        <label>{{ $t('settings.agent_ai.edit.feedback.description') }}</label>
        <div class="table-container">
          <table>
            <thead>
            <th class="table-limit-column">{{ $t('settings.agent_ai.edit.feedback.response_message_text') }}</th>
            <th class="table-limit-column">{{ $t('settings.agent_ai.edit.feedback.feedback_text') }}</th>
            <th></th>
            </thead>
            <tbody>
            <tr :key="feedback.id" v-for="feedback in feedbacks">
              <td class="table-limit-column" v-tooltip.top="feedback.response_message_text">{{ feedback.response_message_text }}</td>
              <td class="table-limit-column" v-tooltip.top="feedback.feedback_text">{{ feedback.feedback_text }}</td>
              <td>
                <i @click="deleteFeedback(feedback)" class="far fa-trash-alt"></i>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'editConchiUserFeedback',
  data () {
    return {
      modalOpen: false,
      feedbacks: null
    }
  },
  methods: {
    ...mapActions([
      'deleteAgentAIFeedback',
      'fetchAgentAIFeedback'
    ]),
    close () {
      this.modalOpen = false
    },
    open (userId) {
      if (!userId) {
        return
      }

      this.fetchAgentAIFeedback(userId).then((feedbacks) => {
        this.feedbacks = feedbacks
      })

      this.modalOpen = true
    },
    closeOverlay (event) {
      if (event.target.classList.contains('overlay')) {
        this.close()
      }
    },
    deleteFeedback (feedback) {
      if (confirm(this.$t('settings.agent_ai.edit.feedback.delete.confirm', { name: feedback.feedback_text }))) {
        this.deleteAgentAIFeedback(feedback).then(() => {
          this.fetchAgentAIFeedback(feedback.user_id).then((feedbacks) => {
            this.feedbacks = feedbacks
          })
        })
      }
    }
  },
  created () {
    this.emitter.on('openEditConchiUserFeedback', this.open)
    this.emitter.on('closeEditConchiUserFeedback', this.close)
  },
  unmounted () {
    this.emitter.off('openEditConchiUserFeedback')
    this.emitter.off('closeEditConchiUserFeedback')
  }
}
</script>

<style scoped>
.overlay {
  z-index: 5000005;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111b21;
  opacity: 0.3;
}

.modal {
  z-index: 5000006;
  background-color: #ffffff;
  position: fixed;
  left: 50%;
  margin-left: -500px;
  width: 1000px;
  height: calc(100% - 100px);
  max-height: 840px;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .13);
}

.mobile .modal {
  width: calc(100% - 40px);
  left: unset;
  margin-left: unset;
}

.modal h3 {
  margin-top: 0;
}

.modal .modal-close {
  position: absolute;
  top: 20px;
  right: 20px;
  line-height: 20px;
  width: 20px;
  height: 20px;
  text-align: center;
  cursor: pointer;
}

table {
  width: 100%;
  margin-top: 50px;
  border-collapse: collapse;
}

thead {
  height: 40px;
  text-align: left;
  border-bottom: 1px solid #EDEFF1;
}

tr {
  height: 50px;
  border-bottom: 1px solid #EDEFF1;
}

tr i {
  margin-left: 10px;
  cursor: pointer;
}

.table-container {
  /*height: 400px; !* Ajustar la altura según sea necesario *!*/
  /*overflow-y: auto;*/
}

.table-limit-column{
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scrolleable{
  overflow-y: auto;
  height: calc(100% - 140px);
  max-height: 840px;
}
</style>
