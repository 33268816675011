<template>
  <div class="settings-group">
    <h3>{{ $t('settings.routing.title') }}</h3>
    <br>
    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.routing.random.title') }}</h4>
      <p>{{ $t('settings.routing.description') }} {{ $t('settings.routing.random.description') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'autoRoutingRandom')" v-model:checked="organization.autoRoutingRandom" />
    </div>
    <div v-if="organization && organization.autoRoutingRandom === true && organizationUsers && teams" >
      <div style="margin-bottom: 10px">{{ $t('settings.routing.random.users') }}:</div>
      <div :key="member.id" v-for="member in getUsersAndTeamsAutoRoutingActive()" class="member" :member="member">
        <span v-if="member.type === 'user'"><i class="fas fa-user" aria-hidden="true"></i></span>
        <span v-if="member.type === 'team'"><i class="fas fa-users" aria-hidden="true"></i></span>
        {{ member.name }}
        <i @click="disableUserOrTeamAutoRouting(member)" v-tooltip.top="{ content: $t('settings.routing.random.remove') }" class="fas fa-times"></i>
      </div>
      <v-select v-if="getUsersAndTeamsAutoRouting()" @option:selected="enableUserOrTeamAutoRouting()" class="add-member" v-model="this.selectedUserOrTeam" :placeholder="$t('settings.routing.random.add')" :options="getUsersAndTeamsAutoRouting()" label="name" :selectable="(option) => option.autoRouting === false">
        <template #option="option">
                    <span>
                      <i v-if="option.type==='user'" class="fas fa-user" aria-hidden="true"></i>
                      <i v-if="option.type==='team'" class="fas fa-users" aria-hidden="true"></i>
                      {{ option.name }}
                    </span>
        </template>
      </v-select>
    </div>
    <hr>
    <div v-if="organization" class="form-group">
      <h4>{{ $t('settings.routing.offline.title') }}</h4>
      <p>{{ $t('settings.routing.offline.description') }}</p>
      <Switch v-on:update:checked="onChangeSwitch($event, 'autoRoutingOffline')" v-model:checked="organization.autoRoutingOffline" />
    </div>
  </div>
</template>

<script>
import Switch from '@/components/common/Switch'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'SectionRouting',
  components: {
    Switch
  },
  data () {
    return {
      timer: null,
      selectedUserOrTeam: null
    }
  },
  computed: mapGetters(['organization', 'organizationUsers', 'teams']),
  methods: {
    ...mapActions(['fetchOrganization', 'updateOrganization', 'updateUser', 'fetchOrganizationUsers', 'updateTeam']),
    getUsersAndTeamsAutoRouting () {
      const usersAndTeams = []

      for (const user of this.organizationUsers) {
        user.type = 'user'
        usersAndTeams.push(user)
      }

      for (const team of this.teams) {
        team.type = 'team'
        usersAndTeams.push(team)
      }

      return usersAndTeams
    },
    getUsersAndTeamsAutoRoutingActive () {
      const usersAndTeams = []

      for (const user of this.organizationUsers) {
        if (user.autoRouting === true) {
          user.type = 'user'
          usersAndTeams.push(user)
        }
      }

      for (const team of this.teams) {
        if (team.autoRouting === true) {
          team.type = 'team'
          usersAndTeams.push(team)
        }
      }

      return usersAndTeams
    },
    disableUserOrTeamAutoRouting (member) {
      member.autoRouting = false
      if (member.type === 'user') {
        this.updateUser(member)
      } else {
        this.updateTeam(member)
      }
    },
    enableUserOrTeamAutoRouting () {
      this.selectedUserOrTeam.autoRouting = true

      if (this.selectedUserOrTeam.type === 'user') {
        this.updateUser(this.selectedUserOrTeam).then(() => {
          this.selectedUserOrTeam = null
        })
      } else {
        this.updateTeam(this.selectedUserOrTeam).then(() => {
          this.selectedUserOrTeam = null
        })
      }
    },
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    onChangeSwitch (value, key) {
      this.organization[key] = value

      this.updateOrganization(this.organization).then(() => {
        this.$toast.open({
          message: this.$t('form.saved')
        })
      })
    }
  },
  created () {
    if (this.organization === null) {
      this.fetchOrganization()
    }
    if (this.organizationUsers === null) {
      this.fetchOrganizationUsers(this.$t)
    }
  }
}
</script>

<style scoped>
h4, p {
  width: calc(100% - 70px);
  margin: 0;
  padding-right: 20px;
}

.form-group {
  position: relative;
  margin-bottom: 30px;
}

.form-group.value {
  width: 50px;
}

.form-group textarea {
  height: 200px;
  z-index: 10;
}

.form-group select {
  width: 100%;
  height: 35px;
}

p {
  margin-bottom: 10px;
}

.container {
  position: absolute;
  right: 40px;
  top: 5px;
}

.routing-options {
  margin-top: 100px;
}

.routing-options p {
  margin: 5px 0;
}

.routing-options textarea {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
}

.member {
  display: inline-block;
  border-radius: 4px;
  padding: 5px;
  margin-right: 10px;
  width: fit-content;
  background-color: #EDEFF1;
  margin-bottom: 5px;
}

.add-member {
  width: 200px;
  display: inline-block;
  border-radius: 4px;
  cursor: pointer;
}
</style>
