<template>
  <div v-if="organization && selectedContact" class="content-panel">
    <Header />
    <Messages />
    <Input v-if="organization && organization.watype !== null && hasCredits()"/>
    <InputNoCredits v-if="!hasCredits()"/>
    <NewCalendarEvent />
  </div>
  <div v-if="!selectedContact" class="content-panel content-panel-empty">
    {{ $t('messages.list_empty') }}
  </div>
</template>

<script>
import Header from './Header'
import Messages from './Messages'
import Input from './Input'
import NewCalendarEvent from '@/components/modals/NewCalendarEvent'

import { mapGetters } from 'vuex'
import InputNoCredits from '@/components/home/chat/InputNoCredits'

export default {
  name: 'Panel',
  computed: mapGetters(['selectedContact', 'organization', 'contactMessages']),
  components: {
    InputNoCredits,
    Header,
    Messages,
    Input,
    NewCalendarEvent
  },
  methods: {
    hasCredits () {
      if (this.isLast24hMessage()) {
        return true
      }

      if (this.organization.watype !== 'api') {
        return true
      }

      if (!this.organization.features.credits) {
        return true
      }

      if (this.organization.watype === 'api' && this.organization.credits <= 0) {
        return false
      }

      return true
    },
    isWABusiness () {
      if (this.organization && this.organization.watype === 'business') {
        return true
      }

      return false
    },
    isLast24hMessage () {
      if (this.isWABusiness()) {
        return true
      }

      if (this.selectedContact.isInternalSupport === 1) {
        return true
      }

      if (this.selectedContact.user_id) {
        return true
      }

      if (!this.contactMessages || this.contactMessages.length === 0) {
        return false
      }

      return new Date(this.selectedContact.lastInbound).getTime() > (new Date().getTime() - (1 * 24 * 60 * 60 * 1000))
    }
  }
}
</script>

<style scoped>
.content-panel {
  height: calc(100vh - 67px);
  width: calc(100% - 401px);
  margin-left: 330px;
  padding-left: 0;
  padding-right: 0;
  position: relative;
}

.importantAlert .content-panel, .unpaidInvoiceAlert .content-panel, .mustMigrate .content-panel {
  height: calc(100vh - 127px);
}

.content-panel-empty {
  text-align: center;
  padding-top: 100px;
}

.mobile .content-panel {
  width: 100%;
  margin-left: 0;
  z-index: 100;
  position: fixed;
  top: 0;
  height: 100%;
}

.mobile .content-panel-empty {
  display: none;
}
</style>
